import React from "react";
import { UUID } from "@screencloud/uuid";
import {
  getMediaPicker,
  getMediaPickerSkipProps,
} from "src/domains/mediaPicker/mediaPicker";
import { Playlist } from "src/types.g";
import { isOrgWidePath } from "src/utils/orgWideFeature";
import { useAllPlaylistInCurrentOrg } from "./useAllPlaylistInCurrentOrg";
import { useAllPlaylistInCurrentSpace } from "./useAllPlaylistInCurrentSpace";

export interface UsePlaylistPicker {
  playlists: Playlist[];
  isLoading: boolean;
  loading: boolean;
  renderFetchMoreButton: React.ReactNode;
}

export const usePlaylistPicker = (
  excludeShare: boolean = false,
  spaceId?: UUID,
): UsePlaylistPicker => {
  const isOrgWide = isOrgWidePath();
  const { skipOrgWideQuery, skipSpaceWideQuery } = getMediaPickerSkipProps({
    isOrgWide,
  });
  return getMediaPicker({
    isOrgWide,
    orgWideMediaPicker: useAllPlaylistInCurrentOrg({
      skip: skipOrgWideQuery,
      excludeShare,
    }),
    spaceWideMediaPicker: useAllPlaylistInCurrentSpace({
      skip: skipSpaceWideQuery,
      excludeShare,
      spaceId,
    }),
  }) as unknown as UsePlaylistPicker; // TODO vite migration: remove this as unknown as
};
