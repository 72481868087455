import { UUID } from "@screencloud/uuid";
import { ChannelListContentItem, RefType } from "src/constants/constants";
import { generateImgixThumbnail } from "src/helpers/mediaHelper";
import { shouldDisableItem } from "../../../helpers/channelHelper";
import { isExpired } from "../../../helpers/scheduleableHelper";
import {
  AppInstance,
  Channel,
  ChannelByIdQuery,
  File,
  Link,
  Playlist,
  Site,
} from "../../../types.g";
import { Content, Zone } from "./types";
import { cloneDeep, isEmpty } from "lodash";

const MAIN_ZONE = "zone1";
const HIDDEN_ZONE = "hidden";
type ItemRefCollection = { [key: string]: boolean };
interface ValidateChannelResult {
  shouldDisplayCleanupButton: boolean;
  doesNonMainZoneContainInvalidItems: boolean;
}

interface ValidateContentResult {
  doesChannelContainExpiredItem: boolean;
  doesChannelContainOutdatedItem: boolean;
  doesNonMainZoneContainInvalidItems: boolean;
}

const isNotHiddenZone = (zoneName: string) => zoneName !== HIDDEN_ZONE;

export const validateChannelByIdQuery = (
  channelByIdQuery: ChannelByIdQuery | undefined,
): ValidateChannelResult => {
  if (channelByIdQuery?.channelById?.draft) {
    const expiredFileRefs: ItemRefCollection = {};
    const expiredAppRefs: ItemRefCollection = {};
    const { filesByChannelId, appInstancesByChannelId } =
      channelByIdQuery.channelById.draft;

    filesByChannelId.nodes.forEach((node) => {
      if (isExpired(node)) {
        expiredFileRefs[node.id] = true;
      }
    });
    appInstancesByChannelId.nodes.forEach((node) => {
      if (isExpired(node)) {
        expiredAppRefs[node.id] = true;
      }
    });

    const content = channelByIdQuery?.channelById?.draft.content;
    const {
      doesChannelContainExpiredItem,
      doesChannelContainOutdatedItem,
      doesNonMainZoneContainInvalidItems,
    } = validateContent(content, expiredFileRefs, expiredAppRefs);

    return {
      shouldDisplayCleanupButton:
        doesChannelContainExpiredItem || doesChannelContainOutdatedItem,
      doesNonMainZoneContainInvalidItems,
    };
  }
  return {
    shouldDisplayCleanupButton: false,
    doesNonMainZoneContainInvalidItems: false,
  };
};

export const validateContent = (
  content,
  expiredFileRefs: ItemRefCollection,
  expiredAppRefs: ItemRefCollection,
): ValidateContentResult => {
  let doesChannelContainExpiredItem = false;
  let doesChannelContainOutdatedItem = false;
  let doesNonMainZoneContainInvalidItems = false;
  const doesListContainExpiredContent = (list) => {
    if (!list) {
      return false;
    }
    return (
      (list.content?._ref.type === "file" &&
        expiredFileRefs[list.content?._ref.id]) ||
      (list.content?._ref.type === "app" &&
        expiredAppRefs[list.content?._ref.id])
    );
  };

  Object.keys(content.zones)
    .filter(isNotHiddenZone)
    .forEach((zone) => {
      const doesZoneContainExpiredContent = content.zones[zone].list.some(
        doesListContainExpiredContent,
      );
      const doesZoneContainOutdatedContent = content.zones[zone].list.some(
        (list) => shouldDisableItem(list?.rules),
      );
      doesChannelContainExpiredItem =
        doesChannelContainExpiredItem || doesZoneContainExpiredContent;
      doesChannelContainOutdatedItem =
        doesChannelContainOutdatedItem || doesZoneContainOutdatedContent;
      doesNonMainZoneContainInvalidItems =
        doesNonMainZoneContainInvalidItems ||
        ((doesZoneContainExpiredContent || doesZoneContainOutdatedContent) &&
          zone !== MAIN_ZONE);
    });

  return {
    doesChannelContainExpiredItem,
    doesChannelContainOutdatedItem,
    doesNonMainZoneContainInvalidItems,
  };
};

export const doesContainOutDatedChannelItems = (
  channelByIdQuery: ChannelByIdQuery | undefined,
): boolean => {
  if (channelByIdQuery?.channelById?.draft) {
    const content = channelByIdQuery?.channelById?.draft.content;
    return Object.keys(content.zones).some((zoneKey: string) =>
      content.zones[zoneKey].list.some((list) => shouldDisableItem(list.rules)),
    );
  }
  return false;
};

const getValidContentData = (
  content: Content,
  validItemRefs: ItemRefCollection,
): Content => {
  const zones: Zone = {};
  Object.keys(content.zones).forEach((zoneKey: string) => {
    const list = content.zones[zoneKey].list.filter(
      (zoneContent) =>
        !!validItemRefs[zoneContent.content._ref.id] &&
        !shouldDisableItem(zoneContent.rules),
    );
    const props = content.zones[zoneKey].props;
    zones[zoneKey] = {
      list,
      props,
    };
  });

  return {
    zones,
    props: content.props,
  };
};

export const getValidItemRef = (draftChannel: Channel): ItemRefCollection => {
  const validItemRefs: ItemRefCollection = {};
  const nodes = [
    ...(draftChannel.appInstancesByChannelId?.nodes ?? []),
    ...(draftChannel.filesByChannelId?.nodes ?? []),
    ...(draftChannel.linksByChannelId?.nodes ?? []),
    ...(draftChannel.sitesByChannelId?.nodes ?? []),
  ];
  nodes.forEach((node) => {
    if (
      node.__typename === "Link" ||
      node.__typename === "Site" ||
      !isExpired(node) ||
      !node.expireAt
    ) {
      validItemRefs[node.id] = true;
    }
  });
  return validItemRefs;
};

const getValidPlaylistRef = (
  nodes: Pick<Playlist, "id">[],
): ItemRefCollection => {
  const validItemRefs: ItemRefCollection = {};
  nodes.forEach((node) => {
    validItemRefs[node.id] = true;
  });
  return validItemRefs;
};

export const getValidChannelContent = (
  channelByIdQuery: ChannelByIdQuery | undefined,
): any => {
  if (
    channelByIdQuery?.channelById?.draft &&
    channelByIdQuery?.channelById?.draft.content
  ) {
    const draft = channelByIdQuery.channelById.draft as Channel;
    const content = { ...draft.content };
    const validItemRefs = getValidItemRef(draft);
    const validPlaylistRefs = getValidPlaylistRef(
      draft.playlistsByChannelId.nodes,
    );
    return {
      content: getValidContentData(content, {
        ...validItemRefs,
        ...validPlaylistRefs,
      }),
    };
  }
  return channelByIdQuery;
};

export const getContentByRefId = ({
  list,
  filesByChannelId,
  playlistsByChannelId,
  linksByChannelId,
  sitesByChannelId,
  appsByChannelId,
}: {
  list: ChannelListContentItem;
  filesByChannelId: File[];
  playlistsByChannelId: Playlist[];
  linksByChannelId: Link[];
  sitesByChannelId: Site[];
  appsByChannelId: AppInstance[];
}):
  | (File & { isReadOnly?: boolean })
  | (Playlist & { isReadOnly?: boolean })
  | (Link & { isReadOnly?: boolean })
  | (Site & { isReadOnly?: boolean })
  | (AppInstance & { isReadOnly?: boolean })
  | undefined => {
  const contentRef = list.content._ref;
  if (!contentRef) {
    return;
  }
  switch (contentRef.type) {
    case RefType.FILE:
      const fileByRefId = filesByChannelId.find(
        (file) => file.id === contentRef.id,
      );
      return { ...fileByRefId, isReadOnly: list.is_read_only } as File & {
        isReadOnly?: boolean;
      };
    case RefType.PLAYLIST:
      const playlistByRefId = playlistsByChannelId.find(
        (playlist) => playlist.id === contentRef.id,
      );
      return {
        ...playlistByRefId,
        isReadOnly: list.is_read_only,
      } as Playlist & {
        isReadOnly?: boolean;
      };
    case RefType.LINK:
      const linkByRefId = linksByChannelId.find(
        (link) => link.id === contentRef.id,
      );
      return { ...linkByRefId, isReadOnly: list.is_read_only } as Link & {
        isReadOnly?: boolean;
      };
    case RefType.SITE:
      const siteByRefId = sitesByChannelId.find(
        (site) => site.id === contentRef.id,
      );
      return { ...siteByRefId, isReadOnly: list.is_read_only } as Site & {
        isReadOnly?: boolean;
      };
    case RefType.APP:
      const appByRefId = appsByChannelId.find(
        (app) => app.id === contentRef.id,
      );
      return { ...appByRefId, isReadOnly: list.is_read_only } as AppInstance & {
        isReadOnly?: boolean;
      };
    default:
      return;
  }
};

export const getChannelItemByRefType = ({
  listId,
  duration,
  refType,
  list,
  contentByRefId,
  secureMediaPolicy,
}: {
  listId: UUID;
  duration: number;
  refType: RefType;
  list: ChannelListContentItem;
  contentByRefId: File | Playlist | Link | Site | AppInstance | undefined;
  secureMediaPolicy?: string;
}) => {
  if (!contentByRefId) {
    return;
  }
  switch (refType) {
    case RefType.APP_INSTANCE:
      return;
    case RefType.FILE:
      if (contentByRefId.__typename === "File") {
        return {
          data: contentByRefId,
          id: contentByRefId.id,
          list_id: listId,
          schedule: list.rules,
          type: RefType.FILE,
          duration,
        };
      }
      return;
    case RefType.PLAYLIST:
      if (contentByRefId.__typename === "Playlist") {
        return {
          data: contentByRefId,
          duration,
          id: contentByRefId.id,
          list_id: listId,
          schedule: list.rules,
          thumbnail: "",
          playback: list.content.props?.playback ?? {},
          type: RefType.PLAYLIST,
        };
      }
      return;
    case RefType.LINK:
      if (contentByRefId.__typename === "Link") {
        return {
          data: contentByRefId,
          duration,
          id: contentByRefId.id,
          list_id: listId,
          schedule: list.rules,
          type: RefType.LINK,
        };
      }
      return;
    case RefType.SITE:
      if (contentByRefId.__typename === "Site") {
        return {
          data: contentByRefId,
          duration,
          id: contentByRefId.id,
          siteType: contentByRefId.type,
          list_id: listId,
          schedule: list.rules,
          thumbnail: contentByRefId.fileByThumbnailId
            ? generateImgixThumbnail(
                contentByRefId.fileByThumbnailId,
                secureMediaPolicy,
              )
            : "",
          title: contentByRefId.name,
          type: RefType.SITE,
        };
      }
      return;
    case RefType.APP:
      if (contentByRefId.__typename === "AppInstance") {
        return {
          data: contentByRefId,
          duration,
          id: contentByRefId.id,
          list_id: listId,
          schedule: list.rules,
          type: RefType.APP,
        };
      }
      return;
    default:
      return;
  }
};

export const removeInvalidContent = (zones: Zone): Zone => {
  const _zones = cloneDeep(zones);
  if (isEmpty(_zones)) {
    return _zones;
  }
  for (const zone in _zones) {
    if (_zones[zone]?.list && _zones[zone]?.list.length > 0) {
      _zones[zone].list = _zones[zone].list.filter(
        (item) => item !== null && !isEmpty(item),
      );
    }
  }
  return _zones;
};

export const formatContentTemplateInput = (zones: Zone): Zone => {
  const result = {};
  for (const key in zones) {
    if (zones[key].list) {
      result[key] = {
        list: [
          { is_parent_content: true },
          ...zones[key].list.filter((item) => !item.is_read_only),
        ],
        props: zones[key].props,
      };
    }
  }
  return result;
};
