import { WithApolloClient } from "@apollo/client/react/hoc";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "../../../utils/compose";

import { ssm } from "../../../state/session/ssm";
import {
  ChannelByIdQueryHookResult,
  LayoutBySpaceIdQueryQuery,
  PublishDraftChannelMutationFn,
  UpdateChannelCoverMutationFn,
  UpdateChannelMutationFn,
  UpdateChannelThemeMutationFn,
  useChannelByIdQuery,
  useLayoutsBySpaceIdQuery,
  usePublishDraftChannelMutation,
  useUpdateChannelCoverMutation,
  useUpdateChannelMutation,
  useUpdateChannelThemeMutation,
} from "../../../types.g";
import { removeInvalidContent } from "./helper";
import { set, cloneDeep } from "lodash";
export interface ApolloProps
  extends RouteComponentProps<any>,
    WithApolloClient<{}> {
  updateChannelCover: UpdateChannelCoverMutationFn;
  updateChannel: UpdateChannelMutationFn;
  publishDraftChannel: PublishDraftChannelMutationFn;
  updateChannelTheme: UpdateChannelThemeMutationFn;
  layoutsData: LayoutBySpaceIdQueryQuery;
  data: ChannelByIdQueryHookResult;
}

export interface ChannelDetailQueryInputProps {}

export const withData = compose(
  withRouter,
  (Component) => (props: ApolloProps) => {
    const layoutsData = useLayoutsBySpaceIdQuery({
      variables: {
        spaceId: ssm.current.settings.spaceId,
      },
      fetchPolicy: "cache-and-network",
    });

    const channelById = useChannelByIdQuery({
      fetchPolicy: "cache-and-network",
      variables: {
        id: props.match.params.channelId,
      },
      skip:
        !props.match || !props.match.params || !props.match.params.channelId,
    });
    const [updateChannel] = useUpdateChannelMutation();
    const [updateChannelCover] = useUpdateChannelCoverMutation();
    const [publishDraftChannel] = usePublishDraftChannelMutation();
    const [updateChannelTheme] = useUpdateChannelThemeMutation();

    const channelData = cloneDeep(channelById?.data?.channelById);
    if (channelData?.content && channelData?.draft?.content) {
      set(
        channelData,
        "content.zones",
        removeInvalidContent(channelById?.data?.channelById?.content.zones),
      );

      set(
        channelData,
        "draft.content.zones",
        removeInvalidContent(
          channelById?.data?.channelById?.draft?.content.zones,
        ),
      );
    }

    return (
      <Component
        {...props}
        data={{ ...channelById, data: { channelById: channelData } }}
        layoutsData={layoutsData.data}
        updateChannel={updateChannel}
        updateChannelCover={updateChannelCover}
        publishDraftChannel={publishDraftChannel}
        updateChannelTheme={updateChannelTheme}
      />
    );
  },
);
