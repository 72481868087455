import {
  SyncEvent,
  MessageType,
  VersionString,
  CheckIncognitoAccessEvent,
  OpenSettingsPageEvent,
  Message,
} from "@screencloud/secure-sites-extension-types";
import { appConfig } from "../../../appConfig";

const SECURE_SITES_INCOGNITO_WARNING_KEY = "ignoreSecureSitesIncognitoWarning";

let isTestExtension = false;

export const isChromeBrowser = (): boolean => {
  return (
    !!window.chrome && (!!window.chrome.runtime || !!window.chrome.webstore)
  );
};

/**
 * @description Retrieves the chrome extension ID based on whether or not the test extension is in use
 * @returns The ID of the extension in use.
 */
export const getChromeExtensionId = (): string => {
  if (isTestExtension && appConfig.secureSiteTestChromeExtensionId) {
    return appConfig.secureSiteTestChromeExtensionId;
  }

  return appConfig.secureSiteChromeExtensionId;
};

/**
 * @description Determines whether or not the chrome extension is installed
 * Will also check for the Test extension
 * @returns true if extension is installed; false otherwise
 */
export const isChromeExtensionInstalled = (): Promise<boolean> => {
  return new Promise((resolve) => {
    if (!isChromeBrowser()) {
      return resolve(false);
    }

    isTestChromeExtensionInstalled().then((isInstalled) => {
      if (isInstalled) {
        isTestExtension = true;
        console.log("Using Test Extension");
        return resolve(true);
      }

      chrome.runtime.sendMessage(
        appConfig.secureSiteChromeExtensionId,
        new SyncEvent(),
        (response: Message) => {
          if (chrome.runtime.lastError) {
            console.log(
              "extension runtime error:",
              chrome.runtime.lastError.message,
            );
            return resolve(false);
          }

          if (
            response.type !== MessageType.SyncAck &&
            response.version !== VersionString
          ) {
            console.log(
              "extension runtime error:",
              "invalid response to sync command",
              response,
            );
            return resolve(false);
          }

          resolve(true);
        },
      );
    });
  });
};

/**
 * @description Determines whether or not the test chrome extension is installed
 * @returns true if test extension is installed; false otherwise
 */
const isTestChromeExtensionInstalled = (): Promise<boolean> => {
  return new Promise((resolve) => {
    if (!appConfig.secureSiteTestChromeExtensionId) {
      console.debug("No Test Extension Id Set");
      return resolve(false);
    }

    chrome.runtime.sendMessage(
      appConfig.secureSiteTestChromeExtensionId,
      new SyncEvent(),
      (response: Message) => {
        if (chrome.runtime.lastError) {
          console.debug(
            "Test extension runtime error:",
            chrome.runtime.lastError.message,
          );
          return resolve(false);
        }

        if (
          response.type !== MessageType.SyncAck &&
          response.version !== VersionString
        ) {
          console.debug(
            "Test extension runtime error:",
            "invalid response to sync command",
            response,
          );
          return resolve(false);
        }

        resolve(true);
      },
    );
  });
};

export const hasIncognitoModeAccess = (): Promise<boolean> => {
  return new Promise((resolve) => {
    if (!isChromeBrowser()) {
      return resolve(false);
    }

    chrome.runtime.sendMessage(
      getChromeExtensionId(),
      new CheckIncognitoAccessEvent(),
      (response: Message) => {
        if (chrome.runtime.lastError) {
          console.log(
            "extension runtime error:",
            chrome.runtime.lastError.message,
          );
          return resolve(false);
        }

        if (
          response.type !== MessageType.CheckIncognitoAccessAck &&
          response.version !== VersionString
        ) {
          console.log(
            "extension runtime error:",
            "invalid response to check incognito access command",
            response,
          );
          return resolve(false);
        }

        resolve(response.payload.hasIncognitoAccess);
      },
    );
  });
};

export const openExtensionSettings = () => {
  chrome.runtime.sendMessage(
    getChromeExtensionId(),
    new OpenSettingsPageEvent(),
  );
};

export const getRemindSetting = () =>
  localStorage.getItem(SECURE_SITES_INCOGNITO_WARNING_KEY) === "true";
export const setRemindSetting = () => {
  localStorage.setItem(SECURE_SITES_INCOGNITO_WARNING_KEY, "true");
};

export const clearRemindSetting = () => {
  localStorage.removeItem(SECURE_SITES_INCOGNITO_WARNING_KEY);
};
