import { cloneDeep } from "lodash";
import { MediaOrderBy, Typenames } from "../../../constants/constants";
import { useAppContext } from "../../../hooks/useAppContext";
import {
  CreateFolderMutationVariables,
  Folder,
  FolderByIdDocument,
  FolderByIdQuery,
  FolderByIdQueryVariables,
  Scalars,
  useCreateFolderMutation,
} from "../../../types.g";

export interface CreateNewFolderVariables {
  name: string;
  parentFolderId: Scalars["UUID"];
  currentFolders?: Folder[];
}

function getNewFolderName(name: string, folderList, count: number = 0) {
  let tempName = name.trim();
  if (count > 0) {
    tempName = `${name.trim()} (${count})`;
  }

  const found = folderList.find((item) => item.name === tempName.trim());

  if (found) {
    // if folder name is exist, generate new folder name with number. ex: gallery (1), gallery (2)
    const nextCount = count + 1;
    return getNewFolderName(name, folderList, nextCount); // recursive
  }

  return tempName;
}

export function useCreateNewFolderMutation() {
  const context = useAppContext();
  const [createFolder] = useCreateFolderMutation();

  const createNewFolder = ({
    name,
    parentFolderId,
    currentFolders,
  }: CreateNewFolderVariables) => {
    const folderName = getNewFolderName(name, currentFolders ?? []);
    const spaceId = context.currentSpace?.id;
    const orgId = context.currentOrg?.id;
    const variables: CreateFolderMutationVariables = {
      input: {
        name: folderName,
        spaceId,
        parentId: parentFolderId,
      },
    };
    return createFolder({
      variables,
      optimisticResponse: {
        __typename: Typenames.Mutation,
        createFolder: {
          __typename: Typenames.CreateFolderPayload,
          folder: {
            __typename: Typenames.Folder,
            createdAt: new Date().toISOString(),
            createdBy: "",
            filesByFolderId: {
              __typename: Typenames.FilesConnection,
              nodes: [],
              totalCount: 0,
            },
            foldersByParentId: {
              __typename: Typenames.FoldersConnection,
              totalCount: 0,
            },
            sharedSpacesByFolderId: {
              __typename: Typenames.SpacesConnection,
              nodes: [],
            },
            id: "dummyId",
            isFavorite: false,
            name,
            orgId,
            parentId: parentFolderId,
            spaceId,
            tags: [],
            updatedAt: new Date().toISOString(),
            updatedBy: "",
            isShared: false,
            isSharedAll: false,
          },
        },
      },
      update: (proxy, data) => {
        const queryVar: FolderByIdQueryVariables = {
          fileOrderBy: [MediaOrderBy.FILE_CREATED_AT_DESC],
          folderId: parentFolderId,
          folderOrderBy: [MediaOrderBy.FOLDER_CREATED_AT_DESC],
          endCursor: null,
        };
        const cacheData = proxy.readQuery({
          query: FolderByIdDocument,
          variables: queryVar,
        }) as FolderByIdQuery;
        const cloneCacheData = cloneDeep(cacheData);
        if (cloneCacheData?.folderById?.foldersByParentId) {
          const folderData = data!.data!.createFolder!.folder as Folder;
          if (folderData!.id !== "dummyId" && folderData!.filesByFolderId) {
            cloneCacheData.folderById.foldersByParentId.nodes.push(
              folderData as any,
            );
          }
          proxy.writeQuery({
            data: cloneCacheData,
            query: FolderByIdDocument,
            variables: queryVar,
          });
        }
      },
    });
  };
  return { createNewFolder };
}
