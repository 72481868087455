import { getOnCastableDeletedRefetchQueries } from "src/domains/castableEntity/events/onCastableEntityDeleted";
import { useModifyAppInstanceCache } from "src/hooks/caches/modifications/useModifyAppInstanceCache";
import { useCacheInvalidation } from "src/hooks/useCacheInvalidation";
import { getCurrentSpaceId } from "src/state/session/ssm";
import { AppInstance, TagsByAppIdAndSpaceIdDocument } from "src/types.g";

export const getOnAppInstanceDeletedRefetchQueries = ({
  isCanvasApp,
  canvasAppId,
}: {
  isCanvasApp: boolean;
  canvasAppId: string;
}) => {
  const refetchAppTagsQuery = {
    query: TagsByAppIdAndSpaceIdDocument,
    variables: { spaceId: getCurrentSpaceId(), appId: canvasAppId },
  };

  const refetchQueries = getOnCastableDeletedRefetchQueries();

  if (isCanvasApp) {
    refetchQueries.push(refetchAppTagsQuery);
  }

  return refetchQueries;
};

export function useOnAppInstanceDeleted() {
  const { deleteAppInstanceFromCache } = useModifyAppInstanceCache();
  const { invalidateScreenGroupCache, invalidateEntityCache } =
    useCacheInvalidation();

  const onAppInstanceDeleted = ({
    appInstance,
  }: {
    appInstance: Pick<AppInstance, "__typename" | "id" | "name" | "appId">;
  }) => {
    deleteAppInstanceFromCache(appInstance);
    invalidateEntityCache(appInstance);
    invalidateScreenGroupCache();
  };

  return {
    onAppInstanceDeleted,
  };
}
