import { useApolloClient } from "@apollo/client";
import { useAppContext } from "src/hooks/useAppContext";
import { AppInstance } from "src/types.g";

export const useModifyAppInstanceCache = () => {
  const client = useApolloClient();
  const cache = client.cache;
  const context = useAppContext();

  const deleteAppInstanceFromCache = (
    deletedAppInstance: Pick<
      AppInstance,
      "__typename" | "id" | "name" | "appId"
    >,
  ) => {
    cache.modify({
      id: cache.identify(context.currentSpace!),
      fields: {
        availableAppInstancesBySpaceIdAndAppId(appInstances, options) {
          if (
            options.storeFieldName.includes(
              '"appId":"' + deletedAppInstance.appId + '"',
            )
          ) {
            const nodesRef = appInstances.nodes.filter(
              (node) =>
                options.readField("id", node) !== deletedAppInstance?.id,
            );
            return {
              ...appInstances,
              nodes: nodesRef,
              totalCount: appInstances.totalCount - 1,
            };
          } else {
            return appInstances;
          }
        },
      },
    });
  };
  return {
    deleteAppInstanceFromCache,
  };
};
