import { Icon } from "@screencloud/screencloud-ui-components";
import React from "react";

import { ScheduleRules } from "../../../constants/constants";

import { Styled } from "./styles";

// tslint:disable-next-line: array-type
type DisplayingRules = (keyof ScheduleRules)[];
type RuleIconColors = {
  [x in keyof ScheduleRules]: {
    iconName: string;
    iconColor: string;
    backgroundColor: string;
  };
};
// TODO: implement to support other rules
const displayingRulesRtlOrder: DisplayingRules = [
  // 'date',
  // 'exclusive',
  "full_screen",
  // 'day_of_week'
];
const ruleIconColors: RuleIconColors = {
  // date: {
  //   iconName: 'schedule',
  //   iconColor: 'black',
  //   backgroundColor: 'white',
  // },
  // exclusive: {
  //   iconName: 'star',
  //   iconColor: 'white',
  //   backgroundColor: 'red',
  // },
  full_screen: {
    iconName: "play-fullscreen",
    iconColor: "#ffffff",
    backgroundColor: "rgb(31, 96, 236)",
  },
  // day_of_week: {
  //   iconName: 'calendar',
  //   iconColor: 'yellow',
  //   backgroundColor: '',
  // },
};

export const hasDisplayingRules = (rules: ScheduleRules[]): boolean => {
  return (
    rules.some((rule) => {
      for (const displayingRule of displayingRulesRtlOrder) {
        if (rule[displayingRule]) {
          return true;
        }
      }
      return false;
    }) ?? false
  );
};

const renderIcon = (
  rules: ScheduleRules[],
  displayingRule: keyof ScheduleRules,
  key: React.Key,
): JSX.Element | null => {
  const matchRule: boolean = rules.some((rule) => {
    const result = rule[displayingRule];

    if (typeof result === "boolean") {
      return result;
    } else if (Array.isArray(result)) {
      return result.length > 0;
    } else if (typeof result === "object") {
      // *NOTE: maybe need to handle custom logic for each rule eg. day_of_week
      return JSON.stringify(result) !== "{}";
    }

    return false;
  });

  if (!matchRule) {
    return null;
  }

  const colors = ruleIconColors[displayingRule];
  return (
    <div
      className="rule-icon"
      key={key}
      style={{ backgroundColor: colors?.backgroundColor }}
    >
      <Icon name={colors?.iconName ?? ""} color={colors?.iconColor} />
    </div>
  );
};

export interface RuleIconsProps {
  rules: ScheduleRules[];
}
const RuleIcons: React.FC<RuleIconsProps> = ({ rules }) => {
  if (!hasDisplayingRules(rules)) {
    return null;
  }

  return (
    <Styled className="rule-icons">
      <div className="rule-icon-container">
        {displayingRulesRtlOrder.map((displayingRule, idx) =>
          renderIcon(rules, displayingRule, idx),
        )}
      </div>
    </Styled>
  );
};

export default RuleIcons;
