import React from "react";
import { Dropdown } from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import { DropdownItemProps } from "semantic-ui-react";
import { SnapshotType, Space } from "src/types.g";
import styles from "./style.module.css";

type SelectableSpace = Pick<Space, "id" | "name">;
interface Props {
  contentName: string;
  spaces: SelectableSpace[];
  onSpaceSelected: (targetSpacesId: string[]) => void;
  snapshotType: SnapshotType;
}

const getDropdownItemProps = (spaces: SelectableSpace[]): DropdownItemProps[] =>
  spaces.map((space) => {
    return {
      key: space.id,
      value: space.id,
      text: space.name,
    };
  });

const DuplicateContentModal: React.FunctionComponent<Props> = (props) => {
  const { contentName, spaces, snapshotType } = props;
  const [options] = React.useState<DropdownItemProps[]>(
    getDropdownItemProps(spaces),
  );

  const onDropdownChange = React.useCallback(
    (_: React.KeyboardEvent<HTMLElement>, d: { value: string[] }) => {
      props.onSpaceSelected(d.value);
    },
    [props.onSpaceSelected],
  );

  const renderLabel = (item: DropdownItemProps) => ({
    as: "span",
    className: styles.label,
    content: item.text,
  });

  return (
    <>
      <h2 className={styles.modalHeader}>
        <FormattedMessage
          id="ui_component.confirm.duplicate_content.to_another_space"
          defaultMessage="Duplicate {contentName} to another space?"
          values={{ contentName }}
        />
      </h2>
      <p>
        <FormattedMessage
          id="ui_component.confirm.duplicate_content.choose_space"
          defaultMessage="Choose space"
        />
      </p>
      <Dropdown
        fluid
        multiple
        search
        selection
        closeOnChange
        onChange={onDropdownChange}
        options={options}
        renderLabel={renderLabel}
        wrapSelection
        data-testid="duplicate-channel-space-selector"
        className={styles.selection}
      />
      <p className={styles.info}>
        <FormattedMessage
          id="ui_component.confirm.duplicate_content.info"
          defaultMessage="This {snapshotType}, including all of its contents and settings, will be duplicated to the selected space."
          values={{ snapshotType: snapshotType.toLowerCase() }}
        />
      </p>
    </>
  );
};

export default DuplicateContentModal;
