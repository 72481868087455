import { SubscriptionStatus } from "src/billing/types";

/**
 * A function to display live chat if organization is in trial status
 * or any expect Core plan.
 *
 * @param isCorePlan true if billing plan is Core plan
 * @param billingStatus billing status for this org
 * @param isResellerFeatureFlagEnabled true if RESELLER feature flag is enabled for this org
 * @returns true if should display live chat, false if we should not display.
 */
export const shouldDisplayLiveChat = (
  isCorePlan: boolean | undefined,
  subscriptionStatus: string | null,
  isResellerFeatureFlagEnabled: boolean,
): boolean =>
  (isCorePlan === false || subscriptionStatus === SubscriptionStatus.InTrial) &&
  !isResellerFeatureFlagEnabled;
