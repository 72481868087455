import { AppContextState } from "src/AppContextProvider";
import { FEATURE_FLAGS_ENUM } from "src/constants/featureFlag";
import { Screen } from "src/types.g";

// Allow only users that is owner or have org-wide screen:read, screen:update and screen:delete to access Screens Manager page.
export const canAccessScreensManager = (context: AppContextState): boolean => {
  const isOwner = context.currentUser?.isOwner;
  const hasRequiredOrgScreenPermissions =
    context.currentPermissions.validateCurrentSpace("screen", "read") &&
    context.currentPermissions.validateCurrentSpace("screen", "update");

  return (
    context.shouldShowFeature(FEATURE_FLAGS_ENUM.SCREENS_MANAGER) &&
    (isOwner || hasRequiredOrgScreenPermissions)
  );
};

export const canSetNotification = (context: AppContextState): boolean => {
  return (
    context.currentPermissions.validateCurrentSpace(
      "notification_rule",
      "create",
    ) &&
    context.currentPermissions.validateCurrentSpace(
      "notification_rule",
      "delete",
    ) &&
    context.shouldShowFeature(FEATURE_FLAGS_ENUM.SCREEN_NOTIFICATIONS)
  );
};

export const canReplaceDevice = (
  context: AppContextState,
  screen: Screen,
): boolean => {
  return (
    context.currentPermissions.validateCurrentSpace("screen", "update") &&
    screen?.deviceProvider !== "fake"
  );
};
