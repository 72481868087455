import {
  Button,
  ColorPicker,
  Dropdown,
  Icon,
  Loader,
  Popover,
  ScreenPreview,
} from "@screencloud/screencloud-ui-components";
import { sortBy } from "lodash";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { AppContext } from "../../../../AppContextProvider/AppContext";
import { AppContextType } from "../../../../AppContextProvider/type";
import { Colors, DEFAULT_FONT, UUID } from "../../../../constants/constants";
import { DropdownOption } from "../../../../constants/fontList";
import { PrimaryButton } from "../../../../helpers/whiteLabel";
import { File, Maybe, Theme } from "../../../../types.g";
import { getContrastText } from "../../../../utils/colorConversions";
import FullScreenModalContent from "../../../FullScreenModal/FullScreenModalContent";
import FullScreenModalContentMain from "../../../FullScreenModal/FullScreenModalContentMain";
import FullScreenModalContentSidebar from "../../../FullScreenModal/FullScreenModalContentSidebar";
import FullScreenModalHeader from "../../../FullScreenModal/FullScreenModalHeader";
import FullScreenModalWrapper from "../../../FullScreenModal/FullScreenModalWrapper";
import { ThemeFormData } from "../ThemeContainer";
import ThemePreview, { FontData } from "../ThemePreview";
import { ApolloProps, withData } from "./apollo";
import themeIcon from "./image/themes.svg";
import { Styled } from "./styles";

interface State {
  isEditingName: boolean;
  name?: string;
  primaryColor: string;
  secondaryColor: string;
  headingFont?: Font;
  bodyFont?: Font;
  titleFontColor: string;
  bodyFontColor: string;
}

export interface Font {
  id?: UUID;
  family: string;
  url: string;
}

export interface ThemeConfigureProps {
  theme?: Theme;
  title: string;
  logo: File | null | undefined;
  initialLogo: Maybe<Partial<File>> | null | undefined;
  initialLogoRemoved: boolean;
  onSave: (theme: ThemeFormData, isUpdate?: boolean) => Promise<void>;
  onUploadLogoClick: () => void;
  onRemoveLogoClick: () => void;
  isImageUploading: boolean;
}

export class ThemeConfigure extends React.PureComponent<
  ThemeConfigureProps & ApolloProps,
  State
> {
  public static contextType = AppContext;
  public context: AppContextType;
  public getDefaultFont = () => {
    const { allCommonFonts } = this.props;
    const defaultFont =
      allCommonFonts &&
      allCommonFonts.find((font) => font.name === DEFAULT_FONT.family);
    return {
      url: defaultFont?.cssUrl,
      family: defaultFont?.fontFamily,
      id: defaultFont?.id,
    } as Font;
  };

  constructor(props: ThemeConfigureProps & ApolloProps) {
    super(props);
    const { theme } = props;
    const defaultFont = this.getDefaultFont();
    const primaryColor = theme?.config?.primaryColor[500] ?? Colors.BLACK;
    const secondaryColor = theme?.config?.secondaryColor[500] ?? Colors.WHITE;
    const headingFont = theme?.titleFontId
      ? { id: theme?.titleFontId, ...theme?.config?.headingFont }
      : defaultFont;
    const bodyFont = theme?.bodyFontId
      ? { id: theme?.bodyFontId, ...theme?.config?.bodyFont }
      : defaultFont;
    const titleFontColor =
      theme?.config?.textOnPrimary[500] ?? getContrastText(primaryColor);
    const bodyFontColor =
      theme?.config?.textOnSecondary[500] ?? getContrastText(primaryColor);
    this.state = {
      bodyFont,
      headingFont,
      isEditingName: true,
      name: theme?.name ?? undefined,
      primaryColor,
      secondaryColor,
      titleFontColor,
      bodyFontColor,
    };
  }

  componentDidUpdate(prevProps: ThemeConfigureProps & ApolloProps) {
    const { allCommonFonts } = this.props;
    const { headingFont, bodyFont } = this.state;
    if (
      !headingFont &&
      !bodyFont &&
      prevProps.allCommonFonts &&
      allCommonFonts
    ) {
      const defaultFont = this.getDefaultFont();
      this.setState({
        headingFont: defaultFont,
        bodyFont: defaultFont,
      });
    }
  }

  public onSubmit = (event: React.SyntheticEvent<any>) => {
    event.preventDefault();
    const {
      bodyFont,
      headingFont,
      name,
      primaryColor,
      secondaryColor,
      titleFontColor,
      bodyFontColor,
    } = this.state;
    const theme: ThemeFormData = {
      bodyFont,
      headingFont,
      name: name!,
      primaryColor,
      secondaryColor,
      titleFontColor,
      bodyFontColor,
      titleFontId: headingFont?.id,
      bodyFontId: bodyFont?.id,
    };
    return this.props.onSave(theme);
  };

  public handleNameSaved = (
    _event: React.SyntheticEvent<any>,
    value: string,
  ) => {
    this.setState({ name: value, isEditingName: false });
  };

  public onPrimaryColorChange = (color: string) => {
    this.setState({ primaryColor: color });
  };

  public onSecondaryColorChange = (color: string) => {
    this.setState({ secondaryColor: color });
  };

  public onTitleFontColorChange = (color: string) => {
    this.setState({ titleFontColor: color });
  };

  public onBodyFontColorChange = (color: string) => {
    this.setState({ bodyFontColor: color });
  };

  public getSelectedFont = (fontId: UUID) => {
    const allFonts = [
      ...this.props.allCommonFonts,
      ...this.props.allCustomFonts,
    ];
    return allFonts.find((font) => font.id === fontId);
  };

  public onHeadingFontChange = (
    event: React.SyntheticEvent<any>,
    data: { value: string },
  ) => {
    const url = this.getSelectedFontUrl(data.value);
    const family = this.getSelectedFontFamily(data.value);
    this.setState({
      headingFont: {
        url,
        family,
        id: data.value,
      },
    });
  };

  public onBodyFontChange = (
    event: React.SyntheticEvent<any>,
    data: { value: string },
  ) => {
    const url = this.getSelectedFontUrl(data.value);
    const family = this.getSelectedFontFamily(data.value);
    this.setState({
      bodyFont: {
        url,
        family,
        id: data.value,
      },
    });
  };

  public getFontSource = (font): string => {
    if (font.fileByRegularFileId) {
      return font.fileByRegularFileId.source;
    } else if (font.fileByBoldFileId) {
      return font.fileByBoldFileId.source;
    } else if (font.fileByItalicFileId) {
      return font.fileByItalicFileId.source;
    } else if (font.fileByBoldItalicFileId) {
      return font.fileByBoldItalicFileId.source;
    } else if (font.cssUrl) {
      return font.cssUrl;
    } else {
      return this.getDefaultFont().url;
    }
  };

  public getSelectedFontFamily(fontId: UUID): string {
    const { allCommonFonts, allCustomFonts } = this.props;
    const allFonts = [...allCommonFonts, ...allCustomFonts];
    const selectedFont = allFonts.find((font) => {
      return font.id === fontId;
    });

    return selectedFont
      ? selectedFont.fontFamily
      : this.getDefaultFont().family;
  }

  public getSelectedFontUrl(fontId: UUID): string {
    const { allCommonFonts, allCustomFonts } = this.props;
    const allFonts = [...allCommonFonts, ...allCustomFonts];
    const selectedFont = allFonts.find((font) => {
      return font.id === fontId;
    });

    return selectedFont
      ? this.getFontSource(selectedFont)
      : this.getDefaultFont().url;
  }

  public getCommonFontsOptions(): DropdownOption[] {
    const { allCommonFonts, loading } = this.props;
    if (!loading) {
      const fonts = sortBy(
        allCommonFonts.map((font) => {
          return {
            key: font.name,
            text: font.name,
            value: font.id,
          };
        }),
        (font) => font.key,
      );
      return sortBy(fonts, (font) => font.key).map((font, index) => {
        if (index === 0) {
          return {
            className: "custom-divider",
            ...font,
          };
        }
        return font;
      }) as DropdownOption[];
    }
    return [];
  }

  public getCustomFontsOptions(): DropdownOption[] {
    const { allCustomFonts, loading } = this.props;
    if (!loading) {
      const fonts = allCustomFonts.map((font) => {
        return {
          key: font.name,
          text: font.name,
          value: font.id,
        };
      });
      return sortBy(fonts, (font) => font.key) as DropdownOption[];
    }
    return [];
  }

  public render() {
    const {
      isImageUploading,
      initialLogo,
      initialLogoRemoved,
      logo,
      title,
      onUploadLogoClick,
      onRemoveLogoClick,
    } = this.props;
    const {
      name,
      isEditingName,
      headingFont,
      bodyFont,
      primaryColor,
      secondaryColor,
      titleFontColor,
      bodyFontColor,
    } = this.state;
    const logoExists: boolean =
      initialLogo && !initialLogoRemoved ? !!initialLogo : !!logo!;
    const canUpdateTheme = this.context.currentPermissions.validateOrg(
      "theme",
      "update",
    );

    const headingFontselected = this.getSelectedFont(headingFont?.id);
    const bodyFontselected = this.getSelectedFont(bodyFont?.id);
    const headingFontData = {
      fontFamily: headingFontselected?.fontFamily,
      regularFont: headingFontselected?.fileByRegularFileId?.source,
      boldFont: headingFontselected?.fileByBoldFileId?.source,
      italicFont: headingFontselected?.fileByItalicFileId?.source,
      boldItalicFont: headingFontselected?.fileByBoldItalicFileId?.source,
      cssUrl: headingFontselected?.cssUrl ?? "",
    } as FontData;

    const bodyFontData = {
      fontFamily: bodyFontselected?.fontFamily,
      regularFont: bodyFontselected?.fileByRegularFileId?.source,
      boldFont: bodyFontselected?.fileByBoldFileId?.source,
      italicFont: bodyFontselected?.fileByItalicFileId?.source,
      boldItalicFont: bodyFontselected?.fileByBoldItalicFileId?.source,
      cssUrl: bodyFontselected?.cssUrl ?? "",
    } as FontData;

    const options = [
      ...this.getCustomFontsOptions(),
      ...this.getCommonFontsOptions(),
    ];

    return (
      <FullScreenModalWrapper isPreview={false}>
        <FullScreenModalHeader
          handleClose={this.handleClose}
          appIcon={themeIcon}
          title={title}
          instanceName={name}
          isEdit={isEditingName}
          handleNameSaved={this.handleNameSaved}
          textPlaceholder={this.context.intl.formatMessage({
            defaultMessage: "New Theme",
            id: "admin.themes.name_placeholder",
          })}
          disabled={!canUpdateTheme}
          className="themes"
        >
          {canUpdateTheme && (
            <PrimaryButton
              className="button-save"
              onClick={this.onSubmit}
              data-testid="save-button"
              disabled={isImageUploading}
            >
              <FormattedMessage id="common.text.save" defaultMessage="Save" />
            </PrimaryButton>
          )}
        </FullScreenModalHeader>

        <FullScreenModalContent>
          <FullScreenModalContentSidebar>
            <Styled
              onSubmit={(event: React.SyntheticEvent<any>) =>
                this.onSubmit(event)
              }
            >
              <div className="form">
                <div className="theme-row gap">
                  <div className="theme-column theme-text-column">
                    <h5 className="label logo-title" data-testid="logo-title">
                      <FormattedMessage
                        defaultMessage="Logo"
                        id="common.text.logo"
                      />
                      <Popover
                        inverted
                        trigger={<Icon name="info" />}
                        content={
                          <FormattedMessage
                            defaultMessage="Uploaded logos are shown on your Splash screen"
                            id="ui_component.theme.logo_info"
                          />
                        }
                        position="top center"
                      />
                    </h5>
                    <p className="desc" data-testid="logo-desc">
                      <FormattedMessage
                        defaultMessage="We recommend uploading a logo with at least 600px width"
                        id="admin.themes.logo_desc"
                      />
                    </p>
                  </div>
                  <div className="theme-column">
                    <Button
                      type="button"
                      disabled={!canUpdateTheme}
                      onClick={
                        logoExists ? onRemoveLogoClick : onUploadLogoClick
                      }
                      data-testid="logo-button"
                    >
                      <FormattedMessage
                        defaultMessage={
                          logoExists ? "Remove Logo" : "Upload Logo"
                        }
                        id={
                          logoExists
                            ? "admin.themes.remove_logo"
                            : "admin.themes.upload_logo"
                        }
                      />
                    </Button>
                  </div>
                </div>
                <div className="theme-row gap">
                  <div className="theme-column theme-text-column">
                    <h5 className="label" data-testid="color-title">
                      <FormattedMessage
                        defaultMessage="Colors"
                        id="common.text.colors"
                      />
                    </h5>
                    <p className="desc" data-testid="color-desc">
                      <FormattedMessage
                        defaultMessage="Choose your color settings"
                        id="admin.themes.color_desc"
                      />
                    </p>
                  </div>
                  <div className="theme-column">
                    <div className="colors">
                      <div className="colour">
                        <h6 className="label" data-testid="color-primary-title">
                          <FormattedMessage
                            defaultMessage="Primary Color"
                            id="common.color.primary_color"
                          />
                        </h6>
                        <div
                          className="picker"
                          data-testid="color-primary-picker"
                        >
                          <ColorPicker
                            data-testid="primary-color-picker"
                            disabled={!canUpdateTheme}
                            color={primaryColor}
                            onComplete={this.onPrimaryColorChange}
                            disableAlpha
                            inlineInput
                          />
                        </div>
                      </div>
                      <div className="colour">
                        <h6
                          className="label"
                          data-testid="color-secondary-title"
                        >
                          <FormattedMessage
                            defaultMessage="Secondary Color"
                            id="common.color.secondary_color"
                          />
                        </h6>
                        <div
                          className="picker"
                          data-testid="color-secondary-picker"
                        >
                          <ColorPicker
                            data-testid="secondary-color-picker"
                            disabled={!canUpdateTheme}
                            color={secondaryColor}
                            onComplete={this.onSecondaryColorChange}
                            disableAlpha
                            inlineInput
                          />
                        </div>
                      </div>
                      <div className="colour">
                        <h6
                          className="label color-label-row"
                          data-testid="color-title-font"
                        >
                          <FormattedMessage
                            defaultMessage="Title Font Color"
                            id="common.color.title_font_color"
                          />
                          <div className="tooltip">
                            <a
                              onClick={() =>
                                this.onTitleFontColorChange(
                                  getContrastText(primaryColor),
                                )
                              }
                            >
                              <FormattedMessage
                                defaultMessage="Use Default"
                                id="common.text.use_default"
                              />
                            </a>
                            <Popover
                              inverted
                              position="top center"
                              content={
                                <FormattedMessage
                                  id="admin.themes.title_font_color_tooltip"
                                  defaultMessage="ScreenCloud looks at your primary color and decides which title font color is most eligible"
                                />
                              }
                              trigger={
                                <Icon name="info" className="popover-icon" />
                              }
                            />
                          </div>
                        </h6>
                        <div className="picker" data-testid="color-title-font">
                          <ColorPicker
                            data-testid="title-font-color"
                            disabled={!canUpdateTheme}
                            color={titleFontColor}
                            onComplete={this.onTitleFontColorChange}
                            disableAlpha
                            inlineInput
                          />
                        </div>
                      </div>
                      <div className="colour">
                        <h6
                          className="label color-label-row"
                          data-testid="color-body-font"
                        >
                          <FormattedMessage
                            defaultMessage="Body Font Color"
                            id="common.color.body_font_color"
                          />{" "}
                          <div className="tooltip">
                            <a
                              onClick={() =>
                                this.onBodyFontColorChange(
                                  getContrastText(primaryColor),
                                )
                              }
                            >
                              <FormattedMessage
                                defaultMessage="Use Default"
                                id="common.text.use_default"
                              />
                            </a>
                            <Popover
                              inverted
                              position="top center"
                              content={
                                <FormattedMessage
                                  id="admin.themes.body_font_color_tooltip"
                                  defaultMessage="ScreenCloud looks at your secondary color and decides which body font color is most eligible"
                                />
                              }
                              trigger={
                                <Icon name="info" className="popover-icon" />
                              }
                            />
                          </div>
                        </h6>
                        <div className="picker" data-testid="color-body-font">
                          <ColorPicker
                            data-testid="body-font-color"
                            disabled={!canUpdateTheme}
                            color={bodyFontColor}
                            onComplete={this.onBodyFontColorChange}
                            disableAlpha
                            inlineInput
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="theme-row gap">
                  <div className="theme-column theme-text-column">
                    <h5 className="label" data-testid="font-title">
                      <FormattedMessage
                        defaultMessage="Fonts"
                        id="common.text.fonts"
                      />
                    </h5>
                    <p className="desc" data-testid="font-desc">
                      <FormattedMessage
                        defaultMessage="Choose a title and body font"
                        id="admin.themes.font_desc"
                      />
                    </p>
                  </div>
                  <div className="theme-column">
                    <div className="fonts">
                      <h6 className="label" data-testid="heading-title">
                        <FormattedMessage
                          defaultMessage="Title font"
                          id="admin.themes.title_font"
                        />
                      </h6>
                      <Dropdown
                        disabled={!canUpdateTheme}
                        placeholder={this.context.intl.formatMessage({
                          defaultMessage: "DEFAULT",
                          id: "ui_component.dropdown.default",
                        })}
                        selection
                        fluid
                        options={options}
                        selectOnBlur={false}
                        onChange={this.onHeadingFontChange}
                        value={
                          headingFontselected?.id ??
                          this.props.theme?.titleFontId ??
                          headingFont?.id
                        }
                        data-testid="heading-options"
                      />
                      <h6 className="label" data-testid="body-title">
                        <FormattedMessage
                          defaultMessage="Body font"
                          id="admin.themes.body_font"
                        />
                      </h6>
                      <Dropdown
                        disabled={!canUpdateTheme}
                        placeholder={this.context.intl.formatMessage({
                          defaultMessage: "DEFAULT",
                          id: "ui_component.dropdown.default",
                        })}
                        selection
                        fluid
                        options={options}
                        selectOnBlur={false}
                        onChange={this.onBodyFontChange}
                        value={
                          bodyFontselected?.id ??
                          this.props.theme?.bodyFontId ??
                          bodyFont?.id
                        }
                        data-testid="body-options"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Styled>
          </FullScreenModalContentSidebar>
          <FullScreenModalContentMain>
            <ScreenPreview height={1080} width={1920}>
              <ThemePreview
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                titleColor={titleFontColor}
                bodyColor={bodyFontColor}
                headingFont={headingFont}
                bodyFont={bodyFont}
                image={initialLogo && !initialLogoRemoved ? initialLogo : logo!}
                headingFontData={headingFontData}
                bodyFontData={bodyFontData}
              />
            </ScreenPreview>
          </FullScreenModalContentMain>
        </FullScreenModalContent>

        {/* TODO: show loading status when saving */}
        <Loader active={false} />
      </FullScreenModalWrapper>
    );
  }

  private handleClose = async () => {
    this.context.modal.closeFullscreenModal();
  };
}

export default withData(
  ThemeConfigure,
) as React.ComponentType<ThemeConfigureProps>;
