import { DataValue } from "@apollo/client/react/hoc";
import { useScreenCastStop } from "../../hooks/useStopCasts";
import {
  CastByCastIdFragment,
  CastByIdQuery,
  Exact,
  Maybe,
  ScreenCastStopInput,
  useCastByIdQuery,
} from "../../types.g";
import { compose } from "../../utils/compose";

export interface ApolloProps {
  castId: string;
  castById: DataValue<CastByIdQuery>;
  screenCastStop: (
    stopCastsInput: Exact<{
      input: ScreenCastStopInput;
    }>,
    castItem?: Maybe<CastByCastIdFragment>,
  ) => Promise<any>;
}

export const withData = compose((Component) => (props: ApolloProps) => {
  const { screenCastStop } = useScreenCastStop();
  const { data } = useCastByIdQuery({
    variables: {
      castId: props.castId,
    },
  });
  return (
    <Component {...props} castById={data} screenCastStop={screenCastStop} />
  );
});
