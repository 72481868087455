import {
  DatetimeFormat,
  Icon,
  LoaderBar,
} from "@screencloud/screencloud-ui-components";
import { UUID } from "@screencloud/uuid";
import { PureComponent } from "react";
import { FormattedMessage } from "react-intl";
import { SortingActions } from "../../constants/constants";
import { appInstanceIconUrl } from "../../helpers/appHelper";
import { updateAndToggleSelectedItems } from "../../helpers/updateAndToggleSelectedItemsHelper";

import { AppInstance } from "../../types.g";
import { compose } from "../../utils/compose";
import DateTime from "../DateTime";
import { AppPickerItem } from "./styles";
import EmptyState from "../EmptyState";
import {
  useSearchAppInstancePicker,
  UseSearchAppInstancePicker,
} from "./hooks/useSearchAppInstancePicker";
import { useSearchAppInstancePickerByAppId } from "./hooks/useSearchAppInstancePickerByAppId";

export interface SearchAppContentPickerProps
  extends UseSearchAppInstancePicker {
  isMultipleSelect: boolean | undefined;
  callback: (ids: string[], selectedApps: AppInstance[]) => void;
  query: string;
  customContainer?: React.ReactNode;
  appId: string;
  spaceId?: UUID;
}

const withData = compose(
  (Component) => (props: SearchAppContentPickerProps) => {
    const newSearchTerms = "+" + props.query.replace(/\s/g, " +");
    let searchAppInstance;

    if (props.appId) {
      searchAppInstance = useSearchAppInstancePickerByAppId(
        newSearchTerms,
        props.appId,
        props.spaceId,
      );
    } else {
      searchAppInstance = useSearchAppInstancePicker(
        newSearchTerms,
        props.spaceId as UUID,
      );
    }
    return <Component {...props} {...searchAppInstance} />;
  },
);

export interface AppContentPickerState {
  isOrderByAscending: boolean;
  selectedIds: string[];
  selectedApps: AppInstance[];
  sortBy: SortingActions;
  isFetchMore: boolean;
}

class SearchAppPicker extends PureComponent<
  SearchAppContentPickerProps,
  AppContentPickerState
> {
  constructor(props: SearchAppContentPickerProps) {
    super(props);
    this.state = {
      isFetchMore: false,
      isOrderByAscending: true,
      selectedApps: [],
      selectedIds: [],
      sortBy: SortingActions.SORT_BY_NAME,
    };
  }

  public onToggleSelection = (app: any) => {
    if (this.props.appInstances) {
      const appInstances = this.props.appInstances;
      const { selectedArray } = updateAndToggleSelectedItems(
        app.id,
        app,
        this.state.selectedIds,
        this.state.selectedApps,
        this.props.isMultipleSelect,
      );
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            selectedIds: selectedArray,
          };
        },
        () => {
          const selectedApps = this.state.selectedIds.reduce(
            (allSelectedApps: AppInstance[], currentId) => {
              const appsId = appInstances.find(
                (appInstance) => appInstance!.id === currentId,
              );
              return [...allSelectedApps, appsId];
            },
            [],
          );
          this.props.callback(
            this.state.selectedIds,
            selectedApps as AppInstance[],
          );
        },
      );
    }
  };

  public renderOrderCaretIcon = (): JSX.Element => {
    const orderIcon = this.state.isOrderByAscending ? "caret-down" : "caret-up";
    return <Icon className="caret-order" name={orderIcon} />;
  };

  public renderAppInstanceItems = (): JSX.Element[] => {
    if (this.props.appInstances) {
      const appInstances = this.props.appInstances;
      return appInstances.map((node) => {
        const app = node!;
        const iconUrl = appInstanceIconUrl(app as unknown as AppInstance);
        return (
          <AppPickerItem
            className="media-item"
            key={`apps-${app.id}`}
            onClick={() => this.onToggleSelection(app)}
            selected={this.state.selectedIds.includes(app.id)}
          >
            <div className="media-core">
              <div className="media-alpha">
                <div className="thumbnail-preview">
                  <div className="thumbnail">
                    <div className="wrapper">
                      <img src={iconUrl} />
                    </div>
                  </div>
                </div>
                <div className="media-title">
                  <h3>{app.name}</h3>
                  <div>
                    <span className="media-item__date">
                      <FormattedMessage
                        id="common.text.created_on"
                        defaultMessage="Created on"
                      />{" "}
                      <DateTime
                        value={app.createdAt}
                        format={DatetimeFormat.Long}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="media-checked">
              <Icon name="checked-circle" />
            </div>
          </AppPickerItem>
        );
      });
    } else {
      return [];
    }
  };

  public render() {
    const { loading, appInstances, renderFetchMoreButton } = this.props;
    return (
      <>
        {appInstances.length > 0 && (
          <>
            {this.renderAppInstanceItems()}
            {renderFetchMoreButton}
          </>
        )}
        {appInstances.length === 0 && !loading && (
          <EmptyState section="search-apps" className="empty">
            <h3>
              <FormattedMessage
                id="common.search.no_results"
                defaultMessage="We couldn’t find any matches"
              />
            </h3>
            <p>
              <FormattedMessage
                id="common.search.no_results_description"
                defaultMessage="Try adjusting or using different search terms."
              />
            </p>
          </EmptyState>
        )}
        {loading && <LoaderBar />}
      </>
    );
  }
}

export default withData(SearchAppPicker);
