import * as filestack from "filestack-js";
import { appConfig } from "../appConfig";

export const filestackApiURL = `https://process.filestackapi.com/${appConfig.fileStackApiKey}/security=policy:${appConfig.fileStackSecurityPolicy},signature:${appConfig.fileStackSecuritySignature}`;
const filestackSecurity = {
  security: {
    policy: appConfig.fileStackSecurityPolicy,
    signature: appConfig.fileStackSecuritySignature,
  },
};

const fileStackClient = filestack.init(
  appConfig.fileStackApiKey,
  filestackSecurity,
);

interface UploadFileToSlack {
  file: File;
}

/**
 * TODO: Refactor other files to use this
 */
export async function uploadFileByData({ file }: UploadFileToSlack) {
  // upload file to filestack
  const fileUploaded = await fileStackClient.upload(
    file,
    {},
    {
      filename: file.name,
      access: "public",
      container: appConfig.uploadsBucket,
      location: appConfig.uploadsLocation,
      path: "",
      region: appConfig.s3Region,
    },
  );
  return resolveFileKey(fileUploaded);
}

export async function uploadFileDataByImageDataAndName(
  imageData: string,
  name: string,
) {
  const fileUploaded = await fileStackClient.upload(
    imageData,
    {},
    {
      filename: name,
      access: "public",
      container: appConfig.uploadsBucket,
      location: appConfig.uploadsLocation,
      path: "",
      region: appConfig.s3Region,
    },
  );
  return resolveFileKey(fileUploaded);
}

const isFileMetaDataReady = (metadata: any): boolean => {
  return Boolean(metadata?.key) && Boolean(metadata?.location);
};

export async function getFileMetaData(handle: string) {
  const data = await fileStackClient.metadata(handle, {
    location: true,
    container: true,
    path: true,
    uploaded: true,
  });
  return data;
}

export async function waitForFileKey(handle: string) {
  const maxRetries = 5;
  let metadata = await getFileMetaData(handle);
  for (
    let retry = 0;
    retry < maxRetries && !isFileMetaDataReady(metadata);
    retry++
  ) {
    await new Promise((f) => setTimeout(f, 1000));
    metadata = await getFileMetaData(handle);
  }
  return metadata;
}

export async function resolveFileKey(file, getMetaData = waitForFileKey) {
  if (!isFileMetaDataReady(file)) {
    const extraMetadata = await getMetaData(file.handle);
    return Object.assign(file, extraMetadata);
  }
  return file;
}

export default fileStackClient;
