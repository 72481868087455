import {
  Button,
  DurationInput,
  Icon,
  Popover,
  Thumbnail,
} from "@screencloud/screencloud-ui-components";
import classNames from "clsx";
import { FormattedMessage } from "react-intl";
import {
  ListItemType,
  MIN_DURATION_CONTENT,
  RefType,
} from "../../../constants/constants";
import { appInstanceIconUrl } from "../../../helpers/appHelper";
import { hasSchedule } from "../../../helpers/scheduleableHelper";
import {
  generateImgixThumbnail,
  isAudio,
  isVideo,
} from "../../../helpers/mediaHelper";
import {
  AppInstanceByIdForContentQuery,
  File,
  FileByIdForContentQuery,
  FileOutput,
  LinkByIdForContentQuery,
  SiteByIdForContentQuery,
} from "../../../types.g";
import { getListItemType } from "../../../utils";
import { withData } from "./apollo";
import { Styled } from "./styles";
import videoIcon from "../../../images/video.svg";
import audioPlaceHolder from "../../../images/audio_thumb_placeholder.svg";
import AvailabilityStatusIcon from "src/components/AvailabilityStatusIcon";
import { useAppContext } from "src/hooks/useAppContext";

export enum PlaylistContentItemActions {
  DELETE = "delete",
  PREVIEW = "preview",
}

export interface Props {
  contentId: string;
  type: string;
  callBack: (
    listId: string,
    contentId: string,
    action: PlaylistContentItemActions,
    contentIndex: number | undefined,
  ) => void;
  onDurationChange?: (duration: number, listId: string) => void;
  onDurationFocus?: (isFocus: boolean) => void;
  listId: string;
  title?: string | null;
  contentType: string;
  duration: number;
  selected: boolean;
  defaultDuration?: number;
  contentIndex?: number;
  isFixedDuration?: boolean;
  isExpired?: boolean;
  isEditable: boolean;
  imageSet?: FileOutput;
  fileById?: FileByIdForContentQuery["fileById"];
  linkById?: LinkByIdForContentQuery["linkById"];
  appInstanceById?: AppInstanceByIdForContentQuery["appInstanceById"];
  siteById?: SiteByIdForContentQuery["siteById"];
}

export const handleDurationChange = (props: Props, duration: number) => {
  if (props.onDurationChange) {
    props.onDurationChange(duration, props.listId);
  }
};

export const handleItemClick = (props: Props) => {
  props.callBack(
    props.listId,
    props.contentId,
    PlaylistContentItemActions.PREVIEW,
    props.contentIndex,
  );
};

export const handleDeleteClick = (
  props: Props,
  action: PlaylistContentItemActions,
) => {
  if (props.callBack) {
    props.callBack(props.listId, props.contentId, action, props.contentIndex);
  }
};

export const getMediaTypeText = (contentType: string) => {
  const itemType = getListItemType(contentType);
  switch (itemType) {
    case ListItemType.DOCUMENT:
      return (
        <FormattedMessage
          defaultMessage="Document"
          id="listitem.type.document"
        />
      );
    case ListItemType.IMAGE:
      return (
        <FormattedMessage defaultMessage="Image" id="listitem.type.image" />
      );
    case ListItemType.AUDIO:
      return (
        <FormattedMessage defaultMessage="Audio" id="listitem.type.audio" />
      );
    case ListItemType.VIDEO:
      return (
        <FormattedMessage defaultMessage="Video" id="listitem.type.video" />
      );
    case ListItemType.APP:
      return <FormattedMessage defaultMessage="App" id="listitem.type.app" />;
    case ListItemType.LINK:
      return <FormattedMessage defaultMessage="Link" id="listitem.type.link" />;
    case ListItemType.SITE:
      return (
        <FormattedMessage defaultMessage="Dashboard" id="listitem.type.site" />
      );
    default:
      return;
  }
};

export const getThumbnail = (
  props: Props,
  secureMediaPolicy: string | undefined,
) => {
  let thumbnail = "";
  const { type, fileById, linkById, appInstanceById, siteById } = props;
  switch (type) {
    case RefType.FILE:
      if (fileById) {
        const imageFileOutput = fileById.fileOutputsByFileId.nodes.find(
          (fileOutput) => fileOutput?.mimetype?.startsWith("image"),
        );
        thumbnail =
          imageFileOutput && fileById.fileOutputsByFileId.nodes.length
            ? generateImgixThumbnail(fileById as File, secureMediaPolicy, true)
            : isAudio(fileById as File)
              ? audioPlaceHolder
              : isVideo(fileById as File)
                ? videoIcon
                : generateImgixThumbnail(
                    fileById as File,
                    secureMediaPolicy,
                    true,
                  );
      }

      break;
    case RefType.LINK:
      if (linkById) {
        thumbnail = generateImgixThumbnail(
          linkById?.fileByFileId as File,
          secureMediaPolicy,
          true,
        );
      }
      break;
    case RefType.SITE:
      if (siteById) {
        thumbnail = generateImgixThumbnail(
          siteById.fileByThumbnailId as File,
          secureMediaPolicy,
          true,
        );
      }
      break;
    case RefType.APP:
    case RefType.APP_INSTANCE:
      if (appInstanceById) {
        thumbnail = appInstanceIconUrl(appInstanceById);
      }
      break;
    default:
      if (fileById) {
        thumbnail = generateImgixThumbnail(
          fileById as File,
          secureMediaPolicy,
          true,
        );
      }
      break;
  }
  return thumbnail;
};

export const PlaylistContentItem = (props: Props): JSX.Element => {
  const {
    fileById,
    appInstanceById,
    isEditable,
    isFixedDuration,
    isExpired,
    title,
    contentType,
    imageSet,
    defaultDuration,
    duration,
    contentId,
  } = props;

  const context = useAppContext();
  const isDisabled = isFixedDuration || !isEditable;
  const thumbnail = getThumbnail(props, context.secureMediaPolicy);

  const renderAvailabilityExpiryIcon = (contentById) => {
    return (
      hasSchedule(contentById) && (
        <AvailabilityStatusIcon
          data-testid="playlist-media-scheduled"
          availableAt={contentById.availableAt}
          expireAt={contentById.expireAt}
          callback={() => handleItemClick(props)}
        />
      )
    );
  };

  return (
    <Styled expired={isExpired ?? false}>
      <div
        className={classNames("content-alpha", {
          "extra-width-invalid-app":
            contentType === "AppInstance" && !appInstanceById?.config,
        })}
      >
        <div
          className="thumbnail-preview"
          onClick={() => handleItemClick(props)}
        >
          <Thumbnail size="medium" src={thumbnail} />
        </div>
        <div className="content-title" onClick={() => handleItemClick(props)}>
          <h3>{title}</h3>
          {getListItemType(contentType) === ListItemType.DOCUMENT &&
          imageSet?.content?.urls ? (
            <span data-testid="document-type-content" className="document-type">
              {imageSet.content.urls.length} page
            </span>
          ) : null}
          {contentType === "AppInstance" &&
          appInstanceById &&
          !appInstanceById?.config ? (
            <span
              data-testid="invalid-app-message"
              className="invalid-app-config"
            >
              <FormattedMessage
                id="channel.content.invalid_app"
                defaultMessage="Invalid app configuration, open this instance to fix it"
              />
            </span>
          ) : null}
        </div>
      </div>
      <div
        className={classNames("media-scheduled", {
          "hide-action":
            contentType === "AppInstance" && !appInstanceById?.config,
        })}
      >
        {fileById && renderAvailabilityExpiryIcon(fileById)}
        {appInstanceById && renderAvailabilityExpiryIcon(appInstanceById)}
      </div>
      <div
        className={classNames("content-duration", {
          "hide-action":
            contentType === "AppInstance" && !appInstanceById?.config,
        })}
      >
        <DurationInput
          showBorder
          min={MIN_DURATION_CONTENT}
          key={`duration-input-${contentId}`}
          isDisabled={isDisabled}
          defaultValue={defaultDuration ?? 0}
          value={duration}
          onFocus={(isFocus: boolean) => {
            props.onDurationFocus && props.onDurationFocus(isFocus);
          }}
          onDurationValueChange={(duration: number) => {
            handleDurationChange(props, duration);
          }}
        />
        {getListItemType(contentType) === ListItemType.DOCUMENT ? (
          <span data-testid="document-type-per-page" className="document-type">
            per page
          </span>
        ) : null}
      </div>
      <div
        className={classNames("content-type", {
          "hide-action":
            contentType === "AppInstance" && !appInstanceById?.config,
        })}
      >
        <span>{getMediaTypeText(contentType)}</span>
      </div>

      {isEditable && (
        <>
          <div data-testid="content-actions" className="content-actions">
            <Icon name="drag" className="drag" />
          </div>
          <Popover
            inverted
            position="top center"
            content={
              <FormattedMessage
                id="ui_component.common.label.remove"
                defaultMessage="Remove"
              />
            }
            trigger={
              <Button
                icon
                borderless
                mini
                danger
                title="Remove"
                onClick={() =>
                  handleDeleteClick(props, PlaylistContentItemActions.DELETE)
                }
                className="content-remove"
                data-testid="content-remove"
              >
                <Icon name="trash" />
              </Button>
            }
          />
        </>
      )}
    </Styled>
  );
};

export default withData(PlaylistContentItem);
