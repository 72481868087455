import { utils } from "@rjsf/core";
import { Scalars } from "../../../types.g";

/**
 * Returns form data object for an app instance config (returns default config when necessary)
 */
export function getFormDataForAppInstance(
  appInstanceConfig: Scalars["JSON"] | undefined, // config object of a target app instance
  schemaForm: Scalars["JSON"],
): Scalars["JSON"] {
  const updatedConfigObject = { ...appInstanceConfig };
  const configPropsNumber = Object.keys(updatedConfigObject).length;
  const requiredConfigPropsNumber = schemaForm?.required?.length ?? 0;

  if (configPropsNumber === 0 && requiredConfigPropsNumber > 0) {
    // return default config
    // IMPORTANT: @rjsf/core package is used here, which is an underlying package that is used in JSONSchemaForm
    //  component. It's worth revisiting this implementation and hide the behavior of detecting defaults inside
    //  JSONSchemaForm component. This will also ensure no dependency conflict with screencloud-ui-components
    //  package.
    return utils.getDefaultFormState(
      schemaForm,
      utils.getDefaultFormState(schemaForm, appInstanceConfig, schemaForm),
      schemaForm,
    );
  } else {
    return updatedConfigObject;
  }
}
