import { Button, Theme } from "@screencloud/screencloud-ui-components";
import { cloneDeep } from "lodash";
import memoize from "memoize-one";
import classNames from "clsx";
import { PureComponent } from "react";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { FormattedMessage } from "react-intl";
import { TimeRange } from "src/helpers/timeRangeHelper/types";

import ChannelContentItem, {
  ChannelContentActions,
} from "src/components/ChannelContentItem";
import EmptyState from "src/components/EmptyState";
import { RefType } from "src/constants/constants";
import { getScheduleOverlap } from "src/helpers/timeRangeHelper";
import { ContentAccordion } from "./ContentAccordion";
import { AppContext } from "src/AppContextProvider/AppContext";
import { AppContextType } from "src/AppContextProvider/type";
import { BoundLabel } from "src/components/BoundLabel";
import { ParentChannelFragment } from "src/types.g";

const getDropStyle = (isDraggingOver) => ({
  background: isDraggingOver ? Theme.color.silver : "transparent",
});

const getDragStyle = (isDragging, draggableStyle) => {
  let transformYaxis = "";
  if (draggableStyle.transform && draggableStyle.transform !== "") {
    let values = draggableStyle.transform.split("(")[1];
    values = values.split(")")[0];
    values = values.split(",");
    transformYaxis = `translate(0, ${values[1]})`;
  }

  return {
    ...draggableStyle,
    boxShadow: isDragging ? "0 2px 15px 0 rgba(0,0,0,0.15)" : null,
    transform: transformYaxis,
  };
};

export interface ChannelContentsListProps {
  channelItems: any[];
  defaultTimeValue: TimeRange[];
  disabled?: boolean;
  isOnBoarding?: boolean;
  isRevertChange: boolean;
  selectedZone: string;
  totalZones: number;
  onChannelItemChange: (
    listId: string,
    action: ChannelContentActions,
    data: any,
  ) => void;
  onContentPick: () => void;
  onPreviewClick: (content: any, contentType: string) => void;
  onDurationFocus: (listId: string, isFocus: boolean) => void;
  readOnly?: boolean;
  isEditableSharedChannel: boolean;
  parentChannelByChildOf?: ParentChannelFragment | null;
}

interface ChannelContentsListState {}

class ChannelContentsList extends PureComponent<
  ChannelContentsListProps,
  ChannelContentsListState
> {
  public static contextType = AppContext;
  public context: AppContextType;

  public getPlaylistItemsDuration = memoize((list: any[]) => {
    return list.reduce((sum, item) => {
      const duration = item.duration;
      return sum + duration;
    }, 0);
  });

  constructor(props: ChannelContentsListProps) {
    super(props);
    this.state = {
      totaDaterangeItem: undefined,
    };
  }

  showFullscreenOption = (
    totalZones: number,
    selectedZone: string,
  ): boolean => {
    return totalZones > 1 && selectedZone === "zone1";
  };

  public getMasterContent = (readOnlyChannelItems, overlapList) => {
    return readOnlyChannelItems.map((item, itemIndex) => {
      const duration = item.duration;
      const isReadOnly = item.data.isReadOnly;
      return (
        <div
          key={itemIndex}
          className={classNames({
            ["read-only"]: isReadOnly,
            ["first-child"]: itemIndex === 0,
          })}
        >
          <ChannelContentItem
            key={`channel-item-${itemIndex}`}
            data-testid="channel-content-item"
            id={item.id}
            isExpired={item.isExpired}
            overlapList={overlapList[item.list_id]}
            listId={item.list_id}
            duration={duration}
            title={item.data.name}
            isPublished={
              item.type === RefType.PLAYLIST
                ? item.data.draft?.isPublished
                : false
            }
            playbackMode={item.playback?.mode}
            pickToPlay={item.playback?.pick_to_play}
            isRevertChange={this.props.isRevertChange}
            thumbnail={item.thumbnail}
            type={item.type}
            defaultTimeValues={this.props.defaultTimeValue}
            schedule={item.schedule}
            callback={this.props.onChannelItemChange}
            onPreviewClick={this.props.onPreviewClick}
            showFullscreenOption={this.showFullscreenOption(
              this.props.totalZones,
              this.props.selectedZone,
            )}
            onDurationFocus={(isFocus) =>
              this.props.onDurationFocus(item.list_id, isFocus)
            }
            readOnly={isReadOnly}
            isEditableSharedChannel={this.props.isEditableSharedChannel}
          />
        </div>
      );
    });
  };
  public render() {
    const channelItems = cloneDeep(this.props.channelItems);
    const readOnlyChannelItems =
      channelItems.filter((item) => item.data.isReadOnly) || [];
    const localChannelItems = channelItems.filter(
      (item) => !item.data.isReadOnly,
    );

    const overlapList = getScheduleOverlap(channelItems);
    return (
      <div className="channel-content-list">
        {this.props.disabled && (
          <div
            style={{
              backgroundColor: "rgba(255,255,255,0.2)",
              height: "100%",
              position: "absolute",
              top: 180,
              width: "100%",
              zIndex: 2,
            }}
          />
        )}
        {readOnlyChannelItems.length + localChannelItems.length === 0 && (
          <EmptyState section="channel-detail">
            <h3>
              <FormattedMessage
                data-testid="empty-channel-title"
                id="channels.channel_content_empty.there_is_nothing_here"
                defaultMessage="This channel is nothing without content"
              />
            </h3>
            <p>
              <FormattedMessage
                data-testid="empty-channel-content-message"
                id="channels.channel_content_empty.add_your_playlists_photos_videos_to_the_channel"
                defaultMessage="Add your playlists or individual pieces of content and schedule them for something great."
              />
            </p>
            <Button onClick={this.props.onContentPick}>
              <FormattedMessage
                data-testid="add-content-button"
                id="channels.channel_content_empty.add_content"
                defaultMessage="Add Content"
              />
            </Button>
          </EmptyState>
        )}
        <>
          {this.props.parentChannelByChildOf &&
            readOnlyChannelItems.length > 0 && (
              <div>
                <ContentAccordion
                  title={
                    <BoundLabel
                      instanceName={
                        this.props.parentChannelByChildOf?.name ?? ""
                      }
                      spaceName={
                        this.props.parentChannelByChildOf.spaceName ?? ""
                      }
                    />
                  }
                  description={`${readOnlyChannelItems.length} Items`}
                  content={this.getMasterContent(
                    readOnlyChannelItems,
                    overlapList,
                  )}
                />
              </div>
            )}
          <Droppable droppableId={"channelContentDropable"}>
            {(
              droppableProvided: DroppableProvided,
              droppableSnapshot: DroppableStateSnapshot,
            ) => (
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
                style={getDropStyle(droppableSnapshot.isDraggingOver)}
              >
                {localChannelItems.map((item, itemIndex) => {
                  const duration = item.duration;
                  return (
                    <Draggable
                      key={item.list_id}
                      draggableId={item.list_id}
                      index={itemIndex}
                    >
                      {(
                        draggableProvided: DraggableProvided,
                        draggableSnapshot: DraggableStateSnapshot,
                      ) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          style={getDragStyle(
                            draggableSnapshot.isDragging,
                            draggableProvided.draggableProps.style,
                          )}
                        >
                          <ChannelContentItem
                            key={`channel-item-${itemIndex}`}
                            data-testid="channel-content-item"
                            id={item.id}
                            isExpired={item.isExpired}
                            overlapList={overlapList[item.list_id]}
                            listId={item.list_id}
                            duration={duration}
                            title={item.data.name}
                            isPublished={
                              item.type === RefType.PLAYLIST
                                ? item.data.draft?.isPublished
                                : false
                            }
                            playbackMode={item.playback?.mode}
                            pickToPlay={item.playback?.pick_to_play}
                            isRevertChange={this.props.isRevertChange}
                            thumbnail={item.thumbnail}
                            type={item.type}
                            defaultTimeValues={this.props.defaultTimeValue}
                            schedule={item.schedule}
                            callback={this.props.onChannelItemChange}
                            onPreviewClick={this.props.onPreviewClick}
                            showFullscreenOption={this.showFullscreenOption(
                              this.props.totalZones,
                              this.props.selectedZone,
                            )}
                            onDurationFocus={(isFocus) =>
                              this.props.onDurationFocus(item.list_id, isFocus)
                            }
                            readOnly={false}
                            isEditableSharedChannel={
                              this.props.isEditableSharedChannel
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
          {channelItems.length > 0 && (
            <div className="cta">
              <Button
                className="btn-add-content"
                onClick={this.props.onContentPick}
              >
                <FormattedMessage
                  data-testid="add-content-button"
                  id="channels.channel_content_empty.add_content"
                  defaultMessage="Add Content"
                />
              </Button>
            </div>
          )}
        </>
      </div>
    );
  }
}

export default ChannelContentsList;
