import { useApolloClient } from "@apollo/client";
import { File, FilesConnection } from "src/types.g";

export const useModifyFolderCache = () => {
  const client = useApolloClient();
  const cache = client.cache;

  const removeFileFromFolder = ({
    file,
  }: {
    file: Pick<File, "id" | "folderId">;
  }) => {
    cache.modify({
      id: `Folder:${file.folderId}`,
      fields: {
        filesByFolderId(filesConnection: FilesConnection, options) {
          const nodesRef: File[] = filesConnection.nodes.filter(
            (node) => options.readField("id", node) !== file?.id,
          );
          return {
            ...filesConnection,
            nodes: nodesRef,
            totalCount: filesConnection.totalCount - 1,
          };
        },
      },
    });
  };
  return {
    removeFileFromFolder,
  };
};
