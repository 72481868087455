import { useState, useEffect } from "react";
import {
  useAllChannelsInOrgQuery,
  AllChannelsInOrgQueryVariables,
} from "src/types.g";
import { useAppContext } from "src/hooks/useAppContext";
import { renderFetchMorePickerButton } from "src/helpers/generalHelper";
import { useLoadMore } from "src/hooks/useLoadMore";
import { FIRST_FETCH_ITEMS } from "src/constants/constants";

export function useAllChannelsInOrg(props: { skip?: boolean }) {
  const context = useAppContext();
  const [isFirstTimeAlreadyLoaded, setIsFirstTimeAlreadyLoaded] =
    useState(false);

  const variables: AllChannelsInOrgQueryVariables = {
    orgId: context.currentOrg?.id,
    first: FIRST_FETCH_ITEMS,
    endCursor: null,
  };

  const { data, fetchMore, loading } = useAllChannelsInOrgQuery({
    fetchPolicy: "cache-first",
    variables,
    skip: props.skip,
  });

  const channelNodes = data?.orgById?.channelsByOrgId.nodes ?? [];
  const channelTotalCount = data?.orgById?.channelsByOrgId.totalCount;
  const isQueryHasNextPage =
    data?.orgById?.channelsByOrgId.pageInfo.hasNextPage;
  const endCursor = data?.orgById?.channelsByOrgId.pageInfo.endCursor;

  const { isLoading, loadMore } = useLoadMore(fetchMore, endCursor);

  useEffect(() => {
    setIsFirstTimeAlreadyLoaded(Boolean(data?.orgById));
  }, [data?.orgById]);

  const fetchMoreButton = renderFetchMorePickerButton(
    Number(channelNodes?.length),
    Number(channelTotalCount),
    isLoading,
    Boolean(isQueryHasNextPage),
    loadMore,
  );

  return {
    channels: channelNodes,
    isFirstTimeAlreadyLoaded,
    loading,
    renderFetchMoreButton: fetchMoreButton,
  };
}
