import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { useHistory } from "react-router";
import { ROUTES } from "src/constants/routes";
import { useSubscription } from "src/billinglatest/hooks/useSubscription";
import { usePaymentSource } from "src/billinglatest/hooks/usePaymentSource";

export interface WithActivateBanner {
  isShowActivateBanner: boolean;
  remainingTrialDays: number | null;
  onClickActivateBanner: () => void;
  planId: string | null;
}

const withActivateBanner =
  <T extends {}>(Component: React.ComponentType<T & WithActivateBanner>) =>
  (props: T) => {
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const subscription = useSubscription({ cache: true });
    const paymentSource = usePaymentSource({ cache: true });
    const onClickActivateBanner = () =>
      history.push(ROUTES.BILLING_LATEST_MANAGEMENT);

    /**
     * Fetch the subscription data from the billing API.
     */
    useEffect(() => {
      setIsLoading(true);
      const fetch = async () => {
        try {
          await subscription.fetch();
          await paymentSource.fetch();
          setIsLoading(false);
        } catch (error) {
          // Keep isLoading as true to just show nothing.
          Sentry.captureException(error);
        }
      };
      fetch();
    }, []);

    /**
     * Still loading or errored, then show nada!
     */
    if (isLoading) {
      return null;
    }

    /**
     * Show the banner
     */

    const isForceTrialEnd = localStorage.getItem("isTrialEnd") === "true";
    const planId = localStorage.getItem("planId") || subscription.get().planId;
    return (
      <Component
        {...props}
        isShowActivateBanner={
          isForceTrialEnd ||
          (subscription.isInTrial() && !paymentSource.exists())
        }
        remainingTrialDays={subscription.remainingTrialDays()}
        onClickActivateBanner={onClickActivateBanner}
        planId={planId}
      />
    );
  };

export default withActivateBanner;
