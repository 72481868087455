import { AllTagsQuery, Maybe, Tag } from "../types.g";

export const sortByTagname = (a: Tag, b: Tag) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

export const appendNewTags = (
  orgId: string,
  spaceId: string,
  newTags: Maybe<string>[],
  existingTags?: NonNullable<AllTagsQuery["allTags"]>["nodes"],
): NonNullable<AllTagsQuery["allTags"]>["nodes"] | undefined => {
  if (existingTags) {
    while (newTags.length > 0) {
      const newTagName = newTags[0];
      if (newTagName) {
        let match = false;
        for (const tag of existingTags) {
          if (tag && tag.name === newTagName) {
            newTags.shift();
            match = true;
            break;
          }
        }
        if (!match) {
          existingTags.push({
            __typename: "Tag",
            name: newTagName,
            orgId,
            spaceId,
          });
          newTags.shift();
        }
      }
    }

    existingTags.sort(sortByTagname);
  }

  return existingTags;
};
