import { uniq } from "lodash";
import * as React from "react";
import { Element, Events, scroller } from "react-scroll";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import { MediaParams } from "../../AppContextProvider/modals/type";
import { Typenames } from "../../constants/constants";
import { MediaDropResult } from "../../constants/mediaTypes";
import { isInMultipleSelection } from "../../helpers/reactEventHelper";
import { File, Folder, JobStatus } from "../../types.g";
import { CastedScreenInfoActions } from "../CastedScreenInfo";
import { CheckBoxStyle } from "../MediaPicker/media";
import TrackVisibility from "../TrackVisibility";
import MediaFile, { MediaFileActions } from "./mediaFile";
import MediaFolder, { MediaFolderActions } from "./mediaFolder";
import MediaItemDnD from "./mediaItemDnD";

export interface MediaItemsProps {
  files: File[];
  folders: Folder[];
  selectedMediaItems: (File | Folder)[];
  scrollToMediaId?: string;
  canPreview?: boolean;
  isReadonly?: boolean;
  canAddFileItem?: boolean;
  canDeleteFileItem?: boolean;
  canSelectFile?: boolean;
  canSelectFolder?: boolean;
  isSelectionActive?: boolean;
  isDraggableFile?: boolean;
  isDraggableFolder?: boolean;
  isDroppableFolder?: boolean;
  isMultiSelect?: boolean;
  isAutoMultiSelect?: boolean;
  isInPicker?: boolean;
  isCompactLayout?: boolean;
  isGridMode?: boolean;
  showFavorite?: boolean;
  showTags?: boolean;
  showActionMenu?: boolean;
  useReactDnD?: boolean;
  customContainer?: React.ReactNode;
  checkboxStyle?: CheckBoxStyle;
  onScrollToNewFolderFinish?: () => void;
  selectedMediaFileCallback?: (media: File | Folder) => void;
  onMediaFileCallback?: (
    mediaId: string[],
    action: MediaFileActions,
    value: File[] | string | boolean,
    params?: MediaParams,
  ) => void;
  onMediaFolderCallback?: (
    folderId: string,
    action: MediaFolderActions,
    value: File[] | string | boolean,
  ) => void;
  onDropMediaItems?: (dropResult: MediaDropResult) => void;
  onSelectMediaItems: (mediaItems: (File | Folder)[]) => void;
  castedScreensCallback?: (
    data: string,
    action: CastedScreenInfoActions,
    fileId?: string,
  ) => void;
  onLiveUpdateCallback?: () => void;
  showAddToPlaylists: boolean;
  targetContainerId?: string;
}

export interface MediaItemsState {
  lastSelectedIndex: number;
}

class MediaItems extends React.Component<MediaItemsProps, MediaItemsState> {
  public static contextType = AppContext;
  public context: AppContextType;

  private timeout;

  constructor(props: MediaItemsProps) {
    super(props);
    this.state = {
      lastSelectedIndex: -1,
    };
  }
  public componentDidMount() {
    Events.scrollEvent.register("end", (to, element) => {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = undefined;
      }
      if (this.props.onScrollToNewFolderFinish) {
        this.props.onScrollToNewFolderFinish();
      }
    });
  }

  public componentDidUpdate() {
    if (this.props.scrollToMediaId) {
      if (!this.timeout) {
        this.timeout = setTimeout(() => {
          scroller.scrollTo(this.props.scrollToMediaId, {
            delay: 0,
            duration: (distance) => {
              if (distance < 420) {
                return 0;
              }
              return 1500;
            },
            offset: -280,
            smooth: "easeInOutQuart",
          });
        }, 350);
      }
    }
  }

  public componentWillUnmount() {
    Events.scrollEvent.remove("end");
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }

  public getMediaItems = () => {
    const { folders, files } = this.props;
    return [...folders, ...files];
  };

  public selectItem = (
    media: File | Folder,
    selectedItems: (File | Folder)[],
  ) => {
    const foundItem = selectedItems.find(
      (item) => item.id === media.id && item.__typename === media.__typename,
    );
    if (selectedItems.length > 1) {
      return [media];
    } else {
      return foundItem ? [] : [media];
    }
  };

  public shiftSelectItem = (
    media: File | Folder,
    selectedIdx: number,
    lastIdx: number,
    selectedItems: (File | Folder)[],
    mediaItems: (File | Folder)[],
  ) => {
    let selected: (File | Folder)[] = [];
    if (selectedItems.length > 0) {
      if (lastIdx >= selectedIdx) {
        selected = [
          ...selectedItems,
          ...mediaItems.slice(selectedIdx, lastIdx),
        ];
      } else if (lastIdx !== -1) {
        selected = [
          ...selectedItems,
          ...mediaItems.slice(lastIdx, selectedIdx + 1),
        ];
      }
      return uniq(selected); // remove duplicated items
    }

    return this.selectItem(media, selectedItems);
  };

  public selectMultipleItem = (
    media: File | Folder,
    index: number,
    lastSelectedIndex: number,
    { ctrlKey, metaKey, shiftKey }: React.MouseEvent,
    selectedItems: (File | Folder)[],
    mediaItems: (File | Folder)[],
  ) => {
    // with shift key
    if (shiftKey) {
      return this.shiftSelectItem(
        media,
        index,
        lastSelectedIndex,
        selectedItems,
        mediaItems,
      );
    }

    // with ctrl/cmd key
    if (metaKey || ctrlKey || this.props.isAutoMultiSelect) {
      const foundItem = selectedItems.find(
        (item) => item.id === media.id && item.__typename === media.__typename,
      );
      if (foundItem) {
        return selectedItems.filter(
          (item) =>
            !(
              item.id === foundItem.id &&
              item.__typename === foundItem.__typename
            ),
        );
      } else {
        return [...selectedItems, media];
      }
    }

    return this.selectItem(media, selectedItems);
  };

  public handleSelectItemAction = async (
    media: File | Folder,
    index: number,
    event: React.MouseEvent,
  ) => {
    const { selectedMediaItems } = this.props;
    const lastSelectedIndex = this.state.lastSelectedIndex;
    const mediaItems = this.getMediaItems();

    const items = this.props.isMultiSelect
      ? this.selectMultipleItem(
          media,
          index,
          lastSelectedIndex,
          event,
          selectedMediaItems,
          mediaItems,
        )
      : this.selectItem(media, selectedMediaItems);

    this.setState({
      lastSelectedIndex: index,
    });

    if (this.props.onSelectMediaItems) {
      this.props.onSelectMediaItems(items);
    }
    if (
      this.props.scrollToMediaId !== "" &&
      this.props.onScrollToNewFolderFinish
    ) {
      this.props.onScrollToNewFolderFinish();
    }
  };

  public handleMediaFileCallback = async (
    id: string,
    action: MediaFileActions,
    value: string | boolean,
    event?: React.MouseEvent,
    index?: number,
    params?: MediaParams,
  ) => {
    const props = this.props as MediaItemsProps;
    if (index !== undefined) {
      const mediaFile = this.getMediaItems()[index] as File;

      switch (action) {
        case MediaFileActions.SELECTED:
          if (this.props.canSelectFile && event) {
            this.handleSelectItemAction(mediaFile, index, event);
          }
          break;
        case MediaFileActions.RENAME:
          if (props.onMediaFileCallback) {
            props.onMediaFileCallback([id], action, [
              { ...mediaFile, name: value as string },
            ]);
          }
          break;
        case MediaFileActions.REFETCH:
          if (props.onLiveUpdateCallback) {
            props.onLiveUpdateCallback();
          }
          break;
        default:
          if (props.onMediaFileCallback) {
            props.onMediaFileCallback([id], action, [mediaFile], params);
          }
      }
    }
  };

  public handleMediaFolderCallback = async (
    id: string,
    action: MediaFolderActions,
    value: File[] | string | boolean,
    event?: React.MouseEvent,
    index?: number,
  ) => {
    const {
      canSelectFolder,
      onMediaFolderCallback,
      scrollToMediaId,
      onScrollToNewFolderFinish,
    } = this.props;
    const mediaItems = this.getMediaItems();

    if (action === MediaFolderActions.SELECTED) {
      if (canSelectFolder && event && index !== undefined) {
        const mediaFolder = mediaItems[index] as Folder;
        return this.handleSelectItemAction(mediaFolder, index, event);
      }
    } else if (isInMultipleSelection(event)) {
      if (canSelectFolder && event && index !== undefined) {
        const mediaFolder = mediaItems[index] as Folder;
        return this.handleSelectItemAction(mediaFolder, index, event);
      }
    } else {
      return onMediaFolderCallback && onMediaFolderCallback(id, action, value);
    }

    if (scrollToMediaId !== "" && onScrollToNewFolderFinish) {
      return onScrollToNewFolderFinish();
    }
  };

  public renderMediaFolder = (
    folder: Folder,
    index: number,
    selected: boolean,
  ) => {
    const { selectedMediaItems } = this.props;
    const selectedFolder =
      selectedMediaItems.length > 1 ? selectedMediaItems : [folder];
    return (
      <TrackVisibility
        key={`trackvisibility-media-${index}`}
        className="media-visibility"
        container={
          this.props.targetContainerId
            ? document.getElementById(this.props.targetContainerId)
            : this.props.customContainer
        }
        partialVisibility={true}
        offset={500}
      >
        {({ isVisible }) => (
          <MediaItemDnD
            key={folder.id}
            index={index}
            media={folder}
            selectedMediaItems={selectedFolder}
            selectedMediaFileCallback={this.props.selectedMediaFileCallback}
            isDraggable={this.props.isDraggableFolder}
            isDroppable={this.props.isDroppableFolder}
            onDrop={this.props.onDropMediaItems}
            useReactDnD={this.props.useReactDnD}
          >
            <Element name={folder.id}>
              <MediaFolder
                key={`media-folder-picker-${folder.id}`}
                callBack={this.handleMediaFolderCallback}
                index={index}
                media={folder}
                canSelect={this.props.canSelectFolder}
                isReadonly={this.props.isReadonly}
                isSelected={selected}
                isNewFolder={this.props.scrollToMediaId === folder.id}
                isCompactLayout={this.props.isCompactLayout}
                isSelectionActive={this.props.isSelectionActive}
                showActionMenu={this.props.showActionMenu}
                showFavorite={this.props.showFavorite}
                isVisible={isVisible}
                hideShareIcon={Boolean(this.props.isInPicker)}
                hideCheckbox={Boolean(this.props.isInPicker)}
              />
            </Element>
          </MediaItemDnD>
        )}
      </TrackVisibility>
    );
  };

  public renderMediaFile = (file: File, index: number, selected: boolean) => {
    const { selectedMediaItems } = this.props;
    const selectedFiles =
      selectedMediaItems.length > 1 ? selectedMediaItems : [file];

    return (
      <TrackVisibility
        className="media-visibility"
        key={`trackvisibility-media-${index}`}
        container={
          this.props.targetContainerId
            ? document.getElementById(this.props.targetContainerId)
            : this.props.customContainer
        }
        partialVisibility={true}
        offset={500}
      >
        {({ isVisible }) => (
          <MediaItemDnD
            key={file.id}
            index={index}
            media={file}
            selectedMediaItems={selectedFiles}
            selectedMediaFileCallback={this.props.selectedMediaFileCallback}
            isDraggable={this.props.isDraggableFile}
            isDroppable={false}
            useReactDnD={this.props.useReactDnD}
          >
            <Element name={file.id}>
              <MediaFile
                key={`media-file-picker-${file.id}`}
                callBack={this.handleMediaFileCallback}
                castedScreensCallback={(data, action) =>
                  this.props.castedScreensCallback &&
                  this.props.castedScreensCallback(data, action, file.id)
                }
                index={index}
                duration={0}
                media={file}
                canAdd={
                  file.fileProcessingStatus === JobStatus.Failed
                    ? false
                    : this.props.canAddFileItem
                }
                canSelect={
                  this.props.isInPicker &&
                  (file.fileProcessingStatus === JobStatus.Failed ||
                    file.fileProcessingStatus === null)
                    ? false
                    : this.props.canSelectFile
                }
                canDelete={this.props.canDeleteFileItem}
                canPreview={this.props.canPreview}
                isReadonly={
                  this.props.isInPicker &&
                  file.fileProcessingStatus === JobStatus.Failed
                    ? true
                    : this.props.isReadonly
                }
                isSelected={selected}
                isSelectionActive={this.props.isSelectionActive}
                isGridMode={this.props.isGridMode}
                isCompactLayout={this.props.isCompactLayout}
                showActionMenu={this.props.showActionMenu}
                showFavorite={this.props.showFavorite}
                showAddToPlaylists={this.props.showAddToPlaylists}
                showTags={this.props.showTags}
                shouldHighlighted={this.props.scrollToMediaId === file.id}
                isVisible={isVisible}
                checkboxStyle={this.props.checkboxStyle}
              />
            </Element>
          </MediaItemDnD>
        )}
      </TrackVisibility>
    );
  };

  public isMediaSelected = (mediaId: string, type: string = "") => {
    const { selectedMediaItems } = this.props;
    return selectedMediaItems.some(
      (item) => item.id === mediaId && item.__typename === type,
    );
  };

  public renderMediaItems = (mediaItems: (File | Folder)[]) => {
    return mediaItems.map((media, index) => {
      const selected = this.isMediaSelected(media.id, media.__typename);

      if (media.__typename === Typenames.Folder) {
        return this.renderMediaFolder(media as Folder, index, selected);
      }

      if (media.__typename === Typenames.File) {
        return this.renderMediaFile(media as File, index, selected);
      }

      return null;
    });
  };

  public render() {
    const mediaItems = this.getMediaItems();
    return <>{this.renderMediaItems(mediaItems)}</>;
  }
}

export default MediaItems;
