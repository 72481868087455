import { Maybe } from "src/types.g";

export const removeAppByAppId = <
  T extends { appByAppId: Maybe<{ id: string | null }> },
>(
  apps: T[],
  appId: string,
) => {
  return apps.filter((app) => app.appByAppId?.id !== appId);
};
