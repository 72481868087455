import MarkdownPreview from "@uiw/react-markdown-preview";
import { Icon, Popover } from "@screencloud/screencloud-ui-components";
import clsx from "clsx";
import spacetime from "spacetime";
import { ActiveMessage, MessageType } from "./types";
import {
  NotificationBadge,
  PopoverBadgeStyled,
  PopoverNotificationBadgesStyled,
} from "./styles";
import { capitalizeFirstLetter } from "../../../../../utils";

const NotificationRow: React.FC<{ message: ActiveMessage }> = ({ message }) => {
  const messageTypeClassName = clsx("font-weight-700", message.type);
  const iconClassName = clsx(
    messageTypeClassName,
    "icon-background-" + message.type,
  );
  const className = clsx("notification-row", "background-" + message.type);
  const titleClassNames = clsx(
    "col-start-2",
    "col-end-4",
    "font-weight-700",
    `${message.type}-800`,
  );
  return (
    <div className={className}>
      <Icon className={iconClassName} name="info" />
      <span className={messageTypeClassName}>
        {capitalizeFirstLetter(message.type)}
      </span>
      <span className="gray-300">
        {spacetime(message.createdAt).unixFmt("MMM dd, yyyy, hh:mm a")}
      </span>
      <span className={titleClassNames}>{message.name}</span>
      {message.message && (
        <span className="gray-400 col-start-2 col-end-4 row-start-3">
          <MarkdownPreview
            source={message.message}
            className="markdown-preview"
          />
        </span>
      )}
    </div>
  );
};

interface PopoverNotificationBadgesProps {
  messages: ActiveMessage[] | null;
  mini?: boolean;
}
export const PopoverNotificationBadges: React.FC<
  PopoverNotificationBadgesProps
> = ({ messages, mini }) => {
  const displayBadgeTypes: MessageType[] = [
    "critical",
    "error",
    "warning",
    "info",
  ];

  if (!messages) return null;

  // Get the current date
  const currentDate = spacetime.now();

  // Filter the messages
  const filteredMessages = messages.filter((message) => {
    const startDate = spacetime(message.startDate);
    const endDate = message.endDate ? spacetime(message.endDate) : null;

    // If there's an end date, check if the current date is between the start and end dates
    if (endDate) {
      return startDate.isBefore(currentDate) && endDate.isAfter(currentDate);
    }

    // If there's no end date, check if the current date is after the start date
    return startDate.isBefore(currentDate);
  });

  // Group messages by type
  const groupedMessages = filteredMessages.reduce(
    (grouped, message) => {
      (grouped[message.type] = grouped[message.type] || []).push(message);
      return grouped;
    },
    {} as Record<MessageType, ActiveMessage[]>,
  );

  // Create a new array of message groups, sorted by type and then by start date within each type
  const sortedGroupedMessages = displayBadgeTypes.flatMap((type) => {
    if (groupedMessages[type]) {
      return groupedMessages[type].sort((a: ActiveMessage, b: ActiveMessage) =>
        spacetime(a.startDate).isBefore(spacetime(b.startDate)) ? -1 : 1,
      );
    }
    return [];
  });

  return (
    <PopoverNotificationBadgesStyled
      className="popover-notification-badges"
      data-testid="popover-notification-badges"
    >
      <Popover
        className="popover-notification-container"
        trigger={
          <div className="popover-badge-style">
            {displayBadgeTypes.map((type) => {
              const count = sortedGroupedMessages.filter(
                (message) => message.type === type,
              ).length;
              if (count === 0) return null;
              return (
                <NotificationBadge
                  key={`notification-badge-${type}`}
                  type={type}
                >
                  <Icon name="info" />
                  <span>
                    {count}
                    {!mini &&
                      ` ${capitalizeFirstLetter(type)}${count > 1 ? "s" : ""}`}
                  </span>
                </NotificationBadge>
              );
            })}
          </div>
        }
        content={
          <PopoverBadgeStyled>
            {sortedGroupedMessages.map((message) => (
              <NotificationRow key={message.id} message={message} />
            ))}
          </PopoverBadgeStyled>
        }
        on="click"
        position="bottom center"
      />
    </PopoverNotificationBadgesStyled>
  );
};
