import { UUID } from "@screencloud/uuid";
import {
  getMediaPicker,
  getMediaPickerSkipProps,
} from "src/domains/mediaPicker/mediaPicker";
import { useAllChannelsInCurrentSpace } from "src/hooks/useChannelList";
import { ChannelListItemFragment } from "src/types.g";
import { isOrgWidePath } from "src/utils/orgWideFeature";

import { useAllChannelsInOrg } from "./useAllChannelsInOrg";

export interface UseChannelPicker {
  channels: ChannelListItemFragment[];
  isFirstTimeAlreadyLoaded: boolean;
  loading: boolean;
  renderFetchMoreButton: React.ReactNode;
}

export function useChannelPicker(spaceId?: UUID): UseChannelPicker {
  const isOrgWide = isOrgWidePath();
  const { skipOrgWideQuery, skipSpaceWideQuery } = getMediaPickerSkipProps({
    isOrgWide,
  });

  return getMediaPicker({
    isOrgWide,
    orgWideMediaPicker: useAllChannelsInOrg({ skip: skipOrgWideQuery }),
    spaceWideMediaPicker: useAllChannelsInCurrentSpace({
      spaceId: spaceId ?? undefined,
      skip: skipSpaceWideQuery,
    }),
  }) as unknown as UseChannelPicker; // TODO vite migration: remove this as unknown as;
}
