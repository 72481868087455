import { UUID } from "@screencloud/uuid";
import { FIRST_FETCH_ITEMS } from "src/constants/constants";
import { renderFetchMorePickerButton } from "src/helpers/generalHelper";
import { useAppContext } from "src/hooks/useAppContext";
import { useLoadMore } from "src/hooks/useLoadMore";
import {
  AllPlaylistsInSpaceQueryVariables,
  Playlist,
  useAllInternalPlaylistsInSpaceQuery,
  useAllPlaylistsInSpaceQuery,
} from "src/types.g";

interface AllPlaylistInCurrentSpaceParams {
  skip: boolean;
  excludeShare?: boolean;
  spaceId?: UUID;
}

/**
 *
 * @returns All playlist in current spaces, including shared one
 */
export function useAllPlaylistInCurrentSpace({
  skip,
  excludeShare,
  spaceId,
}: AllPlaylistInCurrentSpaceParams) {
  const context = useAppContext();

  const variables: AllPlaylistsInSpaceQueryVariables = {
    spaceId: spaceId ?? context?.currentSpace?.id,
    first: FIRST_FETCH_ITEMS,
    endCursor: null,
  };
  const playlistQuery = excludeShare
    ? useAllInternalPlaylistsInSpaceQuery
    : useAllPlaylistsInSpaceQuery;
  const { data, fetchMore, loading } = playlistQuery({
    variables,
    fetchPolicy: "cache-first",
    skip,
  });

  const playlistNodes = (data?.spaceById?.publishedPlaylistsBySpaceId.nodes ??
    []) as Playlist[];
  const playlistTotalCount =
    data?.spaceById?.publishedPlaylistsBySpaceId.totalCount;
  const isQueryHasNextPage =
    data?.spaceById?.publishedPlaylistsBySpaceId.pageInfo.hasNextPage;
  const endCursor =
    data?.spaceById?.publishedPlaylistsBySpaceId.pageInfo.endCursor;

  const { isLoading, loadMore } = useLoadMore(fetchMore, endCursor);

  const fetchMoreButton = renderFetchMorePickerButton(
    Number(playlistNodes?.length),
    Number(playlistTotalCount),
    isLoading,
    Boolean(isQueryHasNextPage) && playlistNodes.length! < playlistTotalCount!,
    loadMore,
  );

  return {
    playlists: playlistNodes,
    isLoading,
    loading,
    renderFetchMoreButton: fetchMoreButton,
  };
}
