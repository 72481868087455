import { FEATURE_FLAGS_ENUM } from "src/constants/featureFlag";
import {
  MutationUnSubGainsightForUserArgs,
  UnSubGainsightForUserDocument,
  UnSubGainsightForUserResponse,
} from "src/types.g";
const unsubGainsight = async (
  apolloClient,
  identifyId: string,
  featureFlags: string[],
) => {
  if (
    featureFlags.includes(FEATURE_FLAGS_ENUM.UNSUB_GAINSIGHT) &&
    !localStorage.getItem("unsubGainsight")
  ) {
    try {
      const mutation = UnSubGainsightForUserDocument;

      const inputArgs: MutationUnSubGainsightForUserArgs = {
        identifyId,
        globalUnsubscribe: true,
        guidedTours: true,
        onboardingBot: true,
        productUpdates: true,
        surveys: true,
        trackEmailAnalytics: true,
        trackUsage: true,
      };

      const response = await apolloClient.mutate({
        mutation,
        variables: inputArgs,
      });

      const gainsightResponse: UnSubGainsightForUserResponse =
        response.data?.unSubGainsightForUser;

      if (
        gainsightResponse?.globalUnsubscribeUpdateStatus === 200 &&
        gainsightResponse?.userPreferencesUpdateStatus === 200
      ) {
        localStorage.setItem("unsubGainsight", "true");
      } else {
        console.error("Unsubscription was not successful");
      }
    } catch (error) {
      console.error("Error unsubscribing from Gainsight:", error);
    }
  }
};

export default unsubGainsight;
