import { FunctionComponent, ReactElement } from "react";
import {
  useFetchSecureMediaPolicy,
  IFetchMediaPolicyOutput,
} from "@screencloud/studio-media-client";
import { ssm } from "../../../../src/state/session/ssm";

export interface SecureMediaPolicyProviderProps {
  mediaServiceEndpoint: string;
  children: (
    mediaPolicyOutput?: IFetchMediaPolicyOutput,
  ) => ReactElement | null;
}

export const SecureMediaPolicyProvider: FunctionComponent<
  SecureMediaPolicyProviderProps
> = (props) => {
  const { mediaServiceEndpoint, children } = props;

  const mediaPolicy = useFetchSecureMediaPolicy(
    {
      mediaServiceEndpoint,
      studioAuthToken: ssm.current.token as string,
      maxRetryTimeMs: 10000,
      deviceTimeOffsetMs: 0,
      cache: true,
    },
    {
      fetchApi: fetch,
    },
  );

  return mediaPolicy.policy ? children(mediaPolicy) : children();
};
