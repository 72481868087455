import { Button, Icon } from "@screencloud/screencloud-ui-components";
import {
  ClickEvent,
  ElementData,
  InputEvent,
  Message,
  MessageType,
} from "@screencloud/secure-sites-extension-types";
import { SiteContext } from "../../sitecontext";
import React, { useContext } from "react";
import { ActionName, EnterKeyValue, SpaceKeyValue } from "../reducers";
import { ActionContainer, ActionDetailContainer, Description } from "../styles";
import { EditableActionTypes } from "./editableactiontypes";
import {
  ClickAction,
  ClickNewTabAction,
  DelayDurationAction,
  DelayElementAction,
  EnterOneTimeCodeAction,
  EnterPasswordAction,
  EnterTextAction,
  EnterTextConfig,
  EnterUsernameAction,
  HoverAction,
  NavigateAction,
  RenderDurationAction,
  RenderSingleAction,
  ScrollAction,
  SelectOptionAction,
  SelectOptionConfig,
  SessionKeepAliveAction,
  SiteRecorderAction,
  SiteRecorderActionType,
} from "./models";
import { useAppContext } from "../../../../hooks/useAppContext";

interface ActionProps {
  index: number;
  step?: string;
  removeItem: (index: number) => void;
  selectItem?: (index: number) => void;
  isExpandButtonHidden?: boolean;
  actionId: string;
  currentEditorActionId?: string;
}

const getErrorMessage = (type) => {
  const { intl } = useAppContext();

  switch (type) {
    case SiteRecorderActionType.EnterUsername:
      return intl.formatMessage({
        defaultMessage: "No associated username",
        id: "ui_component.site.recorder.no_associated_username",
      });
    case SiteRecorderActionType.EnterPassword:
      return intl.formatMessage({
        defaultMessage: "No associated password",
        id: "ui_component.site.recorder.no_associated_password",
      });
    case SiteRecorderActionType.EnterOneTimeCode:
      return intl.formatMessage({
        defaultMessage: "Requires Secret Key to complete setup",
        id: "ui_component.site.recorder.requires_secret_key",
      });
    default:
      return undefined;
  }
};

const errorTypes: string[] = [
  SiteRecorderActionType.EnterPassword,
  SiteRecorderActionType.EnterUsername,
  SiteRecorderActionType.EnterOneTimeCode,
];

const BUTTON_TITLES = ["Left click", "Middle click", "Right click"];

const Action: React.FC<ActionProps> = ({
  step,
  children,
  index,
  removeItem,
  selectItem,
  isExpandButtonHidden,
  currentEditorActionId,
  actionId,
}) => {
  const siteContext = useContext(SiteContext);
  const settingsButtonLabel =
    currentEditorActionId && currentEditorActionId === actionId
      ? "Hide"
      : "Expand";

  const deleteControl =
    removeItem !== undefined && !!!siteContext.isPreview ? (
      <div className="delete-control" onClick={() => removeItem(index + 1)}>
        <Icon name="trash" className="icon" />
      </div>
    ) : (
      <></>
    );

  const settingsControl =
    !!selectItem && !!!siteContext.isPreview ? (
      <div className="settings-control">
        <Button onClick={() => selectItem(index)}>{settingsButtonLabel}</Button>
      </div>
    ) : (
      <></>
    );

  return (
    <ActionContainer
      className={`${index === 0 ? "first" : ""} ${
        isExpandButtonHidden ? "pointer shadow" : ""
      }`}
    >
      <div
        className="vertical-link container"
        onClick={() => {
          if (selectItem && !siteContext.isPreview && isExpandButtonHidden) {
            selectItem(index);
          }
        }}
      >
        <div className="details">{children}</div>
        {!isExpandButtonHidden && settingsControl}
        {deleteControl}
      </div>
    </ActionContainer>
  );
};

interface ActionDetailProps {
  icon: string;
  name: string;
  description?: string;
  hasSecretKey?: boolean;
  type?: string;
}

const showDescription = (description, hasSecretKey): boolean => {
  if (description) {
    return !hasSecretKey;
  }
  return false;
};

const isErrorTypeAndDescription = (type, description): boolean => {
  const errorDescriptions = [
    getErrorMessage(SiteRecorderActionType.EnterPassword),
    getErrorMessage(SiteRecorderActionType.EnterUsername),
    getErrorMessage(SiteRecorderActionType.EnterOneTimeCode),
  ];
  return errorTypes.includes(type) && errorDescriptions.includes(description);
};

const ActionDetail: React.FC<ActionDetailProps> = ({
  icon,
  name,
  description,
  hasSecretKey,
  type,
}) => {
  if (name === ActionName.MANAGE_SESSION) {
    return (
      <ActionDetailContainer>
        <div className="details">
          <div className="name manage-session inline">
            <div>{name}</div> <Icon className="icon" name={icon} />
          </div>
          {showDescription(description, hasSecretKey) && (
            <div className="description">
              <Description
                isErrorTypeAndDescription={isErrorTypeAndDescription(
                  type,
                  description,
                )}
              >
                {description}
              </Description>
            </div>
          )}
        </div>
      </ActionDetailContainer>
    );
  } else {
    return (
      <ActionDetailContainer>
        <div className={`icon-container ${icon}`}>
          <Icon className="icon" name={icon} />
        </div>
        <div className="details">
          <div className="name">{name}</div>
          {showDescription(description, hasSecretKey) && (
            <div className="description">
              <Description
                isErrorTypeAndDescription={isErrorTypeAndDescription(
                  type,
                  description,
                )}
              >
                {description}
              </Description>
            </div>
          )}
        </div>
      </ActionDetailContainer>
    );
  }
};

const Hover = (props: { action: HoverAction }) => {
  return <ActionDetail icon="plus" name={props.action.name} />;
};

const Navigate = (props: { action: NavigateAction }) => {
  return (
    <ActionDetail
      icon="link"
      name={props.action.name}
      description={props.action.config.url}
    />
  );
};

const Click = (props: { action: ClickAction }) => {
  const description = BUTTON_TITLES[props.action.config.button];
  return (
    <ActionDetail
      icon="screen-guide"
      name={props.action.name}
      description={description}
    />
  );
};

const ClickNewTab = (props: { action: ClickNewTabAction }) => {
  return <ActionDetail icon="external" name={props.action.name} />;
};

const TakeSnapshot = (props: { action: RenderSingleAction }) => {
  return (
    <ActionDetail
      icon="camera"
      name={props.action.name}
      description={"Entire Page"}
    />
  );
};

const EnterText = (props: { action: EnterTextAction }) => {
  return (
    <ActionDetail
      icon="align-text-left"
      name={props.action.name}
      description={props.action.config.input}
    />
  );
};

const EnterUsername = (props: { action: EnterUsernameAction }) => {
  const passwordMessage = getErrorMessage(SiteRecorderActionType.EnterPassword);
  return (
    <ActionDetail
      icon="user"
      name={props.action.name}
      description={
        props.action.config.credPairId
          ? props.action.config.input
          : passwordMessage
      }
      type={props.action.type}
    />
  );
};

const EnterOneTimeCode = (props: { action: EnterOneTimeCodeAction }) => {
  const secretKeyMessage = getErrorMessage(props.action.type);
  const hasSecretKey =
    !props.action.config.secretKey || !props.action.config.credentialName
      ? false
      : true;
  return (
    <ActionDetail
      icon="lock"
      name={props.action.name}
      description={secretKeyMessage}
      hasSecretKey={hasSecretKey}
      type={props.action.type}
    />
  );
};

const EnterPassword = (props: { action: EnterPasswordAction }) => {
  const text = "\u25cf".repeat(props.action.config.input.length);
  const usernameMessage = getErrorMessage(SiteRecorderActionType.EnterUsername);

  return (
    <ActionDetail
      icon="lock"
      name={props.action.name}
      description={props.action.config.credPairId ? text : usernameMessage}
      type={props.action.type}
    />
  );
};

const RenderElement = (props: { action: RenderSingleAction }) => {
  return <ActionDetail icon="camera" name={props.action.name} />;
};

const RenderDuration = (props: { action: RenderDurationAction }) => {
  const { interval } = props.action.config;

  return (
    <ActionDetail
      icon="camera"
      name={props.action.name}
      description={`Every second for ${interval}`}
    />
  );
};

const SelectOption = (props: { action: SelectOptionAction }) => {
  const { text } = props.action.config;

  return (
    <ActionDetail
      icon="app"
      name={props.action.name}
      description={`Selected option ${text}`}
    />
  );
};

const DelayDuration = (props: { action: DelayDurationAction }) => {
  const { interval } = props.action.config;

  return (
    <ActionDetail
      icon="clock"
      name={props.action.name}
      description={`Wait for ${interval}`}
    />
  );
};

const DelayElement = (props: { action: DelayElementAction }) => {
  return <ActionDetail icon="clock" name={props.action.name} />;
};

const Scroll = (props: { action: ScrollAction }) => {
  const { x, y } = props.action.config;
  const hScroll =
    x !== 0 ? `${x} pixel${Math.abs(x) > 1 ? "s" : ""} horizontally` : ``;
  const vScroll =
    y !== 0 ? `${y} pixel${Math.abs(y) > 1 ? "s" : ""} vertically` : ``;

  return (
    <ActionDetail
      icon="switch"
      name={props.action.name}
      description={`${vScroll} ${hScroll}`}
    />
  );
};

const SessionKeepAlive = (props: { action: SessionKeepAliveAction }) => {
  return <ActionDetail icon="settings" name={props.action.name} />;
};

export const renderAction = (
  action: SiteRecorderAction,
  index: number,
  removeItem: any,
  selectItem?: (index: number) => void,
  step?: string,
  currentEditorActionId?: string | undefined,
) => {
  if (
    !EditableActionTypes.includes(action.type) ||
    action.config?.input === EnterKeyValue ||
    action.config?.input === SpaceKeyValue
  ) {
    selectItem = undefined;
  }
  switch (action.type) {
    case SiteRecorderActionType.Click:
      return (
        <Action
          key={index}
          index={index}
          step={step}
          removeItem={removeItem}
          selectItem={selectItem}
          currentEditorActionId={currentEditorActionId}
          actionId={action.id}
        >
          <Click action={action} />
        </Action>
      );
    case SiteRecorderActionType.ClickNewTab:
      return (
        <Action
          key={index}
          index={index}
          step={step}
          removeItem={removeItem}
          selectItem={selectItem}
          currentEditorActionId={currentEditorActionId}
          actionId={action.id}
        >
          <ClickNewTab action={action} />
        </Action>
      );
    case SiteRecorderActionType.SelectOption:
      return (
        <Action
          key={index}
          index={index}
          step={step}
          removeItem={removeItem}
          selectItem={selectItem}
          currentEditorActionId={currentEditorActionId}
          actionId={action.id}
        >
          <SelectOption action={action} />
        </Action>
      );
    case SiteRecorderActionType.DelayDuration:
      return (
        <Action
          key={index}
          index={index}
          step={step}
          removeItem={removeItem}
          selectItem={selectItem}
          currentEditorActionId={currentEditorActionId}
          actionId={action.id}
        >
          <DelayDuration action={action} />
        </Action>
      );
    case SiteRecorderActionType.DelayElement:
      return (
        <Action
          key={index}
          index={index}
          step={step}
          removeItem={removeItem}
          selectItem={selectItem}
          currentEditorActionId={currentEditorActionId}
          actionId={action.id}
        >
          <DelayElement action={action} />
        </Action>
      );
    case SiteRecorderActionType.EnterOneTimeCode:
      return (
        <Action
          key={index}
          index={index}
          step={step}
          removeItem={removeItem}
          selectItem={selectItem}
          currentEditorActionId={currentEditorActionId}
          actionId={action.id}
        >
          <EnterOneTimeCode action={action} />
        </Action>
      );
    case SiteRecorderActionType.EnterPassword:
      return (
        <Action
          key={index}
          index={index}
          step={step}
          removeItem={removeItem}
          selectItem={selectItem}
          currentEditorActionId={currentEditorActionId}
          actionId={action.id}
        >
          <EnterPassword action={action} />
        </Action>
      );
    case SiteRecorderActionType.EnterText:
      return (
        <Action
          key={index}
          index={index}
          step={step}
          removeItem={removeItem}
          selectItem={selectItem}
          currentEditorActionId={currentEditorActionId}
          actionId={action.id}
        >
          <EnterText action={action} />
        </Action>
      );
    case SiteRecorderActionType.EnterUsername:
      return (
        <Action
          key={index}
          index={index}
          step={step}
          removeItem={removeItem}
          selectItem={selectItem}
          currentEditorActionId={currentEditorActionId}
          actionId={action.id}
        >
          <EnterUsername action={action} />
        </Action>
      );
    case SiteRecorderActionType.Hover:
      return (
        <Action
          key={index}
          index={index}
          step={step}
          removeItem={removeItem}
          selectItem={selectItem}
          currentEditorActionId={currentEditorActionId}
          actionId={action.id}
        >
          <Hover action={action} />
        </Action>
      );
    case SiteRecorderActionType.Navigate:
      if (index < 2) {
        removeItem = undefined;
      }
      return (
        <Action
          key={index}
          index={index}
          step={step}
          removeItem={removeItem}
          selectItem={selectItem}
          currentEditorActionId={currentEditorActionId}
          actionId={action.id}
        >
          <Navigate action={action} />
        </Action>
      );
    case SiteRecorderActionType.RenderDuration:
      return (
        <Action
          key={index}
          index={index}
          step={step}
          removeItem={removeItem}
          selectItem={selectItem}
          currentEditorActionId={currentEditorActionId}
          actionId={action.id}
        >
          <RenderDuration action={action} />
        </Action>
      );
    case SiteRecorderActionType.RenderSingle:
      if (action.name === "Take snapshot") {
        removeItem = undefined;
        return (
          <Action
            key={index}
            index={index}
            step={step}
            removeItem={removeItem}
            selectItem={selectItem}
            currentEditorActionId={currentEditorActionId}
            actionId={action.id}
          >
            <TakeSnapshot action={action} />
          </Action>
        );
      }
      return (
        <Action
          key={index}
          index={index}
          step={step}
          removeItem={removeItem}
          selectItem={selectItem}
          currentEditorActionId={currentEditorActionId}
          actionId={action.id}
        >
          <RenderElement action={action} />
        </Action>
      );
    case SiteRecorderActionType.Scroll:
      return (
        <Action
          key={index}
          index={index}
          step={step}
          removeItem={removeItem}
          selectItem={selectItem}
          currentEditorActionId={currentEditorActionId}
          actionId={action.id}
        >
          <Scroll action={action} />
        </Action>
      );
    case SiteRecorderActionType.SessionKeepAlive:
      removeItem = undefined;
      return (
        <Action
          key={index}
          index={index}
          step={step}
          removeItem={removeItem}
          selectItem={selectItem}
          isExpandButtonHidden={true}
          currentEditorActionId={currentEditorActionId}
          actionId={action.id}
        >
          <SessionKeepAlive action={action} />
        </Action>
      );
    default:
      return <></>;
  }
};

export const createActionFromMessage = (
  message: Message,
): SiteRecorderAction | null => {
  const element: ElementData | undefined = message.payload?.element;

  if (!element) {
    return null;
  }

  switch (message.type) {
    case MessageType.ClickEvent: {
      const button = (message as ClickEvent).payload!.button;
      let clickType = "Click";

      if (button === 1) {
        clickType = "Middle click";
      } else if (button === 2) {
        clickType = "Right click";
      }

      return new ClickAction(
        `${clickType} on ${getElementDescription(element)}`,
        {
          url: message.payload.url,
          element: element.tagName,
          selector: element.selector,
          button,
          type: element.type,
          id: element.id,
          shortSelector: element.shortSelector,
          className: element.className,
          value: element.value,
          ariaLabel: element.ariaLabel,
          text: element.text,
          alt: element.alt,
          coordinates: message.payload.coordinates
            ? message.payload.coordinates
            : undefined,
          offsetCoordinates: message.payload.offsetCoordinates
            ? message.payload.offsetCoordinates
            : undefined,
          iframe: message.payload.iframe || undefined,
          role: element.role || undefined,
          a11yName: element.a11yName || undefined,
          nameAttr: element.name || undefined,
        },
      );
    }

    case MessageType.SelectOptionEvent: {
      const config: SelectOptionConfig = {
        url: message.payload!.url,
        element: element.tagName,
        selector: element.selector,
        input: message.payload!.value,
        text: message.payload!.text,
        type: element.type,
        id: element.id,
        shortSelector: element.shortSelector,
        className: element.className,
        iframe: message.payload.iframe || undefined,
        role: element.role || undefined,
        a11yName: element.a11yName || undefined,
        nameAttr: element.name || undefined,
      };

      return new SelectOptionAction(
        `Select option from ${getElementDescription(element)}`,
        config,
      );
    }

    case MessageType.InputEvent: {
      const config: EnterTextConfig = {
        url: message.payload.url,
        element: element.tagName,
        selector: element.selector,
        input: (message as InputEvent).payload!.value,
        type: element.type,
        id: element.id,
        shortSelector: element.shortSelector,
        className: element.className,
        value: element.value,
        ariaLabel: element.ariaLabel,
        placeholder: element.placeholder,
        iframe: message.payload.iframe || undefined,
        role: element.role || undefined,
        a11yName: element.a11yName || undefined,
        nameAttr: element.name || undefined,
      };

      if (isPasswordField(element)) {
        // need to actually ensure that the type is password and not input
        element.type = "password";
        config.type = "password";

        return new EnterPasswordAction(
          `Enter password into ${getElementDescription(element)}`,
          config,
        );
      }

      if (isOneTimePasscodeField(element)) {
        return new EnterOneTimeCodeAction(
          `Enter OTP into ${getElementDescription(element)}`,
          config,
        );
      }

      if (isUsernameField(element, message)) {
        return new EnterUsernameAction(
          `Enter username into ${getElementDescription(element)}`,
          config,
        );
      }

      return new EnterTextAction(
        `Enter text into ${getElementDescription(element)}`,
        config,
      );
    }
    case MessageType.SelectElement:
      return new RenderSingleAction(
        `Render ${getElementDescription(element)}`,
        {
          url: message.payload.url,
          element: element.tagName,
          selector: element.selector,
          type: element.type,
          id: element.id,
          shortSelector: element.shortSelector,
          className: element.className,
          iframe: message.payload.iframe || undefined,
          role: element.role || undefined,
          a11yName: element.a11yName || undefined,
          nameAttr: element.name || undefined,
        },
      );
    case MessageType.EnterKeyPress:
      const config: EnterTextConfig = {
        url: message.payload.url,
        element: element.tagName,
        selector: element.selector,
        input: message.payload.value,
        type: element.type,
        id: element.id,
        shortSelector: element.shortSelector,
        className: element.className,
        iframe: message.payload.iframe || undefined,
        role: element.role || undefined,
        a11yName: element.a11yName || undefined,
        nameAttr: element.name || undefined,
      };
      return new EnterTextAction(
        `Press enter on ${getElementDescription(element)}`,
        config,
      );
    case MessageType.SpaceKeyPress:
      const spaceConfig: EnterTextConfig = {
        url: message.payload.url,
        element: element.tagName,
        selector: element.selector,
        input: message.payload.value,
        type: element.type,
        id: element.id,
        shortSelector: element.shortSelector,
        className: element.className,
        iframe: message.payload.iframe || undefined,
        role: element.role || undefined,
        a11yName: element.a11yName || undefined,
        nameAttr: element.name || undefined,
      };
      return new EnterTextAction(
        `Press space on ${getElementDescription(element)}`,
        spaceConfig,
      );
    default:
      return null;
  }
};

export function getElementDescription(element: ElementData): string {
  const { alt, className, id, tagName, text, name } = element;

  let identifier = "";

  if (alt || name || text) {
    let displayText = (alt || name || text).trim();

    if (displayText.length > 50) {
      displayText = `${displayText.substring(0, 46)} ...`;
    }

    identifier = `"${displayText}"`;
  } else if (id) {
    identifier = `#${id}`;
  } else if (className) {
    identifier = className;
  }

  return `${getFullTagName(tagName)} ${identifier}`;
}

function getFullTagName(tag: string): string {
  switch (tag) {
    case "a":
      return "link";
    case "h1":
    case "h2":
    case "h3":
    case "h4":
    case "h5":
    case "h6":
      return "heading";
    case "img":
      return "image";
    case "li":
      return "list item";
    case "p":
      return "paragraph";
    default:
      return tag;
  }
}

function isOneTimePasscodeField(element: ElementData): boolean {
  const otcArr = [
    "totppin",
    "otc",
    "otpcode",
    "otp",
    "tc",
    "credentials.passcode",
  ];

  if (!element.name) {
    return false;
  }

  // only interested in exact (case insensitive) matches
  return otcArr.includes(element.name.toLowerCase());
}

function isUsernameField(element, message): boolean {
  const usernameKeys = ["user", "email"];

  // Helper function to determine if the value contains at least one
  // of the keys (case insensitive)
  const includesKey = (value: string, keys: string[]): boolean => {
    if (value) {
      return keys.some((key) => value.toLowerCase().includes(key));
    }
    return false;
  };

  if (
    includesKey(element.name, usernameKeys) ||
    includesKey(element.a11yName, usernameKeys)
  ) {
    return true;
  }

  return (
    /login|log-in|signin|sign-in/.test(message.payload.url) ||
    isGoogleAuthUsername(element)
  );
}

function isPasswordField(element): boolean {
  return (
    element.type === "password" ||
    element.name === "password" ||
    element.ariaLabel?.includes("password") ||
    element.a11yName?.includes("password")
  );
}

// Created a google auth only function to limit other scenarios
// that could inherit these generic look up terms
function isGoogleAuthUsername(element): boolean {
  return (
    element.id === "identifierId" &&
    element.name === "identifier" &&
    element.type === "email"
  );
}
