import ReplaceContent from "src/components/ReplaceContent";
import { useAppContext } from "./useAppContext";
import { useCallback } from "react";
import { AppInstancesExtendedAssociation } from "./useAllAppinstances";
import { File, PlaylistAndChannelAssociationsFragment } from "src/types.g";

export type UseReplaceContent = {
  openReplaceContentModal: ({
    originalContent,
    associations,
  }: {
    originalContent: AppInstancesExtendedAssociation | File;
    associations: PlaylistAndChannelAssociationsFragment[];
  }) => void;
};

export const useReplaceContent = (): UseReplaceContent => {
  const context = useAppContext();
  const openReplaceContentModal = useCallback(
    ({
      originalContent,
      associations,
    }: {
      originalContent: AppInstancesExtendedAssociation | File;
      associations: PlaylistAndChannelAssociationsFragment[];
    }) => {
      context.modal.openModal(
        <ReplaceContent
          originContent={originalContent}
          associations={associations || []}
          onCloseModal={() => {
            context.modal.closeModals();
          }}
        />,
        `Replace ${originalContent.name}`,
        {
          opts: {
            overflow: true,
            disableTitle: true,
          },
        },
      );
    },
    [],
  );
  return { openReplaceContentModal };
};
