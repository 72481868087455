import { ToggleStar } from "@screencloud/screencloud-ui-components";
import classNames from "clsx";
import { iconSharedFolder } from "../../helpers/folderHelper";
import { StyledMediaItem } from "./styleMediaItem";

interface Props {
  isMediaPicker?: boolean;
  onClick: () => void;
  isCompactLayout?: boolean;
}

export function MediaSharedFolder(props: Props) {
  const { onClick, isMediaPicker, isCompactLayout } = props;
  return (
    <div className="media-visibility">
      <div className="column">
        <div>
          <StyledMediaItem
            onClick={onClick}
            data-testid="media-shared-folder"
            className={classNames(
              "media-item media-folder",
              { readonly: isMediaPicker },
              { "media-item-small": isCompactLayout },
            )}
          >
            <div className="media-core">
              <div className="media-alpha" style={{ width: "100%" }}>
                <div className="thumbnail-preview">
                  <div className="thumbnail folder">
                    <div className="wrapper">
                      <img src={iconSharedFolder} className="folder-img" />
                    </div>
                  </div>
                </div>
                <div className="media-title">
                  <h3 title="Shared by Others">
                    <span>Shared by Others</span>
                  </h3>
                </div>
              </div>
              <div
                className="media-item__count"
                style={{ visibility: "hidden" }}
              >
                -
              </div>
              <div className="media-starred" style={{ visibility: "hidden" }}>
                <ToggleStar active={false} />
              </div>
            </div>
          </StyledMediaItem>
        </div>
      </div>
    </div>
  );
}
