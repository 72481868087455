import {
  Checkbox,
  DatetimeInput,
} from "@screencloud/screencloud-ui-components";
import { format as toFormat } from "date-fns";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { AppContext } from "../../AppContextProvider/AppContext";
import { AppContextType } from "../../AppContextProvider/type";
import { PrimaryButton } from "../../helpers/whiteLabel";
import { StyledModalContent } from "./styles";

const TIME_FORMAT = "YYY-MM-dd'T'HH:mm:00+00:00";

export enum AvailabilityActions {
  ADD_EXPIRY_DATE = "add_expiry_date",
  REMOVE_EXPIRY_DATE = "remove_expiry_date",
  ADD_AVAILABLE_DATE = "add_available_at",
  REMOVE_AVAILABLE_DATE = "remove_available_date",
  RESET_PUBLICATION_DATES = "reset_publication_dates",
}

export enum AvailabilityStatus {
  IS_PLAYING = "is-playing",
  IS_NOT_AVAILABLE = "is-not-available",
  IS_EXPIRED = "is-expired",
}

export interface AvailabilityModalProps {
  onBulkSetAvailable?: ({
    availableTime,
    expireTime,
  }: {
    availableTime: string | null;
    expireTime: string | null;
  }) => void;
  callback?: (
    action: AvailabilityActions,
    {
      availableTime,
      expireTime,
    }: {
      availableTime?: string | null;
      expireTime?: string | null;
    },
  ) => Promise<void>;
  availableDate?: string | null;
  expiryDate?: string | null;
  disabled?: boolean;
}

export interface AvailabilityModalState {
  availableTime: string | null;
  expireTime: string | null;
  isAvailabilityEnable: boolean;
  isExpiryDateFocus: boolean;
}

const dateNow = new Date();
const now = toFormat(dateNow, TIME_FORMAT);

class AvailabilityModal extends Component<
  AvailabilityModalProps,
  AvailabilityModalState
> {
  public static contextType = AppContext;
  public context: AppContextType;

  constructor(props: AvailabilityModalProps) {
    super(props);
    this.state = {
      availableTime: props.availableDate ?? now,
      expireTime: props.expiryDate ?? null,
      isAvailabilityEnable: !!props.availableDate || !!props.expiryDate,
      isExpiryDateFocus: false,
    };
  }

  public handleAvailableDatetimeChange = (value: string) => {
    this.setState({ availableTime: value });
    this.props.callback &&
      this.props.callback(AvailabilityActions.ADD_AVAILABLE_DATE, {
        availableTime: value,
      });
  };

  public handleExpiryDatetimeChange = (value: string) => {
    this.setState({ expireTime: value });
    this.props.callback &&
      this.props.callback(AvailabilityActions.ADD_EXPIRY_DATE, {
        expireTime: value,
      });
  };

  public handleEnablePublication = (
    event: React.SyntheticEvent,
    data: { checked: boolean },
  ) => {
    this.setState({
      isExpiryDateFocus: true,
      isAvailabilityEnable: data.checked,
    });
    if (this.props.callback && data.checked) {
      this.setState({ availableTime: toFormat(new Date(), TIME_FORMAT) });
      this.props.callback(AvailabilityActions.ADD_AVAILABLE_DATE, {
        availableTime: toFormat(new Date(), TIME_FORMAT),
      });
    } else if (this.props.callback && !data.checked) {
      this.setState({
        expireTime: "",
      });
      this.props.callback(AvailabilityActions.RESET_PUBLICATION_DATES, {
        availableTime: "",
        expireTime: "",
      });
    }
  };

  public handleResetExpiry = () => {
    this.setState({
      expireTime: null,
    });
    this.props.callback?.(AvailabilityActions.REMOVE_EXPIRY_DATE, {
      expireTime: null,
    });
  };

  public renderTimeEditor = () => {
    const { expireTime, availableTime } = this.state;
    return (
      <div className="time-editor">
        <div className="available">
          <div className="sub-text">
            <FormattedMessage
              id="media.available_from"
              defaultMessage="Available from"
            />
          </div>
          <DatetimeInput
            locale={
              this.context?.currentUser?.preferences?.settings?.locale ?? null
            }
            disabled={this.props.disabled}
            placeholder="Available"
            minDate={dateNow}
            maxDate={undefined}
            value={availableTime ?? ""}
            callback={this.handleAvailableDatetimeChange}
            workInUTC
          />
        </div>
        <div className="expire">
          <div className="sub-text">
            <FormattedMessage id="media.expire_at" defaultMessage="Expire at" />
            <span
              onClick={
                !this.props.disabled ? this.handleResetExpiry : undefined
              }
              className={`red ${this.props.disabled ? "disabled" : ""}`}
            >
              clear
            </span>
          </div>
          <DatetimeInput
            data-testid="expire-date-picker"
            locale={
              this.context?.currentUser?.preferences?.settings?.locale ?? null
            }
            disabled={this.props.disabled}
            placeholder="No expires"
            minDate={dateNow}
            value={expireTime ?? ""}
            callback={this.handleExpiryDatetimeChange}
            workInUTC
            autoFocus={this.state.isExpiryDateFocus}
          />
        </div>
        <div className="expiry-description">
          <span>Expired content won’t be played on your screen.</span>
          <span>Leave this empty to disable the expiration date.</span>
        </div>
      </div>
    );
  };

  public render(): JSX.Element {
    const isBulkUpdate = !!this.props.onBulkSetAvailable;
    const { isAvailabilityEnable, availableTime, expireTime } = this.state;
    return (
      <StyledModalContent>
        <div
          className={`content availability ${
            isBulkUpdate ? "bulk-update" : ""
          }`}
        >
          {!isBulkUpdate && (
            <div
              className="enable-app-availability-toggle"
              data-testid="enable-app-availability"
            >
              <div className="text">
                <FormattedMessage
                  id="ui_component.media_preview.enable_availability"
                  defaultMessage="Enable availability"
                />
                <span>Allows you to set an expiration date in the future</span>
              </div>
              <Checkbox
                disabled={this.props.disabled}
                checked={isAvailabilityEnable}
                toggle
                onChange={this.handleEnablePublication}
              />
            </div>
          )}
          {isBulkUpdate || isAvailabilityEnable
            ? this.renderTimeEditor()
            : null}
          {isBulkUpdate && (
            <div className="button">
              <PrimaryButton
                onClick={() =>
                  this.props.onBulkSetAvailable?.({
                    availableTime,
                    expireTime,
                  })
                }
              >
                <FormattedMessage
                  id="media.set_availability_button"
                  defaultMessage="Set availability"
                />
              </PrimaryButton>
            </div>
          )}
        </div>
      </StyledModalContent>
    );
  }
}

export default AvailabilityModal;
