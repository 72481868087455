import {
  SiteRecorderAction,
  SiteRecorderActionType,
} from "../components/SiteRecorderModal/SiteRecorder/actions/models";

const getInverseId = (actions, type) => {
  const inverseObj = actions.find((a) => a.type !== type);
  if (inverseObj) {
    return inverseObj.id;
  }
  return null;
};

const fetchCredPair = (actions, slice) => {
  const [usernameArr, passwordArr] = getCredPairsArr(actions);
  const username = usernameArr.slice(slice, slice + 1);
  const password = passwordArr.slice(slice, slice + 1);
  return addCredPairId([...username, ...password]);
};

const getCredPairsArr = (actions) => {
  const usernameArr = actions.filter(
    (a) => a.type === SiteRecorderActionType.EnterUsername,
  );
  const passwordArr = actions.filter(
    (a) => a.type === SiteRecorderActionType.EnterPassword,
  );
  return [usernameArr, passwordArr];
};

const addCredPairId = (credPair) => {
  const credPairArr = credPair.map((credItem) => {
    return {
      ...credItem,
      config: {
        ...credItem.config,
        credPairId: getInverseId(credPair, credItem.type),
      },
    };
  });
  return credPairArr;
};

const credActionLength = (actions) => {
  return actions.filter(
    (a) =>
      a.type === SiteRecorderActionType.EnterUsername ||
      a.type === SiteRecorderActionType.EnterPassword,
  ).length;
};

export const pairCredentials = (actions: SiteRecorderAction[]) => {
  const arr = Array();
  const loopLength = Math.ceil(credActionLength(actions) / 2);

  for (let i = 0; i < loopLength; i++) {
    const credPair = fetchCredPair(actions, i);
    arr.push(credPair);
  }

  const flatArr = arr.flat(1);

  flatArr.forEach((action) => {
    const index = actions.findIndex((a) => a.id === action.id);
    actions[index] = action;
  });

  return [...actions];
};

export const hasMismatchedCredentials = (
  actions: SiteRecorderAction[],
): boolean => {
  return (
    actions.filter(
      (a) =>
        (a.type === SiteRecorderActionType.EnterUsername ||
          a.type === SiteRecorderActionType.EnterPassword) &&
        !a.config.credPairId,
    ).length > 0
  );
};

export const sanitiseFormerCredentialActions = (
  actions: SiteRecorderAction[],
): SiteRecorderAction[] => {
  const sanitisableActions: SiteRecorderActionType[] = [
    SiteRecorderActionType.EnterText,
    SiteRecorderActionType.EnterOneTimeCode,
  ];

  actions.forEach((action) => {
    if (sanitisableActions.includes(action.type)) {
      if (action.config.hasOwnProperty("credPairId")) {
        const { credPairId, ...sanitised } = action.config;
        action.config = sanitised;
      }
    }
  });

  return actions;
};
