import {
  Icon,
  Loader,
  Tab,
  TabPane,
} from "@screencloud/screencloud-ui-components";
import { UUID } from "@screencloud/uuid";
import * as React from "react";
import { AppContextType } from "src/AppContextProvider/type";
import { appConfig } from "../../appConfig";
import { AppContext } from "../../AppContextProvider/AppContext";
import { getTokenPayloadByRegions } from "../../helpers/userHelper";
import { SystemUserClaims } from "../../state/session/StudioSession";
import { Styled } from "./styles";

interface OrgSwitcherProps {
  style?: object;
}

interface OrgSwitchItem {
  org_name: string;
  org_id: UUID;
  isSystemSession: boolean;
  isCurrentOrg: boolean;
  isMigrationOrg?: boolean;
  key: string;
  region?: {
    app: {
      baseUrl: string;
    };
  };
}

interface OrgSwitchState {
  items: OrgSwitchItem[];
}

class OrgSwitcher extends React.Component<OrgSwitcherProps, OrgSwitchState> {
  public static contextType = AppContext;
  public context: AppContextType;
  public listenId: number;

  constructor(props: OrgSwitcherProps) {
    super(props);

    this.state = {
      items: [],
    };
  }

  public getItems(): OrgSwitchItem[] {
    return this.context.sessions.users
      .map((s) => ({
        org_id: s.claims.orgId!,
        key: s.key,
        org_name: s.claims.orgName,
        isSystemSession: !!(s.claims as SystemUserClaims).systemUserId,
        isMigrationOrg: this.context.migrationOrgIds.some(
          (orgId) => orgId === s.claims.orgId,
        ),
        isCurrentOrg: this.context.currentOrg!.id === s.claims.orgId,
      }))
      .sort((a, b) =>
        a.org_name.localeCompare(b.org_name, undefined, {
          sensitivity: "base",
        }),
      );
  }

  public async componentDidMount() {
    this.listenId = this.context.sessions.subscribe(() => {
      this.setState({
        items: this.getItems(),
      });
    });

    this.setState({
      items: this.getItems(),
    });
  }

  public componentWillUnmount() {
    this.context.sessions.unsubscribe(this.listenId);
  }

  public renderLoader() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          paddingTop: "30px",
        }}
      >
        <Loader
          active
          size="small"
          style={{ position: "relative", left: "auto", right: "auto" }}
        />
      </div>
    );
  }

  public renderCurrentOrgs(items: OrgSwitchItem[]) {
    return items.map((item, index) => {
      return (
        <a
          href={
            !item.isCurrentOrg && item.region?.app?.baseUrl
              ? item.region.app.baseUrl + "/?org=" + item.org_id
              : "/?org=" + item.org_id
          }
          className={`org ${item.isCurrentOrg ? "active" : ""} ${
            index === items.length - 1 ? "" : "break-point"
          }`}
          key={index}
        >
          <span className="title">
            <h3>
              {item.org_name}{" "}
              {item.isSystemSession && (
                <span style={{ color: "red", fontWeight: "bold" }}>
                  (system session)
                </span>
              )}{" "}
              {item.isMigrationOrg && (
                <span style={{ color: "red", fontWeight: "bold" }}>
                  (pending migration)
                </span>
              )}
            </h3>
          </span>
          {item.isCurrentOrg && <Icon name="checked-circle" />}
        </a>
      );
    });
  }

  public renderOtherOrgsToOneList() {
    const { tokenPayloadsByRegion } = this.context;
    const tokenByRegions = !!tokenPayloadsByRegion
      ? getTokenPayloadByRegions(tokenPayloadsByRegion)
      : undefined;
    if (tokenByRegions?.other.length <= 0) {
      throw new Error("No existing organizations in others regions.");
    }

    return tokenByRegions?.other
      .sort((a, b) =>
        a.org_name.localeCompare(b.org_name, undefined, {
          sensitivity: "base",
        }),
      )
      .map((token, idx) => {
        return (
          <a
            key={idx}
            href={token.app.baseUrl + "/?org=" + token.org_id}
            className="org"
          >
            <h3>{token.org_name}</h3>
          </a>
        );
      });
  }

  public render(): JSX.Element {
    const { style } = this.props;
    const { items } = this.state;
    const { tokenPayloadsByRegion } = this.context;
    const hasOrgInMultiRegions =
      items.length > 0 &&
      appConfig.regions.allNonCurrent.length > 0 &&
      tokenPayloadsByRegion &&
      tokenPayloadsByRegion.length > 1;
    const tokenByRegions = !!tokenPayloadsByRegion
      ? getTokenPayloadByRegions(tokenPayloadsByRegion)
      : undefined;

    const tabItems: {
      menuItem?: any;
      render?: () => React.ReactNode;
    }[] = [];

    if (hasOrgInMultiRegions) {
      tabItems.push({
        menuItem: appConfig.regions.current.name,
        render: () => (
          <TabPane attached={false}>{this.renderCurrentOrgs(items)}</TabPane>
        ),
      });

      appConfig.regions.allNonCurrent.map((region) => {
        const otherRegionTabs = {
          menuItem: region.name,
          render: () => (
            <TabPane attached={false}>
              {tokenByRegions?.other.length > 0
                ? tokenByRegions?.other
                    .sort((a, b) =>
                      a.org_name.localeCompare(b.org_name, undefined, {
                        sensitivity: "base",
                      }),
                    )
                    .map((token, idx) => {
                      return (
                        <a
                          key={idx}
                          href={region.app.baseUrl + "/?org=" + token.org_id}
                          className="org"
                        >
                          <h3>{token.org_name}</h3>
                        </a>
                      );
                    })
                : this.renderLoader()}
            </TabPane>
          ),
        };

        return tabItems.push(otherRegionTabs);
      });
    }

    return (
      <Styled style={style}>
        <div className="container">
          {hasOrgInMultiRegions ? (
            <Tab
              menu={{ secondary: true, pointing: true }}
              panes={tabItems}
              center
              totaltab={appConfig.regions.all.length}
            />
          ) : (
            <>
              {items?.length > 0 ? (
                this.renderCurrentOrgs(items)
              ) : tokenByRegions?.other.length > 0 ? (
                this.renderOtherOrgsToOneList()
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </Styled>
    );
  }
}

export default OrgSwitcher as React.ComponentType<OrgSwitcherProps>;
