import { FormattedMessage } from "react-intl";
import { CurrencyCode, Maybe } from "../../types.g";
import * as types from "../types";

/**
 * Replace the default space name with a human readable version
 *
 * @param name - Space name
 */
export function replaceDefaultSpace(name: string): JSX.Element | string {
  if (name !== "_default_space") {
    return name;
  }
  return (
    <FormattedMessage
      defaultMessage="Default Space"
      id="defaults._default_space"
    />
  );
}

/**
 * Convert cents/pence to dollars/pounds/euros etc.
 *
 * @param cents - Value in cents to be converted
 * @param currencyCode - 3 digit country currency code
 * @param decimals - Show or hide .00
 */
export function centsToDollars(
  cents: number = 0,
  currencyCode: CurrencyCode,
  decimals: boolean = false,
): string {
  const dollars: number = (cents ?? 0) / 100;
  let formatted: string = dollars.toLocaleString("en-US", {
    style: "currency",
    currency: currencyCode,
  });
  if (!decimals) {
    formatted = formatted.replace(".00", "");
  }
  return formatted;
}

/**
 * Simple util to return null or string and a break
 *
 * @param str - String to parse
 */
export function emptyStringOrBreak(str: Maybe<string>): Maybe<JSX.Element> {
  if (str && str.trim()) {
    return (
      <>
        {str}
        <br />
      </>
    );
  }
  return null;
}

/**
 * Sort an object by its keys and return
 *
 * @param obj - Object to sort
 */
export function sortObjectByKey(obj: types.Dictionary): types.Dictionary {
  return Object.keys(obj)
    .sort()
    .reduce((r, k) => ({ ...r, [k]: obj[k] }), {});
}

/**
 * For inputs, will return an empty string if null
 *
 * @param str - The string to test against
 */
export function nullToEmptyString(str: Maybe<string>): string {
  return str || "";
}
