export const ALL_SPACES = "all-spaces";
export const ALL_STATUS = "all-status";
export const ALL_PLATFORMS = "all-platforms";
export const INITIAL_PAGE = 1;
export const ITEMS_TO_DISPLAY_SPACE_FILTER_SEARCH_INPUT = 10;
export const SCREEN_ITEMS_PER_PAGE = 20;

export const getTotalPages = (totalCount: number): number =>
  Math.ceil(totalCount / SCREEN_ITEMS_PER_PAGE);

export const isAllScreensInOrgSelected = (
  isAllScreensSelected: boolean,
  spaceId: string | undefined,
) => {
  return isAllScreensSelected && isAllSpacesSelected(spaceId);
};

export const isAllScreensInSpaceSelected = (
  isAllScreensSelected: boolean,
  spaceId: string | undefined,
) => {
  return isAllScreensSelected && !isAllSpacesSelected(spaceId);
};

export const isSomeScreensSelected = (selectedScreens: string[]) => {
  return selectedScreens && selectedScreens.length > 0;
};

const isAllSpacesSelected = (spaceId: string | undefined) => {
  return spaceId === ALL_SPACES || !spaceId;
};
