import { IAuthClaims } from "@screencloud/auth-sdk";
import localStore from "store";
import { specialPagePaths } from "src/constants/constants";
import { Roles } from "./StudioSession";
import { parsePathname } from "./sessionUtils";
import { ssm } from "./ssm";
import { appConfig } from "src/appConfig";
import { appendAllowedQueryParams } from "../../helpers/autoPairingCodeHelper";

export function hasUserChanged(
  prev: IAuthClaims | null,
  next: IAuthClaims | null,
) {
  // unless prev/next match perfectly (aka. both null or provider/connection/email match
  return (
    (prev === null && next) ||
    (prev && next === null) ||
    prev!.connection !== next!.connection ||
    prev!.provider !== next!.provider ||
    prev!.email !== next!.email
  );
}

export function determineRoleForPath(page: string) {
  if (specialPagePaths.guestPages.find((p) => page.startsWith(p))) {
    return Roles.GUEST;
  } else if (specialPagePaths.anonPages.find((p) => page.startsWith(p))) {
    return Roles.ANON;
  }

  return Roles.USER;
}

export function setLastAccessedOrg(orgId: string) {
  localStore.set(`lastAccessedOrg`, orgId);
}

export function determineReactionForRoleOnPath(): {
  logout?: boolean;
  redirect?: string;
} {
  const { crossOrgUserPages, crossOrgFeaturePages } = specialPagePaths;
  const { prefix, page, path } = parsePathname();
  const roleForPage = determineRoleForPath(path);
  const {
    current: { claims },
    userSessionCount,
  } = ssm;
  const role: Roles = claims.role;
  // Guests
  if (role === Roles.GUEST) {
    if (roleForPage !== Roles.GUEST) {
      return { redirect: `${appConfig.auth.frontend}/create` };
    }
    // else do nothing
  } else if (role === Roles.ANON) {
    // Anons
    if (roleForPage !== Roles.ANON) {
      return { logout: true };
    }
    // else do nothing
  } else if (role === Roles.USER) {
    // Users
    // real users on multi-org pages
    const lastAccessedOrg = localStore.get(`lastAccessedOrg`, null);
    const session = ssm.all.find((s) => s.claims.orgId === lastAccessedOrg);

    if (isPageStartWithSomeArrayItem(crossOrgFeaturePages)) {
      return {};
    }

    if (
      crossOrgUserPages.some((coup) => page.startsWith(coup)) ||
      (window.location.pathname.startsWith("/migrate/") && !session)
    ) {
      // should not use prefix
      if (prefix) {
        return { redirect: page };
      }
    } else {
      // real user on org page
      // need prefix, but doesnt have one?
      if (!prefix && userSessionCount > 1) {
        const targetPage = !page || page === "/" ? "/screens" : page;
        const migrationOrgId = window.location.pathname.startsWith("/migrate/")
          ? window.location.pathname.split(`/`).pop()
          : undefined;
        const orgId =
          new URLSearchParams(window.location.search).get("org") ||
          migrationOrgId;
        const invite = new URLSearchParams(window.location.search).get(
          "invite",
        );
        if (orgId) {
          setLastAccessedOrg(orgId);
        } else if (invite) {
          setLastAccessedOrg(invite);
        }

        if (invite && userSessionCount > 1) {
          return { redirect: `/org/${userSessionCount - 1}${targetPage}` };
        }

        if (session) {
          return { redirect: `/org/${session.key}${targetPage}` };
        } else {
          return { redirect: `${appConfig.auth.frontend}/organization` };
        }
      } else if (prefix && userSessionCount === 1) {
        // has prefix, but shouldn't
        return { redirect: path && path !== "/" ? path : "/screens" };
      }
    }
  } else {
    // unknown role
    console.error(
      `determineReactionForRoleOnPath() encountered unknown role '${role}'`,
    );
    return { logout: true };
  }
  return {};
}

export const getPageQueryString = (): string => {
  const { crossOrgFeaturePages } = specialPagePaths;

  const params = new URLSearchParams(window.location.search);
  params.delete("org");

  if (isPageStartWithSomeArrayItem(crossOrgFeaturePages) && params.toString()) {
    return `?${params}`;
  }

  return "";
};

export const isPageStartWithSomeArrayItem = (
  itemList: string[] | readonly string[],
): boolean => {
  const { page } = parsePathname();
  return itemList.some((item) => page.startsWith(item));
};

export const getRedirectPage = (page: string, queryString: string): string => {
  const { crossOrgFeaturePages } = specialPagePaths;
  return isPageStartWithSomeArrayItem(crossOrgFeaturePages)
    ? `${page}${queryString}`
    : "/screens";
};

export const getRedirectUrl = (
  userSessionCount: number,
  indexOrOrgId: string,
  redirectPage: string,
): string => {
  const baseUrl =
    userSessionCount > 1 ? `/org/${indexOrOrgId}${redirectPage}` : redirectPage;
  return appendAllowedQueryParams(baseUrl);
};
