import { Icon } from "@screencloud/screencloud-ui-components";
import {
  ReactElement,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "../../helpers/whiteLabel";
import { useAppContext } from "../../hooks/useAppContext";
import { withData } from "../SiteRecorderModal/apollo";
import { advancedSettingsReducer } from "./advancedSettingsReducer";
import { ApolloProps } from "./apollo";
import { Styled, StyledPresetCard } from "./styles";
import { AdvancedSettingsActionType, ComputeType } from "./types";

interface ISiteConfigurationModalProps extends ApolloProps {
  onSubmitConfiguration: (
    advancedSettingsConfig: any,
    computeType: string,
    computeSize: string,
  ) => void;
  computeType: string;
  computeSize: string;
  config: any;
}

export interface ComputeOption {
  id: string;
  name: string;
  description: string;
  type: ComputeType;
  cpu: number | null;
  ram: number | null;
}

const computeOptions: ComputeOption[] = [
  {
    id: "small",
    name: "Small",
    description: "Ideal for light dashboards",
    type: ComputeType.SMALL,
    cpu: 256,
    ram: 512,
  },
  {
    id: "medium",
    name: "Medium",
    description: "Ideal for basic dashboards",
    type: ComputeType.MEDIUM,
    cpu: 512,
    ram: 1024,
  },
  {
    id: "large",
    name: "Large",
    description: "Ideal for heavy dashboards",
    type: ComputeType.LARGE,
    cpu: 1024,
    ram: 2048,
  },
  {
    id: "xlarge",
    name: "X-Large",
    description: "Ideal for very heavy dashboards",
    type: ComputeType.XLARGE,
    cpu: 2048,
    ram: 4096,
  },
];

const SiteConfigurationModal = (
  props: ISiteConfigurationModalProps,
): ReactElement<ISiteConfigurationModalProps> => {
  const { onSubmitConfiguration, computeType, computeSize, config } = props;
  const context = useAppContext();
  const [selectedOption, setSelectedOption] = useState<ComputeOption | null>(
    computeOptions[1],
  );

  const [advancedSettingsState, dispatchAdvancedSettingsAction] = useReducer(
    advancedSettingsReducer,
    {
      zoom: 0,
      scrollFrom: 0,
      scrollTo: 0,
    },
  );

  useEffect(() => {
    const optionToSelect = computeOptions.find((o) => o.id === computeSize);

    setSelectedOption(optionToSelect!);
  }, [computeType, computeSize]);

  useEffect(() => {
    if (config) {
      dispatchAdvancedSettingsAction({
        type: AdvancedSettingsActionType.SET_SCROLL_FROM,
        value: config.scrollFrom || 0,
      });
      dispatchAdvancedSettingsAction({
        type: AdvancedSettingsActionType.SET_SCROLL_TO,
        value: config.scrollTo || 0,
      });
      dispatchAdvancedSettingsAction({
        type: AdvancedSettingsActionType.SET_ZOOM,
        value: config.zoom || 0,
      });
    }
  }, [config]);

  const onSubmit = useCallback(() => {
    const advancedSettingsConfig: any = {};
    const { scrollFrom, scrollTo, zoom } = advancedSettingsState;
    if (scrollFrom) {
      advancedSettingsConfig.scrollFrom = scrollFrom;
    }
    if (scrollTo) {
      advancedSettingsConfig.scrollTo = scrollTo;
    }
    if (zoom) {
      advancedSettingsConfig.zoom = zoom;
    }
    // Default to 1s for Secure Sites
    advancedSettingsConfig.refreshInterval = "1s";
    onSubmitConfiguration(advancedSettingsConfig, "preset", selectedOption!.id);
    (context as any).modal.closeModals();
  }, [onSubmitConfiguration, advancedSettingsState, selectedOption, context]);

  return (
    <Styled>
      <div className="secure-site-configuration">
        <div className="compute-size-container">
          <span className="compute-size">
            <FormattedMessage
              id="ui_component.common.label.compute_size"
              defaultMessage="Compute Size"
            />
          </span>
        </div>
        <div className="preset-options-container">
          <div className="preset-options">
            {computeOptions.map((option, index) => {
              return (
                <StyledPresetCard
                  onClick={() => setSelectedOption(option)}
                  selected={!!selectedOption && selectedOption.id === option.id}
                  key={index}
                >
                  <p className="card-header">
                    <FormattedMessage
                      id="ui_component.site.secure.preset_text"
                      defaultMessage={option.name}
                    />
                  </p>
                  <span className="card-subheader">{option.description}</span>
                  <Icon
                    className="selected-icon"
                    name={
                      selectedOption && selectedOption.id === option.id
                        ? "checked-circle"
                        : "circle"
                    }
                  />
                </StyledPresetCard>
              );
            })}
          </div>
          {selectedOption && (
            <div className="options-container">
              <div className="title">
                <FormattedMessage
                  id={`ui_component.common.label.compute.${selectedOption.id}`}
                  defaultMessage={selectedOption.name}
                />
              </div>
              <div className="info">
                <FormattedMessage
                  id="ui_component.common.label.compute_size"
                  defaultMessage="Compute Size"
                />
                <FormattedMessage
                  id="ui_component.site.secure.custom_cpu"
                  defaultMessage={`${selectedOption.cpu} vCPU`}
                />
              </div>
              <hr className="separator" />
              <div className="info">
                <FormattedMessage
                  id="ui_component.common.label.memory"
                  defaultMessage="Memory"
                />
                <FormattedMessage
                  id="ui_component.site.secure.custom_ram"
                  defaultMessage={`${selectedOption.ram} MB RAM`}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="footer-container">
        <PrimaryButton onClick={onSubmit} disabled={!selectedOption}>
          {props.computeType !== "preset" ? (
            <FormattedMessage
              id="ui_component.site.save_settings"
              defaultMessage="Save settings"
            />
          ) : (
            <FormattedMessage
              id="ui_component.site.update_settings"
              defaultMessage="Update settings"
            />
          )}
        </PrimaryButton>
      </div>
    </Styled>
  );
};

export default withData(SiteConfigurationModal);
