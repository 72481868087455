import { ApolloQueryResult } from "@apollo/client";
import { UUID } from "@screencloud/uuid";
import React, { useState, useEffect } from "react";
import {
  FIRST_FETCH_ITEMS,
  MAXIMUM_FETCH_MORE_ITEMS,
  MINIMUM_FETCH_MORE_ITEMS,
} from "../constants/constants";
import { renderFetchMoreButton } from "../helpers/generalHelper";
import {
  Maybe,
  Exact,
  AvailableAppInstanceFragment,
  useAllAppInstancesQuery,
  AllAppInstancesQueryVariables,
  AllAppInstancesQuery,
  AppInstanceFilter,
  AppInstancesOrderBy,
  useAllAppInstancesExtendedAssociationQuery,
  PlaylistAndChannelAssociationsFragment,
} from "../types.g";
import { useAppContext } from "./useAppContext";

export type AppInstancesExtendedAssociation = AvailableAppInstanceFragment & {
  associationsByAppInstanceIdAndSpaceId: {
    nodes: [PlaylistAndChannelAssociationsFragment];
  };
};
export interface UseAllAppInstances {
  appInstances:
    | AvailableAppInstanceFragment[]
    | AppInstancesExtendedAssociation[];
  hasNextPage: boolean;
  loadMore: () => void;
  loading: boolean;
  isLoading: boolean;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            appId: any;
            spaceId: any;
            first: Maybe<number>;
            endCursor: any;
            orderBy: Maybe<AppInstancesOrderBy[]>;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<AllAppInstancesQuery>>;
  isFirstTimeAlreadyLoaded: boolean;
  totalCount: number;
  renderFetchMoreButton: React.ReactNode;
}

export interface AllAppInstancesProps {
  appId: string;
  spaceId?: UUID;
  filter: AppInstanceFilter;
  skip?: boolean;
  extendedAssociation?: boolean;
}
function useAllAppInstancesSection(
  props: AllAppInstancesProps,
): UseAllAppInstances {
  const context = useAppContext();
  const [hasNextPage, setHasNextPage] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [fetchCount, setFetchCount] = useState(0);
  const [isFirstTimeAlreadyLoaded, setIsFirstTimeAlreadyLoaded] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const spaceId = props.spaceId ?? context?.currentSpace?.id;

  const variables: AllAppInstancesQueryVariables = {
    appId: props.appId,
    spaceId,
    first: FIRST_FETCH_ITEMS,
    endCursor: null,
    orderBy: [AppInstancesOrderBy.NameAsc, AppInstancesOrderBy.IdDesc],
    filter: props.filter,
  };

  const queryHook = props.extendedAssociation
    ? useAllAppInstancesExtendedAssociationQuery
    : useAllAppInstancesQuery;

  const {
    data: appInstancesQuery,
    fetchMore,
    refetch,
    loading,
  } = queryHook({
    variables,
    fetchPolicy: "cache-and-network",
    skip: props.skip || !props.appId,
  });

  const loadMore = () => {
    if (!isLoading) {
      setIsLoading(true);
      const endCursor =
        appInstancesQuery?.spaceById?.availableAppInstancesBySpaceIdAndAppId
          ?.pageInfo?.endCursor;
      fetchMore({
        variables: {
          first:
            fetchCount > 1
              ? MAXIMUM_FETCH_MORE_ITEMS
              : MINIMUM_FETCH_MORE_ITEMS,
          endCursor,
        },
      }).then(() => {
        setIsLoading(false);
        setFetchCount(fetchCount + 1);
      });
    }
  };

  useEffect(() => {
    setTotalCount(
      appInstancesQuery?.spaceById?.availableAppInstancesBySpaceIdAndAppId
        .totalCount ?? 0,
    );
  }, [
    appInstancesQuery?.spaceById?.availableAppInstancesBySpaceIdAndAppId
      .totalCount,
  ]);

  useEffect(() => {
    setHasNextPage(
      Boolean(
        appInstancesQuery?.spaceById?.availableAppInstancesBySpaceIdAndAppId
          .pageInfo.hasNextPage,
      ) &&
        appInstancesQuery?.spaceById?.availableAppInstancesBySpaceIdAndAppId
          ?.nodes.length! <
          appInstancesQuery?.spaceById?.availableAppInstancesBySpaceIdAndAppId
            ?.totalCount!,
    );
  }, [
    appInstancesQuery?.spaceById?.availableAppInstancesBySpaceIdAndAppId
      .pageInfo!.hasNextPage,
    appInstancesQuery?.spaceById?.availableAppInstancesBySpaceIdAndAppId
      .totalCount,
  ]);

  useEffect(() => {
    setIsFirstTimeAlreadyLoaded(
      Boolean(
        appInstancesQuery?.spaceById?.availableAppInstancesBySpaceIdAndAppId,
      ),
    );
  }, [appInstancesQuery?.spaceById?.availableAppInstancesBySpaceIdAndAppId]);

  const currentItemsCount =
    appInstancesQuery?.spaceById?.availableAppInstancesBySpaceIdAndAppId?.nodes
      .length ?? 0;

  const fechMoreButton = renderFetchMoreButton(
    currentItemsCount,
    totalCount,
    isLoading,
    hasNextPage,
    loadMore,
  );

  return {
    appInstances:
      appInstancesQuery?.spaceById?.availableAppInstancesBySpaceIdAndAppId
        ?.nodes ?? [],
    hasNextPage,
    loadMore,
    refetch,
    loading,
    isLoading,
    isFirstTimeAlreadyLoaded,
    totalCount,
    renderFetchMoreButton: fechMoreButton,
  };
}

export interface UseAllAppInstancesProps {
  appId: string;
  tags?: string[];
  skip?: boolean;
  spaceId?: UUID;
  extendedAssociation?: boolean;
}

/**
 *
 * @returns All Appinstances that belong to this space.
 * Shared from other space will not be shown.
 */
export function useAllAppInstancesInCurrentSpace(
  props: UseAllAppInstancesProps,
) {
  const { appId, spaceId, extendedAssociation } = props;
  const context = useAppContext();
  const selectSpaceId = spaceId ?? context?.currentSpace?.id;
  const filter: AppInstanceFilter = { spaceId: { equalTo: selectSpaceId } };

  if (props.tags?.length ?? 0 > 0) {
    filter.tags = { contains: props.tags };
  }

  const variables: AllAppInstancesProps = {
    appId,
    filter,
    skip: props.skip,
    extendedAssociation,
  };
  return useAllAppInstancesSection(variables);
}

/**
 *
 * @returns All Appinstances **shared** from other space
 * Allinstances from other spaces that has not been shared will not be shown
 */
export function useAllAppInstanceFromOtherSpaces(
  props: UseAllAppInstancesProps,
) {
  const context = useAppContext();
  const spaceId = context?.currentSpace?.id;
  const filter: AppInstanceFilter = { spaceId: { notEqualTo: spaceId } };

  if (props.tags?.length ?? 0 > 0) {
    filter.tags = { contains: props.tags };
  }
  const variables: AllAppInstancesProps = {
    appId: props.appId,
    filter,
    skip: props.skip,
    extendedAssociation: props.extendedAssociation,
  };
  return useAllAppInstancesSection(variables);
}

/**
 *
 * @returns All Appinstances in this space and also
 * App instance from other spaces that has been shared to this space
 */
export function useAllAppInstances(props: UseAllAppInstancesProps) {
  const { appId, spaceId, extendedAssociation } = props;
  const variables: AllAppInstancesProps = {
    appId,
    spaceId,
    filter: { appId: { equalTo: appId } },
    extendedAssociation,
  };
  return useAllAppInstancesSection(variables);
}
