import { Icon } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { generateImgixThumbnail } from "../../helpers/mediaHelper";
import { Link } from "../../types.g";
import { LinkListItemActions } from "../LinkListItem";
import { StyledItem } from "./styles";

export type LinkItemCallBack = (
  action: LinkListItemActions,
  value: Partial<Link> | string | boolean,
  event?: React.SyntheticEvent,
) => void;

export interface LinkContentItemProps {
  onClickLinkCallback: LinkItemCallBack;
  link: Partial<Link>;
  className?: string;
  secureMediaPolicy: string | undefined;
}

class LinkContentItem extends React.Component<LinkContentItemProps, {}> {
  public handleOnClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (event && (event.shiftKey || event.metaKey || event.ctrlKey)) {
      this.props.onClickLinkCallback(
        LinkListItemActions.SELECTED,
        this.props.link,
        event,
      );
    } else {
      this.props.onClickLinkCallback(
        LinkListItemActions.PREVIEW,
        this.props.link,
        event,
      );
    }
  };
  public handleAddLinkItem = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (event && (event.shiftKey || event.metaKey || event.ctrlKey)) {
      this.props.onClickLinkCallback(
        LinkListItemActions.SELECTED,
        this.props.link,
        event,
      );
    } else {
      this.props.onClickLinkCallback(
        LinkListItemActions.ADD,
        this.props.link,
        event,
      );
    }
  };

  public render() {
    const { id, url, name, fileByFileId } = this.props.link;
    const thumbnail = fileByFileId
      ? generateImgixThumbnail(fileByFileId, this.props.secureMediaPolicy, true)
      : "";
    return (
      <StyledItem className={this.props.className} onClick={this.handleOnClick}>
        <div className="list" key={id}>
          <div className="link-thumbnail" onClick={this.handleAddLinkItem}>
            <img src={thumbnail} className="thumbnail" />
            <div className="overlay">
              <Icon name="plus" color="#fff" />
            </div>
          </div>

          <div className="link-info">
            <p title={name} className="link-title">
              {name}
            </p>
            <label title={url} className="link-url">
              {url}
            </label>
          </div>
        </div>
      </StyledItem>
    );
  }
}

export default LinkContentItem;
