import {
  IStudioCustomCssFont,
  IStudioCustomFileFont,
  StudioCustomCssFont,
  StudioCustomFileFont,
} from "@screencloud/studio-custom-fonts";
import { useState, useEffect } from "react";
import { Font } from "../components/Admin/Theme/ThemeConfigure";
import { FontData } from "../components/Admin/Theme/ThemePreview";
import { StyleElement } from "../components/CustomFontPreview";
import { DEFAULT_FONT } from "../constants/constants";

interface Props {
  headingFont?: Font | undefined;
  bodyFont?: Font | undefined;
}

export const isFontFileURL = (url: string | undefined): boolean => {
  if (
    url &&
    (url.indexOf("fonts.googleapis") > -1 ||
      url.indexOf(".css") > -1 ||
      url.indexOf("data:text/css;base64") > -1)
  ) {
    return false;
  }
  return true;
};

export function useRegisterFont(props: Props) {
  const [styleElement, setStyleElement] = useState<StyleElement>(undefined);
  const { headingFont, bodyFont } = props;

  const headingFonturl = headingFont?.url;
  let headingFontData = {
    fontFamily: headingFont?.family,
    regularFont: isFontFileURL(headingFonturl) ? headingFonturl : "",
    cssUrl: !isFontFileURL(headingFonturl) ? headingFonturl : "",
    boldFont: "",
    boldItalicFont: "",
    italicFont: "",
  };

  if (!headingFontData.regularFont && !headingFontData.cssUrl) {
    headingFontData = {
      ...headingFontData,
      fontFamily: DEFAULT_FONT.family,
      cssUrl: DEFAULT_FONT.url,
    };
  }

  const bodyFontUrl = bodyFont?.url;
  let bodyFontData = {
    fontFamily: bodyFont?.family,
    regularFont: isFontFileURL(bodyFontUrl) ? bodyFontUrl : "",
    cssUrl: !isFontFileURL(bodyFontUrl) ? bodyFontUrl : "",
    boldFont: "",
    boldItalicFont: "",
    italicFont: "",
  };

  if (!bodyFontData.regularFont && !bodyFontData.cssUrl) {
    bodyFontData = {
      ...bodyFontData,
      fontFamily: DEFAULT_FONT.family,
      cssUrl: DEFAULT_FONT.url,
    };
  }

  useEffect(() => {
    const headingCf = regisCustomFont(headingFontData as FontData);
    const bodyCf = regisCustomFont(bodyFontData as FontData);
    return () => {
      headingCf.unregister();
      bodyCf.unregister();
    };
  }, [headingFont, bodyFont]);

  const regisCustomFont = (
    fontData?: FontData,
  ): StudioCustomCssFont | StudioCustomFileFont => {
    const cf = fontData?.cssUrl
      ? new StudioCustomCssFont({ font: fontData as IStudioCustomCssFont })
      : new StudioCustomFileFont({ font: fontData as IStudioCustomFileFont });

    cf.register();
    setStyleElement(cf.elem?.cloneNode(true)); // clone element to using this in iframe
    return cf;
  };

  if (styleElement) {
    document.getElementsByTagName("head")[0].appendChild(styleElement as any);
  }
}
