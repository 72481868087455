import {
  Button,
  JSONSchemaForm,
  Loader,
  ModalSize,
  ScreenPreview,
} from "@screencloud/screencloud-ui-components";
import {
  renderStudioPlayer,
  StudioPlayer,
  AppFile,
} from "@screencloud/studio-player-sdk"; // TODO: export StudioPlayer type
import { debounce, isEmpty, isEqual } from "lodash";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { appConfig } from "../../../appConfig";
import { AppContext } from "../../../AppContextProvider/AppContext";
import apolloClient from "../../../state/apolloClient";
import queryHelper from "../../../state/helper/query";
import { ssm } from "../../../state/session/ssm";
import {
  AppInstance,
  AppInstanceByIdDocument,
  AppVersion,
  CastsBySpaceIdDocument,
  CreateCastsByAppInstanceIdDocument,
  CreateCastsByAppInstanceIdMutationVariables,
  Scalars,
  ScreenCastStopDocument,
  ScreenCastStopMutation,
  ScreenCastStopMutationVariables,
  SetScreenContentByAppInstanceIdDocument,
  SetScreenContentByAppInstanceIdMutationVariables,
  CreateSignedAppManagementJwtDocument,
  CreateSignedAppManagementJwtMutation,
  CreateSignedAppManagementJwtMutationVariables,
  UpdateShareAppInstanceToSpacesMutationVariables,
  UpdateShareAppInstanceToAllSpacesMutationVariables,
  UpdateAppInstanceAvailableMutationFn,
  UpdateAppInstanceExpireMutationFn,
  UpdateAppInstanceAvailableMutationVariables,
  UpdateAppInstanceExpireMutationVariables,
  UpdateShareAppInstanceToSpacesMutationFn,
  UpdateShareAppInstanceToAllSpacesMutationFn,
  AvailableAppInstanceFragment,
  DuplicateAppInstanceMutationFn,
  Playlist,
  UpdateAppInstanceTagsMutationVariables,
  UpdateAppInstanceTagsDocument,
  TagsByAppIdAndSpaceIdDocument,
} from "../../../types.g";
import { callPreAppConfigureSaveHook } from "../../../utils/callPreAppConfigureSaveHook";
import { CastedScreenInfoActions } from "../../CastedScreenInfo";
import FullScreenModalContent from "../../FullScreenModal/FullScreenModalContent";
import FullScreenModalContentMain from "../../FullScreenModal/FullScreenModalContentMain";
import FullScreenModalContentSidebar from "../../FullScreenModal/FullScreenModalContentSidebar";
import FullScreenModalHeader from "../../FullScreenModal/FullScreenModalHeader";
import FullScreenModalWrapper from "../../FullScreenModal/FullScreenModalWrapper";
import { ScreenPickerActions } from "../../ScreenPicker";

import { renderCastingStatus } from "../../../helpers/castingHelper";
import {
  mapEnvToPlayerSdk,
  mapRegionToPlayerSdk,
} from "../../../helpers/playerSdkHelper";
import { PrimaryButton } from "../../../helpers/whiteLabel";
import {
  modifyCastScreenByAppInstance,
  onDuplicateInstance,
} from "../../../utils/appInstances";
import AppNotConfigured from "../AppNotConfigured";
import * as Sentry from "@sentry/browser";
import { FEATURE_FLAGS_ENUM } from "../../../constants/featureFlag";
import {
  canAppBeUpdated,
  canAppBeDeleted,
  getDeleteAppInstanceConfirmMsg,
  onEditAppInstance,
  shouldAppShowShareButton,
} from "../../../helpers/appHelper";
import ShareModal from "../../ShareModal";
import { canBeShared } from "../../../helpers/shareableHelper";
import AvailabilityModal, {
  AvailabilityActions,
} from "../../AvailabilityModal";
import { AppInstanceAction } from "../AppContainer";
import { AppContextType } from "src/AppContextProvider/type";
import { UseDeleteAppInstance } from "src/hooks/entities/appInstance/useDeleteAppInstance";
import { UseAddAppsToPlaylistsMutation } from "src/pages/Apps/AppInstances/hooks/useAddAppsToPlaylist";
import AddToPlaylistModal from "src/components/AddToPlaylistModal";
import { getStudioPlayerUrl } from "src/utils";
import { shouldShowFindAndReplaceButton } from "src/domains/findAndReplace/visibility";

export interface AppConfigureProps extends UseDeleteAppInstance {
  appId: string;
  appName: string;
  appInstance?: Partial<AppInstance>;
  appVersion: Partial<AppVersion>;
  displayAppPreview: boolean;
  instanceName: string | undefined;
  formData: Scalars["JSON"];
  jsonSchema: Scalars["JSON"];
  onSave: (
    instanceName: string | undefined,
    jsonConfig: Scalars["JSON"],
  ) => Promise<void>;
  onClose?: () => void;
  openMediaPicker: (payload: {
    mimeTypes: string | string[];
    multiSelect: boolean;
  }) => Promise<{ media: AppFile[] }>;
  updateAppInstanceAvailable: UpdateAppInstanceAvailableMutationFn;
  updateAppInstanceExpire: UpdateAppInstanceExpireMutationFn;
  updateShareAppInstanceToSpaces: UpdateShareAppInstanceToSpacesMutationFn;
  updateShareAppInstanceToAllSpaces: UpdateShareAppInstanceToAllSpacesMutationFn;
  addAppIntoPlaylists: UseAddAppsToPlaylistsMutation["addAppsToPlaylists"];
  duplicateAppInstance: DuplicateAppInstanceMutationFn;
  availableTags: string[];
  isAvailableTagsLoading: boolean;
  openReplaceContentModal: ({ originalContent, associations }: any) => void;
}
export interface AppConfigureState {
  appInstance: Partial<AppInstance>;
  appVersion: Partial<AppVersion>;
  instanceName: string | undefined;
  isPreview: boolean;
  isRename: boolean;
  isNewInstance: boolean;
  jsonSchema: Scalars["JSON"];
  formData: Scalars["JSON"];
  hasUnsavedChanges: boolean;
  hasPreAppConfigData: boolean;
  isFormValid: boolean;
  isPreconfigAppDataError: boolean;
}

class AppConfigure extends React.Component<
  AppConfigureProps,
  AppConfigureState
> {
  public static contextType = AppContext;
  public context: AppContextType;
  public schemaForm: Scalars["JSON"];
  public closePopupEventListener;
  private playerPreviewTarget = React.createRef<HTMLDivElement>();
  private debounceOnChange = debounce((formData) => {
    this.handleChange(formData);
  }, 2000);
  private studioPlayer: StudioPlayer | null = null;

  constructor(props: AppConfigureProps) {
    super(props);
    this.state = {
      appInstance: props.appInstance!,
      appVersion: props.appVersion,
      formData: props.formData,
      instanceName: props.instanceName ? props.instanceName : undefined,
      isPreview: false,
      isRename: false,
      isNewInstance: location.search === "?new=true",
      jsonSchema: props.jsonSchema,
      hasUnsavedChanges: false,
      hasPreAppConfigData:
        props.appInstance?.config?.appInstanceDurationInSeconds !== undefined,
      isFormValid: false,
      isPreconfigAppDataError: false,
    };
  }

  public componentDidMount() {
    const currentRegion = mapRegionToPlayerSdk(appConfig.studioPlayerRegion);
    const currentEnvironment = mapEnvToPlayerSdk(
      appConfig.studioPlayerEnvironment,
    );
    const playerDevicePlatform = "studio";
    const playerDeviceModel = "app-preview";

    if (
      this.playerPreviewTarget.current &&
      this.state.appInstance &&
      this.state.appVersion.viewerUrl &&
      currentRegion &&
      currentEnvironment
    ) {
      const applicationId = this.state.appVersion.appId;
      const instanceId = this.state.appInstance.id;

      // render single instance of studio player
      this.studioPlayer = renderStudioPlayer({
        target: this.playerPreviewTarget.current,
        region: currentRegion, // eu | us
        token: this.context.user.token || "",
        contentPath: `/appId/${applicationId}/${instanceId}/preview`,
        environment: currentEnvironment, // production | staging
        playerUrl: getStudioPlayerUrl(this.context),
        spaceId: this.context.user.settings.spaceId,
        overrideAppInitialize: {
          appInstanceId: instanceId,
          config: this.state.formData,
          orgId: this.context.currentUser?.orgId,
          viewerUrl: this.state.appVersion.viewerUrl,
          device: {
            platform: playerDevicePlatform,
            model: playerDeviceModel,
          },
        },
        context: {
          userInteractionEnabled: true,
          isAiFeatureEnabled: this.context.shouldShowFeature(
            FEATURE_FLAGS_ENUM.AI_FEATURES,
          ),
        },
        features: {
          offlineStorage: false,
          simulatedDimensions: {
            width: 1920, // Simulate HD screen values
            height: 1080,
          },
        },
      });
      // setup callbacks to studio player to handle communication from apps
      this.studioPlayer?.on("requestAuthToken", this.handleRequestAuthToken);
    }

    this.setState({
      isFormValid: this.schemaForm.schemaForm.formElement.checkValidity(),
    });
  }

  public componentDidUpdate(
    prevProps: Readonly<AppConfigureProps>,
    prevState: Readonly<AppConfigureState>,
    snapshot?: any,
  ) {
    if (
      this.playerPreviewTarget.current &&
      this.state.appInstance &&
      this.state.appVersion.viewerUrl
    ) {
      const instanceId = this.state.appInstance.id;

      this.studioPlayer?.setOverrideAppInitialize({
        appInstanceId: instanceId,
        config: this.state.formData,
        orgId: this.context.currentUser?.orgId,
        viewerUrl: this.state.appVersion.viewerUrl,
      });
    }

    if (this.state.formData !== prevState.formData && this.schemaForm) {
      this.setState({
        isFormValid: this.schemaForm.schemaForm.formElement.checkValidity(),
      });
    }
  }

  public componentWillUnmount() {
    this.studioPlayer?.destroy();
  }

  public handleAppNameSaved = (
    event: React.SyntheticEvent<any>,
    value: string,
  ) => {
    const { formData, instanceName, hasUnsavedChanges } = this.state;

    this.setState({
      instanceName: value,
      isRename: false,
      hasUnsavedChanges:
        !isEmpty(formData) && (instanceName !== value || hasUnsavedChanges),
    });
  };

  public handleRequestAuthToken = () => {
    return new Promise(
      (
        resolve: (value: { authToken: string; expiresAt: number }) => void,
        reject,
      ) => {
        resolve({ authToken: ssm.current.token || "", expiresAt: 0 });
      },
    );
  };

  /**
   * Encode the given object for the socialLogin external field type
   */
  public wrapAppConfig = async (config?: any): Promise<string | void> => {
    const token = ssm.current.authorization;

    if (!token) {
      console.warn("No token exists. No user logged in?");
      return;
    }

    return fetch(appConfig.appServicesUrl + "/apps/wrap-config", {
      body: JSON.stringify(config || {}),
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((response) => response.text())
      .catch((e) => console.log(`wrapAppConfig error`, e));
  };

  /**
   * Generate an appConfigId and JWT for login
   */
  public createAppConfigId = async (
    appName: string,
  ): Promise<{
    token: string;
    appConfigId: string;
  } | void> => {
    try {
      const appManagementTokenResponse = await apolloClient.mutate<
        CreateSignedAppManagementJwtMutation,
        CreateSignedAppManagementJwtMutationVariables
      >({
        mutation: CreateSignedAppManagementJwtDocument,
        variables: {
          input: {
            spaceId: this.context.user.settings.spaceId,
          },
        },
      });
      if (
        !appManagementTokenResponse.data?.createSignedAppManagementJwt
          ?.signedAppManagementToken
      ) {
        console.warn("No valid token");
        Sentry.captureException(
          new Error("Failed to get signedAppManagementToken"),
          (scope) => {
            scope.setTag(
              "connections-error",
              "Successful createSignedAppManagementJwt response with no token",
            );
            scope.setTag(
              "connections-data",
              appManagementTokenResponse.data?.createSignedAppManagementJwt
                ?.signedAppManagementToken,
            );
            return scope;
          },
        );
        return;
      }

      const signedToken =
        appManagementTokenResponse.data.createSignedAppManagementJwt
          .signedAppManagementToken;

      return fetch(
        appConfig.appConnectionsUrl + `/create-app-config?appName=${appName}`,
        {
          body: JSON.stringify({
            appConfigId:
              this.state.formData &&
              this.state.formData.config &&
              this.state.formData.config.appConfigId,
          }),
          headers: {
            Authorization: `Bearer ${signedToken}`,
            "Content-Type": "application/json",
          },
          method: "POST",
        },
      )
        .then((response) => response.json())
        .then((response) => {
          return { ...response.data, token: signedToken };
        })
        .catch((e) => console.warn(`Unable to create app config id`, e));
    } catch (e) {
      console.warn("No app management token created");
      Sentry.captureException(e, (scope) => {
        scope.setTag("connections-error", "No app management token created");
        return scope;
      });
      return;
    }
  };

  public onScreenPickerCallback = async (data: string[], expiresAt?: Date) => {
    if (this.props.appInstance) {
      const spaceId = this.context.user.settings.spaceId;
      const castStartVar: CreateCastsByAppInstanceIdMutationVariables = {
        input: {
          screenIds: data,
          appInstanceId: this.props.appInstance?.id,
          expiresAt,
        },
      };

      await apolloClient.mutate({
        mutation: CreateCastsByAppInstanceIdDocument,
        update: (cache, { data }) => {
          const cast =
            data?.createCastsByAppInstanceId?.casts &&
            data?.createCastsByAppInstanceId.casts[0];
          modifyCastScreenByAppInstance(cache, cast, this.props.appInstance);
        },
        variables: castStartVar,
      });
      queryHelper.updateCastedScreen(spaceId);
      this.context.modal.closeModals();
    }
  };

  public openScreenPicker = async () => {
    if (this.props.appInstance) {
      const result = await this.context.modal.openScreenPicker(
        this.props.appInstance,
      );
      if (result.action === ScreenPickerActions.SET_CONTENT) {
        const setContents = result.data.map((screenId) => {
          const setAppContent: SetScreenContentByAppInstanceIdMutationVariables =
            {
              input: {
                screenId,
                appInstanceId: this.props.appInstance?.id,
              },
            };
          return apolloClient.mutate({
            mutation: SetScreenContentByAppInstanceIdDocument,
            variables: setAppContent,
          });
        });
        await Promise.all(setContents);
        this.context.modal.closeModals();
      } else {
        this.onScreenPickerCallback(result.data, result.expiresAt);
      }
    }
  };

  public onCastedScreensCallback = async (
    data: string,
    action: CastedScreenInfoActions,
  ) => {
    switch (action) {
      case CastedScreenInfoActions.STOP_CAST:
        const spaceId = this.context.user.settings.spaceId;
        const castStopVar: ScreenCastStopMutationVariables = {
          input: {
            screenId: data,
          },
        };
        await apolloClient
          .mutate<ScreenCastStopMutation, ScreenCastStopMutationVariables>({
            mutation: ScreenCastStopDocument,
            refetchQueries: [
              {
                query: AppInstanceByIdDocument,
                variables: {
                  id: this.props.appInstance?.id,
                },
              },
              {
                query: CastsBySpaceIdDocument,
                variables: {
                  spaceId,
                },
              },
            ],
            variables: castStopVar,
          })
          .then(() => {
            queryHelper.updateCastedScreen(spaceId);
          });
        break;
      case CastedScreenInfoActions.NAVIGATE_SCREEN:
        this.context.history.push("/screens/" + data);
        break;
      default:
    }
  };

  public renderPreview() {
    const { isNewInstance, isFormValid, isPreconfigAppDataError } = this.state;
    return (
      <>
        <div
          style={{ height: "100%", width: "100%", background: "black" }}
          ref={this.playerPreviewTarget}
        />
        {((isNewInstance && !isFormValid) || isPreconfigAppDataError) && (
          <AppNotConfigured />
        )}
      </>
    );
  }

  public handleShare = (selectedApp: AppInstance, sharedSpaceIds: string[]) => {
    const shareToSpaceInput: UpdateShareAppInstanceToSpacesMutationVariables = {
      input: {
        appInstanceId: selectedApp.id,
        spaces: sharedSpaceIds,
      },
    };

    this.props.updateShareAppInstanceToSpaces({
      variables: shareToSpaceInput,
    });
  };

  public handleShareAll = (selectedApp: AppInstance, value: boolean) => {
    const sharedAllInput: UpdateShareAppInstanceToAllSpacesMutationVariables = {
      input: {
        appInstanceId: selectedApp.id,
        isSharedAll: value,
      },
    };

    this.props.updateShareAppInstanceToAllSpaces({
      variables: sharedAllInput,
    });
  };

  public showShareModal = () => {
    const selectedApp = this.props.appInstance as AppInstance;
    this.context.modal.openModal(
      <ShareModal
        shareable={selectedApp}
        isDisabled={
          !canBeShared({ shareable: selectedApp, context: this.context })
        }
        sharedSpaces={selectedApp.sharedSpacesByAppInstanceId.nodes}
        onShareToSpaceChanges={(sharedSpaceIds) => {
          this.handleShare(selectedApp, sharedSpaceIds);
        }}
        onShareAllChange={(value) => this.handleShareAll(selectedApp, value)}
      />,
      <>
        {(this.context.allSpaces?.length ?? 0) > 1 ? (
          <>
            <FormattedMessage
              id="common.label.share_app_instance"
              defaultMessage="Share App Instance"
            />
            : {selectedApp.name}
          </>
        ) : (
          <>Share {selectedApp.name} with others</>
        )}
      </>,
      {
        opts: {
          size:
            (this.context.allSpaces ?? []).length > 1
              ? ModalSize.MEDIUM
              : ModalSize.SMALL,
          disableTitle: true,
        },
      },
    );
  };

  public updateAppInstanceAvailableTime = (availableTime: string | null) => {
    const variables: UpdateAppInstanceAvailableMutationVariables = {
      input: {
        appInstanceId: this.props.appInstance?.id,
        availableAt: availableTime,
      },
    };

    this.props.updateAppInstanceAvailable({
      variables,
    });
  };

  public updateAppInstnaceExpireTime = (expireTime: string | null) => {
    const variables: UpdateAppInstanceExpireMutationVariables = {
      input: {
        appInstanceId: this.props.appInstance?.id,
        expireAt: expireTime,
      },
    };

    this.props.updateAppInstanceExpire({
      variables,
    });
  };

  public onAvailabilityAppInstanceCallBack = async (
    action: AvailabilityActions,
    data: {
      availableTime?: string | null;
      expireTime?: string | null;
    },
  ) => {
    switch (action) {
      case AvailabilityActions.ADD_AVAILABLE_DATE:
        if (data.availableTime) {
          this.updateAppInstanceAvailableTime(data.availableTime);
        }
        break;
      case AvailabilityActions.REMOVE_AVAILABLE_DATE:
        await this.updateAppInstanceAvailableTime(null);
        break;
      case AvailabilityActions.ADD_EXPIRY_DATE:
        if (data && data.expireTime) {
          await this.updateAppInstnaceExpireTime(data.expireTime);
        }
        break;
      case AvailabilityActions.REMOVE_EXPIRY_DATE:
        await this.updateAppInstnaceExpireTime(null);
        break;
      case AvailabilityActions.RESET_PUBLICATION_DATES:
        await this.updateAppInstanceAvailableTime(null);
        await this.updateAppInstnaceExpireTime(null);
        break;
      default:
        break;
    }
  };

  public showAvailabilityModal = () => {
    const { appInstance } = this.props;
    const canUpdate = canAppBeUpdated({
      context: this.context,
      appInstance: appInstance as AvailableAppInstanceFragment,
    });
    this.context.modal.openModal(
      <AvailabilityModal
        availableDate={appInstance?.availableAt}
        expiryDate={appInstance?.expireAt}
        callback={this.onAvailabilityAppInstanceCallBack}
        disabled={!canUpdate}
      />,
      <FormattedMessage
        id="ui_component.common.label.set_availability"
        defaultMessage="Set Availability"
      />,
      {
        opts: {
          closeOnDimmerClick: true,
          size: ModalSize.SMALL,
        },
      },
    );
  };

  public onDelete = async () => {
    const { appInstance } = this.props;
    if (appInstance) {
      try {
        const message = await getDeleteAppInstanceConfirmMsg(
          appInstance as AppInstance,
          appInstance!.castedScreenByAppInstanceId!.totalCount,
          appInstance!.castedScreenByAppInstanceId!.nodes,
        );
        const { confirm } = await this.context.modal.confirm(message, {
          confirm: (
            <FormattedMessage
              id="ui_component.common.label.delete"
              defaultMessage="Delete"
            />
          ),
          isDanger: true,
        });
        if (confirm) {
          this.props.deleteAppInstance({ appInstanceId: appInstance?.id });
          this.context.modal.closeFullscreenModal();
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  public onAddToPlaylists = (appInstanceIds: string[]) => {
    this.context.modal.openModal(
      <AddToPlaylistModal
        onAddToPlaylistClick={async (playlists: Playlist[]) => {
          try {
            await this.props.addAppIntoPlaylists({ appInstanceIds, playlists });
            this.context.modal.closeModals();
          } catch (error) {
            this.context.modal.closeModals();
            console.error(error);
          }
        }}
      />,
      "Select playlists",
      {
        opts: {
          closeOnDimmerClick: true,
          size: ModalSize.LARGE,
        },
      },
    );
  };

  public onDuplicateItem = async () => {
    try {
      const newAppInsatnce = await onDuplicateInstance({
        appId: this.props.appId,
        appInstance: this.props.appInstance as AppInstance,
        duplicateAppInstance: this.props.duplicateAppInstance,
        context: this.context,
      });
      if (newAppInsatnce?.id) {
        onEditAppInstance(this.context, newAppInsatnce as AppInstance);
      }
    } catch (err) {
      console.error(err);
    }
  };

  public handleFindAndReplace = () => {
    this.props.openReplaceContentModal({
      originalContent: this.props.appInstance,
      associations:
        this.props.appInstance?.associationsByAppInstanceIdAndSpaceId?.nodes,
    });
  };

  public onAppActionCallback = (action: AppInstanceAction) => {
    switch (action) {
      case AppInstanceAction.SET_AVAILABILITY:
        this.showAvailabilityModal();
        break;
      case AppInstanceAction.SHARE:
        this.showShareModal();
        break;
      case AppInstanceAction.SET_TO_SCREEN:
        this.openScreenPicker();
        break;
      case AppInstanceAction.DELETE:
        this.onDelete();
        break;
      case AppInstanceAction.ADD_TO_PLAYLISTS:
        this.onAddToPlaylists([this.props.appInstance?.id]);
        break;
      case AppInstanceAction.DUPLICATE:
        this.onDuplicateItem();
        break;
      case AppInstanceAction.FIND_AND_REPLACE:
        this.handleFindAndReplace();
        break;
      default:
        break;
    }
  };

  public onUpdateTagCallback = async (tags: string[]) => {
    const updateTagsInput: UpdateAppInstanceTagsMutationVariables = {
      input: {
        id: this.props.appInstance?.id,
        tags,
      },
    };

    apolloClient.mutate({
      mutation: UpdateAppInstanceTagsDocument,
      variables: updateTagsInput,
      refetchQueries: [
        {
          query: TagsByAppIdAndSpaceIdDocument,
          variables: {
            appId: this.props.appVersion.appId,
            spaceId: this.context.currentSpace?.id,
          },
        },
      ],
    });
  };

  public render() {
    const {
      jsonSchema,
      instanceName,
      isFormValid,
      formData,
      isNewInstance,
      isPreview,
      hasUnsavedChanges,
      isPreconfigAppDataError,
    } = this.state;
    const { appInstance } = this.props;
    const canUpdateAppInstance = canAppBeUpdated({
      appInstance: appInstance as AvailableAppInstanceFragment,
      context: this.context,
    });
    const canDeleteAppInstance = canAppBeDeleted({
      appInstance: appInstance as AvailableAppInstanceFragment,
      context: this.context,
    });
    const canCreateAppInstane =
      this.context.currentPermissions.validateCurrentSpace(
        "app_instance",
        "create",
      );

    const isAppInstanceOwner =
      this.context.currentSpace?.id === this.props.appInstance?.spaceId;
    const castedScreens =
      this.props.appInstance?.castedScreenByAppInstanceId?.nodes ?? [];

    const shouldShowSetToScreenOption =
      !isNewInstance &&
      this.context.currentPermissions.validateCurrentSpace("screen", "cast") &&
      this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING);

    const shouldShowShareOption = shouldAppShowShareButton({
      appInstance: this.props.appInstance as AvailableAppInstanceFragment,
      context: this.context,
    });

    const shouldShowSaveAndClose = !!(
      !isNewInstance &&
      (canUpdateAppInstance || canCreateAppInstane) &&
      hasUnsavedChanges &&
      isFormValid &&
      !isPreconfigAppDataError
    );

    const shouldShowAddToPlaylistOption =
      this.context.currentPermissions.validateCurrentSpace(
        "playlist",
        "update",
      );

    const shouldShowDuplicateOption =
      this.context.currentPermissions.validateCurrentSpace(
        "app_instance",
        "create",
      );

    const shouldShowFindAndReplace = shouldShowFindAndReplaceButton(
      appInstance?.associationsByAppInstanceIdAndSpaceId?.nodes,
    );
    return (
      <FullScreenModalWrapper isPreview={isPreview}>
        <FullScreenModalHeader
          disabled={!canUpdateAppInstance && !isAppInstanceOwner}
          handleClose={this.handleClose}
          appIcon={this.props.appVersion?.appByAppId?.iconUrl ?? ""}
          title={this.props.appName}
          instanceName={instanceName}
          isEdit={isNewInstance}
          disabledInline={!isAppInstanceOwner}
          handleNameSaved={this.handleAppNameSaved}
          textPlaceholder={this.context.intl.formatMessage({
            defaultMessage: "Instance Name",
            id: "ui_component.apps.instance_name",
          })}
          className="app"
          onAppActionCallback={this.onAppActionCallback}
          shoudShowSaveAndCloseOption={shouldShowSaveAndClose}
          shouldShowSetToScreenOption={shouldShowSetToScreenOption}
          shouldShowShareOption={!isNewInstance && shouldShowShareOption}
          shoudShowSetAvailabilityOption={
            !isNewInstance && canUpdateAppInstance
          }
          shouldShowDeleteOption={!isNewInstance && canDeleteAppInstance}
          shouldShowAddToPlaylistOption={
            !isNewInstance && shouldShowAddToPlaylistOption
          }
          shouldShowDuplicateOption={
            !isNewInstance && shouldShowDuplicateOption
          }
          shouldShowFindAndReplace={shouldShowFindAndReplace}
          expireAt={appInstance?.expireAt}
          availableAt={appInstance?.availableAt}
          allowTags={!isNewInstance && canUpdateAppInstance}
          onUpdateTagCallback={this.onUpdateTagCallback}
          tags={appInstance?.tags as string[]}
          availableTags={this.props.availableTags}
          isAvailableTagsLoading={this.props.isAvailableTagsLoading}
        >
          {renderCastingStatus({
            castScreenData: castedScreens,
            callBack: this.onCastedScreensCallback,
            context: this.context,
          })}
          <Button
            disabled={!isFormValid || isPreconfigAppDataError}
            onClick={() => this.handleFullPagePreview()}
          >
            {isPreview ? (
              <FormattedMessage
                id="ui_component.common.label.edit"
                defaultMessage="Edit"
              />
            ) : (
              <FormattedMessage
                id="player_preview.preview"
                defaultMessage="Preview"
              />
            )}
          </Button>

          {canUpdateAppInstance && isAppInstanceOwner && (
            <>
              <PrimaryButton
                className="button-save"
                onClick={() => this.handleSubmit(isNewInstance)}
                disabled={
                  !hasUnsavedChanges || !isFormValid || isPreconfigAppDataError
                }
              >
                {isNewInstance ? (
                  <FormattedMessage
                    id="common.text.save_and_close"
                    defaultMessage="Save & Close"
                  />
                ) : (
                  <FormattedMessage
                    id="common.text.save"
                    defaultMessage="Save"
                  />
                )}
              </PrimaryButton>
            </>
          )}
        </FullScreenModalHeader>

        <FullScreenModalContent disabled={!canUpdateAppInstance}>
          <FullScreenModalContentSidebar>
            <JSONSchemaForm
              schema={jsonSchema.schemaForm}
              disabled={
                !isNewInstance && (!canUpdateAppInstance || !isAppInstanceOwner)
              }
              uiSchema={jsonSchema.schemaForm.uiSchema}
              formData={formData}
              onSubmit={this.handleSubmit}
              onChange={(data) => {
                const newFormData = data.formData;
                const hasConfigUrl =
                  newFormData?.url?.length > 0 ||
                  newFormData?.presentationUrl?.length > 0;
                const isUrlChanged =
                  !isEqual(newFormData?.url, formData?.url) ||
                  !isEqual(
                    newFormData?.presentationUrl,
                    formData?.presentationUrl,
                  );
                if (
                  jsonSchema.hooks?.preAppConfigureSave?.url &&
                  hasConfigUrl &&
                  isUrlChanged
                ) {
                  this.debounceOnChange(data);
                } else {
                  this.handleChange(data);
                }
              }}
              onError={this.onError}
              formContext={{
                auth: {
                  environment: appConfig.environment,
                  secureUrl: this.secureExternalUrl,
                  wrapAppConfig: this.wrapAppConfig,
                  createAppConfigId: this.createAppConfigId,
                },
              }}
              ref={(form) => {
                this.schemaForm = form;
              }}
              onRequestFiles={this.props.openMediaPicker}
            >
              <Button style={{ display: "none" }} />
            </JSONSchemaForm>
          </FullScreenModalContentSidebar>
          <FullScreenModalContentMain>
            <ScreenPreview width={1920} height={1080}>
              {this.renderPreview()}
            </ScreenPreview>
          </FullScreenModalContentMain>
        </FullScreenModalContent>

        {/* todo: show loading status when saving */}
        <Loader active={false} />
      </FullScreenModalWrapper>
    );
  }

  private handleClose = async () => {
    const { formData, hasUnsavedChanges } = this.state;

    if (hasUnsavedChanges && !isEmpty(formData)) {
      const newJsonSchema = { ...this.state.jsonSchema };
      const { confirm } = await this.context.modal.confirmSaveData();

      this.setState({ jsonSchema: newJsonSchema });
      if (confirm) {
        await this.handleSubmit();
      }
    }
    this.context.modal.closeFullscreenModal();

    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  private handleChange = ({ formData: newFormData }) => {
    const { appInstance, formData, jsonSchema } = this.state;

    if (appInstance !== undefined) {
      const newAppInstance = { ...appInstance };

      newAppInstance.config = newFormData;
      this.setState({
        hasUnsavedChanges: !isEqual(formData, newFormData),
        appInstance: newAppInstance,
        formData: newFormData,
      });
      if (jsonSchema.hooks?.preAppConfigureSave?.url) {
        const hasConfigUrl =
          newFormData?.url?.length > 0 ||
          newFormData?.presentationUrl?.length > 0;

        if (!hasConfigUrl) {
          this.setState({ formData: {} });
        }

        const isUrlChanged =
          !isEqual(newFormData?.url, formData?.url) ||
          !isEqual(newFormData?.presentationUrl, formData?.presentationUrl);

        if (hasConfigUrl && isUrlChanged) {
          try {
            callPreAppConfigureSaveHook(
              jsonSchema.hooks.preAppConfigureSave,
              appInstance.id,
              newFormData,
            ).then((result) => {
              if (Boolean(result)) {
                newAppInstance.config = { ...newFormData, ...result };

                this.setState({
                  appInstance: newAppInstance,
                  formData: result,
                  hasPreAppConfigData: true,
                  hasUnsavedChanges: !isEqual(formData, newFormData),
                  isPreconfigAppDataError: false,
                });
              } else {
                this.setState({
                  isPreconfigAppDataError: true,
                });
              }
            });
          } catch (error) {
            console.log("Failed to get config ", error);
          }
        }
      } else {
        newAppInstance.config = newFormData;
        this.setState({
          appInstance: newAppInstance,
          formData: newFormData,
          hasUnsavedChanges: !isEqual(formData, newFormData),
        });
      }
    }
  };

  private handleFullPagePreview = () => {
    this.setState({
      isPreview: !this.state.isPreview,
    });
    // Rescale player to fit preview dimensions
    setTimeout(() => {
      this.studioPlayer?.resetPlayerScaling();
    }, 5);
  };

  private handleSubmit = async (isNewInstance?: boolean) => {
    const { formData, instanceName } = this.state;
    const { onSave } = this.props;
    this.setState({ hasUnsavedChanges: false });
    await onSave(instanceName, formData);
    if (isNewInstance) {
      this.handleClose();
    }
  };

  private onError = (errors) => {
    console.log("errors =", errors);
  };

  private secureExternalUrl = async (url) => {
    if (url.indexOf("?url=") > 0) {
      // If the redirect is in the form "https://signage.../redirect?url=<encodedUri>
      const redirectParts = url.split("?url=");
      let serviceUrl = decodeURIComponent(redirectParts[1]);
      serviceUrl = `${serviceUrl}${
        serviceUrl.indexOf("?") < 0 ? "?" : "&"
      }token=${this.context.user.token}`;
      return `${redirectParts[0]}?url=${encodeURIComponent(serviceUrl)}`;
    } else {
      return `${url}${url.indexOf("?") < 0 ? "?" : "&"}${
        this.context.user.token
      }`;
    }
  };
}

export default AppConfigure as React.ComponentType<AppConfigureProps>;
