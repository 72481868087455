import { Theme } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import styled from "styled-components";

import { WhiteLabel } from "../../types.g";

export interface SideBarType {
  className?: string;
  children?: React.ReactNode;
}

const Sidebar = ({ className, children }: SideBarType) => (
  <div className={className}>{children}</div>
);

const StyledSidebar = styled(Sidebar)<Partial<WhiteLabel>>`
  background: ${(props) => props.bgColor ?? `var(--color-gray-950)`};
  width: ${Theme.sidebar.maxWidth};
  height: 100%;
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .menu {
    height: calc(100% - 140px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .sidebar-top {
    position: relative;
    width: 100%;
    overflow: auto;
    height: 100%;

    ::-webkit-scrollbar-track {
      background: rgba(221, 221, 221, 0.1);
    }
    ::-webkit-scrollbar-track:hover {
      background: rgba(221, 221, 221, 0.2);
    }
    ::-webkit-scrollbar-thumb {
      border: 3px solid transparent;
      background-clip: padding-box;
      border-radius: var(--radius-lg);
      background-color: rgba(170, 170, 170, 0.6);
      box-shadow:
        inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
      opacity: 0.5;
    }
  }

  .sidebar-bottom {
    width: 100%;
  }

  .logo-banner-mainmenu-wrapper {
    height: 70px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    width: 100%;

    > a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    width: ${Theme.sidebar.minWidth};

    div[class^="sidebar-"] {
      > .button.active,
      > .button {
        padding: 0px;
        justify-content: center;

        i {
          margin-right: 0px;
          float: none;
        }

        span {
          display: none;
          margin: 0;
        }
      }
    }

    .logo-banner-mainmenu-wrapper {
      padding: 0;
      justify-content: center;
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    display: none;
  }
`;

Object.assign(StyledSidebar, {
  displayName: "Sidebar",
});

export default StyledSidebar;
