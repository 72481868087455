import { isUuid, UUID } from "@screencloud/uuid";
import { appConfig } from "../../appConfig";

export interface StudioTokensProviderResultItem {
  tokenPayload: { [claim: string]: any };
  token: string | null;
}

export interface StudioTokenProviderResult {
  accessTokens: StudioTokensProviderResultItem[];
  lastAccessedOrgId: string | null;
}

export type StudioTokenProviderOptions = {
  authAccessToken?: string;
  omitTokens?: boolean;
  systemAccessIds?: UUID[];
};

export type StudioTokenProvider = (
  options: StudioTokenProviderOptions,
) => Promise<StudioTokenProviderResult | null>;

export function isValidStudioTokensProviderResultItem(
  x: StudioTokensProviderResultItem | any,
): x is StudioTokensProviderResultItem {
  return (
    typeof x === "object" &&
    (x.token === null ||
      (typeof x.token === "string" && x.token.startsWith("ey")))
  );
}

export function isValidFetchStudioTokensResult(
  obj: any | StudioTokenProviderResult,
): obj is StudioTokenProviderResult {
  return (
    typeof obj === "object" &&
    (!obj.lastAccessedOrgId || isUuid(obj.lastAccessedOrgId)) &&
    Array.isArray(obj.accessTokens) &&
    // should not find any invalid array members
    !obj.accessTokens.find((x) => !isValidStudioTokensProviderResultItem(x))
  );
}

export const fetchStudioTokens: StudioTokenProvider = async ({
  authAccessToken,
  systemAccessIds,
  omitTokens,
}) => {
  if (authAccessToken) {
    try {
      const request: Response = await fetch(
        `${appConfig.regions.current.service.baseUrl}/auth`,
        {
          body: JSON.stringify({ omitTokens, systemAccessIds }),
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authAccessToken}`,
            "Content-Type": "application/json",
          },
          method: "POST",
        },
      );
      const result = await request.json();
      if (!isValidFetchStudioTokensResult(result)) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error("invalid json response: " + JSON.stringify(result));
      }

      return result;
    } catch (e) {
      console.error("fetchStudioTokens() failed with Error", e);
      throw e;
    }
  }
  return null;
};
