import { UUID } from "@screencloud/uuid";
import { FIRST_FETCH_ITEMS } from "src/constants/constants";
import { renderFetchMorePickerButton } from "src/helpers/generalHelper";
import { useAppContext } from "src/hooks/useAppContext";
import { useLoadMore } from "src/hooks/useLoadMore";
import {
  LinksOrderBy,
  Exact,
  Maybe,
  useLinkPickerQuery,
  LinkListItemFragment,
  useLinkListQuery,
} from "src/types.g";
import { isOrgWidePath } from "src/utils/orgWideFeature";
import { shouldUseSpecificSpaceId } from "src/utils/shouldUseSpecificSpaceId";

export interface UseLinkPicker {
  links: LinkListItemFragment[];
  loading: boolean;
  refetch: (
    variables?: Partial<
      Exact<{ spaceId: any; endCursor: any; orderBy: Maybe<LinksOrderBy[]> }>
    >,
  ) => void;
  renderFetchMorePickerButton?: React.ReactNode;
}

interface GetMediaPickerProps {
  spaceWideMediaPicker: any;
  orgWideMediaPicker: any;
  isOrgWide: boolean;
}

export function useAllLinkInCurrentSpace({
  skip,
  spaceId,
}: {
  skip: boolean;
  spaceId?: UUID;
}): UseLinkPicker {
  const variables = {
    spaceId: shouldUseSpecificSpaceId(spaceId),
    first: FIRST_FETCH_ITEMS,
    endCursor: null,
    orderBy: [LinksOrderBy.CreatedAtDesc, LinksOrderBy.IdDesc],
  };

  const { data, fetchMore, refetch, loading } = useLinkListQuery({
    variables,
    fetchPolicy: "cache-first",
    skip,
  });

  const linkNodes = data?.spaceById?.linksBySpaceId.nodes ?? [];
  const linkTotalCount = data?.spaceById?.linksBySpaceId.totalCount;
  const isQueryHasNextPage =
    data?.spaceById?.linksBySpaceId.pageInfo.hasNextPage;
  const endCursor = data?.spaceById?.linksBySpaceId.pageInfo.endCursor;

  const { isLoading, loadMore } = useLoadMore(fetchMore, endCursor);

  const fetchMoreButton = renderFetchMorePickerButton(
    Number(linkNodes?.length),
    Number(linkTotalCount),
    isLoading,
    Boolean(isQueryHasNextPage) && linkNodes.length! < linkTotalCount!,
    loadMore,
  );

  return {
    links: linkNodes,
    refetch,
    loading,
    renderFetchMorePickerButton: fetchMoreButton,
  };
}

export function useAllLinkInCurrentOrg({
  skip,
}: {
  skip: boolean;
}): UseLinkPicker {
  const context = useAppContext();

  const variables = {
    orgId: context?.currentOrg?.id,
    spaceId: undefined,
    first: FIRST_FETCH_ITEMS,
    endCursor: null,
  };

  const { data, fetchMore, refetch, loading } = useLinkPickerQuery({
    variables,
    fetchPolicy: "cache-first",
    skip,
  });

  const linkNodes = data?.orgById?.linksByOrgId.nodes ?? [];
  const linkTotalCount = data?.orgById?.linksByOrgId.totalCount;
  const isQueryHasNextPage = data?.orgById?.linksByOrgId.pageInfo.hasNextPage;
  const endCursor = data?.orgById?.linksByOrgId.pageInfo.endCursor;

  const { isLoading, loadMore } = useLoadMore(fetchMore, endCursor);

  const fetchMoreButton = renderFetchMorePickerButton(
    Number(linkNodes?.length),
    Number(linkTotalCount),
    isLoading,
    Boolean(isQueryHasNextPage) && linkNodes.length! < linkTotalCount!,
    loadMore,
  );

  return {
    links: linkNodes,
    refetch,
    loading,
    renderFetchMorePickerButton: fetchMoreButton,
  };
}

export const getLinkPickerSkipProps = ({
  isOrgWide,
}: {
  isOrgWide: boolean;
}) => {
  return {
    skipSpaceWideQuery: isOrgWide,
    skipOrgWideQuery: !isOrgWide,
  };
};

export function getMediaPicker(props: GetMediaPickerProps): UseLinkPicker {
  const { isOrgWide, orgWideMediaPicker, spaceWideMediaPicker } = props;
  return isOrgWide ? orgWideMediaPicker : spaceWideMediaPicker;
}

export const useLinkPicker = (spaceId?: UUID): UseLinkPicker => {
  const isOrgWide = isOrgWidePath();
  const { skipOrgWideQuery, skipSpaceWideQuery } = getLinkPickerSkipProps({
    isOrgWide,
  });
  return getMediaPicker({
    isOrgWide,
    orgWideMediaPicker: useAllLinkInCurrentOrg({
      skip: skipOrgWideQuery,
    }),
    spaceWideMediaPicker: useAllLinkInCurrentSpace({
      skip: skipSpaceWideQuery,
      spaceId,
    }),
  });
};
