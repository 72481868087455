import { Icon, ModalSize } from "@screencloud/screencloud-ui-components";
import { ElementData } from "@screencloud/secure-sites-extension-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SessionKeepAliveEditor } from "./actions/editor";
import { ActionList } from "./actions/list";
import { SiteRecorderAction, SiteRecorderActionType } from "./actions/models";
import { ActionMessage, ActionMessageType } from "./reducers";
import { useAppContext } from "src/hooks/useAppContext";

import {
  ActionsChangeInfo,
  ActionsControlPanel,
  PreviewSubMessage,
} from "./styles";

export interface ActionsControlProps {
  actions: SiteRecorderAction[];
  isSelectingElement: boolean;
  isRecording: boolean;
  selectedElement?: ElementData;
  editorType?: SiteRecorderActionType;
  editorAction?: SiteRecorderAction;
  dispatch: (action: ActionMessage) => void;
  isChangesToSave: boolean;
}

export const ActionsControl = (
  props: React.PropsWithChildren<ActionsControlProps>,
) => {
  const context = useAppContext();

  // If the editor type is session/keep-alive, open the editor view in a modal rather than inline
  if (props.editorType === "session/keep-alive") {
    context.modal.openModal(
      <SessionKeepAliveEditor
        action={props.editorAction}
        dispatch={props.dispatch}
      />,
      "",
      {
        opts: {
          disableTitle: true,
          size: ModalSize.SMALL,
        },
        onClose: () => {
          props.dispatch({ type: ActionMessageType.CloseActionEditor });
        },
      },
    );
  } else {
    // If the editor type changes from session/keep-alive to something else, close the modal
    context.modal.closeModals();
  }

  const renderManageSession =
    props.actions.length === 1 ||
    (props.actions.length > 1 && props.isChangesToSave);

  return (
    <ActionsControlPanel>
      <>
        {renderManageSession && (
          <div>
            <ActionList
              showManageSession // This prop is passed as true because we always want ActionLists render function to show the manage session button
              actions={[props.actions[0] ?? []]}
              dispatch={props.dispatch}
              editAction={{
                editorType: props.editorType,
                dispatch: props.dispatch,
                editorAction: props.editorAction,
              }}
            />
          </div>
        )}
        <PreviewSubMessage>
          <Icon name="lock" className="icon" />
          <FormattedMessage
            id="ui_component.site.recorder.username_password_encrypted_stored"
            defaultMessage="All usernames and passwords will be encrypted and stored in your account"
          />
        </PreviewSubMessage>
        {!!props.actions && (
          <div className="actions">
            <div className="actions-header">
              <FormattedMessage
                id="ui_component.site.recorder.actions"
                defaultMessage="Actions"
              />
            </div>
            <ActionsChangeInfo>
              <Icon name="info" className="icon" />
              <FormattedMessage
                id="ui_component.site.recorder.actions_info"
                defaultMessage="Manual changes to action configurations may affect the final preview snapshot. Proceed only if you are absolutely certain of the adjustments."
              />
            </ActionsChangeInfo>
            <ActionList
              actions={props.actions}
              dispatch={props.dispatch}
              showManageSession={false} // This is passed as false as we want the ActionLists render function to ignore the manage session button as it isn't part of the action steps
              editAction={{
                editorType: props.editorType,
                dispatch: props.dispatch,
                editorAction: props.editorAction,
              }}
            />
          </div>
        )}
      </>
    </ActionsControlPanel>
  );
};
