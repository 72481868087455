import { UUID } from "@screencloud/uuid";
import { RefType } from "src/constants/constants";
import { IUseOptionHeadersActionParams } from "src/pages/Screens/ScreensManager/components/hooks/useOptionHeadersAction";
import queryHelper from "../state/helper/query";
import {
  CreateCastsMutation,
  CreateCastsMutationVariables,
  useBulkCreateCastsMutation,
  useCreateCastsMutation,
} from "../types.g";
import { Notification } from "@screencloud/screencloud-ui-components";
import { useAppContext } from "./useAppContext";
import { getMutationInput } from "src/domains/screensManager/bulkAction/setAndCastContent";

export function modifyScreenByCastId(cache, cast) {
  cast.screensByCastId.nodes.forEach((screen) => {
    cache.modify({
      id: cache.identify(screen),
      fields: {
        castId() {
          return screen.castId;
        },
        castByCastId() {
          return cast;
        },
      },
    });
  });
}

export function useCreateCasts() {
  const [createCastsMutation] = useCreateCastsMutation();
  const createCasts = (createCastsInput: CreateCastsMutationVariables) => {
    return createCastsMutation({
      variables: createCastsInput,
      update: (cache, data: { data: CreateCastsMutation }) => {
        if (data.data.createCasts?.casts) {
          const cast =
            data.data.createCasts.casts && data.data.createCasts.casts[0];
          if (cast.playlistByCastId) {
            queryHelper.updatePlaylistDetail(cast.playlistByCastId.id);
          }

          if (cast.linkByCastId) {
            queryHelper.updateLinkDetail(cast.linkByCastId.id);
          }

          if (cast.channelByCastId) {
            queryHelper.updateChannelDetail(cast.channelByCastId.id);
          }

          if (cast.fileByCastId) {
            queryHelper.updateFileDetail(cast.fileByCastId.id);
          }

          if (cast.appInstanceByCastId) {
            queryHelper.updateAppInstanceDetail(cast.appInstanceByCastId.id);
          }

          if (cast.siteByCastId) {
            queryHelper.updateSiteDetail(cast.siteByCastId.id);
          }

          cast.screensByCastId.nodes.forEach((screen) => {
            cache.modify({
              id: cache.identify(screen),
              fields: {
                castId() {
                  return screen.castId;
                },
                castByCastId() {
                  return cast;
                },
              },
            });
          });

          Promise.all(
            cast.screensByCastId.nodes.map((screen) => {
              queryHelper.updateCastedScreen(screen.spaceId);
            }),
          );
        }
      },
    });
  };
  return { createCasts };
}

interface IBulkCreateCastsParams extends IUseOptionHeadersActionParams {
  contentId: UUID;
  contentType: RefType;
}

export const useBulkCreateCasts = () => {
  const context = useAppContext();
  const [bulkCreateCasts] = useBulkCreateCastsMutation();

  const handleBulkCreateCasts = async (params: IBulkCreateCastsParams) => {
    const {
      isAllScreensSelected,
      spaceId,
      selectedScreens,
      contentId,
      contentType,
    } = params;

    try {
      await bulkCreateCasts({
        variables: {
          input: {
            content: {
              _ref: { id: contentId, type: contentType },
            },
            ...getMutationInput(isAllScreensSelected, spaceId, selectedScreens),
          },
        },
      });

      Notification.success({
        message: context.intl.formatMessage({
          id: "screens_manager.notification.bulk_create_casts_success",
          defaultMessage: "Selected screens casted successfully",
        }),
      });
    } catch (e) {
      Notification.error({ message: e.message });
    }
  };

  return { handleBulkCreateCasts };
};
