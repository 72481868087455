import { Tab, TabPane } from "@screencloud/screencloud-ui-components";
import { PickerFileMetadata } from "filestack-js/build/main/lib/picker";
import * as React from "react";
import CoverThumbnail, {
  CoverBackgroundType,
  CoverColor,
  CoverImage,
} from "../CoverThumbnail";
import ColorPanel from "./colorizePanel";
import ImagesPanel from "./imagesPanel";
import { StyledCover } from "./styles";

export { colorsPreset } from "./colorsPreset";

export interface ChannelCoverModalProps {
  onChangeCover?: (
    backgroundType: string,
    color: CoverColor,
    image: CoverImage,
  ) => void;
  onUploadSuccess?: (res: PickerFileMetadata[]) => void;
  selectedBackgroundType: CoverBackgroundType;
  selectedImage: CoverImage;
  selectedColor: CoverColor;
}

export interface ChannelCoverModalState {
  selectedBackgroundType: CoverBackgroundType;
  selectedColor: CoverColor;
  selectedImage: CoverImage;
}

class ChannelCover extends React.Component<
  ChannelCoverModalProps,
  ChannelCoverModalState
> {
  private myRef: React.RefObject<HTMLDivElement>;
  constructor(props: ChannelCoverModalProps) {
    super(props);

    this.state = {
      selectedBackgroundType:
        this.props.selectedBackgroundType === CoverBackgroundType.TYPE_IMAGE
          ? CoverBackgroundType.TYPE_IMAGE
          : CoverBackgroundType.TYPE_COLOR,
      selectedColor: this.props.selectedColor,
      selectedImage: this.props.selectedImage,
    };
    this.myRef = React.createRef();
  }
  public componentDidMount() {
    if (this.props.selectedBackgroundType === CoverBackgroundType.TYPE_IMAGE) {
      const imgSrc = new Image();
      imgSrc.onload = () => {
        if (this.myRef && this.myRef.current) {
          this.myRef.current.style.backgroundImage =
            "url(" + this.props.selectedImage.url + ") !important";
        }
      };
      imgSrc.src = this.props.selectedImage.url;
    }
  }
  public componentDidUpdate(
    prevProps: ChannelCoverModalProps,
    prevState: ChannelCoverModalState,
  ) {
    if (
      this.props.selectedBackgroundType !== prevProps.selectedBackgroundType ||
      this.props.selectedColor !== prevProps.selectedColor ||
      this.props.selectedImage !== prevProps.selectedImage
    ) {
      this.setState({
        selectedBackgroundType: this.props.selectedBackgroundType,
        selectedColor: this.props.selectedColor,
        selectedImage: this.props.selectedImage,
      });
      if (
        this.props.selectedBackgroundType === CoverBackgroundType.TYPE_COLOR &&
        this.myRef &&
        this.myRef.current
      ) {
        this.myRef.current.style.backgroundImage = "";
      }
    }
  }

  public callBack = (
    type: CoverBackgroundType,
    selectedColor: CoverColor,
    selectedImage: CoverImage,
  ): void => {
    if (this.props.onChangeCover) {
      this.props.onChangeCover(type, selectedColor, selectedImage);
    }
  };

  public onChangeColor = (color: CoverColor): void => {
    if (this.state.selectedColor.name !== color.name) {
      this.setState(
        {
          selectedBackgroundType: CoverBackgroundType.TYPE_COLOR,
          selectedColor: color,
        },
        () => {
          if (this.myRef && this.myRef.current) {
            this.myRef.current.style.backgroundImage = "";
          }
          this.callBack(
            CoverBackgroundType.TYPE_COLOR,
            color,
            this.state.selectedImage,
          );
        },
      );
    }
  };

  public onChangeImage = (image: CoverImage): void => {
    this.setState({
      selectedBackgroundType: CoverBackgroundType.TYPE_IMAGE,
      selectedImage: image,
    });
    this.callBack(
      CoverBackgroundType.TYPE_IMAGE,
      this.state.selectedColor,
      image,
    );
  };

  public render(): JSX.Element {
    const panes = [
      {
        menuItem: "Image",
        render: (): JSX.Element => (
          <TabPane data-testid="cover-thumbnail">
            <ImagesPanel onUploadSuccess={this.props.onUploadSuccess} />
          </TabPane>
        ),
      },
      {
        menuItem: "Color",
        render: (): JSX.Element => (
          <TabPane>
            <ColorPanel
              onChangeColor={this.onChangeColor}
              selectedColor={this.state.selectedColor}
            />
          </TabPane>
        ),
      },
    ];
    return (
      <StyledCover>
        <div className="column-left" data-testid="column-left">
          <div ref={this.myRef} data-testid="cover-left-thumbnail">
            <CoverThumbnail
              type={this.state.selectedBackgroundType}
              color={this.state.selectedColor}
              image={this.state.selectedImage}
            />
          </div>
        </div>
        <div className="column-right">
          <Tab
            className="cover-type-picker"
            panes={panes}
            data-testid="cover-right-pane"
          />
        </div>
      </StyledCover>
    );
  }
}

export default ChannelCover;
