import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Loader, Notification } from "@screencloud/screencloud-ui-components";
import { useAppContext } from "src/hooks/useAppContext";
import ReplaceContentStatusItem from "src/components/ReplaceContentStatus";
import { SEC_MS } from "src/constants/constants";
import { useGetCurrentReplacementJobsQuery } from "src/types.g";
import { getPlaylistList } from "src/state/helper/query";

export type ReplacementJob = {
  __typename: string;
  id: string;
  originalFile: {
    __typename: string;
    name: string;
  } | null;
  originalAppInstance: {
    __typename: string;
    name: string;
  } | null;
};

const shouldDisplayNotification = (isFromModal: boolean, pollCount: number) => {
  if (isFromModal) {
    // once click on replace button in replacement modal
    return true;
  } else if (!isFromModal && pollCount > 0) {
    // first time access from media list
    return true;
  }
  return false;
};

export const useReplaceContentStatus = (): {
  jobs: ReplacementJob[] | undefined;
  setIsFromReplaceModal: (isFromReplaceModal: boolean) => void;
} => {
  const context = useAppContext();
  const [isFromModal, setIsFromModal] = useState(false);
  const [pollCount, setPollCount] = useState(0);
  const pollInterval = SEC_MS * 2;

  const { data, stopPolling, startPolling } = useGetCurrentReplacementJobsQuery(
    {
      variables: { spaceId: context?.currentSpace?.id },
    },
  );
  const jobs =
    (data?.getCurrentReplacementJobs?.nodes as ReplacementJob[]) ?? [];

  const setIsFromReplaceModal = (isFromModal: boolean) => {
    setIsFromModal(isFromModal);
  };

  useEffect(() => {
    const title = (
      <span>
        <Loader inline size="small" active />
        <FormattedMessage
          id="job-status-title"
          defaultMessage="Replacing Content"
        />
      </span>
    );

    if (jobs.length > 0) {
      setPollCount(pollCount + 1);
      startPolling(pollInterval);
      context.modal.openNotificationExpandable(
        jobs?.map((item) => (
          <ReplaceContentStatusItem
            key={item.id}
            title={
              item.originalFile?.name ?? item.originalAppInstance?.name ?? ""
            }
          />
        )),
        title,
        () => {},
      );
    } else {
      getPlaylistList(context?.currentSpace?.id);
      stopPolling();
      if (shouldDisplayNotification(isFromModal, pollCount)) {
        Notification.success({
          message: context.intl.formatMessage({
            id: "media.replace.successfully",
            defaultMessage: "Replaced content successfully",
          }),
        });
      }
      context.modal.closeNotificationExpandable();
    }
  }, [context.modal, jobs]);

  return { jobs, setIsFromReplaceModal };
};
