import { LinkCloudServiceClient } from "@screencloud/signage-link-client";
import { UUID } from "@screencloud/uuid";
import * as React from "react";
import { AppContextType } from "src/AppContextProvider/type";
import { appConfig } from "../../appConfig";
import { AppContext } from "../../AppContextProvider/AppContext";
import {
  LinkCloudServiceStatusCode,
  LinkValidationErrorCode,
} from "../../constants/constants";
import { FEATURE_FLAGS_ENUM } from "../../constants/featureFlag";
import { filestackApiURL } from "../../helpers/filestackHelper";
import { LinkByIdQueryHookResult } from "../../types.g";
import CreateLink, { CreateLinkActions, CreateLinkPayload } from "./create";
import { Styled } from "./styles";

const credentialServerUrl = appConfig.link.credentialServerUrl;
const validationServiceUrl = appConfig.link.validationServiceUrl;
const cloudServiceUrl = appConfig.link.cloudServiceUrl;
const baseUrl = window.location.origin;

export interface LinkPickerProps {
  callback: (param: CreateLinkPayload) => void;
  linkById?: NonNullable<LinkByIdQueryHookResult["data"]>["linkById"];
  spaceId?: UUID;
}

export interface LinkPickerState {
  isValidatingLink: boolean;
  isValidateLinkComplete: boolean;
  isValidateLinkSuccess: boolean;
  isShowInternalOption: boolean;
  isShowCloudOption: boolean;
  isSupportIframe: boolean;
  forceCloudRendering: boolean;
  forceInternal: boolean;
  validateLinkMessage: string;
  validatePageTitle: string;
  thumbnail: string;
  thumbnailError: string;
  thumbnailUrl: string;
  appsSuggestion: object[];
  credential: string;
  hasCredential: boolean;
  isShowBadPageResponse: boolean;
}

class LinkPicker extends React.PureComponent<LinkPickerProps, LinkPickerState> {
  public static contextType = AppContext;
  public context: AppContextType;

  public _isMounted: boolean;
  public closePopupEventListener;

  protected targetScreenshotId = 0;
  private createLinkRef: React.RefObject<CreateLink>;
  private linkCloudServiceClient: LinkCloudServiceClient;

  constructor(props: LinkPickerProps) {
    super(props);
    this.state = this.getInitialState();
    this.createLinkRef = React.createRef();
  }

  public componentDidMount() {
    this._isMounted = true;
  }

  public componentWillUnmount() {
    this._isMounted = false;
    if (this.closePopupEventListener) {
      window.removeEventListener("message", this.closePopupEventListener);
      this.closePopupEventListener = undefined;
    }
  }

  public getInitialState() {
    return {
      appsSuggestion: [],
      credential: "",
      forceCloudRendering: false,
      forceInternal: false,
      hasCredential: false,
      isShowCloudOption: false,
      isShowInternalOption: false,
      isSupportIframe: true,
      isValidateLinkComplete: false,
      isValidateLinkSuccess: false,
      isValidatingLink: false,
      thumbnail: "",
      thumbnailError: "",
      thumbnailUrl: "",
      validateLinkMessage: "",
      validatePageTitle: "",
      isShowBadPageResponse: false,
    };
  }

  public clearExistingThumbnail() {
    this.setState({
      thumbnail: "",
      thumbnailError: "",
      thumbnailUrl: "",
    });
  }

  public setLinkErrorMessage(
    errorCode: LinkValidationErrorCode,
    errorMessage: string = "",
  ) {
    let linkErrorMessage = "";
    switch (errorCode) {
      case LinkValidationErrorCode.BAD_PAGE_RESPONSE:
        linkErrorMessage =
          this.context.intl.formatMessage({
            defaultMessage: "Bad page response",
            id: "links.validate_message.bad_page_response",
          }) +
          ". " +
          errorMessage;
        break;
      case LinkValidationErrorCode.CLOUD_ERR:
        linkErrorMessage = this.context.intl.formatMessage({
          defaultMessage: "Cloud render server return failed",
          id: "links.validate_message.cloud_err",
        });
        break;
      case LinkValidationErrorCode.IFRAME_DENIED:
        /* linkErrorMessage = this.context.intl.formatMessage({
          defaultMessage: 'Remote page can\'t be loaded into an iframe',
          id: 'links.validate_message.iframe_denied',
        })*/
        linkErrorMessage =
          "Cloud render is the only option available for link with iframe disabled";
        break;
      case LinkValidationErrorCode.INTERNAL_ERR:
        linkErrorMessage = this.context.intl.formatMessage({
          defaultMessage: "Validation failed",
          id: "links.validate_message.internal_err",
        });
        break;
      case LinkValidationErrorCode.INVALID_URL:
        linkErrorMessage = this.context.intl.formatMessage({
          defaultMessage: "Please provide a valid url",
          id: "links.validate_message.invalid_url",
        });
        break;
      case LinkValidationErrorCode.URL_UNAVAILABLE:
        linkErrorMessage = this.context.intl.formatMessage({
          defaultMessage: "Could not reach url",
          id: "links.validate_message.url_unavailable",
        });
        break;
      default:
      /* linkErrorMessage = this.context.intl.formatMessage({
          defaultMessage: 'Please verify your url again',
          id: 'links.validate_message.default_message',
        })*/
    }

    this.setState({
      validateLinkMessage: linkErrorMessage,
    });
  }

  public async handleRendering(param: CreateLinkPayload) {
    const currentScreenshotId = ++this.targetScreenshotId;
    this.clearExistingThumbnail();

    if (param && param.link) {
      try {
        if (param.isCloudRendering) {
          const screenshotUrl =
            await this.getLinkCloudServiceClient().getScreenshotUrl({
              height: 768,
              linkCredential: this.state.credential || null,
              linkTargetUrl: param.link,
              width: 1366,
            });

          this.loadAndSetThumbnailAsBase64(
            screenshotUrl || undefined,
            currentScreenshotId,
          );
        } else {
          const linkUrl = param.link;
          const screenshotUrl = `${filestackApiURL}/urlscreenshot=mode:window,height:768,width:1366/${linkUrl}`;

          // security=policy:${appConfig.fileStackSecurityPolicy},signature:${appConfig.fileStackSecuritySignature}
          this.loadAndSetThumbnailAsBase64(screenshotUrl, currentScreenshotId);
        }
      } catch (statusErr) {
        this.setLinkErrorMessage(LinkValidationErrorCode.CLOUD_ERR);
      }
    }
  }

  public loadAndSetThumbnailAsBase64(
    thumbnailUrl?: string,
    currentScreenshotId?: number,
  ) {
    // reset to last thumbnailUrl
    if (!thumbnailUrl) {
      thumbnailUrl = this.state.thumbnailUrl;
      currentScreenshotId = ++this.targetScreenshotId;
    } else if (currentScreenshotId !== this.targetScreenshotId) {
      return;
    } else {
      this.setState({ thumbnailUrl });
    }

    fetch(thumbnailUrl)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        this.readFileAsync(blob).then((base64: string) => {
          const baseString = base64.toString();
          if (this._isMounted) {
            if (currentScreenshotId !== this.targetScreenshotId) {
              return;
            }
            this.setState({
              thumbnail: baseString,
              thumbnailError: "",
            });
          }
        });
      })
      .catch((err) => {
        if (currentScreenshotId !== this.targetScreenshotId) {
          return;
        }
        const errorMessage = this.context.intl.formatMessage({
          defaultMessage: "Could not load thumbnail",
          id: "links.validate_message.thumbnail_err",
        });
        if (this._isMounted) {
          this.setState({
            thumbnailError: errorMessage,
          });
        }
      });
  }

  public readFileAsync(blob) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new DOMException("Problem parsing input file"));
      };

      fileReader.onload = () => {
        const result = (fileReader && fileReader.result) || {};
        resolve(result);
      };
      fileReader.readAsDataURL(blob);
    });
  }

  public isHttpUrl(url) {
    return url.trim().indexOf("http://") === 0;
  }

  public handleLinkValidation(param: CreateLinkPayload) {
    const allowCloudRenderingOption = this.context.shouldShowFeature(
      FEATURE_FLAGS_ENUM.CLOUD_RENDERING,
    );

    this.setState({
      isValidatingLink: true,
    });

    fetch(validationServiceUrl, {
      body: JSON.stringify({
        url: param.link,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((response) => response.json())
      .then((json) => {
        const pageTitle = (json.data && json.data.title) || "";
        let errorCode;
        let errorMessage;
        let isInvalidLink = false;
        const isIframe = json.errCode === LinkValidationErrorCode.IFRAME_DENIED;
        const isBadPageResponse =
          json.errCode === LinkValidationErrorCode.BAD_PAGE_RESPONSE;
        const cannotDisplayIframe = isIframe && !allowCloudRenderingOption;
        if (json.errCode) {
          isInvalidLink = !isIframe && !isBadPageResponse;
          errorCode = json.errCode;
          errorMessage = json.errMsg;
        }
        if (this._isMounted) {
          this.setState({
            isValidateLinkComplete: true,
            isValidateLinkSuccess: !(isInvalidLink || cannotDisplayIframe),
            isValidatingLink: false,
            validatePageTitle: pageTitle,
            isSupportIframe: !isIframe,
            isShowBadPageResponse: false,
          });
        }
        if (isInvalidLink) {
          this.setState({
            forceCloudRendering: false,
            forceInternal: false,
            isShowCloudOption: false,
            isShowInternalOption: true,
            isShowBadPageResponse: false,
          });
          this.checkAlternativeUrl(json.url);
        } else {
          if (isIframe) {
            this.setState({
              forceCloudRendering: allowCloudRenderingOption,
              forceInternal: false,
              isShowCloudOption: allowCloudRenderingOption,
              isShowInternalOption: false,
              validatePageTitle: json.url,
              isShowBadPageResponse: false,
            });
          } else if (this.isHttpUrl(json.url)) {
            this.setState({
              forceCloudRendering: allowCloudRenderingOption,
              forceInternal: false,
              isShowCloudOption: allowCloudRenderingOption,
              isShowInternalOption: false,
              isShowBadPageResponse: false,
            });
            this.checkAlternativeUrl(json.url);
          } else if (isBadPageResponse) {
            this.setState({
              forceCloudRendering: allowCloudRenderingOption,
              forceInternal: false,
              isShowCloudOption: allowCloudRenderingOption,
              isShowInternalOption: false,
              isShowBadPageResponse: true,
            });
          } else {
            this.setState({
              forceCloudRendering: false,
              forceInternal: false,
              isShowCloudOption: allowCloudRenderingOption,
              isShowInternalOption: false,
              isShowBadPageResponse: false,
            });
          }
        }

        this.setLinkErrorMessage(errorCode, errorMessage);
        if (!(isInvalidLink || cannotDisplayIframe)) {
          const newUrl = json.url || param.link;
          if (
            this.createLinkRef.current &&
            this.createLinkRef.current.triggerUpdateUrl
          ) {
            this.createLinkRef.current.triggerUpdateUrl(newUrl);
          }
          this.handleRendering({
            isCloudRendering: false,
            link: newUrl,
          });
        } else {
          this.clearExistingThumbnail();
          ++this.targetScreenshotId;
        }
      })
      .catch((err) => {
        if (this._isMounted) {
          this.setState({
            isValidateLinkComplete: true,
            isValidateLinkSuccess: false,
            isValidatingLink: false,
            validatePageTitle: "",
          });
        }

        this.setLinkErrorMessage(LinkValidationErrorCode.INTERNAL_ERR);
      });
  }

  public checkAlternativeUrl(url) {
    if (this.isHttpUrl(url)) {
      const newUrl = url.replace("http://", "https://");

      fetch(validationServiceUrl, {
        body: JSON.stringify({
          url: newUrl,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      })
        .then((response) => response.json())
        .then((json) => {
          if (!json.errCode) {
            if (
              this.createLinkRef.current &&
              this.createLinkRef.current.triggerShowProtocolOption
            ) {
              this.createLinkRef.current.triggerShowProtocolOption();
            }
          }
        });
    }
  }

  public fetchServiceStatus(url) {
    return new Promise((resolve, reject) => {
      let totalTime = 0;
      const timeLimit = 30000;
      const interval = 2000;
      const loop = () => {
        fetch(url)
          .then((response) => response.json())
          .then((json) => {
            if (json.status === LinkCloudServiceStatusCode.READY) {
              resolve(true);
            } else if (json.status === LinkCloudServiceStatusCode.FAILED) {
              reject();
            } else if (totalTime > timeLimit) {
              reject();
            } else {
              totalTime += interval;
              setTimeout(loop, interval);
            }
          });
      };
      loop();
    });
  }

  public handleSubmitCredential(param: CreateLinkPayload, credential: string) {
    this.setState(
      {
        credential,
        hasCredential: true,
        validateLinkMessage: "",
      },
      () => {
        this.handleRendering(param);
      },
    );
  }

  public handleClosePopupEvent(param: CreateLinkPayload) {
    if (this.closePopupEventListener) {
      window.removeEventListener("message", this.closePopupEventListener);
      this.closePopupEventListener = undefined;
    }

    window.addEventListener(
      "message",
      (this.closePopupEventListener = (event) => {
        if (event.origin === baseUrl) {
          this.handleSubmitCredential(param, event.data);
        }
      }),
    );
  }

  public handleCallback(action: CreateLinkActions, param: CreateLinkPayload) {
    switch (action) {
      case CreateLinkActions.RESET:
        break;
      case CreateLinkActions.LINK_VALIDATION:
        this.setState(this.getInitialState());
        this.handleLinkValidation(param);
        break;
      case CreateLinkActions.ADD_CREDENTIAL:
        const callbackUrl = `${baseUrl}/links/callback`; // move to config/env
        const credentialServer = `${credentialServerUrl}/?link=${param.link}&redirect=${callbackUrl}`; // move to config/env
        const popup = window.open(
          credentialServer,
          "_blank",
          "width=400, height=570",
        );
        if (popup) {
          popup.focus();
        }
        this.handleClosePopupEvent(param);
        break;
      case CreateLinkActions.REMOVE_CREDENTIAL:
        this.setState(
          {
            credential: "",
            hasCredential: false,
            validateLinkMessage: "",
          },
          () => {
            this.handleRendering(param);
          },
        );
        break;
      case CreateLinkActions.RETRY_THUMBNAIL:
        this.setState(
          {
            thumbnailError: "",
          },
          this.loadAndSetThumbnailAsBase64,
        );
        break;
      case CreateLinkActions.RENDER:
        this.handleRendering(param);
        break;
      case CreateLinkActions.SUBMIT:
      case CreateLinkActions.UPDATE:
        param.thumbUrl = this.state.thumbnail;
        param.credential = this.state.credential;
        this.props.callback(param);
        break;
    }
  }

  public render() {
    return (
      <Styled>
        <CreateLink
          ref={this.createLinkRef}
          isValidatingLink={this.state.isValidatingLink}
          isValidateLinkComplete={this.state.isValidateLinkComplete}
          isValidateLinkSuccess={this.state.isValidateLinkSuccess}
          isShowInternalOption={this.state.isShowInternalOption}
          isShowCloudOption={this.state.isShowCloudOption}
          isSupportIframe={this.state.isSupportIframe}
          forceCloudRendering={this.state.forceCloudRendering}
          forceInternal={this.state.forceInternal}
          validateLinkMessage={this.state.validateLinkMessage}
          validatePageTitle={this.state.validatePageTitle}
          thumbUrl={this.state.thumbnail}
          thumbnailError={this.state.thumbnailError}
          appsSuggestion={this.state.appsSuggestion}
          hasCredential={this.state.hasCredential}
          callback={this.handleCallback.bind(this)}
          linkById={this.props.linkById}
          isShowBadPageResponse={this.state.isShowBadPageResponse}
        />
      </Styled>
    );
  }

  private getLinkCloudServiceClient = (): LinkCloudServiceClient => {
    if (!this.linkCloudServiceClient) {
      this.linkCloudServiceClient = new LinkCloudServiceClient({
        cloudServiceUrl,
      });
    }
    return this.linkCloudServiceClient;
  };
}

export default LinkPicker as React.ComponentType<LinkPickerProps>;
