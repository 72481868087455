import { useState, useEffect, useCallback } from "react";
import { Theme } from "@screencloud/screencloud-ui-components";

const mobileBreakPoint = +Theme.break.small.replace("px", "");
const tinyScreenBreakPoint = +Theme.break.tiny.replace("px", "");

export enum ScreenSize {
  Tiny,
  Mobile,
  Desktop,
}

export interface UseGetScreenSize {
  width: number;
  height: number;
  screenSize: ScreenSize;
  isMobileView: boolean;
  isDesktopView: boolean;
}

export function useGetScreenSize(): UseGetScreenSize {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  const [screenSize, setScreenSize] = useState<ScreenSize>(ScreenSize.Desktop);

  const getScreenSize = (width: number) => {
    return isTinyView(width)
      ? ScreenSize.Tiny
      : isMobileView(width)
        ? ScreenSize.Mobile
        : ScreenSize.Desktop;
  };

  const updateDimension = useCallback(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    setScreenSize(getScreenSize(window.innerWidth));
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => {
    window.addEventListener("resize", updateDimension);
    updateDimension();
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, []);

  return {
    width,
    height,
    screenSize,
    isMobileView: isMobileView(width),
    isDesktopView: !isMobileView(width),
  };
}

const isMobileView = (width: number) => width <= mobileBreakPoint;
const isTinyView = (width: number) => width <= tinyScreenBreakPoint;
