import {
  DatetimeFormat,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TagInput,
} from "@screencloud/screencloud-ui-components";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { DropdownItemProps } from "semantic-ui-react";
import { useAppContext } from "../../hooks/useAppContext";
import { LinkByIdQueryHookResult } from "../../types.g";
import DateTime from "../DateTime";
import { LinkPreviewActions, LinkPreviewPayload } from "./preview";
export interface LinkDetailsState {}

export interface LinkDetailsProps {
  callBack?: (
    mediaId: string,
    action: LinkPreviewActions,
    data: LinkPreviewPayload,
  ) => void;
  id: string;
  url: string;
  tags: string[];
  tagsList: DropdownItemProps[];
  createdAt: string;
  createdBy: string;
  isLinkOwner: boolean;
  linkById?: NonNullable<LinkByIdQueryHookResult["data"]>["linkById"];
}

export enum LinkDetailActions {}

const LinkDetails = (props: LinkDetailsProps) => {
  const context = useAppContext();
  const handleTagsChanged = useCallback(
    (tags: DropdownItemProps[]) => {
      if (props.callBack) {
        props.callBack(props.id, LinkPreviewActions.UPDATE_TAGS, {
          tagsList: tags,
        });
      }
    },
    [props.id],
  );
  const canUpdateLink = context.currentPermissions.validateCurrentSpace(
    "link",
    "update",
  );
  return (
    <>
      <div className="wrapper">
        <div className="url-wrapper">
          {canUpdateLink ? (
            <>
              <div data-testid="link-url">
                <span>
                  <a
                    href={props.url}
                    title={props.url}
                    className="linkurl"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {props.url}
                  </a>
                </span>
              </div>
              <Icon
                data-testid="edit-link-icon"
                onClick={() =>
                  props.callBack &&
                  props.callBack(
                    props.id,
                    LinkPreviewActions.UPDATE_CURRENT_URL,
                    { linkById: props.linkById },
                  )
                }
                name="edit"
              />
            </>
          ) : (
            <a
              data-testid="link-url"
              href={props.url}
              title={props.url}
              className="linkurl"
              style={{ display: "inline-block" }}
              target="_blank"
              rel="noreferrer"
            >
              <span>{props.url}</span>
              <Icon name="external" />
            </a>
          )}
        </div>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  id="ui_component.common.added_by"
                  defaultMessage="Added By"
                />
              </TableCell>
              <TableCell>{props.createdBy}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  id="ui_component.common.date_added"
                  defaultMessage="Date Added"
                />
              </TableCell>
              <TableCell>
                <DateTime
                  value={props.createdAt}
                  format={DatetimeFormat.Long}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className="tags" data-testid="tags-input">
          <h3>
            <FormattedMessage id="common.text.tags" defaultMessage="Tags" />
          </h3>
          <TagInput
            disabled={
              !context.currentPermissions.validateCurrentSpace(
                "link",
                "update",
              ) || !props.isLinkOwner
            }
            onTagsChanged={handleTagsChanged}
            currentTags={props.tags}
            tagsList={props.tagsList}
          />
        </div>
      </div>
    </>
  );
};

export default LinkDetails;
