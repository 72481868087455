import styled from "styled-components";

export const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  .error-feedback-content {
    font-size: 18px;
  }

  span {
    display: block;
    margin-bottom: 8px;
  }

  .error-feedback-content-info {
    font-weight: bold;
  }
`;
