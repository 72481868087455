import { DatetimeFormat } from "@screencloud/screencloud-ui-components";
import { isValid, formatDistanceStrict } from "date-fns";
import DateTime from "../components/DateTime";
import { DateWithoutTimezone } from "../valueObject/DateWithoutTimezone";
import format from "date-fns/format";
import { enGB, enUS } from "date-fns/locale";

export const microSecs = 1000000;
export const dayInMilliSecs = 86400000;

export function normalizedDate(dateString: string) {
  // dateString in 'yyyy-MM-dd' format
  return new Date(dateString.trim() + "T00:00:00");
}

export function isDateTime(dateString: string) {
  // validate date string by following format '2000-01-01T00:00:00'
  const regex =
    /^\d\d\d\d-(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01])T([0-9][0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]):([0-9]|[0-5][0-9])$/;
  return regex.test(dateString);
}

export function getTimefromDateOrTimeString(value: string) {
  // Get time from date or time string (eg. '2021-01-31T00:00:00', 2021-10-31', '24:00', '24:00:00')
  // Also add temp date '2000-01-01T' for Safari support
  return new Date(value.length >= 10 ? value : "2000-01-01T" + value).getTime();
}

export function isRangeOverlap(a_start, a_end, b_start, b_end) {
  if (a_start <= b_start && b_start <= a_end) {
    return true; // b starts in a
  } else if (a_start <= b_end && b_end <= a_end) {
    return true; // b ends in a
  } else if (b_start < a_start && a_end < b_end) {
    return true; // a in b
  }
  return false;
}

export function isEverydaySelected(day_of_week: {
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
}) {
  return Object.values(day_of_week).every((item) => item === true);
}

export function isTimeOver24h(time: string) {
  // Temp date for Safari support
  return time.startsWith("24") && !isValid(new Date("2000-01-01T" + time));
}

/**
 *
 * @param utcDate format 2022-03-03T14:00:00+00:00
 * Output: Date with year 2020, month 03, day 03 time 14:00 in local timezone
 * If local timezone is GMT+7, This will be appeared as
 * 2022-03-03T14:00:00+07:00
 */
export function getDateWithoutTimeZoneFromUtcDate(
  utcDate: string,
): DateWithoutTimezone {
  if (!utcDate) {
    throw new Error(
      "utc date string in this format (2022-03-03T14:00:00+00:00) is required",
    );
  }

  const date = new Date(utcDate.replace("+00:00", ""));
  return getDateWithoutTimeZone(date);
}

export function getCurrentDateWithoutTimeZone(): DateWithoutTimezone {
  const date = new Date();
  return getDateWithoutTimeZone(date);
}

export function getDateWithoutTimeZone(date: Date): DateWithoutTimezone {
  return new DateWithoutTimezone(
    date.getFullYear(),
    // JavaScript date must add by one to get literal month
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  );
}

export function getTimeLeftUntil(futureDate: Date): string | null {
  return formatDistanceStrict(new Date(), futureDate);
}

export const getLastActiveTime = (date: string) => {
  try {
    if (date && Date.parse(date)) {
      return (
        <DateTime
          value={date}
          format={DatetimeFormat.ShortWithRelativeLogStyle}
        />
      );
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getDateFnsLocale = (locale?: string) => {
  if (locale === "en-GB") {
    return enGB;
  } else {
    return enUS;
  }
};

export const getShortTimeFormat = (dateValue: string | null): string | null => {
  if (!dateValue) {
    return null;
  }

  const dateAndTime = dateValue.split("T");
  const dateSplit = dateAndTime[0].split("-");
  const time = dateAndTime[1].split("+");
  const timeOnly = time[0].split(":");
  const date: Date = new Date(
    Number(dateSplit[0]),
    Number(dateSplit[1]) - 1,
    Number(dateSplit[2]),
    Number(timeOnly[0] || 0),
    Number(timeOnly[1] || 0),
    Number(timeOnly[2] || 0),
  );
  const shortFormat = "PPp";
  const localTime = new Date(
    date.getTime() - date.getTimezoneOffset() * 60 * 1000,
  );
  const locale = getDateFnsLocale();

  const finalDate = format(localTime, shortFormat, { locale });

  return finalDate;
};

export const normalizeTimeFormat = (time: string) => {
  const tempDate = new Date().toISOString().split("T")[0];
  return new Date(`${tempDate} ${time}`).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
};
