import {
  DatetimeFormat,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TagInput,
} from "@screencloud/screencloud-ui-components";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { DropdownItemProps } from "semantic-ui-react";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import { formatBytes } from "../../utils";
import DateTime from "../DateTime";
import { MediaPreviewActions, MediaPreviewPayload } from "./preview";

export interface MediaDetailsState {}

export interface MediaDetailsProps {
  callBack?: (
    mediaId: string,
    action: MediaPreviewActions,
    data: MediaPreviewPayload,
  ) => void;
  id: string;
  tags: string[];
  tagsList: DropdownItemProps[];
  mimeType: string;
  createdAt: string;
  updatedBy: string;
  spaceId: string;
  size?: number;
  totalPages?: number;
  duration?: string;
  dimensionWidth?: number;
  dimensionHeight?: number;
  isMediaOwner: boolean;
}

export enum MediaDetailActions {}

class MediaDetails extends Component<MediaDetailsProps, MediaDetailsState> {
  public static contextType = AppContext;
  public context: AppContextType;
  public constructor(props: MediaDetailsProps) {
    super(props);
  }
  public handleTagsChanged = (tags: DropdownItemProps[]) => {
    if (this.props.callBack) {
      this.props.callBack(this.props.id, MediaPreviewActions.UPDATE_TAGS, {
        tagsList: tags,
      });
    }
  };

  public render() {
    return (
      <>
        <div className="wrapper">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Format</TableCell>
                <TableCell>{this.props.mimeType}</TableCell>
              </TableRow>
              {this.props.dimensionHeight && this.props.dimensionWidth ? (
                <TableRow>
                  <TableCell>Dimensions</TableCell>
                  <TableCell>
                    {this.props.dimensionWidth} × {this.props.dimensionHeight}
                  </TableCell>
                </TableRow>
              ) : null}
              {this.props.duration ? (
                <TableRow>
                  <TableCell>Duration</TableCell>
                  <TableCell>{this.props.duration}</TableCell>
                </TableRow>
              ) : null}
              {this.props.totalPages && this.props.totalPages > 0 ? (
                <TableRow>
                  <TableCell>Number of Pages</TableCell>
                  <TableCell>{this.props.totalPages}</TableCell>
                </TableRow>
              ) : null}
              {this.props.size ? (
                <TableRow>
                  <TableCell>File size</TableCell>
                  <TableCell>{formatBytes(this.props.size)}</TableCell>
                </TableRow>
              ) : null}
              <TableRow>
                <TableCell>Uploaded by</TableCell>
                <TableCell>{this.props.updatedBy}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Space</TableCell>
                <TableCell>
                  {this.context.allSpaces.find(
                    (space) => space.id === this.props.spaceId,
                  )?.name || ""}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Uploaded date</TableCell>
                <TableCell>
                  <DateTime
                    value={this.props.createdAt}
                    format={DatetimeFormat.Long}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {this.props.isMediaOwner && (
            <div className="tags">
              <h3>
                <FormattedMessage id="common.text.tags" defaultMessage="Tags" />
              </h3>
              <TagInput
                disabled={
                  !this.context.currentPermissions.validateCurrentSpace(
                    "media",
                    "update",
                  )
                }
                onTagsChanged={this.handleTagsChanged}
                currentTags={this.props.tags}
                tagsList={this.props.tagsList}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default MediaDetails;
