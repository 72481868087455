import { AdvancedSettingsActionType } from "./types";

interface AdvancedSettingsState {
  zoom: number;
  scrollFrom: number;
  scrollTo: number;
}

interface AdvancedSettingsAction {
  type: AdvancedSettingsActionType;
  value: number;
}

export const advancedSettingsReducer = (
  state: AdvancedSettingsState,
  action: AdvancedSettingsAction,
): AdvancedSettingsState => {
  switch (action.type) {
    case AdvancedSettingsActionType.SET_SCROLL_FROM:
      return { ...state, scrollFrom: action.value };
    case AdvancedSettingsActionType.SET_SCROLL_TO:
      return { ...state, scrollTo: action.value };
    case AdvancedSettingsActionType.SET_ZOOM:
      return { ...state, zoom: action.value };
    default:
      return state;
  }
};
