import QRCode from "qrcode";

export enum QRcodePosition {
  BOTTOM_RIGHT = "bottom right",
  BOTTOM_LEFT = "bottom left",
  TOP_RIGHT = "top right",
  TOP_LEFT = "top left",
}

export interface QrcodePositionStyledProps {
  qrcodeleft?: boolean;
  qrcoderight?: boolean;
  qrcodetop?: boolean;
  qrcodebottom?: boolean;
}

export const generateQR = async (text: string): Promise<string> => {
  try {
    const qrcode = await QRCode.toDataURL(text);
    return qrcode;
  } catch (err) {
    console.error(err);
    return "";
  }
};

export const getQrcodePositionStyleProps = (qrcodePosition: QRcodePosition) => {
  // `top left` -->  { qrcodetop: true, qrcodeleft: true }
  return Object.fromEntries(
    qrcodePosition.split(" ").map((position) => [[`qrcode${position}`], true]),
  );
};
