import {
  Plan,
  CfSubscriptionType,
  PeriodUnit,
  Customer,
  Subscription,
} from "../billing/types";
import { centsToDollars } from "../billing/helpers/util";

export const PLAN_NAME = {
  starter: "starter",
  core: "core",
  pro: "pro",
};

/**
 *
 * @param plan
 * @returns this is only for new billing plan
 *
 */
export function isStarterPlan(plan: Pick<Plan, "cfName">) {
  return plan.cfName === PLAN_NAME.starter;
}

/**
 * this is only for new billing plan
 * core is basically starters successor. Of course we still support starter as a legacy,
 * but all new customers will go on core from tomo
 *
 * core is pretty much identical to starter, the main two differences are the name and the minimum limit of screens (from 3 to 1)
 *
 * @returns
 */
export function isCorePlan(plan: Pick<Plan, "cfName">) {
  return plan?.cfName === PLAN_NAME.core;
}

export function isProPlan(plan: Pick<Plan, "cfName">) {
  return plan?.cfName === PLAN_NAME.pro;
}

export function isNewBillingPlanSupportMonthlyPlan(plan: Pick<Plan, "cfName">) {
  return isStarterPlan(plan) || isCorePlan(plan);
}

/**
 * Is this plan a no screens plan
 *
 * @param plan - The plan object the user is on
 */
export function isNoScreens(plan: Pick<Plan, "cfSubscriptionType">): boolean {
  return plan.cfSubscriptionType === CfSubscriptionType.NoScreens;
}

//
// getTermValue
//
export function getTermValue(
  plan: Pick<Plan, "cfSubscriptionType" | "periodUnit">,
): PeriodUnit {
  return isNoScreens(plan) ? PeriodUnit.Year : plan.periodUnit;
}

export function isLegacyPlanInvoiced(
  plan: Pick<Plan, "cfSubscriptionType">,
): boolean {
  return plan.cfSubscriptionType === CfSubscriptionType.AnnuallyInvoiced;
}

/**
 * Is this plan the annually invoiced plan?
 *
 * @param plan - The plan object the user is on
 */
export function isInvoiced({
  plan,
  customer,
}: {
  plan: Pick<
    Plan,
    "cfIncludedPhysicalScreenLicenses" | "cfName" | "cfSubscriptionType"
  >;
  customer: Pick<Customer, "cfInvoicedPayment">;
}): boolean {
  if (isLegacyBillingPlan(plan)) {
    return plan.cfSubscriptionType === CfSubscriptionType.AnnuallyInvoiced;
  }
  return Boolean(customer.cfInvoicedPayment);
}

export function isLegacyBillingPlan(
  plan: Pick<Plan, "cfIncludedPhysicalScreenLicenses" | "cfName">,
) {
  return !isNewBillingPlan(plan);
}

export function isNewBillingPlan(
  plan: Pick<Plan, "cfIncludedPhysicalScreenLicenses" | "cfName">,
) {
  /**
   * We use or instad of and in case we only query one one thing and not the other.
   */
  return (
    Boolean(typeof plan.cfIncludedPhysicalScreenLicenses === "number") ||
    Boolean(plan.cfName)
  );
}

export function getNumberOfLicensesIncludedInPlan(
  plan: Pick<Plan, "cfIncludedPhysicalScreenLicenses" | "cfName">,
) {
  if (isNewBillingPlan(plan)) {
    /**
     * Number of screen come from packages, see https://screen.cloud/pricing
     */
    return plan.cfIncludedPhysicalScreenLicenses ?? 0;
  }
  return 0;
}

export function getPlanNameWithoutCurrency(
  plan: Pick<Plan, "cfName" | "cfIncludedPhysicalScreenLicenses" | "name">,
) {
  if (isNewBillingPlan(plan)) {
    return `${plan?.cfName?.charAt(0).toUpperCase()}${plan?.cfName?.slice(
      1,
    )} Plan`;
  }
  return removeCurrencyFromPlanName(plan?.name);
}

/**
 * Removed the currency from the plan name
 *
 * @param name - Plan name, e.g. Annual Studio Subscription (USD)
 */
export function removeCurrencyFromPlanName(name: string): string {
  const split = name.split("(");
  if (split.length !== 2) {
    return name;
  }
  return split[0].trim();
}

/**
 * Returns a human readable term for a plan
 *
 * @param periodUnit - Period unit of the plan (e.g. month, year etc)
 */
export function getPlanTerm(periodUnit: PeriodUnit): string {
  switch (periodUnit) {
    case PeriodUnit.Month:
      return "Monthly";
    case PeriodUnit.Year:
      return "Annually";
    case PeriodUnit.Week:
      return "Weekly";
    case PeriodUnit.Day:
      return "Daily";
    default:
      return "n/a";
  }
}

export function isYearlySubscription(
  plan: Pick<Plan, "cfSubscriptionType" | "periodUnit">,
) {
  return getTermValue(plan) === PeriodUnit.Year;
}

export function isMonthlySubscription(
  plan: Pick<Plan, "cfSubscriptionType" | "periodUnit">,
) {
  return getTermValue(plan) === PeriodUnit.Month;
}

export function getAnnualPricePerLicenceInCent(
  plan: Pick<Plan, "price" | "cfSubscriptionType" | "periodUnit">,
) {
  if (isMonthlySubscription(plan)) {
    return (plan?.price ?? 0) * 12;
  }
  if (isYearlySubscription(plan)) {
    return plan?.price ?? 0;
  }
  throw new Error("Invalid plan term");
}

export function getMonthlyPricePerLicenceInCent(
  plan: Pick<Plan, "price" | "cfSubscriptionType" | "periodUnit">,
) {
  if (isMonthlySubscription(plan)) {
    return plan?.price ?? 0;
  }
  if (isYearlySubscription(plan)) {
    return (plan?.price ?? 0) / 12;
  }
  throw new Error("Invalid plan term");
}

export function getMonthlyPricePerLicenceWithCurrency({
  plan,
  subscription,
}: {
  plan: Pick<Plan, "price" | "cfSubscriptionType" | "periodUnit">;
  subscription: Pick<Subscription, "currencyCode">;
}) {
  const monthlyPriceInCent = getMonthlyPricePerLicenceInCent(plan);
  return centsToDollars(monthlyPriceInCent, subscription.currencyCode);
}

export function isTeamPlan(plan: Plan) {
  return plan.cfName === "teams";
}

export function isBusinessPlan(plan: Plan) {
  return plan.cfName === "business";
}

export function isEnterprisePlan(plan: Plan) {
  return plan.cfName === "enterprise";
}

/**
 *
 * calculate how much money will be saved by choosing one plan over another
 * practically month plan will never save any money since it should be more expensive than year plan always or at least equal
 *
 * @returns saving percentage percentage
 */
export function calculateSaving({
  annuallyPlan,
  monthlyPlan,
  term,
}: {
  annuallyPlan: Pick<Plan, "price"> | null | undefined;
  monthlyPlan: Pick<Plan, "price"> | null | undefined;
  term: PeriodUnit;
}) {
  const month: number = (monthlyPlan?.price ?? 0) * 12;
  const year: number = annuallyPlan?.price ?? 0;

  if (!month || !year) {
    return null;
  }

  const percentage: number =
    term === PeriodUnit.Month
      ? Math.floor(((year - month) / year) * 100)
      : Math.floor(((month - year) / month) * 100);

  if (percentage < 0) {
    return null;
  }

  return percentage;
}
