import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Icon,
  ScreenPreview,
} from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import ThemeContainer from "../../components/Admin/Theme/ThemeContainer";
import StyledTag from "../../components/Tag";
import { PrimaryButton } from "../../helpers/whiteLabel";
import { ThemeConfig } from "../../pages/Admin/Themes";
import { FileFragment, Maybe, ThemeListItemFragment } from "../../types.g";
import ThemePreview from "../Admin/Theme/ThemePreview";
import { ApolloProps, withData } from "./apollo";
import { Styled } from "./style";
import ThemePreviewSmall from "./ThemePreviewSmall";

export interface ChannelThemeProps {
  defaultSelectedThemeId: string | null;
  defaultThemeId: string | null;
  onThemeSelectedCallback: (selectedThemeId: string | null) => void;
}

export interface ChannelThemeState {
  selectedThemeId: string | null;
  allThemes: ThemeListItemFragment[];
}

export interface ThemeOption {
  name: string;
  id: string;
  config: ThemeConfig;
  fileByLogoFileId?: Maybe<FileFragment>;
  bodyFontId: string;
  titleFontId: string;
}

class ChannelThemePicker extends React.Component<
  ChannelThemeProps & ApolloProps,
  ChannelThemeState
> {
  public static contextType = AppContext;
  public context: AppContextType;

  constructor(props: ChannelThemeProps & ApolloProps) {
    super(props);
    this.state = {
      selectedThemeId: props.defaultSelectedThemeId,
      allThemes: this.getAllThemes(this.props),
    };
  }

  public getAllThemes = (props: ApolloProps) => {
    return props.allThemes?.nodes ?? [];
  };

  public componentDidUpdate(prevProp: ApolloProps) {
    if (prevProp.allThemes !== this.props.allThemes) {
      this.setState({ allThemes: this.getAllThemes(this.props) });
    }
  }

  public getSelectedTheme = (
    selectedThemeId: string | null,
  ): ThemeOption | null => {
    const allThemes = this.getAllThemes(this.props);
    const themeOptions: ThemeOption[] = this.mapThemes(allThemes!);
    return (
      themeOptions.find((theme: ThemeOption) => theme.id === selectedThemeId) ??
      null
    );
  };

  public mapThemes = (themes: ThemeListItemFragment[]): ThemeOption[] => {
    return themes.map((theme: ThemeListItemFragment): ThemeOption => {
      return {
        config: theme.config as ThemeConfig,
        id: theme.id,
        name: theme.name,
        fileByLogoFileId: theme.fileByLogoFileId,
        bodyFontId: theme.bodyFontId,
        titleFontId: theme.titleFontId,
      };
    });
  };

  public renderThemeOption = (themeOption: ThemeOption) => {
    return (
      <>
        <div className="theme-option-left">
          <ThemePreviewSmall {...themeOption.config} />
          <div className="theme-option-text">
            <h3 className="h3 h3-text">{themeOption.name}</h3>
            {themeOption.id === this.props.defaultThemeId && (
              <div className="theme-option-default">
                <StyledTag color="#09CD77">
                  <span data-testid="default-tag">
                    <FormattedMessage
                      id="admin.themes.default_caps"
                      defaultMessage="DEFAULT"
                    />
                  </span>
                </StyledTag>
              </div>
            )}
          </div>
        </div>
        <div className="theme-options">
          <Dropdown
            data-testid="theme-options"
            icon={<Icon name="dots" />}
            direction="right"
            checkEmpty
            className="theme-dropdown"
          >
            <DropdownMenu>
              <DropdownItem
                data-testid="theme-edit-options"
                onClick={() =>
                  this.onShowEditThemeConfigure(themeOption, themeOption.name)
                }
              >
                <Icon name="edit" />{" "}
                <FormattedMessage
                  id="admin.themes.edit_theme"
                  defaultMessage="Edit Theme"
                />
              </DropdownItem>
              <span />
            </DropdownMenu>
          </Dropdown>
        </div>
      </>
    );
  };

  public onShowNewThemeConfigure = async () => {
    await this.context.modal.openFullscreenModal(
      <ThemeContainer
        theme={""}
        title={
          <FormattedMessage
            id="admin.themes.new_theme"
            defaultMessage="New Theme"
          />
        }
      />,
      {
        opts: {
          className: "app-config-modal",
        },
      },
    );
  };

  public onShowEditThemeConfigure = async (theme, title: string) => {
    await this.context.modal.openFullscreenModal(
      <ThemeContainer theme={theme} title={title} />,
      {
        opts: {
          className: "app-config-modal",
        },
      },
    );
  };

  public render(): JSX.Element {
    const { allThemes, selectedThemeId } = this.state;
    const themeOptions = this.mapThemes(allThemes!);
    const selectedTheme = this.getSelectedTheme(selectedThemeId);
    return (
      <Styled>
        <div className="content-container">
          <div className="theme-option-pane">
            <div className="themes-container">
              {themeOptions.map((themeOption) => (
                <div
                  key={themeOption.id}
                  onClick={() => {
                    this.setState({ selectedThemeId: themeOption.id });
                  }}
                  className={`theme-container ${
                    selectedThemeId === themeOption.id
                      ? "theme-container-selected"
                      : ""
                  } `}
                >
                  {this.renderThemeOption(themeOption)}
                </div>
              ))}
            </div>
          </div>
          <div className="theme-preview-pane">
            <div className="theme-preview-wrapper">
              {selectedTheme && (
                <>
                  <ScreenPreview height={1080} width={1920}>
                    <ThemePreview
                      primaryColor={selectedTheme.config.primaryColor[500]}
                      secondaryColor={selectedTheme.config.secondaryColor[500]}
                      titleColor={selectedTheme.config.textOnPrimary[500]}
                      bodyColor={selectedTheme.config.textOnSecondary[500]}
                      headingFont={selectedTheme.config?.headingFont}
                      bodyFont={selectedTheme.config?.bodyFont}
                      image={selectedTheme.fileByLogoFileId}
                    />
                  </ScreenPreview>
                  <div className="theme-preview-text">
                    <h3 className="h3 h3-text">{selectedTheme.name}</h3>
                    {selectedTheme.id === this.props.defaultThemeId && (
                      <StyledTag color="#09CD77">
                        <span data-testid="default-tag">
                          <FormattedMessage
                            id="admin.themes.default_caps"
                            defaultMessage="DEFAULT"
                          />
                        </span>
                      </StyledTag>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="md-footer">
          {this.context.currentPermissions.validateOrg("theme", "create") && (
            <Button onClick={this.onShowNewThemeConfigure}>
              <FormattedMessage
                id="admin.themes.create_new_theme"
                defaultMessage="Create new Theme"
              />
            </Button>
          )}

          <PrimaryButton
            onClick={() => {
              this.props.onThemeSelectedCallback(
                this.state.selectedThemeId ?? null,
              );
              this.context.modal.closeModals();
            }}
          >
            <FormattedMessage id="common.text.done" defaultMessage="Done" />
          </PrimaryButton>
        </div>
      </Styled>
    );
  }
}

export default withData(
  ChannelThemePicker,
) as React.ComponentClass<ChannelThemeProps>;
