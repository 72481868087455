import { isAfter } from "date-fns";
import * as React from "react";
import DateTime from "../DateTime";

import { FormattedMessage } from "react-intl";
import { getDisplayName } from "../../helpers/userHelper";
import { Styled } from "./styles";
import { useUserById } from "../../hooks/useUserById";

export interface UpdatedByUserProp {
  createdAt: string;
  createdBy: string;
  usePublished?: boolean;
  useUpdated?: boolean;
  publishedAt?: string;
  publishedBy?: string;
  updatedAt?: string;
  updatedBy?: string;
}

function UpdatedByUser(props: UpdatedByUserProp) {
  const renderUpdateStatusMessage = (): JSX.Element => {
    const {
      createdAt,
      publishedAt,
      publishedBy,
      updatedAt,
      usePublished,
      updatedBy,
      createdBy,
    } = props;
    const isUpdated =
      publishedAt &&
      updatedAt &&
      isAfter(new Date(updatedAt), new Date(publishedAt));
    const isPublished = usePublished && !isUpdated;

    const userId = isUpdated
      ? updatedBy
      : isPublished
        ? publishedBy
        : createdBy;

    const { user } = useUserById({
      id: userId,
    });

    const getStatusMessage = () => {
      const name = getDisplayName(user);
      if (isUpdated) {
        return {
          dateTime: updatedAt ?? "",
          formattedId: "common.text.updated_on_date_name",
          name,
          statusMessage: "Updated on {date} by {name}",
        };
      } else if (isPublished) {
        return {
          dateTime: publishedAt ?? "",
          formattedId: "common.text.published_on",
          name,
          statusMessage: "Published on {date} by {name}",
        };
      }
      return {
        dateTime: createdAt ?? "",
        formattedId: "common.text.created_on_date_name",
        name,
        statusMessage: "Created on {date} by {name}",
      };
    };

    const { name, formattedId, statusMessage, dateTime } = getStatusMessage();

    return (
      <FormattedMessage
        id={formattedId}
        defaultMessage={statusMessage}
        values={{
          date: <DateTime value={dateTime} />,
          name: <strong>{name}</strong>,
        }}
      />
    );
  };

  return <Styled className="updated-by">{renderUpdateStatusMessage()}</Styled>;
}

export default UpdatedByUser as React.ComponentType<UpdatedByUserProp>;
