/**
 * Variation of material UI
 * https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/colorManipulator.js
 */

export interface ThemeColor {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

const intToHex = (int: number): string => {
  const hex = int.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

/**
 * Converts a color from CSS rgb format to CSS hex format.
 */
const rgbValuesToHex = (rgbValues: number[]): string => {
  rgbValues = rgbValues.map((value, i) => (i < 3 ? Math.floor(value) : value));

  return `#${rgbValues.map((n) => intToHex(n)).join("")}`;
};

const convertHexToRgbValues = (hexValue: string): number[] => {
  // Remove hash
  hexValue = hexValue.substr(1);

  // Split into 3
  const re = new RegExp(`.{1,${hexValue.length / 3}}`, "g");
  let colors = hexValue.match(re);

  if (colors && colors[0].length === 1) {
    colors = colors.map((n) => n + n);
  }

  // Set to white if no colors
  if (!colors) {
    colors = ["FF", "FF", "FF"];
  }
  const rgbValues: number[] = colors.map((n: string): number =>
    parseInt(n, 16),
  );

  return rgbValues;
};

const darken = (hexValue: string, coefficient: number): string => {
  const rgbColorValues = convertHexToRgbValues(hexValue);

  for (let i = 0; i < 3; i += 1) {
    rgbColorValues[i] *= 1 - coefficient;
  }
  const darkerHex = rgbValuesToHex(rgbColorValues);
  return darkerHex;
};

const lighten = (hexValue: string, coefficient: number): string => {
  const rgbColorValues = convertHexToRgbValues(hexValue);

  for (let i = 0; i < 3; i += 1) {
    rgbColorValues[i] += (255 - rgbColorValues[i]) * coefficient;
  }
  const lighterHex = rgbValuesToHex(rgbColorValues);
  return lighterHex;
};

const getContrastRatio = (foreground: string, background: string): number => {
  const lumA = getLuminance(foreground);
  const lumB = getLuminance(background);

  return (Math.max(lumA, lumB) + 0.05) / (Math.min(lumA, lumB) + 0.05);
};

/**
 * The relative brightness of any point in a color space,
 * normalized to 0 for darkest black and 1 for lightest white.
 *
 * Formula: https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
 */
const getLuminance = (hexValue: string): number => {
  let rgbColorValues = convertHexToRgbValues(hexValue);

  rgbColorValues = rgbColorValues.map((val) => {
    val /= 255; // normalized
    return val <= 0.03928 ? val / 12.92 : ((val + 0.055) / 1.055) ** 2.4;
  });

  // Truncate at 3 digits
  return Number(
    (
      0.2126 * rgbColorValues[0] +
      0.7152 * rgbColorValues[1] +
      0.0722 * rgbColorValues[2]
    ).toFixed(3),
  );
};

export const getContrastText = (color: string): string => {
  const white = "#FFFFFF";
  const black = "#000000";
  const contrastThreshold = 3;

  return getContrastRatio(color, white) >= contrastThreshold ? white : black;
};

export const generateColorVariants = (color: string): ThemeColor => {
  // return color variants
  const colorVariants = {
    100: lighten(color, 0.8),
    200: lighten(color, 0.6),
    300: lighten(color, 0.4),
    400: lighten(color, 0.2),
    500: color,
    600: darken(color, 0.2),
    700: darken(color, 0.4),
    800: darken(color, 0.6),
    900: darken(color, 0.8),
  };
  return colorVariants;
};

export const generateTextColor = (color: ThemeColor): ThemeColor => {
  // return color contrasts
  const textColors = {
    100: getContrastText(color[100]),
    200: getContrastText(color[200]),
    300: getContrastText(color[300]),
    400: getContrastText(color[400]),
    500: getContrastText(color[500]),
    600: getContrastText(color[600]),
    700: getContrastText(color[700]),
    800: getContrastText(color[800]),
    900: getContrastText(color[900]),
  };
  return textColors;
};
