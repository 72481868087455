import { Icon, Checkbox } from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import { useAppContext } from "src/hooks/useAppContext";
import { NotificationRule } from "src/types.g";
import { UUID } from "@screencloud/uuid";
import { useMemo } from "react";
import useScreenNotification from "src/hooks/ScreenNotification/useScreenNotification";

interface Props {
  notificationRule: NotificationRule;
  onSubscribe: (userId: UUID[]) => void;
  setIsRuleActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PersonalNotification(props: Props) {
  const context = useAppContext();
  const { deleteSubscription } = useScreenNotification(
    props?.notificationRule?.screenId,
  );

  const subscription = useMemo(
    () =>
      props.notificationRule?.notificationSubscriptionsByNotificationRuleId.nodes.find(
        (sub) => sub.userByUserIdAndOrgId?.id === context.currentUser?.id,
      ),
    [props.notificationRule],
  );

  const handleSubscibeNotification = async (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
    data,
  ) => {
    if (data.checked) {
      props.onSubscribe([context?.currentUser?.id]);
    } else {
      await deleteSubscription(subscription?.id);
      props.setIsRuleActive(false);
    }
  };
  return (
    <div
      className="personal-notifications"
      data-testid="personal-notifications"
    >
      <div className="sub-heading">
        <div className="badge" data-testid="badge">
          <Icon name="bell-on" />
        </div>
        <div className="text" data-testid="info">
          <div className="title">
            <FormattedMessage
              id="ui_component.screen_notification.modal.personal_notification_title"
              defaultMessage="Personal Notification"
            />
          </div>
          <div className="sub-title">
            <FormattedMessage
              id="ui_component.screen_notification.modal.personal_notification_subtitle"
              defaultMessage="You will receive email notifications related to changes in this screen's status"
            />
          </div>
        </div>
        <div className="toggle">
          <Checkbox
            data-testid="toggle-personal"
            toggle
            name={""}
            onChange={handleSubscibeNotification}
            checked={Boolean(subscription?.active)}
          />
        </div>
      </div>
      <div className="personal-email">
        <Icon name="email" /> <span>{context.currentUser?.email}</span>
      </div>
    </div>
  );
}
