import { UUID } from "@screencloud/uuid";
import { isShowInSearchResult } from "../../../helpers/folderHelper";
import { useAppContext } from "../../../hooks/useAppContext";
import {
  Exact,
  File,
  FilesOrderBy,
  Folder,
  FoldersOrderBy,
  InputMaybe,
  Maybe,
  SearchMediaQuery,
  useSearchMediaQuery,
} from "../../../types.g";
import {
  SortOption,
  SortingEntityType,
  getSortOption,
} from "src/helpers/sortingHelper";
import { ApolloQueryResult } from "@apollo/client";
interface SearchMediaVariables {
  query?: string;
  spaceId?: UUID;
}

interface UseSearchMediaResult {
  mediaFilesSearchResults: File[];
  mediaFoldersSearchResults: Folder[];
  isSearchMediaLoading: boolean;
  refetchSearchMedia: (
    variables?:
      | Partial<
          Exact<{
            query: Maybe<string>;
            spaceId: any;
            fileOrderBy: InputMaybe<FilesOrderBy | FilesOrderBy[]>;
            folderOrderBy: InputMaybe<FoldersOrderBy | FoldersOrderBy[]>;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<SearchMediaQuery>>;
  allItemsTotalCount: number;
}

export function useSearchMedia({
  query,
  spaceId,
}: SearchMediaVariables): UseSearchMediaResult {
  const context = useAppContext();
  const mediaOrderBy = getSortOption({
    id: context.currentSpace?.rootFolderId,
    entityType: SortingEntityType.Media,
    isSearch: true,
  }) ?? [SortOption.RECENT, SortOption.ID];
  const {
    data: searchMediaQuery,
    loading,
    refetch: refetchSearchMedia,
  } = useSearchMediaQuery({
    fetchPolicy: "cache-and-network",
    skip: !query,
    variables: {
      query: query ?? "",
      spaceId: spaceId ?? context.currentSpace?.id,
      fileOrderBy: mediaOrderBy as FilesOrderBy[],
      folderOrderBy: mediaOrderBy as FoldersOrderBy[],
    },
  });

  const filesTotalCount = searchMediaQuery?.searchFile?.totalCount ?? 0;
  const foldersTotalCount = searchMediaQuery?.searchFolder?.totalCount ?? 0;
  const allItemsTotalCount = filesTotalCount + foldersTotalCount;

  return {
    mediaFilesSearchResults: (searchMediaQuery?.searchFile?.nodes ??
      []) as File[],
    mediaFoldersSearchResults: (
      searchMediaQuery?.searchFolder?.nodes ?? []
    ).filter(isShowInSearchResult) as Folder[],
    isSearchMediaLoading: loading,
    refetchSearchMedia,
    allItemsTotalCount,
  };
}
