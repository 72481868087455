import { UNKNOWN_MODEL } from "../constants/constants";
import { UseAllScreens } from "../hooks/useAllScreens";
import {
  Screen,
  ScreenDetailQuery,
  ScreensByScreenGroupIdAndOrgIdFragment,
} from "../types.g";

/**
 * @description When deviceModel is not available, we will fallback to the model from deviceInfo.
 *
 * @link https://github.com/screencloud/signage-next-frontend/issues/12376
 */
export const getDeviceModel = (
  screen:
    | Partial<Screen>
    | Partial<ScreenDetailQuery["screenById"]>
    | Partial<ScreensByScreenGroupIdAndOrgIdFragment>
    | Partial<UseAllScreens["allScreens"][number]>
    | null
    | undefined,
): string => {
  const model = screen?.deviceModel || screen?.deviceInfo?.device?.model;

  if (!model) return UNKNOWN_MODEL;

  return model;
};
