import { useEffect } from "react";
import { Styled } from "./styles";
import { appConfig } from "src/appConfig";
import { Button, ModalSize } from "@screencloud/screencloud-ui-components";
import { AppContextType } from "src/AppContextProvider/type";
import { getOrgRegionFromUrl } from "src/helpers/urlHelper";
import { DeviceInformationModal } from "../OnboardingModal/DeviceInformationModal";

declare global {
  interface Window {
    Chargebee: {
      // https://www.chargebee.com/checkout-portal-docs/api.html#init
      init: (options: { site: string; isItemsModel: boolean }) => void;
      // https://www.chargebee.com/checkout-portal-docs/dropIn.html#registeragain
      registerAgain: () => void;
    };
  }
}

export const openScosStore = async ({
  context,
  isFromDeviceInformationModal,
  onCloseModal,
}: {
  context: AppContextType;
  onCloseModal?: () => void;
  isFromDeviceInformationModal?: boolean;
}) => {
  const { isUs } = getOrgRegionFromUrl();
  if (isUs) {
    // close the screen pairing modal if it's open
    context.modal.closeNavigationControlModal();
    context.modal.openModal(<ScosStoreModal />, "", {
      opts: {
        size: ModalSize.FULLHEIGHT,
        className: `sc-store`,
        disableTitle: true,
      },
      onClose: () => {
        if (isFromDeviceInformationModal) {
          context.modal.openNavigationControlModal(
            <DeviceInformationModal
              isDuringOnboarding={false}
              onCloseDeviceInformationModal={() => {
                onCloseModal && onCloseModal();
              }}
            />,
            null,
            {
              opts: {
                closeOnDimmerClick: true,
                disableTitle: true,
                overflow: false,
                size: ModalSize.LARGE,
                disableCloseButton: true,
              },
            },
          );
        }
      },
    });
  } else {
    window.open("https://store.screencloud.com", "_blank");
  }
};

interface ScosStoreModalProps {
  customCloseButton?: JSX.Element;
}
const ScosStoreModal = (props: ScosStoreModalProps) => {
  const ADDON_US_REGION = "screencloud-os---usd---2024"; // 2024 pricing change to $150

  const { customCloseButton } = props;
  useEffect(() => {
    if (window.Chargebee) {
      try {
        // On first load initialize Chargebee with the correct Chargebee site
        // Two buttons on screen means the second will cause a warn in console.
        // We could check if Chargebee.getInstance returns Chargebee but an error is thrown.
        // We could use a catch but cannot depend on this error handling to tell if there is an instance set up or not.
        window.Chargebee.init({
          site: appConfig.chargebeeSite,
          isItemsModel: true,
        });
        // This function is used to rebind all the events attached. Use this function if a new element inserted dynamically after page load.
        // Needed to register the site change to bind button to that site
        window.Chargebee.registerAgain();
      } catch (error) {
        console.log("Unable to register Chargebee", error);
      }
    }
  });

  interface IncludeItemProps {
    figure: string;
    label: string;
  }

  interface SpecsItemProps {
    label: string;
    value: React.ReactNode;
  }

  interface FeatureProps {
    icon: React.ReactNode;
    heading: string;
    description: string;
  }

  const IncludeItem = (props: IncludeItemProps) => {
    return (
      <div className="card">
        <figure className={props.figure} />
        <p>
          <span>{props.label}</span>
        </p>
      </div>
    );
  };

  const SpecsItem = (props: SpecsItemProps) => {
    return (
      <div className="row">
        <span className="label">{props.label}</span>
        <div className="value">{props.value}</div>
      </div>
    );
  };

  const FeatureItem = (props: FeatureProps) => {
    return (
      <div className="column">
        {props.icon}
        <h4>
          <span>{props.heading}</span>
        </h4>
        <p>
          <span>{props.description}</span>
        </p>
      </div>
    );
  };

  const featureProps: FeatureProps[] = [
    {
      heading: "Great performance",
      description:
        "The Station P1 Pro device powered by ScreenCloud OS comes with a six-core CPU, a quad-core GPU and 4GB of RAM, capable of running even demanding content. And there's also 32GB of storage so your cached content can continue to play even when your internet connection becomes unstable.",
      icon: (
        <svg height="31" width="53" viewBox="0 0 53 31">
          <g fill="none" fillRule="evenodd">
            <path
              d="m53 30.07c0-14.67-11.864-26.563-26.5-26.563s-26.5 11.893-26.5 26.563"
              fill="#c2cbf9"
            ></path>
            <path
              d="m43.933 0 2.406 2.577-14.433 15.465-2.406-2.578z"
              fill="#e1e5fc"
            ></path>
          </g>
        </svg>
      ),
    },
    {
      heading: "Fast multi-screen deployment",
      description:
        "Remove all complexity with our plug-in-and-play approach. ScreenCloud OS runs on the high-performing Station P1 Pro device, pre-installed with ScreenCloud's software for streamlined deployment in minutes.",
      icon: (
        <svg height="38" width="52" viewBox="0 0 52 38">
          <g fill="none" fillRule="evenodd">
            <path d="m0 0h52v33.329h-52z" fill="#ffea99"></path>
            <path
              d="m25.44 25.181 12.836 12.819h-25.67z"
              fill="#fefae5"
              fillRule="nonzero"
              transform="matrix(-1 0 0 1 50.881 0)"
            ></path>
          </g>
        </svg>
      ),
    },
    {
      heading: "Complete flexibility",
      description:
        "With free, built-in remote device management features, ScreenCloud OS can help you avoid in person visits for your IT teams with the ability to do things like remotely reboot devices, update network settings or credentials and more.",
      icon: (
        <svg height="45" width="36" viewBox="0 0 36 45">
          <g fill="none" fillRule="evenodd">
            <path
              d="m14.108 45c12.09 0 21.892-10.074 21.892-22.5s-9.801-22.5-21.892-22.5"
              fill="#c2cbf9"
            ></path>
            <path
              d="m0 40.55c10.21 0 18.486-7.97 18.486-17.803 0-9.832-8.276-17.802-18.486-17.802"
              fill="#e1e5fc"
            ></path>
          </g>
        </svg>
      ),
    },
  ];

  const includeItemProps: IncludeItemProps[] = [
    {
      figure: "inbox1",
      label: "Station P1 Pro Device",
    },
    {
      figure: "inbox2",
      label: "Wi-Fi Antenna",
    },
    {
      figure: "inbox3",
      label: "Power Supply",
    },
    {
      figure: "inbox4",
      label: "HDMI Cable",
    },
    {
      figure: "inbox5",
      label: "L-Shaped Allen Wrench",
    },
  ];

  const specsItemProps: SpecsItemProps[] = [
    {
      label: "SOC",
      value: <span>Rockchip RK3399</span>,
    },
    {
      label: "CPU",
      value: (
        <span>
          Six-core 64-bit processor (ARM dual-core Cortex-A72 + quad-core
          Cortex-A53) Frequency up to 1.8GHz
        </span>
      ),
    },
    {
      label: "GPU",
      value: <span>Quad-core GPU (Mali-T860 MP4)</span>,
    },
    {
      label: "RAM",
      value: <span>4GB LPDDR4 RAM</span>,
    },
    {
      label: "Storage",
      value: <span>32GB eMMC storage (onboard)</span>,
    },
    {
      label: "Display output",
      value: (
        <ul>
          <li>
            <span>1 x HDMI 2.0a</span>
          </li>
          <li>
            <span>Supports up to 4K output</span>
          </li>
        </ul>
      ),
    },
    {
      label: "Wi-Fi",
      value: (
        <ul>
          <li>
            <span>2.4GHz / 5GHz dual band WiFi</span>
          </li>
          <li>
            <span>supports 802.11 a/b/g/n/ac protocol</span>
          </li>
          <li>
            <span>3db WiFi antenna</span>
          </li>
        </ul>
      ),
    },
    {
      label: "Security Support - Wi-Fi",
      value: (
        <>
          <ul>
            <li>
              <span>No Authentication</span>
            </li>
            <li>
              <span>Basic Authentication</span>
              <ul>
                <li>
                  <span>WPA2-PSK</span>
                  <ol>
                    <li>
                      <span>Password</span>
                    </li>
                  </ol>
                </li>
              </ul>
            </li>
            <li>
              <span>Enterprise Authentication</span>
              <ul>
                <li>
                  <span>WPA2-Enterprise (TLS)</span>
                  <ol>
                    <li>
                      <span>Identity</span>
                    </li>
                    <li>
                      <span>Password</span>
                    </li>
                  </ol>
                </li>
                <li>
                  <span>WPA2-Enterprise (Protected EAP - PEAP)</span>
                  <ol>
                    <li>
                      <span>Identity</span>
                    </li>
                    <li>
                      <span>Password</span>
                    </li>
                  </ol>
                </li>
              </ul>
            </li>
          </ul>
          <span>
            (Hidden networks are also supported for all of the above network
            types)
          </span>
        </>
      ),
    },
    {
      label: "Ethernet",
      value: <span>1 x 10/100/1000Mbps Gigabit Ethernet(RJ45 interface)</span>,
    },
    {
      label: "Security Support - Ethernet",
      value: <span>No Authentication</span>,
    },
    {
      label: "Other Advanced Configurations Support - WiFi & Ethernet",
      value: (
        <>
          <ul>
            <li>
              <span>Proxy</span>
              <ol>
                <li>
                  <span>Host</span>
                </li>
                <li>
                  <span>Port</span>
                </li>
              </ol>
            </li>
            <li>
              <span>NTP (Custom)</span>
              <ol>
                <li>
                  <span>NTP (multiple entries supported)</span>
                </li>
              </ol>
            </li>
            <li>
              <span>Certificates</span>
              <ol>
                <li>
                  <span>Import</span>
                </li>
              </ol>
            </li>
          </ul>
        </>
      ),
    },
    {
      label: "Currently Not Supported - Ethernet",
      value: <span>IEEE 802.1X</span>,
    },
    {
      label: "I/O",
      value: (
        <ul>
          <li>
            <span>1 x USB 2.0</span>
          </li>
          <li>
            <span>1 x USB 3.0</span>
          </li>
          <li>
            <span>1 x USB-C (USB 3.0)</span>
          </li>
          <li>
            <span>1 x Audio (3/5mm)</span>
          </li>
        </ul>
      ),
    },
    {
      label: "Power input",
      value: (
        <ul>
          <li>
            <span>DC 12V-2A (5.5*2.1mm)</span>
          </li>
          <li>
            <span>Supports 9-12V wide voltage input</span>
          </li>
        </ul>
      ),
    },
    {
      label: "Power consumption",
      value: (
        <ul>
          <li>
            <span>Idle: 0.12W</span>
          </li>
          <li>
            <span>Normal: 3.6W</span>
          </li>
          <li>
            <span>Max: 9.6W</span>
          </li>
        </ul>
      ),
    },
    {
      label: "Size and Weight",
      value: (
        <ul>
          <li>
            <span>Height: 1.22 inches (3.1 cm)</span>
          </li>
          <li>
            <span>Width: 3.11 inches (7.9 cm)</span>
          </li>
          <li>
            <span>Depth: 4.88 inches (12.4 cm)</span>
          </li>
          <li>
            <span>Weight: 0.8 pounds (365g)</span>
          </li>
        </ul>
      ),
    },
    {
      label: "Operating environment",
      value: (
        <ul>
          <li>
            <span>Operating Temperature: -20°C - 60°C</span>
          </li>
          <li>
            <span>Storage Temperature: -20°C - 70°C</span>
          </li>
          <li>
            <span>rage Humidity: 10% - 80%</span>
          </li>
        </ul>
      ),
    },
  ];

  return (
    <Styled>
      {customCloseButton}
      <div className="section hero">
        <div className="column">
          <h1>
            <span>The Station P1 Pro powered by ScreenCloud OS</span>
          </h1>
          <p>
            <span>
              ScreenCloud OS is a purpose-built operating system designed to
              deliver amazing performance and enterprise-grade features at the
              most affordable price. And this device comes with all the
              specifications and features needed to deliver on that promise,
              making it perfect for easy set up and scaling of your digital
              signage.
            </span>
          </p>
          <Button
            // Chargebee set up for checkout addon
            data-cb-type="checkout"
            data-cb-item-0={ADDON_US_REGION}
            data-cb-item-0-quantity="1"
            primary
          >
            <span>Buy Now</span>
          </Button>
          <p>
            <span>
              Need more than 25 devices? Contact Sales for assistance.
            </span>
          </p>
        </div>
        <figure className="column device" />
      </div>
      <div className="section feature">{featureProps.map(FeatureItem)}</div>

      <div className="section banner">
        <div className="column">
          <figure className="device-1"></figure>
          <div className="tagline">
            <div className="heading">
              <h2>
                <span>Easier. Faster. More affordable.</span>
              </h2>
              <p>
                <span>
                  A new digital signage operating system made for ScreenCloud,
                  by ScreenCloud.
                </span>
              </p>
            </div>
            <Button
              primary
              onClick={() =>
                window.open("https://screencloud.com/os", "_blank")
              }
            >
              <span>Find Out More</span>
            </Button>
          </div>
        </div>
      </div>

      <div className="section information">
        <h2>
          <span>Product Information</span>
        </h2>
        <div className="column">
          <h4>
            <span>General</span>
          </h4>
          <p>
            <span>
              ScreenCloud OS lets you focus on what really matters. Built by
              digital signage experts, ScreenCloud OS is the industry's newest
              purpose-built digital signage operating system and device. Its
              all-in-one solution makes it one of the most powerful and
              cost-effective options on the market.
            </span>
          </p>
        </div>
        <div className="column">
          <h4>
            <span>What's included</span>
          </h4>

          <div className="box">{includeItemProps.map(IncludeItem)}</div>
        </div>

        <div className="column content-indent">
          <h4>
            <span>Specifications / Supported features</span>
          </h4>

          <div className="table">{specsItemProps.map(SpecsItem)}</div>
        </div>
        <div className="column content-indent">
          <h4>
            <span>Requirements</span>
          </h4>
          <p>
            <span>
              Valid ScreenCloud subscription or trial
              <br />
              Internet connection
            </span>
          </p>
        </div>
        <div className="column content-indent">
          <h4>
            <span>Warranty</span>
          </h4>
          <p>
            <span>1 year</span>
          </p>
        </div>
      </div>

      <div className="section banner">
        <div className="column">
          <figure className="device-2"></figure>
          <div className="tagline">
            <div className="heading">
              <h2>
                <span>New, updated hardware.</span>
              </h2>
              <p>
                <span>
                  The Station P1 Pro replaces the previous Station P1, with a
                  refined design and improved WiFi capabilities.
                </span>
              </p>
            </div>
            <Button
              primary
              onClick={() =>
                window.open(
                  "https://help.screencloud.com/s/article/Introducing-the-Station-P1-Pro-The-Replacement-for-the-Station-P1",
                  "_blank",
                )
              }
            >
              <span>Find Out More</span>
            </Button>
          </div>
        </div>
      </div>

      <div className="section footer">
        <figure className="scos-logo"></figure>
      </div>
    </Styled>
  );
};

export default ScosStoreModal;
