import { ApolloCache } from "@apollo/client";
import { genUuid, UUID } from "@screencloud/uuid";
import { cloneDeep, get, keyBy, orderBy } from "lodash";
import { isPast, isValid, format, max } from "date-fns";
import { AppContextState } from "../AppContextProvider";
import { OpenMediaPickerResult } from "../AppContextProvider/modals";
import {
  ChannelZone,
  DEFAULT_GLOBAL_DURATIONS,
  Orientation,
  RefType,
  ScheduleRules,
} from "../constants/constants";
import {
  AppInstance,
  Channel,
  ChannelListItemFragment,
  File,
  Layout,
  Link,
  Org,
  Playlist,
  Scalars,
  Site,
  UpdateChannelMutationVariables,
  ChannelDetailFragment,
  ChannelByIdFragment,
  Maybe,
  ChannelDetailForScreenFragment,
  UpdateChannelNameMutation,
  DuplicateChannelMutation,
  CreateChannelMutation,
  ChannelListItemFragmentDoc,
} from "../types.g";
import { appInstanceIconUrl, getAppInstanceDuration } from "./appHelper";
import * as thisModule from "./channelHelper";
import { isTimeOver24h, normalizedDate } from "./datetimeHelper";
import {
  generateImgixThumbnail,
  generateImgixURL,
  getFileOutputImageSet,
  isDocument,
  isVideo,
} from "./mediaHelper";
import {
  canBeDeleted,
  isOwner,
  isShareableOwner,
  isShared,
} from "./shareableHelper";
import { isNotRelatedCacheToModify } from "./generalHelper";

export type ZoneOption = { text: React.ReactNode; value: string; key: string };

export function newItemsFromPickerResult(
  result: OpenMediaPickerResult,
  content: Scalars["JSON"],
  secureMediaPolicy: string | undefined,
): {
  newItems: (File | Playlist | Link | Site | AppInstance)[];
  apps: AppInstance[];
  files: File[];
  playlists: Playlist[];
  links: Link[];
  sites: Site[];
} {
  const createdItems: {
    apps: AppInstance[];
    newItems: (File | Playlist | Link | Site | AppInstance)[];
    files: File[];
    playlists: Playlist[];
    links: Link[];
    sites: Site[];
  } = {
    apps: [],
    files: [],
    links: [],
    sites: [],
    newItems: [],
    playlists: [],
  };

  let newItems: (File | Playlist | Link | Site | AppInstance)[] = [];

  const listRefType =
    result.mediaType === RefType.FILE
      ? (result.data[0] as File).mimetype === "application/pdf"
        ? "document"
        : "image"
      : result.mediaType;

  const duration =
    result.mediaType === RefType.PLAYLIST
      ? 0
      : (content.props?.default_durations?.[listRefType] ??
        DEFAULT_GLOBAL_DURATIONS[listRefType]);

  switch (result.mediaType) {
    case RefType.FILE:
      const files: File[] = result.data as File[];
      createdItems.files = files;
      newItems = files.map((file: File) => {
        if (isVideo(file) && file.fileOutputsByFileId) {
          const videoFileOutput = file.fileOutputsByFileId.nodes.find(
            (fileOutput) => isVideo(fileOutput),
          );
          return {
            data: file,
            duration: videoFileOutput
              ? videoFileOutput.metadata.duration
              : duration,
            id: file.id,
            list_id: genUuid(),
            schedule: {},
            thumbnail: generateImgixThumbnail(file, secureMediaPolicy, true),
            title: file.name,
            type: RefType.FILE,
          };
        } else if (isDocument(file) && file.fileOutputsByFileId) {
          return {
            data: file,
            duration,
            id: file.id,
            imageSet: getFileOutputImageSet(file),
            list_id: genUuid(),
            schedule: {},
            thumbnail: generateImgixThumbnail(file, secureMediaPolicy, true),
            title: file.name,
            type: RefType.FILE,
          };
        } else {
          return {
            data: file,
            duration,
            id: file.id,
            list_id: genUuid(),
            schedule: {},
            thumbnail: generateImgixThumbnail(file, secureMediaPolicy, true),
            title: file.name,
            type: RefType.FILE,
          };
        }
      }) as any[];
      break;
    case RefType.PLAYLIST:
      const playlists: Playlist[] = result.data as Playlist[];
      createdItems.playlists = playlists;
      newItems = playlists.map((playlist: Playlist) => {
        return {
          data: playlist,
          duration,
          id: playlist.id,
          list_id: genUuid(),
          schedule: {},
          thumbnail: "",
          title: playlist.name,
          type: result.mediaType,
        };
      }) as any[];
      break;
    case RefType.LINK:
      const links: Link[] = result.data as Link[];
      createdItems.links = links;
      newItems = links.map((link: Link) => {
        return {
          data: link,
          duration,
          id: link.id,
          list_id: genUuid(),
          schedule: {},
          thumbnail: (link.fileByFileId && link.fileByFileId.source) || "",
          title: link.name,
          type: result.mediaType,
          url: link.url,
        };
      }) as any[];
      break;
    case RefType.SITE:
      const sites: Site[] = result.data as Site[];
      createdItems.sites = sites;
      newItems = sites.map((site: Site) => {
        return {
          data: site,
          duration,
          id: site.id,
          list_id: genUuid(),
          schedule: {},
          thumbnail: site.fileByThumbnailId
            ? generateImgixThumbnail(site.fileByThumbnailId, secureMediaPolicy)
            : "",
          title: site.name,
          type: result.mediaType,
        };
      }) as any[];
      break;
    case RefType.APP:
      const apps: AppInstance[] = result.data as AppInstance[];
      createdItems.apps = apps;
      newItems = apps.map((app: AppInstance) => {
        const iconUrl = appInstanceIconUrl(app as unknown as AppInstance);
        return {
          data: app,
          duration: getAppInstanceDuration(app) ?? duration,
          id: app.id,
          list_id: genUuid(),
          schedule: {},
          thumbnail: iconUrl,
          title: app.name,
          type: result.mediaType,
        };
      }) as any[];
      break;
  }
  createdItems.newItems = newItems;
  return createdItems;
}

export function getZoneContentBySelectedZone(
  selectedZoneOption: string,
  channelById: ChannelDetailFragment,
): ChannelZone {
  let zoneContent: ChannelZone = { list: [] };
  if (channelById && channelById.content) {
    const allZonesContent = { ...channelById.content.zones };
    zoneContent = allZonesContent[selectedZoneOption]
      ? allZonesContent[selectedZoneOption]
      : zoneContent;
  }
  return zoneContent;
}

export async function checkAndUpdateZonesContent(
  channel: Partial<Channel>,
): Promise<Scalars["JSON"]> {
  return new Promise((resolve, reject) => {
    if (channel.content) {
      const filesByChannelId = channel.filesByChannelId?.nodes;
      const playlistsByChannelId = channel.playlistsByChannelId?.nodes;
      const linksByChannelId = channel.linksByChannelId?.nodes;
      const sitesByChannelId = channel.sitesByChannelId?.nodes;
      const appsByChannelId = channel.appInstancesByChannelId?.nodes;
      const newContent = cloneDeep(channel.content);
      const contentZones = newContent.zones;
      const keys = Object.keys(contentZones);
      let isNeedToUpdateChannel = false;
      for (const key of keys) {
        const lists = contentZones[key];
        const listFiltered =
          lists.list?.filter((item, idx) => {
            if (!item?.content?._ref) {
              return false;
            }

            const fileByRefId = filesByChannelId?.find(
              (file) => file.id === item.content._ref!.id,
            );
            const playlistByRefId = playlistsByChannelId?.find(
              (playlist) => playlist.id === item.content._ref!.id,
            );
            const linkByRefId = linksByChannelId?.find(
              (link) => link.id === item.content._ref!.id,
            );
            const sitesByRefId = sitesByChannelId?.find(
              (site) => site.id === item.content._ref!.id,
            );
            const appByRefId = appsByChannelId?.find(
              (app) => app.id === item.content._ref!.id,
            );
            return (
              fileByRefId ||
              playlistByRefId ||
              linkByRefId ||
              appByRefId ||
              sitesByRefId
            );
          }) ?? [];
        if (lists.list?.length !== listFiltered.length) {
          isNeedToUpdateChannel = true;
        }
        lists.list = listFiltered;
      }
      if (isNeedToUpdateChannel) {
        resolve(newContent);
      } else {
        resolve(null);
      }
    }
    resolve(null);
  });
}

export function layoutDataById(
  layoutId: string,
  layoutsData: Partial<Layout>[],
): Layout | null {
  if (layoutsData.length > 0) {
    return layoutsData.find((x) => x.id === layoutId) as Layout;
  }
  return null;
}

export function channelOptimisticDataByInput(
  channelDraft: Partial<Channel>,
  channelInput: UpdateChannelMutationVariables,
  layouts: Layout[],
): Channel {
  // Channel content
  if (channelInput.input.content) {
    channelDraft.content = channelInput.input.content;
  }
  // Set layoutId
  if (channelInput.input.layoutId) {
    channelDraft.layoutId = channelInput.input.layoutId;
    channelDraft.layoutId = thisModule.layoutDataById(
      channelDraft.layoutId,
      layouts,
    );
  }

  // Hidden zone content
  if (
    channelInput.input.hiddenZone !== undefined &&
    channelInput.input.hiddenZone !== null
  ) {
    channelDraft.hiddenZone = channelInput.input.hiddenZone;
    let zones;
    let newContent;
    if (channelDraft.hiddenZone === true) {
      zones = { ...(channelDraft.content.zones || {}) };
      newContent = { ...channelDraft.content };
      zones.hidden = { list: [] };
    } else {
      zones = { ...(channelDraft.content.zones || {}) };
      newContent = { ...channelDraft.content };
      delete zones.hidden;
    }
    newContent.zones = zones;
    channelDraft.content = newContent;
  }

  return channelDraft as Channel;
}

export function getChannelCover(
  channel: ChannelListItemFragment,
  secureMediaPolicy: string | undefined,
): string {
  const cover = channel.coverData;
  const coverData =
    channel.coverImageId && channel.fileByCoverImageId
      ? generateImgixURL(
          channel.fileByCoverImageId,
          600,
          600,
          secureMediaPolicy,
        )
      : `linear-gradient(-45deg, ${cover?.color?.top} 0%, ${cover?.color?.bottom} 100%)`;
  return coverData;
}

export function getChannelName(
  { id, name }: Pick<Channel, "id" | "name">,
  {
    blankChannelId,
    brandChannelId,
  }: Pick<Org, "blankChannelId" | "brandChannelId">,
) {
  switch (id) {
    case blankChannelId:
      return "None";
    case brandChannelId:
      return "Splash";
    default:
      return name;
  }
}

export function countZone(channel: Channel): number {
  return get(channel, ["layoutByChannel", "config", "zones", "length"], 0);
}

export const CHANNEL_PLAYER_ZONE_PREFIX = "Channel_";

/**
 *
 * @param zoneId
 * Zone from player will have prefix as Channel_ in front of zone id such as Channel_Zone1
 */
export function convertToChannelPlayerZone(zoneId: string) {
  return `${CHANNEL_PLAYER_ZONE_PREFIX}${zoneId}`;
}

export function isHidden(zoneId: string) {
  return (
    zoneId === "hidden" ||
    zoneId === thisModule.convertToChannelPlayerZone("hidden")
  );
}

export function getZoneOptions(
  channel: Partial<Channel>,
  options: { isForPlayer?: boolean | null; showHidden?: boolean | null },
) {
  if (!channel) {
    return [];
  }

  const { isForPlayer, showHidden } = options;
  const zones = channel.layoutByChannel?.config.zones || [];

  const hiddenZone = {
    key: `hidden`,
    text: "Background Audio",
    value: isForPlayer
      ? thisModule.convertToChannelPlayerZone("hidden")
      : "hidden",
  };
  const zoneDropdownValue: ZoneOption[] = zones
    .filter(({ id }) => !isHidden(id))
    .map(({ name, id }, idx) => ({
      key: idx,
      text: name,
      value: isForPlayer ? thisModule.convertToChannelPlayerZone(id) : id,
    }));

  if (showHidden) {
    return [...zoneDropdownValue, hiddenZone];
  }

  return zoneDropdownValue;
}

export interface IChannelMap {
  fileMap: { [id: string]: File };
  playlistMap: { [id: string]: Playlist };
  linkMap: { [id: string]: Link };
  siteMap: { [id: string]: Site };
  appMap: { [id: string]: AppInstance };
}

export function getMapFromChannel(channel: Channel): IChannelMap {
  const files: File[] = get(channel, ["filesByChannelId", "nodes"], []);
  const playlists: Playlist[] = get(
    channel,
    ["playlistsByChannelId", "nodes"],
    [],
  );
  const links: Link[] = get(channel, ["linksByChannelId", "nodes"], []);
  const apps: AppInstance[] = get(
    channel,
    ["appInstancesByChannelId", "nodes"],
    [],
  );
  const sites: Site[] = get(channel, ["sitesByChannelId", "nodes"], []);
  return {
    appMap: keyBy(apps, "id"),
    fileMap: keyBy(files, "id"),
    linkMap: keyBy(links, "id"),
    siteMap: keyBy(sites, "id"),
    playlistMap: keyBy(playlists, "id"),
  };
}

export const calculateLayoutForPreview = (
  width: number,
  height: number,
  expectWidth: number,
  expectHeight: number,
): number[] => {
  const aspectRatioWidth: number = expectWidth / width;
  const aspectRatioHeight: number = expectHeight / height;
  const ratio: number = Math.min(aspectRatioWidth, aspectRatioHeight);
  return [width * ratio, height * ratio];
};

export function isHome({
  targetId,
  context,
}: {
  targetId?: UUID;
  context: AppContextState;
}) {
  const startChannelId = context?.currentOrg?.startChannelId;
  return startChannelId === targetId;
}

// Duplicated editable shared channel is the channel that contains both childOf and duplicateOf
export function isDuplicatedEditableSharedChannel(channel: Channel): boolean {
  return Boolean(channel.childOf) && Boolean(channel.duplicateOf);
}

// Original shared channel is the channel that contains only childOf but not duplicateOf
export function isOriginalSharedEditableChannel(channel: Channel): boolean {
  return Boolean(channel.childOf) && !Boolean(channel.duplicateOf);
}

/**
 * We do not allow unshare for home channel
 */
export function canUnshared({
  channel,
  context,
}: {
  channel?: Pick<ChannelListItemFragment, "id" | "spaceId">;
  context: AppContextState;
}) {
  return isHome({ targetId: channel?.id, context }) === false;
}

/**
 *
 * @param param0 Can toggle share channel
 */
export function canToggleShare({
  channel,
  context,
}: {
  channel: Pick<ChannelListItemFragment, "id" | "spaceId">;
  context: AppContextState;
}) {
  return (
    canUnshared({ channel, context }) &&
    isShareableOwner({ shareable: channel, context }) &&
    context.currentPermissions.validateCurrentSpace("channel", "share")
  );
}

export function canChannelBeDeleted({
  channel,
  context,
}: {
  channel: Channel;
  context: AppContextState;
}) {
  return (
    (!isHome({ targetId: channel?.id, context }) ||
      isDuplicatedEditableSharedChannel(channel)) &&
    canBeDeleted({ shareable: channel, context })
  );
}

export function getChannelContentByOwner({
  context,
  channel,
}: {
  channel?: Maybe<ChannelByIdFragment | ChannelDetailForScreenFragment>;
  context: AppContextState;
}) {
  return channel &&
    isShared(channel) &&
    !isOwner({ context, spaceId: channel.spaceId })
    ? channel?.published
    : channel?.draft;
}

export function getChannelOrientation(
  channel: Pick<ChannelDetailFragment, "width" | "height">,
) {
  return channel.width && channel.height && channel.width > channel.height
    ? Orientation.Landscape
    : Orientation.Portrait;
}

/**
 * If user has permission to delete but cant delete playlist because it is belong to other space or it is home channel
 */
export function shouldInformUnableToDeleteOtherSpaceChannel({
  channel,
  context,
}: {
  channel: Channel;
  context: AppContextState;
}) {
  return (
    context.currentPermissions.validateCurrentSpace("channel", "delete") &&
    (!isShareableOwner({ shareable: channel, context }) ||
      isHome({ targetId: channel?.id, context }) ||
      isOriginalSharedEditableChannel(channel))
  );
}

/**
 * If user has permission to duplicate but cant duplicate playlist because it is belong to other space we may inform them
 */
export function shouldInformUnableToDuplicateOtherSpaceChannel({
  channel,
  context,
}: {
  channel: Pick<Channel, "id" | "spaceId">;
  context: AppContextState;
}) {
  return (
    !isShareableOwner({ shareable: channel, context }) &&
    context.currentPermissions.validateCurrentSpace("channel", "create")
  );
}

export function updateNewChannelToList(
  context: AppContextState,
  cache: ApolloCache<CreateChannelMutation | DuplicateChannelMutation>,
  newChannel: ChannelListItemFragment,
) {
  cache.modify({
    id: cache.identify(context.currentSpace!),
    fields: {
      publishedChannelsBySpaceId(publishCh, options) {
        if (isNotRelatedCacheToModify(context, options)) {
          return publishCh;
        }

        const newChannelRef = cache.writeFragment({
          data: newChannel,
          fragment: ChannelListItemFragmentDoc,
          fragmentName: "ChannelListItem",
        });

        if (
          publishCh.nodes.some(
            (ref) => options.readField("id", ref) === newChannel?.id,
          )
        ) {
          return publishCh;
        }

        const reOrder = orderBy(
          [...publishCh.nodes, newChannelRef].map((node) => {
            const name = options.readField("name", node) as string;
            return {
              name,
              node,
            };
          }),
          [(node) => node?.name?.toLowerCase()],
          ["asc"],
        );
        const nodes = reOrder.map((re) => re.node);
        const newChannelList = {
          ...publishCh,
          nodes,
          totalCount: nodes.length,
        };
        return newChannelList;
      },
    },
  });
}
export function updateChannelListOrder(
  context: AppContextState,
  cache: ApolloCache<UpdateChannelNameMutation>,
) {
  cache.modify({
    id: cache.identify(context.currentSpace!),
    fields: {
      publishedChannelsBySpaceId(publishCh, options) {
        if (
          options.storeFieldName.includes('"equalTo":') ||
          !options.storeFieldName.includes('"filter":')
        ) {
          const reOrder = orderBy(
            publishCh.nodes.map((node) => {
              const name = options.readField("name", node) as string;
              return {
                name,
                node,
              };
            }),
            [(node) => node?.name?.toLowerCase()],
            ["asc"],
          );
          const newChannelList = {
            ...publishCh,
            nodes: reOrder.map((re) => re.node),
            totalCount: publishCh.totalCount,
          };
          return newChannelList;
        } else {
          return publishCh;
        }
      },
    },
  });
}

export function getDefaultChannelNameOrg(
  currentOrgStartableChannels: Channel[],
  currentOrg: Org,
) {
  const defaultStartChannel = currentOrgStartableChannels.find(
    (channel) => channel.id === currentOrg?.startChannelId,
  );
  if (defaultStartChannel) {
    const detaultChannelName = getChannelName(defaultStartChannel, currentOrg);
    return detaultChannelName;
  }
  return "Splash";
}

export const shouldDisableItem = (schedules: ScheduleRules[]) => {
  // Check past date on all schedule items
  if (schedules?.length > 0) {
    const allRawEndDate: any[] = [];
    const allRawEndTime: any[] = [];

    let maxEndDateString;
    let maxEndTimeString;
    let isEverydaySelected;

    schedules.flatMap((schedule) => {
      isEverydaySelected = Object.values(schedule.day_of_week ?? {}).every(
        (item) => item === true,
      );
      allRawEndDate.push(schedule.date);
      allRawEndTime.push(schedule.time);

      if (
        schedule.date &&
        schedule.date?.length > 0 &&
        schedule.specific_date
      ) {
        const allEndDate: Date[] = [];
        schedule.date.map((item) => {
          if (item.end !== undefined) {
            const itemEndDate = normalizedDate(item.end);
            if (isValid(itemEndDate)) {
              allEndDate.push(itemEndDate);
            }
          }
        });
        maxEndDateString =
          allEndDate.length > 0 ? format(max(allEndDate), "yyyy-MM-dd") : [];
      }
      if (
        schedule.time &&
        schedule.time?.length > 0 &&
        schedule.date &&
        schedule.date?.length > 0
      ) {
        const allEndTime: Date[] = [];
        schedule.time.map((item) => {
          if (item.end !== undefined) {
            const trimEndTime =
              item.end.trim() === "NaN:NaN:NaN" ? "23:59:00" : item.end.trim();
            const transformEndTime = isTimeOver24h(trimEndTime)
              ? trimEndTime.replace("24", "00")
              : trimEndTime;
            const itemEndTime = new Date("2000-01-01T" + transformEndTime);
            if (isValid(itemEndTime)) {
              // Temp date for Safari support
              allEndTime.push(itemEndTime);
            }
          }
        });
        // Extract time
        maxEndTimeString = format(max(allEndTime), "kk:mm:ss");
        if (
          maxEndTimeString.split(":")[0] === "24" &&
          (maxEndTimeString.split(":")[1] > 0 ||
            maxEndTimeString.split(":")[2] > 0)
        ) {
          maxEndTimeString = format(max(allEndTime), "HH:mm:ss");
        }
      } else {
        maxEndTimeString = format(new Date("2000-01-01T00:00:00"), "kk:mm:ss");
      }
    });

    // Skip when Everyday & All day selected
    if (
      isEverydaySelected &&
      allRawEndTime.some((item) => item?.length === 0) &&
      allRawEndDate.some((item) => item?.length === 0)
    ) {
      return false;
    }
    return isPast(new Date(maxEndDateString + "T" + maxEndTimeString));
  }
  return false;
};
