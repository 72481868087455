import { Dropdown, DropdownMenu } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import {
  ConnectDropTarget,
  DragLayer,
  DragLayerMonitor,
  DropTarget,
  DropTargetConnector,
  DropTargetMonitor,
} from "react-dnd";
import { compose } from "../../utils/compose";
import { StyledDropdown } from "./styles";

export interface MediaBreadCrumbDropdownProps {
  connectDropTarget?: ConnectDropTarget;
  isDragging?: boolean;
  isOver?: boolean;
  icon: React.ReactNode;
  style: React.CSSProperties;
}

const dropTarget = {
  drop(props: MediaBreadCrumbDropdownProps, monitor: DropTargetMonitor) {},
};

const dragCollect = (monitor: DragLayerMonitor) => ({
  isDragging: monitor.isDragging(),
});

const dropCollect = (
  connect: DropTargetConnector,
  monitor: DropTargetMonitor,
) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
});

class MediaBreadCrumbDropdown extends React.Component<
  MediaBreadCrumbDropdownProps,
  {}
> {
  public render() {
    const { connectDropTarget, isDragging, isOver, children, icon, style } =
      this.props;

    return (
      connectDropTarget &&
      connectDropTarget(
        <div style={{ display: "inline-block" }}>
          <StyledDropdown className={isDragging ? "dnd-dragging-active" : ""}>
            <Dropdown icon={icon} style={style} simple open={isOver}>
              <DropdownMenu className={isOver ? "dnd-dropdown-menu" : null}>
                {children}
              </DropdownMenu>
            </Dropdown>
          </StyledDropdown>
        </div>,
      )
    );
  }
}

export default compose(
  DropTarget("MEDIA", dropTarget, dropCollect),
  DragLayer<MediaBreadCrumbDropdownProps>(dragCollect),
)(MediaBreadCrumbDropdown);
