import { useEffect } from "react";

const getPaddingYAxis = (element: HTMLElement | null) => {
  if (!element) return 0;
  const style = getComputedStyle(element);
  const paddingTop = parseInt(style.paddingTop);
  const paddingBottom = parseInt(style.paddingBottom);
  return paddingTop + paddingBottom;
};

export const useNoteAutoHeightByScrollHeight = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string,
) => {
  useEffect(() => {
    if (!textAreaRef || !textAreaRef.parentElement) return;

    const container = textAreaRef.parentElement;
    container.style.height = "0px";

    // Calculate the new height considering padding
    const newHeight = textAreaRef.scrollHeight + getPaddingYAxis(container);

    container.style.height = newHeight + "px";
  }, [textAreaRef, value]);
};
