import { DocumentPreview } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { appConfig } from "../../../appConfig";
import { MediaType, RefType } from "../../../constants/constants";
import { getSecureUrl } from "../../../domains/media/mediaPreview";
import {
  getFileOutputImageSet,
  getFileOutputVideo,
  getMediaPlaceHolder,
  getMediaType,
} from "../../../helpers/mediaHelper";
import {
  AppInstanceByIdExtendedAssociationQuery,
  File,
  Link,
} from "../../../types.g";
import { Content } from "../../ReplaceContent";
import { AppInstancesExtendedAssociation } from "src/hooks/useAllAppinstances";

export const getVideoPlayer = (media: File, secureMediaPolicy: string) => {
  const video = getFileOutputVideo(media);
  if (video) {
    let src = `${appConfig.mediaOrigin}/${video?.content.keys[0]}`;

    src = getSecureUrl(src, secureMediaPolicy);

    return (
      <video className="video-preview" autoPlay controls muted>
        <source src={src ?? video.url ?? ""} type="video/mp4" />
        <p>
          Your browser does not support the<code>video</code> element.
        </p>
      </video>
    );
  } else {
    return null;
  }
};

export const getAudioPlayer = (
  audioFile: string,
  secureMediaPolicy: string,
) => {
  // if secure policy exists access by secure domain
  audioFile = getSecureUrl(audioFile, secureMediaPolicy);

  if (audioFile) {
    return (
      <audio loop controls>
        <source src={audioFile} type="audio/mpeg" />
        <p>
          Your browser does not support the<code>audio</code> element.
        </p>
      </audio>
    );
  } else {
    return null;
  }
};

export const getDocumentPlayer = (
  documentPreviewFiles: string[],
  placeholder: string,
  secureMediaPolicy: string,
) => {
  const documentPreviewRef: React.RefObject<HTMLImageElement>[] = [];
  placeholder = getSecureUrl(placeholder, secureMediaPolicy);
  const imageSetPreview = documentPreviewFiles.map((previewContent, index) => {
    previewContent = getSecureUrl(previewContent, secureMediaPolicy);
    documentPreviewRef[index] = React.createRef();
    return (
      <div className="document" key={index}>
        <img ref={documentPreviewRef[index]} src={previewContent} />
      </div>
    );
  });
  return imageSetPreview.length > 0 ? (
    <DocumentPreview images={documentPreviewFiles}>
      {imageSetPreview}
    </DocumentPreview>
  ) : (
    <div
      className="media-content"
      style={{ backgroundImage: "url(" + placeholder + ")" }}
    />
  );
};

export const getImagePlayer = (source: string) => {
  return <img src={source} />;
};

export const renderContentPreview = (
  content: Content,
  secureMediaPolicy: string,
) => {
  let contentPreview;
  if (content && content.type === RefType.FILE) {
    // cast type first
    const media = content as File;
    const placeholder = getMediaPlaceHolder(media);
    const mediaType = getMediaType(media);
    if (mediaType === MediaType.IMAGE) {
      contentPreview = getImagePlayer(media.source as string);
    } else if (mediaType === MediaType.DOCUMENT) {
      const documentFiles = getFileOutputImageSet(media);
      const documentPreviewFiles = documentFiles
        ? documentFiles.content.urls
        : [];
      contentPreview = getDocumentPlayer(
        documentPreviewFiles,
        placeholder,
        secureMediaPolicy,
      );
    } else if (mediaType === MediaType.VIDEO) {
      contentPreview = getVideoPlayer(media, secureMediaPolicy);
    } else if (mediaType === MediaType.AUDIO) {
      contentPreview = getAudioPlayer(media.source || "", secureMediaPolicy);
    }
  } else if (content && content.type === RefType.LINK) {
    // cast type first
    const link = content as Link;
    const thumbnailSource = link.fileByFileId?.source;
    contentPreview = thumbnailSource ? getImagePlayer(thumbnailSource) : <></>;
  } else if (content && content.type === RefType.APP) {
    const thumbnailSource = content?.fileByThumbnailFileId?.source;
    const appByAppId =
      (content as AppInstancesExtendedAssociation)?.appByAppId ??
      (content as AppInstanceByIdExtendedAssociationQuery["appInstanceById"])
        ?.appVersionByAppInstanceId?.appByAppId;
    const iconUrl = appByAppId?.iconUrl;
    if (thumbnailSource) {
      contentPreview = thumbnailSource ? (
        getImagePlayer(thumbnailSource)
      ) : (
        <></>
      );
    } else {
      contentPreview = iconUrl ? getImagePlayer(iconUrl) : <></>;
    }
  }
  return contentPreview;
};
