import { useCacheInvalidation } from "src/hooks/useCacheInvalidation";
import { Channel } from "src/types.g";

export function useOnChannelDeleted() {
  const { invalidateScreenGroupCache, invalidateEntityCache } =
    useCacheInvalidation();

  const onChannelDeleted = ({
    channel,
  }: {
    channel: Pick<Channel, "__typename" | "id" | "name">;
  }) => {
    invalidateEntityCache(channel);
    invalidateScreenGroupCache();
  };

  return { onChannelDeleted };
}
