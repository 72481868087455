import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  Icon,
  InlineInput,
  ModalSize,
  Popover,
  SyncStateText,
} from "@screencloud/screencloud-ui-components";
import {
  canBePublished,
  updatePlaylistListOrder,
} from "../../../../helpers/playlistHelper";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { PlaylistDetailMode } from "..";
import { CastedScreenInfoActions } from "../../../../components/CastedScreenInfo";
import DateTime from "../../../../components/DateTime";
import Header from "../../../../components/Header";
import { ScreenPickerActions } from "../../../../components/ScreenPicker";
import UpdatedByUser from "../../../../components/UpdatedByUser";
import UserName from "../../../../components/UserName";
import { NameMaxLengthAllow, UUID } from "../../../../constants/constants";
import { FEATURE_FLAGS_ENUM } from "../../../../constants/featureFlag";
import { renderCastingStatus } from "../../../../helpers/castingHelper";
import {
  canBeUpdated,
  shouldShowShareButton,
} from "../../../../helpers/shareableHelper";
import { useAppContext } from "../../../../hooks/useAppContext";
import queryHelper from "../../../../state/helper/query";
import {
  CastByCastIdFragment,
  CastsBySpaceIdDocument,
  CreateCastsByPlaylistIdMutationVariables,
  Playlist,
  ScreenCastStopMutationVariables,
  SetScreenContentByPlaylistIdMutationVariables,
  UpdatePlaylistNameMutationVariables,
} from "../../../../types.g";
import { parse } from "../../../../utils/queryString";
import PlaylistSharedModal from "../../PlaylistSharedModal/index";
import { ApolloProps, withData } from "./apollo";
import { StyledDuplicateDropdownOption } from "./styles";
import { useDuplicatePlaylist } from "../../../../hooks/duplication/useDuplicatePlaylist";
import { useGetScreenSize } from "../../../../hooks/useGetScreenSize";
import { DuplicateDropdownItems } from "./DuplicateDropdownItems";
import { PlaylistDropdown } from "./PlaylistDropdown";
import { getChannelIdFromUrl, isFromDetailPage } from "src/utils/url";

interface Props {
  mode: PlaylistDetailMode;
  playlist: Playlist;
  isHidePublishAnimation: boolean;
  isPublishing: boolean;
  isDraftSaving: boolean;
  onDiscardChanges: () => void;
  onPublishPlaylist: () => void;
  refetchQueries: (playlistId: UUID) => any;
  onDuplicatePlayList: () => void;
  onDeletePlaylist: () => void;
  onEditPlaylistClick: () => void;
  onPreviewPlaylistClick: () => void;
  onGoBackToPlaylistListPage: () => void;
  onPreviewFullscreen: () => void;
  shouldDisplayCleanupButton?: boolean;
  onCleanupPlaylist: () => void;
}
export const PlaylistDetailHeader = (props: Props & ApolloProps) => {
  const {
    isHidePublishAnimation,
    isDraftSaving,
    isPublishing,
    onPublishPlaylist,
  } = props;
  const [playlist, setPlaylist] = useState<Playlist>(props.playlist);
  const context = useAppContext();
  const { isMobileView } = useGetScreenSize();
  const { duplicatePlaylist: duplicateToAnotherSpace } = useDuplicatePlaylist({
    id: playlist.id,
    name: playlist.name,
  });
  const canUpdatePlaylist = context.currentPermissions.validateCurrentSpace(
    "playlist",
    "update",
  );

  useEffect(() => {
    setPlaylist(props.playlist);
  }, [props.playlist]);

  const onCastedScreensCallback = async (
    data: string,
    action: CastedScreenInfoActions,
  ) => {
    switch (action) {
      case CastedScreenInfoActions.STOP_CAST:
        const castStopVar: ScreenCastStopMutationVariables = {
          input: {
            screenId: data,
          },
        };
        const castItem = {
          playlistByCastId: {
            id: props.playlist.id,
          },
        } as CastByCastIdFragment;
        props.screenCastStop(castStopVar, castItem);
        break;
      case CastedScreenInfoActions.NAVIGATE_SCREEN:
        context.history.push("/screens/" + data);
        break;
      default:
    }
  };

  const onScreenPickerCallback = (data: string[], expiresAt?: Date) => {
    const { refetchQueries } = props;
    if (playlist) {
      const selectedPlaylist = playlist;
      if (selectedPlaylist !== undefined) {
        const spaceId = context.user.settings.spaceId;
        const castStartVar: CreateCastsByPlaylistIdMutationVariables = {
          input: {
            playlistId: selectedPlaylist.id,
            screenIds: data,
            expiresAt,
          },
        };
        props
          .createCastsByPlaylistId({
            refetchQueries: [
              refetchQueries(playlist.id),
              {
                query: CastsBySpaceIdDocument,
                variables: {
                  spaceId,
                },
              },
            ],
            variables: castStartVar,
          })
          .then(() => {
            queryHelper.updateCastedScreen(spaceId);
            context.modal.closeModals();
          });
      }
    }
  };

  const updatePlaylistName = async (
    e: React.SyntheticEvent<any>,
    value: string,
  ) => {
    if (playlist) {
      const name = value || playlist.name;
      const playlistNameInput: UpdatePlaylistNameMutationVariables = {
        input: {
          id: playlist.id,
          name,
        },
      };
      await props.updatePlaylistName({
        variables: playlistNameInput,
        update: (cache, { data }) => {
          if (data?.updatePlaylistName?.playlist) {
            updatePlaylistListOrder(context, cache);
          }
        },
      });
      setPlaylist({ ...playlist, name });
    }
  };

  const openScreenPicker = async () => {
    if (playlist) {
      const result = await context.modal.openScreenPicker(playlist);
      if (result.action === ScreenPickerActions.SET_CONTENT) {
        const setContents = result.data.map((screenId) => {
          const setPlaylistContent: SetScreenContentByPlaylistIdMutationVariables =
            {
              input: {
                screenId,
                playlistId: playlist?.id,
              },
            };
          return props.setContentByPlaylistId({
            variables: setPlaylistContent,
          });
        });
        await Promise.all(setContents);
        context.modal.closeModals();
      } else {
        onScreenPickerCallback(result.data, result.expiresAt);
      }
    }
  };

  const showShareModal = () => {
    context.modal.openModal(
      <PlaylistSharedModal playList={playlist as Playlist} />,
      <>
        <FormattedMessage
          id="common.label.share_playlist"
          defaultMessage="Share Playlist"
        />
        : {playlist!.name}
      </>,
      {
        opts: {
          size:
            (context.allSpaces ?? []).length > 1
              ? ModalSize.MEDIUM
              : ModalSize.SMALL,
          disableTitle: true,
        },
      },
    );
  };

  const renderDuplicateDropdown = () => {
    if (isMobileView) {
      return null;
    }
    return (
      <StyledDuplicateDropdownOption
        direction="left"
        data-testid="playlist-duplicate-options"
        data-cy="playlist-duplicate-options"
        icon={
          <Popover
            inverted
            content={
              <FormattedMessage
                id="ui_component.label.duplicate"
                defaultMessage="Duplicate"
              />
            }
            trigger={
              <Button icon>
                <Icon name="duplicate" />
              </Button>
            }
          />
        }
      >
        <DropdownMenu>
          <DuplicateDropdownItems
            onDuplicatePlayList={props.onDuplicatePlayList}
            duplicateToAnotherSpace={duplicateToAnotherSpace}
          />
        </DropdownMenu>
      </StyledDuplicateDropdownOption>
    );
  };
  const onBackButtonClicked = () => {
    if (isFromDetailPage()) {
      history.back();
    } else if (!!getChannelIdFromUrl()) {
      const channelId = getChannelIdFromUrl();
      context.history.push(`/channels/${channelId}/edit`);
    } else {
      props.onGoBackToPlaylistListPage();
    }
  };

  if (playlist?.draft) {
    const isPublished = playlist?.draft?.isPublished && !isHidePublishAnimation;
    const queryStrings = parse(props.location.search, {});
    const playlistName = playlist?.name;
    const showEditButton = props.mode === PlaylistDetailMode.Preview;
    const shouldShowDuplicateButton = !isMobileView && canUpdatePlaylist;
    const publishDropdownItem = (
      <>
        <DropdownItem>
          <div className="metaUpdate">
            <FormattedMessage
              id="common.text.latest_published_on_date_name"
              defaultMessage="Lastest published on  {date} by {name}"
              values={{
                date: <DateTime value={playlist.publishedAt} />,
                name: (
                  <strong>{<UserName id={playlist?.publishedBy} />}</strong>
                ),
              }}
            />
          </div>
        </DropdownItem>
        {canBePublished({ playlist, context }) && (
          <DropdownItem
            data-testid="undo-button"
            disabled={isPublished!}
            onClick={() => !isPublished && props.onDiscardChanges()}
          >
            <Icon name="revert" />
            <FormattedMessage
              id="channels.discard_changes"
              defaultMessage="Discard Changes"
            />
          </DropdownItem>
        )}
      </>
    );
    return (
      <Header data-testid="playlist-header" className="header">
        <div className="wrapper detail">
          <div className="column-back">
            <Button
              className="back"
              basic
              onClick={onBackButtonClicked}
              title={context.intl.formatMessage({
                defaultMessage: "Back to previous page",
                id: "common.text.back_to_previous_page",
              })}
              data-testid="back-button"
            >
              <Icon name="arrow-left" />
            </Button>
          </div>

          <div className="column-title">
            <h1>
              <InlineInput
                data-testid="playlist-name-input"
                maxLength={NameMaxLengthAllow}
                disabled={!canBeUpdated({ context, shareable: playlist })}
                value={playlistName}
                placeholder={
                  queryStrings.new && playlistName
                    ? playlistName
                    : "New Playlist"
                }
                editmode={queryStrings.new}
                onSaved={updatePlaylistName}
                showIcon={true}
              />
            </h1>

            <div className="meta-update">
              <UpdatedByUser
                createdAt={playlist.createdAt}
                createdBy={playlist.createdBy}
                publishedAt={playlist.publishedAt}
                publishedBy={playlist.publishedBy}
                usePublished={true}
              />
            </div>
          </div>

          <div className="column-options">
            <div className="draft-state">
              <SyncStateText isDraftSaving={isDraftSaving} />
            </div>

            {renderCastingStatus({
              castScreenData: playlist.castedScreenByPlaylistId.nodes ?? [],
              callBack: onCastedScreensCallback,
              context,
            })}

            {shouldShowShareButton({ context, shareable: playlist }) && (
              <Popover
                inverted
                content={
                  <FormattedMessage
                    id="ui_component.label.share"
                    defaultMessage="Share"
                  />
                }
                trigger={
                  <Button
                    className="share-button"
                    onClick={showShareModal}
                    data-testid="share-button"
                    data-cy="share-button"
                    icon
                  >
                    <Icon name="users" />
                  </Button>
                }
              />
            )}
            {shouldShowDuplicateButton && renderDuplicateDropdown()}
            {context.currentPermissions.validateCurrentSpace(
              "screen",
              "cast",
            ) &&
              context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING) && (
                <Popover
                  inverted
                  content={
                    <FormattedMessage
                      id="ui_component.common.label.set_to_screen"
                      defaultMessage="Set to Screen"
                    />
                  }
                  trigger={
                    <Button
                      onClick={openScreenPicker}
                      className="button-casting"
                      data-testid="cast-button"
                      data-cy="cast-button"
                      icon
                    >
                      <Icon name="screen-play" />
                    </Button>
                  }
                />
              )}
            {canBeUpdated({ context, shareable: playlist }) && (
              <>
                {showEditButton ? (
                  <Button
                    className="button-edit"
                    onClick={props.onEditPlaylistClick}
                    data-testid="edit-button"
                  >
                    <FormattedMessage
                      id="common.button.edit"
                      defaultMessage="Edit"
                    />
                  </Button>
                ) : (
                  <Button
                    className="btn-preview"
                    onClick={props.onPreviewPlaylistClick}
                    title="Preview"
                    data-testid="preview-button"
                  >
                    <Icon name="play" />
                    <FormattedMessage
                      id="common.button.preview"
                      defaultMessage="Preview"
                    />
                  </Button>
                )}
              </>
            )}
            {canBePublished({ context, playlist }) && (
              <>
                {isPublished ? (
                  <ButtonDropdown
                    className="button-publish"
                    disabled
                    content={
                      <FormattedMessage
                        defaultMessage="Published"
                        id="common.button.published"
                      />
                    }
                    data-testid="published-button"
                  >
                    {publishDropdownItem}
                  </ButtonDropdown>
                ) : (
                  <ButtonDropdown
                    callBack={onPublishPlaylist}
                    primary
                    isSuccess={isHidePublishAnimation}
                    isLoading={isPublishing}
                    content={
                      <FormattedMessage
                        defaultMessage="Publish"
                        id="common.button.publish"
                      />
                    }
                    disabled={
                      !canBePublished({ context, playlist }) ||
                      props.isDraftSaving
                    }
                    className="button-publish"
                    data-testid="publish-button"
                  >
                    {publishDropdownItem}
                  </ButtonDropdown>
                )}
              </>
            )}
            <PlaylistDropdown
              shouldDisplayCleanupButton={props.shouldDisplayCleanupButton}
              onDeletePlaylist={props.onDeletePlaylist}
              onCleanupPlaylist={props.onCleanupPlaylist}
              onDuplicatePlayList={props.onDuplicatePlayList}
              duplicateToAnotherSpace={duplicateToAnotherSpace}
            />
          </div>
        </div>
      </Header>
    );
  }
  return null;
};

export default withData(PlaylistDetailHeader) as React.ComponentType<Props>;
