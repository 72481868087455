import { UUID } from "@screencloud/uuid";
import request from "superagent";
import { ssm } from "../state/session/ssm";

export const callPreAppConfigureSaveHook = async (
  hookConfig: any,
  appInstanceId: UUID,
  config: any,
): Promise<any> => {
  const response = await request
    .post(hookConfig.url)
    .query({ token: ssm.current.token })
    .query({ appInstanceId })
    .send(config)
    .timeout({ response: 3000, deadline: 10000 })
    .retry(2);
  return response.body;
};
