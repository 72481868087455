import {
  Button,
  Dropdown,
  DropdownItem,
  Icon,
} from "@screencloud/screencloud-ui-components";
import { EntityType } from "@screencloud/signage-firestore-client";
import { Component, useState } from "react";
import { FormattedMessage } from "react-intl";
import { subscribeToListUpdates } from "src/state/liveUpdateManager";
import { compose } from "src/utils/compose";
import { AppContext } from "../../AppContextProvider/AppContext";
import { AppContextType } from "../../AppContextProvider/type";
import { getTokenPayloadByRegions } from "../../helpers/userHelper";
import { Space, WhiteLabel } from "../../types.g";
import { orgsList } from "../../utils";
import Avatar from "../Admin/Avatar";
import { Styled } from "./styles";
import { useHistory } from "react-router";
import { useRecyclebinPermission } from "src/domains/recycleBin/hooks.ts/useRecyclebinPermission";
import { shouldDisplayScreensManagerMenu } from "./utils";

export enum AccountNavActions {
  Logout = "logout",
  Settings = "settings",
  ScreensManager = "screensManager",
  SwitchOrg = "switchOrg",
}

export interface AccountNavProps {
  onItemClick?: (action) => void;
  name: string;
  space: string;
  spaces: Pick<Space, "id" | "name">[];
  currentSpace: string;
  className?: string;
}

export interface AdditionalProps {
  goToTrashPage: () => void;
  showTrashMenuItem: boolean;
  showAccountSettingMenu: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export interface AccountNavState {
  open: boolean;
}

export class AccountNavPresentation extends Component<
  AccountNavProps & Partial<WhiteLabel> & AdditionalProps,
  AccountNavState
> {
  public static contextType = AppContext;
  public context: AppContextType;

  private unsubscribeScreenLiveUpdateFn?: () => void;
  private unsubscribeSpaceLiveUpdateFn?: () => void;

  constructor(props: AccountNavProps & WhiteLabel & AdditionalProps) {
    super(props);
  }

  public subscribeToLiveUpdate = () => {
    const { currentUser } = this.context;
    if (currentUser) {
      this.unsubscribeScreenLiveUpdateFn = subscribeToListUpdates(
        currentUser.orgId || "",
        EntityType.SCREEN,
        async () => {
          await this.context.refetchCurrentOrg();
        },
      );

      this.unsubscribeSpaceLiveUpdateFn = subscribeToListUpdates(
        currentUser.orgId || "",
        EntityType.SPACE,
        async () => {
          await this.context.refetchCurrentOrg();
        },
      );
    }
  };

  public unsubscribeFromLiveUpdates() {
    if (this.unsubscribeScreenLiveUpdateFn) {
      this.unsubscribeScreenLiveUpdateFn();
      this.unsubscribeScreenLiveUpdateFn = undefined;
    }

    if (this.unsubscribeSpaceLiveUpdateFn) {
      this.unsubscribeSpaceLiveUpdateFn();
      this.unsubscribeSpaceLiveUpdateFn = undefined;
    }
  }

  public componentDidMount() {
    this.subscribeToLiveUpdate();
  }

  public componentWillUnmount() {
    this.unsubscribeFromLiveUpdates();
  }

  public handleItemClick = (action: AccountNavActions) => {
    if (this.props.onItemClick) {
      this.props.onItemClick(action);
    }
  };

  public render(): JSX.Element | any {
    const { onClose, onOpen, showAccountSettingMenu } = this.props;
    if (this.props.spaces) {
      const hasMultipleOrgs = orgsList().length > 1;
      const hasOtherRegionOrgs =
        this.context.tokenPayloadsByRegion !== undefined &&
        getTokenPayloadByRegions(this.context.tokenPayloadsByRegion).all
          .length > 1;
      const {
        bgColor,
        textColor,
        activeTextColor,
        activeItemColor,
        goToTrashPage,
        showTrashMenuItem,
      } = this.props;

      const trigger = (
        <>
          {showAccountSettingMenu && (
            <div
              className="account-settings"
              data-testid="account-settings-menu"
              data-cy="account-settings-menu"
            >
              {shouldDisplayScreensManagerMenu(this.context) && (
                <>
                  <DropdownItem>
                    <Button
                      borderless
                      mini
                      onClick={this.handleItemClick.bind(
                        this,
                        AccountNavActions.ScreensManager,
                      )}
                      data-testid="screens-manager-menu"
                      data-cy="account-nav-screen-manager"
                    >
                      <Icon name="screens" />
                      <FormattedMessage
                        id="ui_component.account_nav.screens_manager"
                        defaultMessage="Screens Manager"
                      />
                    </Button>
                  </DropdownItem>
                  <div className="divider" />
                </>
              )}
              {showTrashMenuItem && (
                <>
                  <DropdownItem>
                    <Button
                      borderless
                      mini
                      onClick={goToTrashPage}
                      data-testid="account-nav-trash-menu-item"
                      data-cy="account-nav-trash-menu-item"
                    >
                      <Icon name="trash" />
                      <FormattedMessage
                        id="ui_component.account_nav.trash"
                        defaultMessage="Trash"
                      />
                    </Button>
                  </DropdownItem>
                  <div className="divider" />
                </>
              )}
              <DropdownItem>
                <Button
                  data-cy="account-nav-account-settings"
                  className="account-settings-button"
                  borderless
                  mini
                  onClick={this.handleItemClick.bind(
                    this,
                    AccountNavActions.Settings,
                  )}
                >
                  <Icon name="settings" />{" "}
                  <FormattedMessage
                    id="ui_component.account_nav.account_settings"
                    defaultMessage="Account Settings"
                  />
                </Button>
              </DropdownItem>
              {(hasMultipleOrgs || hasOtherRegionOrgs) && (
                <DropdownItem>
                  <Button
                    data-cy="account-switch-org"
                    borderless
                    mini
                    onClick={this.handleItemClick.bind(
                      this,
                      AccountNavActions.SwitchOrg,
                    )}
                  >
                    <Icon name="org-switch" className="icon-move" />{" "}
                    <FormattedMessage
                      id="ui_component.account_nav.switch_org"
                      defaultMessage="Switch Organization"
                    />
                  </Button>
                </DropdownItem>
              )}
              <DropdownItem className="danger">
                <Button
                  data-cy="account-nav-logout"
                  borderless
                  danger
                  mini
                  onClick={this.handleItemClick.bind(
                    this,
                    AccountNavActions.Logout,
                  )}
                >
                  <Icon name="logout" />{" "}
                  <FormattedMessage
                    id="ui_component.account_nav.logout"
                    defaultMessage="Logout"
                  />
                </Button>
              </DropdownItem>
            </div>
          )}
          <div
            className="account-trigger"
            data-testid="account-setting-trigger"
            data-cy="account-setting-trigger"
          >
            <div className="user">
              <Avatar
                url={this.context.user.claims.picture}
                name={this.context.user.claims.givenName}
              />
              <div data-cy="account-setting-user-info" className="user-alpha">
                <span className="name">{this.props.name}</span>
                <span className="email">{this.context.user.claims.email}</span>
              </div>
            </div>
          </div>
        </>
      );

      return (
        <Styled
          bgColor={bgColor}
          textColor={textColor}
          activeTextColor={activeTextColor}
          activeItemColor={activeItemColor}
          className="account-nav"
          data-qa="account-nav"
        >
          <Dropdown
            data-testid="account-nav-items"
            trigger={trigger}
            onOpen={onOpen}
            onClose={onClose}
          />
        </Styled>
      );
    } else {
      return;
    }
  }
}

export default compose(
  (Component) => (props: AccountNavProps & Partial<WhiteLabel>) => {
    const history = useHistory();
    const { canRead } = useRecyclebinPermission();
    const [open, setOpen] = useState(false);

    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);

    const goToTrashPage = () => {
      history.push("/trash");
    };

    return (
      <Component
        {...props}
        goToTrashPage={goToTrashPage}
        showAccountSettingMenu={open}
        showTrashMenuItem={canRead}
        onOpen={onOpen}
        onClose={onClose}
      />
    );
  },
)(AccountNavPresentation) as React.ComponentType<
  AccountNavProps & Partial<WhiteLabel>
>;
