import {
  Button,
  Dropdown,
  DurationInput,
  Icon,
  MultilineSchedulePicker,
  Popover,
  Thumbnail,
} from "@screencloud/screencloud-ui-components";
import { DropdownItemProps } from "semantic-ui-react";
import { isEmpty } from "lodash";
import memoizeOne from "memoize-one";
import { useMemo, useState } from "react";
import classNames from "clsx";
import { FormattedMessage } from "react-intl";
import {
  MIN_DURATION_CONTENT,
  RefType,
  ScheduleRules,
} from "src/constants/constants";
import { appInstanceIconUrl } from "src/helpers/appHelper";
import { OverlapResult } from "src/helpers/timeRangeHelper/types";
import {
  generateImgixThumbnail,
  getFileOutputImageSet,
  getMediaPlaceHolder,
  isAudio,
  isDocument,
  isVideo,
} from "src/helpers/mediaHelper";
import { mapPlaylistToContentListPreview } from "src/helpers/playlistHelper";
import {
  AppInstance,
  AvailableAppInstanceFragment,
  File,
  FileOutput,
  LinkType,
  Playlist,
} from "src/types.g";
import PlaylistItemsPreview, {
  PlaylistItemType,
} from "src/components/Playlist/PlaylistItemsPreview";
import { ChannelContentItemProps, withData } from "./apollo";
import { Styled } from "./styles";
import { hasSchedule, isExpired } from "src/helpers/scheduleableHelper";
import AvailabilityStatusIcon from "../AvailabilityStatusIcon";
import { FEATURE_FLAGS_ENUM } from "src/constants/featureFlag";
import { shouldDisplayPrimaryAudio } from "./utils";
import { useAppContext } from "src/hooks/useAppContext";
import { isChannelItemExpired } from "src/domains/channel/isChannelItemExpired";

export enum ChannelContentActions {
  REMOVE = "remove",
  SCHEDULE = "schedule",
  DURATION = "duration",
  VIEW = "view",
  PLAYBACK_MODE = "playback_mode",
  PICK_TO_PLAY = "pick_to_play",
}

export enum PlaybackMode {
  RANDOM = "random",
  SEQUENCE = "sequence",
}

interface ChannelFileDataType {
  data: Partial<File>;
  duration: number;
  thumbnail: string;
  imageSet?: FileOutput;
  linkType?: LinkType;
  url?: string;
  lists?: [];
  totalItems?: number;
  title: string;
  isExpired: boolean;
}
interface ChannelPlaylistDataType {
  data: Partial<Playlist>;
  duration: number;
  totalItems: number;
  title: string;
  lists?: PlaylistItemType[];
  isPublished?: boolean;
  isExpired?: boolean;
}
export interface ItemProps extends ChannelContentItemProps {
  listId: string;
  title: string;
  isPublished: boolean;
  isExpired: boolean;
  isRevertChange: boolean;
  appName?: string;
  thumbnail: string;
  duration?: number;
  locale?: string;
  variableDuration?: boolean;
  disabled?: boolean;
  schedule: ScheduleRules[];
  defaultTimeValues: { start: string; end: string }[];
  selected?: boolean;
  showFullscreenOption?: boolean;
  overlapList?: OverlapResult;
  playbackMode?: PlaybackMode;
  pickToPlay?: number;
  readOnly?: boolean;
  isEditableSharedChannel: boolean;
  callback?: (
    listId: string,
    action: ChannelContentActions,
    data?: any,
  ) => void;
  onPreviewClick: (content: any, contentType: string) => void;
  onDurationFocus: (isFocus: boolean) => void;
}

export const ChannelContentItem = (props: ItemProps) => {
  const context = useAppContext();
  const [duration, setDuration] = useState<number>(props.duration ?? 0);
  const [playbackMode, setPlaybackMode] = useState<PlaybackMode>(
    props.playbackMode ?? PlaybackMode.SEQUENCE,
  );
  const [pickToPlay, setPickToPlay] = useState<number | "Play All">(
    props.pickToPlay ?? "Play All",
  );

  const handleScheduleUpdate = (data: ScheduleRules[]) => {
    if (props.callback) {
      props.callback(props.listId, ChannelContentActions.SCHEDULE, data);
    }
  };

  const getPlaylistItemsDuration = memoizeOne((list: any[]) => {
    return list.reduce((sum, item) => {
      const duration = item.duration;
      return sum + duration;
    }, 0);
  });

  const removeContentItem = (): void => {
    if (props.callback) {
      props.callback(props.listId, ChannelContentActions.REMOVE);
    }
  };

  const previewContentItem = (): void => {
    if (props.callback) {
      props.callback(props.listId, ChannelContentActions.VIEW);
    }
  };

  const handleDurationChange = (newDuration) => {
    setDuration(newDuration);
    if (props.callback) {
      props.callback(props.listId, ChannelContentActions.DURATION, {
        duration: newDuration,
      });
    }
  };

  const handlePlaybackModeChange = () => {
    let newPlaybackMode;
    if (playbackMode === PlaybackMode.RANDOM) {
      newPlaybackMode = PlaybackMode.SEQUENCE;
    } else {
      newPlaybackMode = PlaybackMode.RANDOM;
    }

    setPlaybackMode(newPlaybackMode);

    if (props.callback) {
      props.callback(props.listId, ChannelContentActions.PLAYBACK_MODE, {
        playbackMode: newPlaybackMode,
      });
    }
  };

  const handlePickToPlayChange = (numberToPlay: number | "Play All") => {
    setPickToPlay(numberToPlay);
    if (props.callback) {
      props.callback(props.listId, ChannelContentActions.PICK_TO_PLAY, {
        pickToPlay: numberToPlay === "Play All" ? undefined : numberToPlay,
      });
    }
  };

  const createDuration = (contentData) => {
    if (props.variableDuration) {
      return (
        <p>
          <FormattedMessage
            id="ui_component.channel.label.variable_duration"
            defaultMessage="Variable Duration"
          />
        </p>
      );
    }
    if (duration) {
      const itemData = contentData;
      let isDisabled = false;
      let newDuration = duration;
      if (props.type === RefType.APP) {
        isDisabled =
          props.disabled || itemData?.data.config?.appInstanceDurationInSeconds;
        newDuration = itemData?.duration;
      } else if (props.type === RefType.FILE) {
        const disabledForFile =
          itemData?.data.mimetype &&
          (itemData?.data.mimetype.startsWith("video") ||
            itemData?.data.mimetype.startsWith("audio"));
        isDisabled = props.disabled || disabledForFile;
        newDuration = itemData?.duration;
      } else {
        isDisabled = props.disabled || props.type === "playlist";
        newDuration = itemData?.duration;
      }
      return (
        <DurationInput
          showBorder={!props.readOnly}
          min={MIN_DURATION_CONTENT}
          isDisabled={isDisabled}
          value={newDuration ?? 0}
          onDurationValueChange={handleDurationChange}
          onFocus={(isFocus: boolean) => {
            props.onDurationFocus(isFocus);
          }}
          readOnly={props.readOnly}
        />
      );
    }
    return null;
  };

  const getPlaylistData = (): ChannelPlaylistDataType | undefined => {
    let channelItem;
    if (props.playlistById?.playlistById) {
      const playlistById = props.playlistById.playlistById;
      const lists = mapPlaylistToContentListPreview(
        playlistById.content.list,
        playlistById as Playlist,
        context.secureMediaPolicy,
      );
      const isSharedFromAnotherSpace =
        playlistById.spaceId !== context.currentSpace?.id;
      channelItem = {
        data: playlistById,
        duration:
          playlistById.content.props.total_durations ||
          getPlaylistItemsDuration(lists),
        totalItems: lists.length,
        lists,
        title: playlistById.name,
        isPublished:
          playlistById.draft?.isPublished || isSharedFromAnotherSpace,
      };
    }

    return channelItem;
  };

  const getLinkData = (): ChannelPlaylistDataType | undefined => {
    let channelItem;
    if (props.linkById?.linkById) {
      const linkById = props.linkById?.linkById;
      channelItem = {
        data: linkById,
        duration,
        linkType: linkById.linkType,
        thumbnail: linkById.fileByFileId?.source ?? "",
        title: linkById.name,
        url: linkById.url,
      };
    }

    return channelItem;
  };

  const getSiteData = (): ChannelPlaylistDataType | undefined => {
    let channelItem;
    if (props.siteById?.siteById) {
      const siteById = props.siteById?.siteById;
      channelItem = {
        data: siteById,
        duration,
        type: siteById.type,
        thumbnail: siteById.fileByThumbnailId?.source ?? "",
        title: siteById.name,
        url: siteById.url,
      };
    }

    return channelItem;
  };

  const getAppInstanceData = (): ChannelPlaylistDataType | undefined => {
    let channelItem;
    if (props.appInstanceById?.appInstanceById) {
      const appInstanceById = props.appInstanceById
        ?.appInstanceById as unknown as AppInstance;
      const iconUrl = appInstanceIconUrl(
        appInstanceById as unknown as AppInstance,
      );
      channelItem = {
        data: appInstanceById,
        duration: appInstanceById.config?.appInstanceDurationInSeconds
          ? appInstanceById.config?.appInstanceDurationInSeconds * 1000
          : duration,
        thumbnail: iconUrl,
        title: appInstanceById.name,
        isExpired: isExpired(appInstanceById),
      };
    }
    return channelItem;
  };

  const getFileData = (): ChannelFileDataType | undefined => {
    let channelItem;
    if (props.data?.fileById) {
      const fileByRefId = props.data.fileById as unknown as File;
      let newDuration = duration;
      if (isVideo(fileByRefId as File) && fileByRefId.fileOutputsByFileId) {
        const videoFileOutput = fileByRefId.fileOutputsByFileId.nodes.find(
          (fileOutput) => isVideo(fileOutput),
        );
        newDuration = videoFileOutput
          ? videoFileOutput.metadata.duration
          : duration;
        channelItem = {
          data: fileByRefId,
          duration: newDuration,
          thumbnail: generateImgixThumbnail(
            fileByRefId,
            context.secureMediaPolicy,
            true,
          ),
          title: fileByRefId.name,
          isExpired: isExpired(fileByRefId),
        };
      } else if (isAudio(fileByRefId) && fileByRefId.fileOutputsByFileId) {
        const audioFileOutput = fileByRefId.fileOutputsByFileId.nodes.find(
          (fileOutput) => isAudio(fileOutput),
        );
        newDuration = audioFileOutput
          ? audioFileOutput.metadata.duration
          : duration;
        channelItem = {
          data: fileByRefId,
          duration: newDuration,
          thumbnail: getMediaPlaceHolder(fileByRefId),
          title: fileByRefId.name,
          isExpired: isExpired(fileByRefId),
        };
      } else if (isDocument(fileByRefId) && fileByRefId.fileOutputsByFileId) {
        channelItem = {
          data: fileByRefId,
          duration: newDuration,
          imageSet: getFileOutputImageSet(fileByRefId),
          thumbnail: generateImgixThumbnail(
            fileByRefId,
            context.secureMediaPolicy,
            true,
          ),
          title: fileByRefId.name,
          isExpired: isExpired(fileByRefId),
        };
      } else {
        channelItem = {
          data: fileByRefId,
          duration: newDuration,
          thumbnail: generateImgixThumbnail(
            fileByRefId,
            context.secureMediaPolicy,
            true,
          ),
          title: fileByRefId.name,
          isExpired: isExpired(fileByRefId),
        };
      }
    }

    return channelItem;
  };

  const getContentDataByType = (type: string) => {
    let contentData;
    switch (type) {
      case RefType.FILE:
        contentData = getFileData();
        break;
      case RefType.PLAYLIST:
        contentData = getPlaylistData();
        break;
      case RefType.LINK:
        contentData = getLinkData();
        break;
      case RefType.SITE:
        contentData = getSiteData();
        break;
      case RefType.APP:
      case RefType.APP_INSTANCE:
        contentData = getAppInstanceData();
        break;
    }
    return contentData;
  };

  const convertOverlapList = (originalList: OverlapResult) => {
    if (!isEmpty(originalList)) {
      return {
        schedules: [
          {
            date_indexes: Array.from(originalList.date_indexes),
            time_indexes: Array.from(originalList.time_indexes),
          },
        ],
      };
    }
    return;
  };

  const renderAvailabilityExpiryIcon = (contentById) => {
    return (
      hasSchedule(contentById) && (
        <AvailabilityStatusIcon
          data-testid="channel-media-scheduled"
          availableAt={contentById.availableAt}
          expireAt={contentById.expireAt}
          callback={previewContentItem}
        />
      )
    );
  };

  const contentData = getContentDataByType(props.type);

  const expired = isChannelItemExpired(
    props.isExpired,
    props.schedule,
    contentData,
  );

  const fileById = props.data?.fileById;
  const appInstanceById = props.appInstanceById?.appInstanceById;

  const getPickToPlayOptions = (): DropdownItemProps[] | undefined => {
    if (contentData?.data?.__typename === "Playlist") {
      const totalItemsOfPlaylist = contentData.totalItems;
      const options =
        totalItemsOfPlaylist > 0
          ? Array.from(Array(totalItemsOfPlaylist - 1).keys()).map((n) => {
              const unit = n + 1 === 1 ? "item" : "items";
              return {
                key: `${n + 1} ${unit}`,
                text: `${n + 1} ${unit}`,
                value: n + 1,
              };
            })
          : [];
      return [
        {
          key: "Play All",
          value: "Play All",
          text: "Play All",
          className: "custom-divider",
        },
        {
          key: "Pick",
          value: "Pick",
          text: "Pick",
          disabled: true,
          className: "label",
        },
        ...options,
      ];
    }
    return;
  };

  const options = useMemo(() => {
    return getPickToPlayOptions();
  }, [contentData]);

  const disabledPlaybackMode = options?.length === 2; // one is Play All, second is Pick:, that's mean playlist has only 1 item

  const renderIconPlaybackMode = (playbackMode: PlaybackMode) => {
    return playbackMode === PlaybackMode.RANDOM ? (
      <Icon className="play-back-icon" name="play-shuffle" />
    ) : (
      <Icon className="play-back-icon" name="play-in-loop" />
    );
  };

  return (
    <Styled
      className={classNames({
        "disabled-state": !props.appInstanceById?.appInstanceById?.config,
      })}
      expired={expired}
      data-testid="channel-content-item"
      readOnly={props.readOnly}
      isEditableSharedChannel={props.isEditableSharedChannel}
    >
      <div className="content-core">
        <div
          className={classNames("content-alpha", {
            "extra-width-invalid-app":
              props.type === RefType.APP &&
              !props.appInstanceById?.appInstanceById?.config,
          })}
        >
          <div
            className="thumbnail-preview"
            onClick={!props.readOnly ? previewContentItem : undefined}
          >
            {props.type === "playlist" ? (
              <div className="thumbnail">
                <div
                  className="wrapper"
                  style={{ backgroundColor: contentData?.data?.color }}
                >
                  <Icon name="playlist" />
                </div>
              </div>
            ) : (
              <Thumbnail
                size="medium"
                src={props.thumbnail || (contentData?.thumbnail ?? "")}
              />
            )}
          </div>

          <div
            className="content-title"
            onClick={!props.readOnly ? previewContentItem : undefined}
          >
            <h3>{props.title || (contentData?.title ?? "")}</h3>
            {props.type === RefType.APP &&
            props.appInstanceById?.appInstanceById &&
            !props.appInstanceById?.appInstanceById?.config ? (
              <span className="invalid-app-config">
                <FormattedMessage
                  id="channel.content.invalid_app"
                  defaultMessage="Invalid app configuration, open this instance to fix it"
                />
              </span>
            ) : null}
            {props.type === RefType.FILE && contentData?.imageSet ? (
              <span className="document-type">
                {contentData.imageSet.content.urls.length} page{" "}
              </span>
            ) : null}
            {contentData && (
              <>
                {props.type === RefType.PLAYLIST &&
                  (contentData?.isPublished ? (
                    <span className="content-status published">
                      <FormattedMessage
                        id="ui_component.common.label.published"
                        defaultMessage="Published"
                      />
                    </span>
                  ) : (
                    <span className="content-status">
                      <Popover
                        inverted
                        position="top center"
                        content={
                          <FormattedMessage
                            id="common.text.draft_content_message"
                            defaultMessage="Changes have been made. Please publish to play the latest changes on your screens."
                          />
                        }
                        trigger={
                          <span>
                            <Icon name="warning" />
                            <FormattedMessage
                              id="common.text.draft_saved"
                              defaultMessage="Draft Saved. Publish required."
                            />
                          </span>
                        }
                      />
                    </span>
                  ))}
              </>
            )}
          </div>

          <div className="media-scheduled">
            {fileById && renderAvailabilityExpiryIcon(fileById)}
            {appInstanceById && renderAvailabilityExpiryIcon(appInstanceById)}
          </div>
          <div className="content-preview">
            {contentData?.lists && (
              <PlaylistItemsPreview
                playlist={props.playlistById?.playlistById as Playlist}
                onPreviewClick={props.onPreviewClick}
                readOnly={props.readOnly}
              />
            )}
          </div>
        </div>

        <div className="content-options">
          {props.type === RefType.PLAYLIST &&
          context.shouldShowFeature(FEATURE_FLAGS_ENUM.PLAYLIST_DEFINITIONS) ? (
            <>
              <div
                className="media-pick-to-play"
                data-testid="media-pick-to-play"
              >
                {props.readOnly ? (
                  !disabledPlaybackMode && pickToPlay
                ) : (
                  <Dropdown
                    className="selection"
                    fluid
                    disabled={disabledPlaybackMode}
                    options={options}
                    value={pickToPlay}
                    onChange={(_e, data) => {
                      handlePickToPlayChange(data.value as number | "Play All");
                    }}
                  />
                )}
              </div>
              <Popover
                inverted
                position="top center"
                content={
                  playbackMode === PlaybackMode.RANDOM
                    ? "Play in random order"
                    : "Play in sequential order"
                }
                trigger={
                  props.readOnly ? (
                    renderIconPlaybackMode(playbackMode)
                  ) : (
                    <Button
                      data-testid="media-playback-mode"
                      className={classNames("media-playback-mode", {
                        hidden: disabledPlaybackMode && props.readOnly,
                      })}
                      onClick={
                        !props.readOnly ? handlePlaybackModeChange : undefined
                      }
                      transparent={props.readOnly}
                      borderless={props.readOnly}
                      disabled={disabledPlaybackMode}
                      icon
                    >
                      {renderIconPlaybackMode(playbackMode)}
                    </Button>
                  )
                }
              />
            </>
          ) : (
            <div
              className={classNames("content-duration", {
                "hide-action":
                  props.type === RefType.APP &&
                  !props.appInstanceById?.appInstanceById?.config,
              })}
            >
              {createDuration(contentData)}
              {props.type === RefType.FILE && contentData?.imageSet ? (
                <span className="document-type">per page</span>
              ) : null}
            </div>
          )}
          <div
            className={classNames(`content-schedule`, {
              "hide-action":
                props.type === RefType.APP &&
                !props.appInstanceById?.appInstanceById?.config,
              "editable-shared-channel": props.isEditableSharedChannel,
            })}
          >
            <MultilineSchedulePicker
              data-testid="multiline-schedule"
              callback={handleScheduleUpdate}
              disabled={
                !context.currentPermissions.validateCurrentSpace(
                  "channel",
                  "update",
                )
              }
              readOnly={props.readOnly}
              expired={expired}
              isRevertChange={props.isRevertChange}
              locale={props.locale}
              overlapList={
                props.overlapList !== undefined
                  ? convertOverlapList(props.overlapList)
                  : {}
              }
              rules={props.schedule ?? []}
              showFullscreenOption={props.showFullscreenOption}
              showPrimaryAudioOption={shouldDisplayPrimaryAudio({
                isPrimaryAudioFeatureFlag: context.shouldShowFeature(
                  FEATURE_FLAGS_ENUM.MUTE_AUDIO,
                ),
                contentType: props.type,
                fileById: props.data?.fileById as File | undefined,
                appInstanceById: props.appInstanceById?.appInstanceById as
                  | AvailableAppInstanceFragment
                  | undefined,
              })}
            />
          </div>
        </div>
      </div>
      {context.currentPermissions.validateCurrentSpace("channel", "update") &&
        !props.readOnly && (
          <>
            <div className="content-actions">
              <Icon name="drag" className="drag" />
            </div>
            <Popover
              inverted
              position="top center"
              content={
                <FormattedMessage
                  id="ui_component.common.label.remove"
                  defaultMessage="Remove"
                />
              }
              trigger={
                <Button
                  data-testid="remove-button"
                  icon
                  borderless
                  mini
                  danger
                  onClick={removeContentItem}
                  className="content-remove"
                  disabled={props.disabled}
                >
                  <Icon name="trash" />
                </Button>
              }
            />
          </>
        )}
    </Styled>
  );
};

export default withData(ChannelContentItem);
