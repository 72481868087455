import { useState } from "react";
import { ssm } from "../state/session/ssm";
import {
  ScreenListQueryVariables,
  useScreenListQuery,
  ScreensByScreenGroupIdAndOrgIdFragment,
} from "../types.g";

/**
 * This is use when we need to get all screen without group seperation
 */
export function useScreenListForScreenPicker() {
  const [totalCount, setTotalCount] = useState(0);

  const queryVar: ScreenListQueryVariables = {
    spaceId: ssm.current.settings.spaceId,
    filter: {},
    endCursor: null,
  };

  const getScreeens = (): ScreensByScreenGroupIdAndOrgIdFragment[] => {
    const screenList =
      (screenListQuery?.spaceById?.screenGroupsBySpaceId?.nodes ?? []).reduce(
        (screens, group) => {
          return [...screens, ...group!.screensByScreenGroupIdAndOrgId.nodes];
        },
        [],
      ) ?? [];
    const screenListInDefaultGroup =
      screenListQuery?.defaultScreenGroupBySpaceId
        ?.screensByScreenGroupIdAndOrgId?.nodes ?? [];
    const screenListData = [...screenListInDefaultGroup, ...screenList];
    return screenListData;
  };
  const {
    data: screenListQuery,
    loading,
    fetchMore,
  } = useScreenListQuery({
    variables: queryVar,
    fetchPolicy: "cache-and-network",
    onCompleted: (screenListQuery) => {
      const totalScreensCountNonDefaultGroup = (
        screenListQuery?.spaceById?.screenGroupsBySpaceId?.nodes ?? []
      )
        .map((item) => item?.screensByScreenGroupIdAndOrgId?.totalCount || 0)
        ?.reduce((a, b) => a + b, 0);

      const totalScreenCountDefaultGroup =
        screenListQuery?.defaultScreenGroupBySpaceId
          ?.screensByScreenGroupIdAndOrgId?.totalCount || 0;
      const totalCount =
        totalScreensCountNonDefaultGroup + totalScreenCountDefaultGroup;
      setTotalCount(totalCount);
    },
  });

  const loadMore = () => {
    if (!loading) {
      const endCursor =
        screenListQuery?.spaceById?.screenGroupsBySpaceId.pageInfo.endCursor;
      fetchMore({
        variables: {
          endCursor,
        },
      });
    }
  };

  const hasNextPage =
    screenListQuery?.spaceById?.screenGroupsBySpaceId?.pageInfo &&
    screenListQuery?.spaceById?.screenGroupsBySpaceId.nodes.length !==
      screenListQuery?.spaceById?.screenGroupsBySpaceId.totalCount
      ? screenListQuery.spaceById.screenGroupsBySpaceId.pageInfo.hasNextPage
      : false;

  return { hasNextPage, loadMore, screens: getScreeens(), totalCount, loading };
}
