import {
  Button,
  Dropdown,
  Icon,
  Input,
} from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AppContextState } from "../../../../AppContextProvider";
import { AppContext } from "../../../../AppContextProvider/AppContext";
import { Device, PreviewDeviceList } from "../../../../constants/constants";
import devicesResolutions from "../../../../constants/deviceResolutions";
import {
  getDeviceDimension,
  getDeviceKeyWidthAndHeight,
  isCustomDevice,
} from "../../../../helpers/deviceHelper";
import { PrimaryButton } from "../../../../helpers/whiteLabel";
import { Layout } from "../../../../types.g";
import { Styled } from "./styles";

interface State {
  customWidthValue: number;
  customHeightValue: number;
  deviceKey: string;
}

interface LayoutDimensionDialogProps {
  layout?: Layout | null;
  presetWidth?: number;
  presetHeight?: number;
  onSave: (width: number, height: number, merge?: boolean) => void;
  onCancel: () => void;
}

class LayoutDimensionDialog extends React.PureComponent<
  LayoutDimensionDialogProps,
  State
> {
  public static contextType = AppContext;
  public context: AppContextState;
  private defaultDeviceKey: Device = Device.FULL_HD_1080P;

  constructor(props: LayoutDimensionDialogProps) {
    super(props);

    let deviceKey: string = this.defaultDeviceKey;
    let { width, height } = getDeviceDimension(deviceKey);

    if (props.presetWidth !== undefined && props.presetHeight !== undefined) {
      deviceKey = getDeviceKeyWidthAndHeight(
        props.presetWidth,
        props.presetHeight,
      );
      width = props.presetWidth;
      height = props.presetHeight;
    }

    this.state = {
      customHeightValue: height,
      customWidthValue: width,
      deviceKey,
    };
  }

  public render() {
    const { customHeightValue, customWidthValue, deviceKey } = this.state;
    return (
      <Styled>
        <div className="common-dimension row">
          <Dropdown
            placeholder="Ungroup"
            selection
            fluid
            options={this.getDimensionDropdownItems()}
            defaultValue={deviceKey}
            onChange={this.onChange}
          />
        </div>
        {isCustomDevice(deviceKey) && (
          <div className="custom-dimension row">
            <div className="custom-width">
              <h3 className="heading">Width</h3>
              <Input
                type="number"
                fluid
                placeholder="width"
                labelPosition="right"
                label={{ basic: true, content: "PX" }}
                value={customWidthValue}
                onChange={this.onChangeCustomDimensionWidth}
              />
            </div>
            <Button className="swap-button" onClick={this.handleSwapDimension}>
              <Icon name="switch" />
            </Button>
            <div className="custom-height">
              <h3 className="heading">Height</h3>
              <Input
                type="number"
                fluid
                placeholder="height"
                labelPosition="right"
                label={{ basic: true, content: "PX" }}
                value={customHeightValue}
                onChange={this.onChangeCustomDimensionHeight}
              />
            </div>
          </div>
        )}
        <p className="row notice">
          Please note, changing the Channel Size will affect content already
          playing on your screen(s). Are you sure you want to continue?
        </p>
        <div className="controller row">
          <Button onClick={this.props.onCancel}>
            <FormattedMessage
              id="channel.layout.button.text.not_now"
              defaultMessage="Not Now"
            />
          </Button>
          <PrimaryButton onClick={this.onSave}>
            <FormattedMessage
              id="channel.layout.button.text.confirm"
              defaultMessage="Confirm"
            />
          </PrimaryButton>
        </div>
      </Styled>
    );
  }

  private handleSwapDimension = () => {
    this.setState({
      customHeightValue: this.state.customWidthValue,
      customWidthValue: this.state.customHeightValue,
    });
  };

  private mapDimensionText = () => {
    const { presetWidth, presetHeight } = this.props;
    return PreviewDeviceList.map((item) => {
      const { width, height } = devicesResolutions[item.key];
      let text = `${item.text}`;
      if (
        item.key !== Device.CUSTOM &&
        presetWidth !== undefined &&
        presetHeight !== undefined
      ) {
        text =
          presetWidth > presetHeight
            ? `${item.text} (${width} × ${height})`
            : `${item.text} (${height} × ${width})`;
      }
      return { ...item, text };
    });
  };

  // tslint:disable-next-line: array-type
  private getDimensionDropdownItems = (): {
    key: string;
    text: string;
    value: string;
  }[] => {
    return this.mapDimensionText();
  };

  private onChangeCustomDimensionWidth = (e) => {
    this.setState({ customWidthValue: e.target.value });
  };

  private onChangeCustomDimensionHeight = (e) => {
    this.setState({ customHeightValue: e.target.value });
  };

  private onChange = (
    event: React.SyntheticEvent<any>,
    data: { [key: string]: any },
  ) => {
    const deviceKey: string = data.value;
    this.setState({ deviceKey });
  };

  private onSave = async () => {
    const { deviceKey } = this.state;
    const { onSave, presetHeight, presetWidth } = this.props;
    const { customWidthValue, customHeightValue } = this.state;
    let { width, height } = getDeviceDimension(deviceKey);

    if (isCustomDevice(deviceKey)) {
      // custom
      width = +customWidthValue;
      height = +customHeightValue;
    } else {
      if (
        presetWidth !== undefined &&
        presetHeight !== undefined &&
        presetWidth < presetHeight
      ) {
        [width, height] = [height, width];
      }
    }

    onSave(width, height);
  };
}

export default LayoutDimensionDialog as React.ComponentType<LayoutDimensionDialogProps>;
