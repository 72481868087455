import { find } from "lodash";
import React, { TouchEvent } from "react";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { Site } from "../../types.g";
import { DraggableContainer, SelectionCount } from "../Main/styles";
import { SiteListItemActions } from "../SiteListItem";
import SiteContentItem from "./item";
import { StyledDraggableWrapper } from "./styles";
export interface SiteContentItemProps {
  siteItem: Partial<Site>;
  index: number;
  selectedSiteCallback?: (site: Site) => void;
  onClickSiteCallback: (
    action: SiteListItemActions,
    value: Site,
    event?: React.MouseEvent,
  ) => void;
  selectedSiteItems?: Partial<Site>[];
  secureMediaPolicy: string | undefined;
}

const getItemStyle = (
  snapshot: DraggableStateSnapshot,
  draggableStyle: object | undefined,
) => {
  const { isDragging } = snapshot;
  return {
    ...draggableStyle,
    backgroundColor: "#FFFFFF",
    boxShadow: isDragging ? "0 2px 15px 0 rgba(0,0,0,0.15)" : undefined,
  };
};

class SiteContentItemWrapper extends React.Component<
  SiteContentItemProps,
  any
> {
  public handleDragSiteItem = (siteItem) => {
    this.props.selectedSiteCallback?.(siteItem);
  };
  public handleOnClickSite = (
    action: SiteListItemActions,
    value: Site,
    event?: React.MouseEvent,
  ) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.props.onClickSiteCallback(action, value, event);
  };
  public render() {
    const { siteItem, index } = this.props;
    const siteContent = (
      <SiteContentItem
        className={
          this.props.selectedSiteItems &&
          find(this.props.selectedSiteItems, { id: siteItem.id })
            ? "selected"
            : ""
        }
        site={siteItem}
        onClickSiteCallback={this.handleOnClickSite}
        secureMediaPolicy={this.props.secureMediaPolicy}
      />
    );
    return (
      <StyledDraggableWrapper key={siteItem.id} className="row">
        <Draggable
          draggableId={siteItem.id}
          index={index}
          isDragDisabled={false}
        >
          {(
            draggableProvided: DraggableProvided,
            draggableSnapshot: DraggableStateSnapshot,
          ): JSX.Element => {
            const onMouseDown = () => {
              this.handleDragSiteItem(siteItem);
            };

            const onTouchStart = (() => {
              if (!draggableProvided.dragHandleProps) {
                // @ts-ignore
                return onTouchStart;
              }
              return (event: TouchEvent) => {
                if (draggableProvided.dragHandleProps) {
                  draggableProvided.dragHandleProps.onTouchStart(event);
                  this.handleDragSiteItem(siteItem);
                }
              };
            })();

            return (
              <>
                <div
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                  {...draggableProvided.dragHandleProps}
                  onMouseDown={onMouseDown}
                  onTouchStart={onTouchStart}
                  style={getItemStyle(
                    draggableSnapshot,
                    draggableProvided.draggableProps.style,
                  )}
                >
                  <DraggableContainer>
                    {siteContent}
                    {draggableSnapshot.isDragging &&
                    this.props.selectedSiteItems &&
                    this.props.selectedSiteItems.length > 1 ? (
                      <SelectionCount>
                        {this.props.selectedSiteItems.length}
                      </SelectionCount>
                    ) : null}
                  </DraggableContainer>
                </div>
                <DraggableContainer>
                  {draggableSnapshot.isDragging && siteContent}
                </DraggableContainer>
              </>
            );
          }}
        </Draggable>
      </StyledDraggableWrapper>
    );
  }
}

export default SiteContentItemWrapper;
