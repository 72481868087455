import {
  Loader,
  LoaderBar,
  Theme,
} from "@screencloud/screencloud-ui-components";
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { FormattedMessage } from "react-intl";
import { appInstanceIconUrl } from "src/helpers/appHelper";
import { useAllAppInstances } from "src/hooks/useAllAppinstances";
import { useAppContext } from "src/hooks/useAppContext";
import EmptyState from "../EmptyState";
import { AvailableAppInstanceFragment } from "../../types.g";
import AppTabPaneItem, {
  AppTabPaneItemActionEnum,
  AppTabPaneItemActions,
} from "../AppTabPane/appItem";
import { Styled } from "../AppTabPane/styles";
import { PropsWithChildren } from "react";
import { useSearchCanvasInstancesPicker } from "src/hooks/useSearchCanvasAppinstances";

export interface CanvasAppTabPaneListProps {
  searchTerms: string;
  addItemToPlaylist: (
    app: AvailableAppInstanceFragment,
    action: AppTabPaneItemActions,
  ) => void;
}

export function CanvasAppTabPaneList(
  props: PropsWithChildren<CanvasAppTabPaneListProps>,
) {
  const { canvasAppId, modal } = useAppContext();
  const { addItemToPlaylist, searchTerms } = props;
  const { appInstances, loading, renderFetchMoreButton } = useAllAppInstances({
    appId: canvasAppId,
  });
  const searchAppInstances = useSearchCanvasInstancesPicker(searchTerms);
  const onShowAppconfigure = async (app: AvailableAppInstanceFragment) => {
    if (app) {
      const { success } = await modal.openAppConfigure(
        app.id,
        undefined,
        undefined,
        undefined,
        <FormattedMessage id="apps.configure" defaultMessage="Configure" />,
      );
      if (success && addItemToPlaylist) {
        addItemToPlaylist(app, AppTabPaneItemActionEnum.PREVIEW);
      }
    }
  };

  const getFilterAppBySearch = () => {
    if (searchTerms.length > 0 && searchAppInstances?.appInstances) {
      return searchAppInstances.appInstances;
    } else if (appInstances) {
      return appInstances;
    } else {
      return [];
    }
  };

  if (appInstances || searchAppInstances?.appInstances) {
    const hasSearchTerm = searchTerms.length;
    const appInstances: any = getFilterAppBySearch();

    return (
      <Styled>
        {loading ? (
          <div className="app-loader">
            <Loader inline size="small" />
          </div>
        ) : (
          <>
            {appInstances.length
              ? appInstances.map((appNode, idx) => {
                  const iconUrl = appInstanceIconUrl(appNode);
                  return (
                    <Droppable
                      isDropDisabled={true}
                      droppableId={`app-${appNode.id}`}
                      key={appNode.id}
                    >
                      {(
                        droppableProvided: DroppableProvided,
                        droppableSnapshot: DroppableStateSnapshot,
                      ) => (
                        <div
                          className="column app-item"
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                          style={{
                            backgroundColor: droppableSnapshot.isDraggingOver
                              ? Theme.color.silver
                              : "transparent",
                          }}
                        >
                          <AppTabPaneItem
                            isCanvas={true}
                            iconUrl={iconUrl}
                            addItemToPlaylist={addItemToPlaylist}
                            onShowAppConfigure={onShowAppconfigure}
                            index={idx}
                            app={appNode}
                          />
                          {droppableProvided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  );
                })
              : null}
            {hasSearchTerm
              ? searchAppInstances.renderFetchMoreButton
              : renderFetchMoreButton}
            {hasSearchTerm &&
            !loading &&
            !searchAppInstances.loading &&
            appInstances.length === 0 ? (
              <EmptyState section="app-sidebar" cover={false} className="empty">
                <h3>
                  <FormattedMessage
                    id="common.text.no_result_found"
                    defaultMessage="No results found"
                  />
                </h3>
              </EmptyState>
            ) : null}
            {!hasSearchTerm && !loading && appInstances.length === 0 ? (
              <EmptyState section="app-sidebar" cover={false} className="empty">
                <h3>
                  <FormattedMessage
                    id="canvas.no_canvas_yet"
                    defaultMessage="No canvas yet"
                  />
                </h3>
                <p>
                  <FormattedMessage
                    id="canvas.create_your_first_canvas"
                    defaultMessage="Create yout first canvas"
                  />
                </p>
              </EmptyState>
            ) : null}
          </>
        )}
      </Styled>
    );
  } else {
    return <LoaderBar />;
  }
}
