import { TimeInput } from "@screencloud/screencloud-ui-components";
import { Dropdown } from "semantic-ui-react";
import { useEffect, useState } from "react";
import {
  getExpirationDate,
  ScheduleOptionValue,
} from "../../helpers/scheduleHelper";
import styled from "styled-components";

interface Props {
  onExpireDateChange: (expirationDate: Date | undefined) => void;
}

// Our TimeInput component got overriden styles from semantic-ui-react, so this is required to make the component renders correctly
const StyledTimeInput = styled.div`
  .increase-btn,
  .decrease-btn,
  .increase-btn:hover,
  .decrease-btn:hover {
    border: none;
    box-shadow: none;
  }
`;

export const ScheduleOptions = (props: Props) => {
  const { onExpireDateChange } = props;
  const [scheduleOptionValue, setScheduleOptionValue] = useState(
    ScheduleOptionValue.Indefinitely,
  );

  const DEFAULT_CUSTOM_DURATION = "00:01";

  const getTotalMinuteFromCustomValue = (hourMinuteValue: string) => {
    const [minutes, seconds] = hourMinuteValue.split(":");
    const totalMinutes = Number(minutes) * 60 + Number(seconds); // convert to number first to cal the actual minutes
    return totalMinutes.toString();
  };

  const onTimeValuechange = (customDurationValue: string) => {
    setScheduleOptionValue(getTotalMinuteFromCustomValue(customDurationValue));
  };

  const onDropdownChange = (e, t) => {
    const defaultValue = getTotalMinuteFromCustomValue(DEFAULT_CUSTOM_DURATION);
    const timeValue = t.value === "custom" ? defaultValue : t.value;
    const expirationDate = getExpirationDate(timeValue);
    onExpireDateChange(expirationDate);
  };

  useEffect(() => {
    if (scheduleOptionValue !== ScheduleOptionValue.Indefinitely) {
      const expirationDate = getExpirationDate(scheduleOptionValue);
      onExpireDateChange(expirationDate);
    }
  }, [scheduleOptionValue]);

  return (
    <Dropdown
      data-testid="cast-expires-at"
      inline
      options={[
        {
          key: "indefinitely",
          text: "indefinitely",
          content: "Indefinitely",
          value: ScheduleOptionValue.Indefinitely,
        },
        {
          key: "for-5-minutes",
          text: "for 5 minutes",
          content: "For 5 minutes",
          value: "5",
        },
        {
          key: "for-30-minutes",
          text: "for 30 minutes",
          content: "For 30 minutes",
          value: "30",
        },
        {
          key: "for-an-hour",
          text: "for 1 hour",
          content: "For 1 hour",
          value: "60",
        },
        {
          key: "custom-duration",
          text: (
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              <span style={{ marginRight: "5px", fontWeight: "bold" }}>
                for
              </span>
              <StyledTimeInput>
                <TimeInput
                  autoFocus={true}
                  value="00:01"
                  maxValue="24:00"
                  minValue="00:01"
                  defaultValue={DEFAULT_CUSTOM_DURATION}
                  onTimeValueChange={onTimeValuechange}
                />
              </StyledTimeInput>
            </div>
          ),
          value: "custom",
          content: "Custom duration",
        },
      ]}
      onChange={onDropdownChange}
      defaultValue={scheduleOptionValue}
    />
  );
};
