import * as _ from "lodash";
import { getNamespacedStore } from "./getNamespacedStore";
import { ChangeSubscriber, Subscribable } from "./Subscribable";
import { StoreJsAPILike } from "./types";

export interface TokenSessionConfig<
  TClaims extends object,
  TSettings extends object,
> {
  key: string;
  store: StoreJsAPILike;
  defaultClaims: TClaims;
  defaultSettings: TSettings;
  parseTokenFn: (token?: string) => TClaims;
  initialToken?: string;
  initialSettings?: TSettings;
}

export type TokenSessionSubscriber<
  TClaims extends object,
  TSettings extends object,
> = ChangeSubscriber<{
  claims: TClaims;
  settings: TSettings;
}>;

enum Keys {
  TOKEN = "token",
  SETTINGS = "settings",
}

export class TokenSession<
  TClaims extends { exp?: number },
  TSettings extends object,
> extends Subscribable<{
  claims: TClaims;
  settings: TSettings;
}> {
  get initialized() {
    return this._token !== undefined;
  }

  get expired(): boolean {
    const { exp } = this._claims;
    return !!exp && (exp - 315) * 1000 <= Date.now();
    // subtract a few seconds of lifetime to ensure refresh is done in time
    // 5min clockskew + 15 seconds
  }

  get token() {
    return this._token;
  }

  get authorization(): string | undefined {
    return this._token ? `Bearer ${this._token}` : undefined;
  }

  get claims(): TClaims {
    return this._claims;
  }

  get settings() {
    return this._settings;
  }

  get key() {
    return this._key;
  }

  protected _token?: string; // JWT
  protected _claims: TClaims;
  protected _settings: TSettings;
  protected _key: string;
  protected store: StoreJsAPILike; // prefixed stored!
  protected parseToken: (token?: string) => TClaims;
  protected defaultClaims: TClaims;
  protected defaultSettings: TSettings;

  constructor(config: TokenSessionConfig<TClaims, TSettings>) {
    super({
      valueFn: () => ({
        claims: this._claims,
        settings: this._settings,
      }),
    });
    this.store = getNamespacedStore({
      namespace: config.key,
      store: config.store,
    });
    this._key = config.key;
    this.parseToken = config.parseTokenFn;
    this.defaultClaims = config.defaultClaims;
    this.defaultSettings = config.defaultSettings || ({} as any);
    this._claims = config.defaultClaims;
    this._settings = config.defaultSettings;

    this.init(config.initialToken, config.initialSettings);
  }

  public unwatch() {
    // unwatch everything in this session's namespace
    try {
      this.store.each((v, k) => {
        this.store.unwatch(k);
      });
    } catch (error) {
      // ignore error here
      console.log("TokenSession: token already wipe out");
    }
  }

  public watch() {
    // Token was updated
    this.store.watch(Keys.TOKEN, (val) => {
      try {
        this.setToken(val || undefined);
      } catch (e) {
        console.log("stored token was invalid or outdated from [watch]", e);
        this.destroy();
      }
    });
    // Settings were updated
    this.store.watch(Keys.SETTINGS, (val) => {
      this.setSettings(val || this.defaultSettings);
    });
  }

  public destroy() {
    this.store.each((v, k) => {
      this.store.remove(k);
    });
    this.setToken(undefined);
    this.setSettings(undefined);
  }

  public setToken(token?: string): void {
    token = token || undefined; // ensure falsy values are converted to undefined

    // Convert the tokens to Uint8Array instances
    const textEncoder = new TextEncoder();
    const tokenArray = textEncoder.encode(token || "");
    const existingTokenArray = textEncoder.encode(this._token || "");

    // Use a custom function to compare Uint8Arrays
    if (
      tokenArray.length === existingTokenArray.length &&
      _.isEqual(tokenArray, existingTokenArray)
    ) {
      return;
    }

    // update claims first (as they might throw!!!)
    this._claims = Object.freeze(this.parseToken(token));
    // ... then save the token
    this._token = token;

    // save to store (which will propagate to other tabs)
    if (!token) {
      this.store.remove(Keys.TOKEN);
      this.setSettings(); // reset settings if token is nullified
    } else {
      this.store.set(Keys.TOKEN, token);
    }

    // inform subscribers
    this.trigger();
  }

  public setSettings(settings?: TSettings): void {
    settings = settings || this.defaultSettings; // reset to defaults on undefined
    if (_.isEqual(this._settings, settings)) {
      return;
    }

    // update local settings
    this._settings = Object.freeze({ ...settings });

    // save to store (which will propagate to other tabs)
    this.store.set(Keys.SETTINGS, settings);

    this.trigger();
  }

  public updateSettings(settings: Partial<TSettings>): void {
    this.setSettings({
      ...this._settings,
      ...settings,
    });
  }

  /**
   * Optimistically updates a claim, which will change the cached value
   * It will be overwritten automatically on next token update and should
   * be used for optimistic responses only!
   */
  public updateClaim<K extends keyof TClaims>(key: K, value: TClaims[K]) {
    // if everything is alright, save both value and claims
    this._claims = Object.freeze({
      ...this._claims,
      [key]: value,
    });

    // call subs
    this.trigger();
  }

  protected init(initialToken?: string, initialSettings?: TSettings) {
    if (!this.initialized) {
      try {
        this.setToken(initialToken || this.store.get(Keys.TOKEN));
        this.setSettings(initialSettings || this.store.get(Keys.SETTINGS));
      } catch (e) {
        console.log("stored token was invalid or outdated from [init]", e);
        this.destroy();
      }
      // init Subscribable.prevValue as well
      this.prevValue = Object.freeze({
        claims: this._claims,
        settings: this._settings,
      });
      this.watch();
    }
  }
}
