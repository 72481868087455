import Feedback from "./feedback";

export type FeedbackTypes = "INVALID_USER" | "ERROR";

interface SiteFeedbackModalProps {
  type: FeedbackTypes;
}

/**
 * This component is used to display the feedback messages for actions carried out
 * with the site context menu such as rerecording site journeys, updating credentials
 * has its own modal component to display feedback.
 *
 * @param props
 * @returns
 */
const SiteFeedbackModal = (props: SiteFeedbackModalProps) => {
  const { type } = props;
  return (
    <div>
      {type === "INVALID_USER" && <Feedback type="INVALID_USER" />}
      {type === "ERROR" && <Feedback type="ERROR" />}
    </div>
  );
};

export default SiteFeedbackModal;
