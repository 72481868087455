import { PlaylistAndChannelAssociationsFragment } from "src/types.g";

export const shouldShowFindAndReplaceButton = (
  associations?: PlaylistAndChannelAssociationsFragment[],
) => {
  if (!associations) return false;
  return associations.some(
    (a) => a.channelByFromChannelAndOrgId || a.playlistByFromPlaylistAndOrgId,
  );
};
