import { Icon, Input } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AppContext } from "../../../AppContextProvider/AppContext";
import { AppContextType } from "../../../AppContextProvider/type";
import { PrimaryButton } from "../../../helpers/whiteLabel";
import {
  AllSpaceIdsAndNamesDocument,
  AllSpacesDocument,
  CreateSpaceMutationVariables,
  SearchSpacesDocument,
  Space,
} from "../../../types.g";
import { compose } from "../../../utils/compose";
import { ApolloProps, WithData } from "./apollo";
import { Styled } from "./styles";
export interface State {
  name: string;
  isNameDuplicate: boolean;
}

interface CreateSpaceSuccessResult {
  isSuccess: boolean;
  space: Space;
}

interface CreateSpaceFailResult {
  isSuccess: boolean;
  isNameDuplicate: boolean;
}

type Props = ApolloProps & {
  onSpaceCreated: (space: Space) => void;
  query?: string;
};

export class CreateSpace extends React.PureComponent<Props, State> {
  public static contextType = AppContext;
  public context: AppContextType;

  public state: Readonly<State> = {
    isNameDuplicate: false,
    name: "",
  };

  constructor(props: Props) {
    super(props);
  }

  public createSpace = (
    name: string,
  ): Promise<CreateSpaceSuccessResult | CreateSpaceFailResult> => {
    const { query } = this.props;
    const refetchSearch = !query
      ? []
      : [{ query: SearchSpacesDocument, variables: { query } }];
    const createSpaceInput: CreateSpaceMutationVariables = {
      input: {
        env: {},
        groupIds: [],
        name: name.trim(),
      },
    };

    return this.props
      .createSpace({
        variables: createSpaceInput,
        refetchQueries: [
          { query: AllSpacesDocument },
          { query: AllSpaceIdsAndNamesDocument },
        ].concat(refetchSearch),
      })
      .then((result) => {
        const space = result!.data!.createSpace!.space as Space;

        return { isSuccess: true, space } as CreateSpaceSuccessResult;
      })
      .catch((result) => {
        const errMessage = result?.graphQLErrors?.["0"]?.message;
        if (
          errMessage ===
          'duplicate key value violates unique constraint "space_org_id_name_key"'
        ) {
          return { isSuccess: false, isNameDuplicate: true };
        }
        return { isSuccess: false, isNameDuplicate: false };
      });
  };

  public onNameChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  public handleSaveNewSpace = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    const { name } = this.state;
    const { onSpaceCreated } = this.props;
    const createSpaceResult = await this.createSpace(name);

    if (createSpaceResult.isSuccess) {
      const { space } = createSpaceResult as CreateSpaceSuccessResult;
      onSpaceCreated(space);
    } else {
      const { isNameDuplicate } = createSpaceResult as CreateSpaceFailResult;
      this.setState({ isNameDuplicate });
    }
  };

  public render() {
    const { formatMessage } = this.context.intl;
    const { isNameDuplicate } = this.state;
    return (
      <Styled>
        <form
          data-testid="form"
          onSubmit={this.handleSaveNewSpace}
          className="create"
        >
          <div className="row">
            <h3 className="heading">
              <FormattedMessage
                defaultMessage="Name"
                id="admin.space.form_create.name"
              />
            </h3>
            <Input
              fluid
              placeholder={formatMessage({
                defaultMessage: "Space name",
                id: "admin.space.form_create.name",
              })}
              onChange={this.onNameChange}
              value={this.state.name}
              autoFocus
              error={isNameDuplicate}
              icon={isNameDuplicate ? <Icon name="warning" /> : null}
            />
            {isNameDuplicate && (
              <div className="error" data-testid="error-name-duplicate">
                <FormattedMessage
                  defaultMessage="This name already exists."
                  id="admin.space.form_create.error_exist_name"
                />
              </div>
            )}
          </div>
          <div className="md-footer">
            <PrimaryButton
              data-testid="btn-create"
              className="btn-create"
              type="submit"
              disabled={(this.state.name?.trim() ?? "") === ""}
            >
              <FormattedMessage
                id="admin.space.form_create.create"
                defaultMessage="Create Space"
              />
            </PrimaryButton>
          </div>
        </form>
      </Styled>
    );
  }
}

export default compose(WithData)(CreateSpace);
