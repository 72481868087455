import { ApolloQueryResult } from "@apollo/client";
import apolloClient from "../apolloClient";

// for recalculate casted screen
import { UUID } from "@screencloud/uuid";
import { CurrentFeatureFlag } from "../../constants/featureFlag";
import {
  AllSpaceIdsAndNamesDocument,
  AllSpaceIdsAndNamesQuery,
  AllSpaceIdsAndNamesQueryVariables,
  AllSpacesDocument,
  AllSpacesQuery,
  AllSpacesQueryVariables,
  AllWhiteLabelsDocument,
  AllWhiteLabelsQuery,
  AllWhiteLabelsQueryVariables,
  AppInstanceByIdDocument,
  AppInstanceByIdQuery,
  AppInstanceByIdQueryVariables,
  AppInstanceByIdAssociationsDocument,
  AppInstanceByIdAssociationsQuery,
  AppInstanceByIdAssociationsQueryVariables,
  CanvasAppDocument,
  CanvasAppQuery,
  CanvasAppQueryVariables,
  CastedScreensCountDocument,
  CastedScreensCountQuery,
  CastedScreensCountQueryVariables,
  Channel,
  ChannelByIdDocument,
  ChannelByIdQuery,
  ChannelByIdQueryVariables,
  CreateOrUpdateWhiteLabelDocument,
  CreateOrUpdateWhiteLabelInput,
  CreateOrUpdateWhiteLabelMutation,
  CurrentFeatureFlagsDocument,
  CurrentOrgDocument,
  CurrentOrgQuery,
  CurrentOrgQueryVariables,
  CurrentOrgStartableChannelsDocument,
  CurrentOrgStartableChannelsQuery,
  CurrentOrgStartableChannelsQueryVariables,
  CurrentUserDocument,
  CurrentUserQuery,
  CurrentUserQueryVariables,
  Exact,
  FileByIdAssociationsDocument,
  FileByIdAssociationsQuery,
  FileByIdAssociationsQueryVariables,
  FileByIdDocument,
  FileByIdQuery,
  FileByIdQueryVariables,
  FontByIdAssociationsQuery,
  FontByIdAssociationsDocument,
  FontByIdAssociationsQueryVariables,
  LinkByIdDocument,
  LinkByIdQuery,
  LinkByIdQueryVariables,
  Maybe,
  Org,
  PlaylistByIdDocument,
  PlaylistByIdQuery,
  PlaylistByIdQueryVariables,
  ScreenByIdDocument,
  ScreenByIdQuery,
  ScreenByIdQueryVariables,
  SiteByIdForContentDocument,
  SiteByIdForContentQuery,
  SiteByIdForContentQueryVariables,
  Space,
  UpdateUserSettingsDocument,
  UpdateUserSettingsMutationVariables,
  User,
  LinkByIdAssociationsQuery,
  LinkByIdAssociationsQueryVariables,
  LinkByIdAssociationsDocument,
  WhiteLabel,
  EmbedsByChannelIdDocument,
  EmbedsByChannelIdQueryVariables,
  EmbedsByChannelIdQuery,
  OrgConnection,
  OrgConnectionByOrgIdQuery,
  OrgConnectionByOrgIdQueryVariables,
  OrgConnectionByOrgIdDocument,
  PlaylistListQuery,
  PlaylistListDocument,
  ContextProviderDataQuery,
  ContextProviderDataDocument,
  CreateSignedAppManagementJwtMutation,
  CreateSignedAppManagementJwtMutationVariables,
  CreateSignedAppManagementJwtDocument,
} from "../../types.g";
import { getBillingFeatureFlags } from "src/billinglatest/clients/service.client";

async function getAppInstanceById(
  id: string,
): Promise<ApolloQueryResult<AppInstanceByIdQuery> | null> {
  if (id && apolloClient) {
    const queryVar: AppInstanceByIdQueryVariables = {
      id,
    };

    return apolloClient.query({
      fetchPolicy: "network-only",
      query: AppInstanceByIdDocument,
      variables: queryVar,
    });
  }
  return Promise.resolve(null);
}

async function updateCastedScreen(
  spaceId: string | null,
): Promise<ApolloQueryResult<CastedScreensCountQuery> | null> {
  if (spaceId && apolloClient) {
    const queryVar: CastedScreensCountQueryVariables = {
      spaceId,
    };

    return apolloClient.query({
      fetchPolicy: "network-only",
      query: CastedScreensCountDocument,
      variables: queryVar,
    });
  }
  return Promise.resolve(null);
}

async function getFeatureFlags(spaceId: string): Promise<CurrentFeatureFlag> {
  const { data } = await apolloClient.query({
    fetchPolicy: "network-only",
    query: CurrentFeatureFlagsDocument,
  });

  let billingFlags: string[] = [];

  billingFlags = (await getBillingFeatureFlags(spaceId)) || [];

  const currentFeatureFlags = data.currentFeatureFlags.concat(
    billingFlags.filter((item) => data.currentFeatureFlags.indexOf(item) < 0),
  );

  return currentFeatureFlags;
}

async function getCurrentOrg(): Promise<Maybe<Org>> {
  try {
    const { data } = await apolloClient.query<
      CurrentOrgQuery,
      CurrentOrgQueryVariables
    >({
      fetchPolicy: "network-only",
      query: CurrentOrgDocument,
    });
    return data?.currentOrg as Org;
  } catch (err) {
    return Promise.resolve(null);
  }
}

/**
 * current user can return null in case we are migrating
 */
async function getCurrentUser(): Promise<Maybe<User>> {
  try {
    const { data } = await apolloClient.query<
      CurrentUserQuery,
      CurrentUserQueryVariables
    >({
      fetchPolicy: "network-only",
      query: CurrentUserDocument,
    });
    return data?.currentUser as Maybe<User>;
  } catch (error) {
    return Promise.resolve(null);
  }
}

async function getAllSpaces(): Promise<Space[] | null> {
  try {
    const { data } = await apolloClient.query<
      AllSpacesQuery,
      AllSpacesQueryVariables
    >({
      fetchPolicy: "network-only",
      query: AllSpacesDocument,
    });
    return data?.allSpaces?.nodes as Space[];
  } catch (error) {
    return null;
  }
}

async function getAllSpaceIdsAndNames(): Promise<Space[] | null> {
  try {
    const { data } = await apolloClient.query<
      AllSpaceIdsAndNamesQuery,
      AllSpaceIdsAndNamesQueryVariables
    >({
      fetchPolicy: "network-only",
      query: AllSpaceIdsAndNamesDocument,
    });
    return data?.allSpaces?.nodes as Space[];
  } catch (error) {
    return null;
  }
}

async function updateUserSettings(
  variables: UpdateUserSettingsMutationVariables,
): Promise<User> {
  const { data } = await apolloClient.mutate({
    mutation: UpdateUserSettingsDocument,
    variables,
  });
  return data.updateUserSettings.user;
}

async function getCanvasAppId(): Promise<Maybe<User> | null> {
  try {
    const { data } = await apolloClient.query<
      CanvasAppQuery,
      CanvasAppQueryVariables
    >({
      fetchPolicy: "network-only",
      query: CanvasAppDocument,
    });
    return data.allApps?.nodes[0].id;
  } catch (error) {
    return null;
  }
}

async function updatePlaylistDetail(
  playlistId: string | null,
): Promise<ApolloQueryResult<PlaylistByIdQuery> | null> {
  if (playlistId && apolloClient) {
    const queryVar: PlaylistByIdQueryVariables = {
      id: playlistId,
    };

    return apolloClient.query({
      fetchPolicy: "network-only",
      query: PlaylistByIdDocument,
      variables: queryVar,
    });
  }
  return Promise.resolve(null);
}

async function updateChannelDetail(
  channelId: string | null,
): Promise<ApolloQueryResult<ChannelByIdQuery> | null> {
  if (channelId && apolloClient) {
    const queryVar: ChannelByIdQueryVariables = {
      id: channelId,
    };

    return apolloClient.query({
      fetchPolicy: "network-only",
      query: ChannelByIdDocument,
      variables: queryVar,
    });
  }
  return Promise.resolve(null);
}

async function updateFileDetail(
  fileId: string | null,
): Promise<ApolloQueryResult<FileByIdQuery> | null> {
  if (fileId && apolloClient) {
    const queryVar: FileByIdQueryVariables = {
      id: fileId,
    };

    return apolloClient.query({
      fetchPolicy: "network-only",
      query: FileByIdDocument,
      variables: queryVar,
    });
  }
  return Promise.resolve(null);
}

async function updateLinkDetail(
  linkId: string | null,
): Promise<ApolloQueryResult<LinkByIdQuery> | null> {
  if (linkId && apolloClient) {
    const queryVar: LinkByIdQueryVariables = {
      id: linkId,
    };

    return apolloClient.query({
      fetchPolicy: "network-only",
      query: LinkByIdDocument,
      variables: queryVar,
    });
  }
  return Promise.resolve(null);
}

async function updateAppInstanceDetail(
  appInstanceId: string | null,
): Promise<ApolloQueryResult<AppInstanceByIdQuery> | null> {
  if (appInstanceId && apolloClient) {
    const queryVar: AppInstanceByIdQueryVariables = {
      id: appInstanceId,
    };

    return apolloClient.query({
      fetchPolicy: "network-only",
      query: AppInstanceByIdDocument,
      variables: queryVar,
    });
  }
  return Promise.resolve(null);
}

async function updateSiteDetail(
  siteId: string | null,
): Promise<ApolloQueryResult<SiteByIdForContentQuery> | null> {
  if (siteId && apolloClient) {
    const queryVar: SiteByIdForContentQueryVariables = {
      id: siteId,
    };

    return apolloClient.query({
      fetchPolicy: "network-only",
      query: SiteByIdForContentDocument,
      variables: queryVar,
    });
  }
  return Promise.resolve(null);
}

async function updateScreenDetail(
  screenId: string | null,
): Promise<ApolloQueryResult<ScreenByIdQuery> | null> {
  if (screenId && apolloClient) {
    const queryVar: ScreenByIdQueryVariables = {
      id: screenId,
    };

    return apolloClient.query({
      fetchPolicy: "network-only",
      query: ScreenByIdDocument,
      variables: queryVar,
    });
  }
  return Promise.resolve(null);
}

async function getFileAssociation(
  fileId: UUID,
): Promise<ApolloQueryResult<FileByIdAssociationsQuery> | null> {
  if (fileId && apolloClient) {
    const queryVar: FileByIdAssociationsQueryVariables = {
      id: fileId,
    };

    return apolloClient.query({
      fetchPolicy: "network-only",
      query: FileByIdAssociationsDocument,
      variables: queryVar,
    });
  }
  return Promise.resolve(null);
}

async function getLinkAssociation(
  linkId: UUID,
): Promise<ApolloQueryResult<LinkByIdAssociationsQuery> | null> {
  if (linkId && apolloClient) {
    const queryVar: LinkByIdAssociationsQueryVariables = {
      id: linkId,
    };

    return apolloClient.query({
      fetchPolicy: "network-only",
      query: LinkByIdAssociationsDocument,
      variables: queryVar,
    });
  }
  return Promise.resolve(null);
}

export async function getPlaylistList(
  spaceId: UUID,
): Promise<ApolloQueryResult<PlaylistListQuery> | null> {
  if (apolloClient) {
    const queryVar = {
      spaceId,
      first: 30,
      endCursor: null,
    };
    return apolloClient.query({
      fetchPolicy: "network-only",
      query: PlaylistListDocument,
      variables: queryVar,
    });
  }
  return Promise.resolve(null);
}

async function getAppInstanceAssociation(
  appId: UUID,
): Promise<ApolloQueryResult<AppInstanceByIdAssociationsQuery> | null> {
  if (appId && apolloClient) {
    const queryVar: AppInstanceByIdAssociationsQueryVariables = {
      id: appId,
    };

    return apolloClient.query({
      fetchPolicy: "network-only",
      query: AppInstanceByIdAssociationsDocument,
      variables: queryVar,
    });
  }
  return Promise.resolve(null);
}

async function getFontAssociationInTheme(
  fontId: UUID,
): Promise<ApolloQueryResult<FontByIdAssociationsQuery> | null> {
  if (fontId && apolloClient) {
    const queryVar: FontByIdAssociationsQueryVariables = {
      fontId,
    };

    return apolloClient.query({
      fetchPolicy: "network-only",
      query: FontByIdAssociationsDocument,
      variables: queryVar,
    });
  }
  return Promise.resolve(null);
}

async function getCurrentOrgStartableChannels(): Promise<Channel[] | null> {
  try {
    const { data } = await apolloClient.query<
      CurrentOrgStartableChannelsQuery,
      CurrentOrgStartableChannelsQueryVariables
    >({
      fetchPolicy: "network-only",
      query: CurrentOrgStartableChannelsDocument,
    });
    return data.currentOrgStartableChannels?.nodes as Channel[];
  } catch (error) {
    return null;
  }
}

// * NOTE: spaceId null means using for Org wide
async function getWhiteLabelBySpaceId(
  spaceId: string | null = null,
): Promise<WhiteLabel | null> {
  try {
    const { data } = await apolloClient.query<
      AllWhiteLabelsQuery,
      AllWhiteLabelsQueryVariables
    >({
      fetchPolicy: "network-only",
      query: AllWhiteLabelsDocument,
    });
    return (
      (data.allWhiteLabels?.nodes.filter(
        (node) => node.spaceId === spaceId,
      )[0] as WhiteLabel) ?? null
    );
  } catch (error) {
    return null;
  }
}

async function createOrUpdateWhiteLabel(
  whiteLabel: Partial<WhiteLabel>,
): Promise<void> {
  await apolloClient.mutate<
    CreateOrUpdateWhiteLabelMutation,
    Exact<{ input: CreateOrUpdateWhiteLabelInput }>
  >({
    mutation: CreateOrUpdateWhiteLabelDocument,
    variables: { input: whiteLabel },
  });
}

async function getEmbedShortId(
  channelId: string | null,
): Promise<string | null> {
  if (channelId && apolloClient) {
    const queryVar: EmbedsByChannelIdQueryVariables = {
      id: channelId,
    };

    const { data } = await apolloClient.query<
      EmbedsByChannelIdQuery,
      EmbedsByChannelIdQueryVariables
    >({
      fetchPolicy: "network-only",
      query: EmbedsByChannelIdDocument,
      variables: queryVar,
    });

    return data.channelById?.embedsByChannelId.nodes[0].shortId ?? null;
  }
  return Promise.resolve(null);
}

async function getOrgConnectionByOrgId(
  orgId: string | null = null,
): Promise<OrgConnection | null> {
  try {
    const { data } = await apolloClient.query<
      OrgConnectionByOrgIdQuery,
      OrgConnectionByOrgIdQueryVariables
    >({
      fetchPolicy: "network-only",
      query: OrgConnectionByOrgIdDocument,
      variables: { orgId },
    });
    return data.orgConnectionByOrgId as OrgConnection;
  } catch (error) {
    return null;
  }
}

async function getContextProviderData(): Promise<ContextProviderDataQuery | null> {
  try {
    const { data } = await apolloClient.query<ContextProviderDataQuery>({
      fetchPolicy: "network-only",
      query: ContextProviderDataDocument,
    });

    return data;
  } catch (error) {
    return null;
  }
}

export async function createAppManagementToken(
  spaceId: string,
): Promise<string> {
  const appManagementTokenResponse = await apolloClient.mutate<
    CreateSignedAppManagementJwtMutation,
    CreateSignedAppManagementJwtMutationVariables
  >({
    mutation: CreateSignedAppManagementJwtDocument,
    variables: {
      input: {
        spaceId,
      },
    },
  });

  const signedToken =
    appManagementTokenResponse.data?.createSignedAppManagementJwt
      ?.signedAppManagementToken;

  if (!signedToken) {
    throw new Error("Failed to create App Management Token");
  }

  return signedToken;
}

export default {
  getAllSpaces,
  getAllSpaceIdsAndNames,
  getAppInstanceById,
  getCurrentOrg,
  getCurrentUser,
  getFeatureFlags,
  updateCastedScreen,
  updateUserSettings,
  getCanvasAppId,
  updatePlaylistDetail,
  updateChannelDetail,
  updateFileDetail,
  updateLinkDetail,
  updateAppInstanceDetail,
  updateSiteDetail,
  updateScreenDetail,
  getFileAssociation,
  getCurrentOrgStartableChannels,
  getWhiteLabelBySpaceId,
  createOrUpdateWhiteLabel,
  getAppInstanceAssociation,
  getLinkAssociation,
  getFontAssociationInTheme,
  getEmbedShortId,
  getOrgConnectionByOrgId,
  getPlaylistList,
  getContextProviderData,
};
