import throttle from "lodash.throttle";
import {
  cloneElement,
  ReactElement,
  Children,
  useState,
  useEffect,
  ReactNode,
} from "react";
export type TrackVisibilityProps = {
  // /**
  // * Define if the visibility need to be tracked once
  // */
  children?: ReactNode;
  once?: boolean;

  // /**
  // * Tweak the throttle interval
  // * Check https://css-tricks.com/debouncing-throttling-explained-examples/ for more details
  // */
  throttleInterval?: number;

  // /**
  // * Additional style to apply
  // */
  style?: object;

  // /**
  // * Additional className to apply
  // */
  className?: string;

  // /**
  // * Define an offset. Can be useful for lazy loading
  // */
  offset?: number;

  // /**
  // * Update the visibility state as soon as a part of the tracked component is visible
  // */
  partialVisibility?: boolean;
  // /**
  // * Reference container for listeners
  // */
  customNodeRef?: any;
  container: any;
  index?: number;
  eventListernerForRefresh?: string;
};

export interface State {
  isVisible: boolean;
}

const TrackVisibility = ({
  once = false,
  throttleInterval = 150,
  offset = 0,
  partialVisibility = false,
  container = null,
  className,
  style,
  children,
  customNodeRef,
  eventListernerForRefresh,
}: TrackVisibilityProps) => {
  const [visible, setVisible] = useState(false);
  const customProps = {
    ...(className && { className }),
    ...(style && { style }),
  };

  const isComponentVisible = () => {
    // isComponentVisible might be called from componentDidMount, before component ref is assigned
    if (!nodeRef || !nodeRef.getBoundingClientRect) {
      return;
    }
    const html = document.documentElement;
    const boundingClientRect = nodeRef.getBoundingClientRect();

    const width =
      container?.innerWidth || window.innerWidth || html.clientWidth;
    const height =
      container?.innerHeight || window.innerHeight || html.clientHeight;
    const visible = isVisible(boundingClientRect, width, height);
    if (visible && once) {
      removeListener();
    }
    setVisible(visible);
  };

  const throttleCb = throttle(isComponentVisible, throttleInterval);

  let nodeRef = customNodeRef;
  const setNodeReference = (ref) => (nodeRef = ref);

  let isEventListenerAdded = false;

  useEffect(() => {
    if (!isEventListenerAdded && container) {
      attachListener();
    }

    isComponentVisible();
    return () => {
      removeListener();
    };
  });

  function attachListener() {
    if (!isEventListenerAdded && container) {
      isEventListenerAdded = true;
      container.addEventListener("scroll", throttleCb);
      container.addEventListener("resize", throttleCb);
      eventListernerForRefresh &&
        window.addEventListener(eventListernerForRefresh, throttleCb);
    }
  }

  function removeListener() {
    if (container) {
      isEventListenerAdded = false;
      container.removeEventListener("scroll", throttleCb);
      container.removeEventListener("resize", throttleCb);
      eventListernerForRefresh &&
        window.removeEventListener(eventListernerForRefresh, throttleCb);
    }
  }

  function getChildProps() {
    const childProps = {
      once,
      throttleInterval,
      offset,
      partialVisibility,
      container,
      className,
      style,
      children,
      customNodeRef,
      eventListernerForRefresh,
    };
    return childProps;
  }

  const isVisible = (
    { top, left, bottom, right, width, height },
    windowWidth,
    windowHeight,
  ) => {
    if (top + right + bottom + left === 0) {
      return false;
    }

    const topThreshold = 0 - (offset || 0);
    const leftThreshold = 0 - (offset || 0);
    const widthCheck = windowWidth + offset;
    const heightCheck = windowHeight + offset;

    return partialVisibility
      ? top + height >= topThreshold &&
          left + width >= leftThreshold &&
          bottom - height <= heightCheck &&
          right - width <= widthCheck
      : top >= topThreshold &&
          left >= leftThreshold &&
          bottom <= heightCheck &&
          right <= widthCheck;
  };

  function getChildren() {
    if (typeof children === "function") {
      return children({
        ...getChildProps(),
        isVisible: visible,
      });
    }
    return Children.map(children, (child) =>
      cloneElement(child as ReactElement, {
        ...getChildProps(),
        isVisible: visible,
      }),
    );
  }

  return (
    <div {...customProps} ref={(el) => setNodeReference(el)}>
      {getChildren()}
    </div>
  );
};

export default TrackVisibility;
