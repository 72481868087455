import { DataValue } from "@apollo/client/react/hoc";
import { RefType } from "../../../constants/constants";
import {
  AppInstanceByIdForContentQuery,
  AppInstanceByIdForContentQueryVariables,
  FileByIdForContentQuery,
  FileByIdForContentQueryVariables,
  LinkByIdForContentQuery,
  LinkByIdForContentQueryVariables,
  SiteByIdForContentQuery,
  SiteByIdForContentQueryVariables,
  useAppInstanceByIdForContentQuery,
  useFileByIdForContentQuery,
  useLinkByIdForContentQuery,
  useSiteByIdForContentQuery,
} from "../../../types.g";
import { compose } from "../../../utils/compose";

export interface PlaylistContentItemProps {
  contentId: string;
  type: string;
  fileById?: DataValue<
    FileByIdForContentQuery,
    FileByIdForContentQueryVariables
  >;
  linkById?: DataValue<
    LinkByIdForContentQuery,
    LinkByIdForContentQueryVariables
  >;
  siteById?: DataValue<
    SiteByIdForContentQuery,
    SiteByIdForContentQueryVariables
  >;
  appInstanceById?: DataValue<
    AppInstanceByIdForContentQuery,
    AppInstanceByIdForContentQueryVariables
  >;
}

export const withData = compose(
  (Component) => (props: PlaylistContentItemProps) => {
    const variables: LinkByIdForContentQueryVariables = {
      id: props.contentId,
    };
    const { data } = useLinkByIdForContentQuery({
      variables,
      fetchPolicy: "cache-first",
      skip: props.type !== RefType.LINK,
    });
    return <Component {...props} linkById={data?.linkById} />;
  },
  (Component) => (props: PlaylistContentItemProps) => {
    const variables: SiteByIdForContentQueryVariables = {
      id: props.contentId,
    };
    const { data } = useSiteByIdForContentQuery({
      variables,
      fetchPolicy: "cache-first",
      skip: props.type !== RefType.SITE,
    });
    return <Component {...props} siteById={data?.siteById} />;
  },
  (Component) => (props: PlaylistContentItemProps) => {
    const variables: AppInstanceByIdForContentQueryVariables = {
      id: props.contentId,
    };
    const { data } = useAppInstanceByIdForContentQuery({
      variables,
      fetchPolicy: "cache-first",
      skip: props.type !== RefType.APP,
    });

    const fileData = useFileByIdForContentQuery({
      variables: {
        id: props.contentId,
      },
      fetchPolicy: "cache-first",
      skip: props.type !== RefType.FILE,
    });
    return (
      <Component
        {...props}
        fileById={fileData.data?.fileById}
        appInstanceById={data?.appInstanceById}
      />
    );
  },
);
