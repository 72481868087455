import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useAppContext } from "src/hooks/useAppContext";

const useShareConfirmAction = (defaultShareOption) => {
  const context = useAppContext();
  const [shareOption, setshareOption] = useState("");

  useEffect(() => {
    setshareOption(defaultShareOption);
  }, [defaultShareOption]);

  const confirmAction = async (onConfirm) => {
    const { confirm } = await context.modal.confirm(
      <div className="reset-permissions-modal">
        <FormattedMessage
          id="ui_component.common.label.reset_permissions_confirmation"
          defaultMessage="All spaces permission will be restored to '{permissionType}'?"
          values={{ permissionType: shareOption }}
        />
        <p>
          <FormattedMessage
            id="ui_component.common.label.confirm.reset_permissions.additional_text"
            defaultMessage="This Action Cannot be undone."
          />
        </p>
      </div>,
      {
        confirm: (
          <FormattedMessage
            id="ui_component.common.label.confirm"
            defaultMessage="Confirm"
          />
        ),
        isDanger: false,
      },
    );

    if (confirm) {
      onConfirm?.();
    }
  };

  return { confirmAction };
};

export default useShareConfirmAction;
