import { StudioPermissions } from "@screencloud/studio-permissions";
import { UUID } from "@screencloud/uuid";
import { AppContextState } from "src/AppContextProvider";
import { Space } from "src/types.g";

export type TransferableSpace = {
  key: string;
  text: string;
  value: UUID;
  content: string;
};

export const canTransferScreen = (context: AppContextState): boolean => {
  const isAllowTransferToAnySpace = context.allSpaces.some(
    isSpaceTransferableByContext(context),
  );
  return (
    context.currentPermissions.validateCurrentSpace("screen", "transfer") &&
    isAllowTransferToAnySpace
  );
};

export const getTransferableSpaces = (
  allSpaces: Pick<Space, "id" | "name" | "billingCustomerId">[],
  currentSpace: Pick<Space, "id" | "name" | "billingCustomerId">,
  currentPermissions: StudioPermissions,
) => {
  return allSpaces
    ?.filter((targetSpace) =>
      isSpaceTransferable({ targetSpace, currentSpace, currentPermissions }),
    )
    .map((space) => {
      return {
        key: space?.name,
        text: space?.name,
        value: space?.id,
        content: space?.name,
      };
    });
};

export const getTransferableSpacesByContext = (context: AppContextState) => {
  return context.allSpaces
    ?.filter(isSpaceTransferableByContext(context))
    .map((space) => {
      return {
        key: space?.name,
        text: space?.name,
        value: space?.id,
        content: space?.name,
      };
    });
};

export const getTransferableSpacesByScreen = (
  context: AppContextState,
  spaceIdByScreen: UUID,
): TransferableSpace[] => {
  return context.allSpaces
    ?.filter((space) => {
      return (
        space.id !== spaceIdByScreen &&
        context.currentPermissions.validateSpace(space.id, "screen", "transfer")
      );
    })
    .map((space) => {
      return {
        key: space?.name,
        text: space?.name,
        value: space?.id,
        content: space?.name,
      };
    });
};

interface isSpaceTransferableProps {
  currentSpace: Pick<Space, "billingCustomerId" | "id">;
  targetSpace: Pick<Space, "billingCustomerId" | "id">;
  currentPermissions: StudioPermissions;
}

const isSpaceTransferable = (props: isSpaceTransferableProps) => {
  const { currentSpace, targetSpace, currentPermissions } = props;
  return (
    currentSpace.id !== targetSpace.id &&
    currentSpace.billingCustomerId === targetSpace.billingCustomerId &&
    currentPermissions.validateSpace(targetSpace.id, "screen", "transfer")
  );
};

const isSpaceTransferableByContext =
  (context: AppContextState) =>
  (space: Pick<Space, "billingCustomerId" | "id">): boolean => {
    return isSpaceTransferable({
      currentSpace: context.currentSpace as Space,
      targetSpace: space,
      currentPermissions: context.currentPermissions,
    });
  };
