import { appConfig } from "../appConfig";

/*
  HubSpot API reference
  https://legacydocs.hubspot.com/docs/methods/forms/submit_form
*/
export const hubspotFormSubmit = async (
  formData: any,
  formId?: string,
): Promise<any> =>
  new Promise((resolve) => {
    // Create the new request
    const xhr = new XMLHttpRequest();
    const hbformId = formId ?? appConfig.hubspot.form_id;
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${appConfig.hubspot.account_id}/${hbformId}`;
    const final_data = JSON.stringify(formData);

    xhr.open("POST", url);
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 400) {
        console.log(xhr.responseText); // Returns a 400 error the submission is rejected.
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        console.log(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        console.log(xhr.responseText); // Returns a 404 error if the formGuid isn't found
      }
      resolve(xhr.status);
    };
    // Sends the request
    xhr.send(final_data);
  });
