import { Maybe, Org, User } from "src/types.g";
import Intercom, { hide, trackEvent } from "@intercom/messenger-js-sdk";
import { appConfig } from "src/appConfig";
import { Subscription } from "src/billinglatest/types";
import { toUnixTimestamp } from "./date";

export type IntercomEvent =
  | "studio_screen_pairing_onboarding_complete"
  | "studio_design_screen_onboarding_complete";

export const initIntercom = ({
  user,
  org,
  subscription,
}: {
  user: Maybe<User>;
  org: Maybe<Org>;
  subscription: Maybe<Subscription>;
}) => {
  if (!user || !org || !subscription) {
    return;
  }

  const appId = appConfig.intercomAppId;
  const userId = user.id;
  const name = `${user.givenName} ${user.familyName}`;
  const email = user.email ?? "unknown";
  const createdAt = user.createdAt;
  const orgId = org?.id;
  const orgName = org?.name;

  Intercom({
    app_id: appId,
    user_id: userId,
    name,
    email,
    created_at: createdAt,
    company: {
      id: orgId,
      name: orgName,
      status: subscription.status,
      created_at: org?.createdAt ? toUnixTimestamp(org.createdAt) : undefined,
    },
  });
};

const closeIntercom = () => {
  hide();
};

const disconnectObserver = (
  observer: MutationObserver,
  frameDocument: Document,
) => {
  const checkListRemainingTime = frameDocument.querySelector(
    '[data-testid="checklist-remaining-time"]',
  );
  if (checkListRemainingTime?.innerHTML.includes("Completed")) {
    observer.disconnect();
  }
};

export const trackIntercomEvent = async (eventName: IntercomEvent) => {
  try {
    await trackEvent(eventName);
  } catch (error) {
    console.error("Failed to track Intercom event:", error);
  }
};

export const watchIntercomButton = ({
  buttonName,
  callback,
}: {
  buttonName: string;
  callback: () => void;
}) => {
  const watchTaskList = (frameDocument: Document) => {
    // Create observer for task list changes
    const taskListObserver = new MutationObserver((mutations) => {
      const taskList = frameDocument?.querySelector(
        '[data-testid="checklist-task-list"]',
      );

      if (taskList) {
        // Create a separate observer for the task list content
        const taskListContentObserver = new MutationObserver((mutations) => {
          // Look for "buttonName" buttons by aria-label
          const button = frameDocument?.querySelector(
            `[aria-label="${buttonName}"]`,
          );

          if (button && !button.hasAttribute("data-custom-handler")) {
            // Mark the button as processed
            button.setAttribute("data-custom-handler", "true");

            // Add click handler
            button.addEventListener(
              "click",
              (e) => {
                e.preventDefault();
                e.stopPropagation();
                callback();
                closeIntercom();
              },
              true,
            );
          }
        });

        // Observe the task list content for changes
        taskListContentObserver.observe(taskList, {
          childList: true,
          subtree: true,
          attributes: true,
        });
      }
    });

    // Observe the entire frame document for task list appearance
    taskListObserver.observe(frameDocument, {
      childList: true,
      subtree: true,
    });
  };

  const observer = new MutationObserver((mutations, obs) => {
    const intercomFrame = document.querySelector("#intercom-container iframe");
    if (intercomFrame) {
      const frameDocument = (intercomFrame as HTMLIFrameElement)
        .contentDocument;
      if (frameDocument) {
        watchTaskList(frameDocument);
        disconnectObserver(obs, frameDocument);
      }
    }
  });

  // Main observer for the iframe
  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
};
