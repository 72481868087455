import {
  Button,
  DurationInput,
  Icon,
  ModalSize,
  PlaylistColor,
  Popover,
  TagInput,
} from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { DropdownItemProps } from "semantic-ui-react";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../../AppContextProvider/AppContext";
import {
  DEBOUNCE_TIMEOUT_MS,
  DEFAULT_GLOBAL_DURATION,
  MIN_DURATION_CONTENT,
} from "../../../constants/constants";
import { Scalars, Tag } from "../../../types.g";
import { StyledOption } from "./styles";

export enum PlaylistOptionActionEnum {
  COLOR = "color",
  TAG = "tag",
  DUPLICATE = "duplicate",
  DELETE = "delete",
  DISCARD = "discard",
  DEFAULT_DURATION = "default_duration",
}
export type PlaylistOptionAction =
  | PlaylistOptionActionEnum.COLOR
  | PlaylistOptionActionEnum.TAG
  | PlaylistOptionActionEnum.DUPLICATE
  | PlaylistOptionActionEnum.DELETE
  | PlaylistOptionActionEnum.DISCARD
  | PlaylistOptionActionEnum.DEFAULT_DURATION;
export type PlaylistOptionDataType =
  | string
  | string[]
  | number
  | Scalars["JSON"];
type PlaylistOptionsProps = {
  isEditable: boolean;
  onUpdatePlaylistOption: (
    action: PlaylistOptionAction,
    data?: PlaylistOptionDataType,
  ) => void;
  color: string;
  tagsList: DropdownItemProps[];
  tags: string[];
  content: Scalars["JSON"];
};

type PlaylistOptionsState = {
  defaultDurationData: Scalars["JSON"];
  color: string;
};

class PlaylistOptions extends React.Component<
  PlaylistOptionsProps,
  PlaylistOptionsState
> {
  public static contextType = AppContext;
  public context: AppContextType;
  private debounceUpdatedefaultDuration: any = "";
  constructor(props: PlaylistOptionsProps) {
    super(props);
    this.state = {
      defaultDurationData: this.props.content.props.default_durations,
      color: this.props.color,
    };
  }

  public cancelDebounceUpdateDefaultDuration = () => {
    if (this.debounceUpdatedefaultDuration) {
      clearTimeout(this.debounceUpdatedefaultDuration);
      this.debounceUpdatedefaultDuration = null;
    }
  };

  public handleTagsChanged = (tags: DropdownItemProps[]) => {
    const tagsFormat: Tag[] = tags.map((tag: any) => {
      return tag;
    });
    if (this.props.onUpdatePlaylistOption) {
      this.props.onUpdatePlaylistOption(
        PlaylistOptionActionEnum.TAG,
        tagsFormat.sort(),
      );
    }
  };
  public onTimeChange = (value: string | number, type: string) => {
    const defaultDurations = {
      ...this.state.defaultDurationData,
      [type]: value,
    };

    this.setState({ defaultDurationData: defaultDurations });
  };

  public onFocus = (isFocus: boolean) => {
    this.cancelDebounceUpdateDefaultDuration();
    if (!isFocus) {
      this.debounceUpdatedefaultDuration = setTimeout(() => {
        if (this.props.onUpdatePlaylistOption) {
          this.props.onUpdatePlaylistOption(
            PlaylistOptionActionEnum.DEFAULT_DURATION,
            this.state.defaultDurationData,
          );
        }
      }, DEBOUNCE_TIMEOUT_MS);
    }
  };
  public handlePlaylistColourModal = () => {
    this.context.modal.openModal(
      <PlaylistColor
        callback={(color: string) => {
          this.setState({ color });
          this.props.onUpdatePlaylistOption(
            PlaylistOptionActionEnum.COLOR,
            color,
          );
        }}
        background={this.props.color}
      />,
      "Change Color",
      {
        opts: { size: ModalSize.MEDIUM, disableTitle: false },
      },
    );
  };

  public render() {
    const defaultDuration = this.state.defaultDurationData;
    const imageDuration = defaultDuration.image
      ? defaultDuration.image
      : DEFAULT_GLOBAL_DURATION;
    const appDuration = defaultDuration.app
      ? defaultDuration.app
      : DEFAULT_GLOBAL_DURATION;

    const linkDuration = defaultDuration.link
      ? defaultDuration.link
      : DEFAULT_GLOBAL_DURATION;

    const siteDuration = defaultDuration.site
      ? defaultDuration.site
      : DEFAULT_GLOBAL_DURATION;

    const documentDuration = defaultDuration.document
      ? defaultDuration.document
      : DEFAULT_GLOBAL_DURATION;

    return (
      <StyledOption
        className={!this.props.isEditable ? "readonly wrapper" : "wrapper"}
        data-testid="option-pane"
      >
        <div className="component">
          <Button
            onClick={this.handlePlaylistColourModal}
            cover={this.state.color}
            coverType="color"
          >
            <span className="gradient" />
            <span className="cover-title">
              <FormattedMessage
                data-testid="change-playlist-color-button"
                id="playlists.change_color"
                defaultMessage="Change Color"
              />
            </span>
          </Button>
        </div>
        <div className="component">
          <h3>
            <FormattedMessage id="common.text.tags" defaultMessage="Tags" />
          </h3>
          <TagInput
            tagTitle={`Tags`}
            onTagsChanged={this.handleTagsChanged}
            currentTags={this.props.tags}
            tagsList={this.props.tagsList}
          />
        </div>
        <div className="component">
          <h3>
            <FormattedMessage
              id="playlists.default.duration"
              defaultMessage="Default Duration"
            />
            <Popover
              inverted
              trigger={<Icon name="info" />}
              content={
                <FormattedMessage
                  id="playlists.duration.info"
                  defaultMessage="Set default duration to all content added into current playlist"
                />
              }
              position="top center"
            />
          </h3>

          <div className="default-duration">
            <div className="row">
              <div className="column">
                <FormattedMessage
                  id="playlists.duration.images"
                  defaultMessage="Images"
                />
              </div>
              <div className="column">
                <DurationInput
                  min={MIN_DURATION_CONTENT}
                  showBorder
                  onFocus={this.onFocus}
                  onDurationValueChange={(time) =>
                    this.onTimeChange(time, "image")
                  }
                  value={imageDuration}
                />
              </div>
            </div>
            <div className="row">
              <div className="column">
                <FormattedMessage
                  id="playlists.duration.apps"
                  defaultMessage="Apps"
                />
              </div>
              <div className="column">
                <DurationInput
                  min={MIN_DURATION_CONTENT}
                  showBorder
                  onFocus={this.onFocus}
                  onDurationValueChange={(time) =>
                    this.onTimeChange(time, "app")
                  }
                  value={appDuration}
                />
              </div>
            </div>
            <div className="row">
              <div className="column">
                <FormattedMessage
                  id="playlists.duration.links"
                  defaultMessage="Links"
                />
              </div>
              <div className="column">
                <DurationInput
                  min={MIN_DURATION_CONTENT}
                  showBorder
                  onFocus={this.onFocus}
                  onDurationValueChange={(time) =>
                    this.onTimeChange(time, "link")
                  }
                  value={linkDuration}
                />
              </div>
            </div>
            <div className="row">
              <div className="column">
                <FormattedMessage
                  id="playlists.duration.dashboards"
                  defaultMessage="Dashboards"
                />
              </div>
              <div className="column">
                <DurationInput
                  min={MIN_DURATION_CONTENT}
                  showBorder
                  onFocus={this.onFocus}
                  onDurationValueChange={(time) =>
                    this.onTimeChange(time, "site")
                  }
                  value={siteDuration}
                />
              </div>
            </div>

            <div className="row">
              <div className="column">
                <FormattedMessage
                  id="playlists.duration.documents"
                  defaultMessage="Documents"
                />
              </div>
              <div className="column">
                <DurationInput
                  min={MIN_DURATION_CONTENT}
                  showBorder
                  onFocus={this.onFocus}
                  onDurationValueChange={(time) =>
                    this.onTimeChange(time, "document")
                  }
                  value={documentDuration}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledOption>
    );
  }
}

export default PlaylistOptions as React.ComponentType<PlaylistOptionsProps>;
