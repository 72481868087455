import { DataValue } from "@apollo/client/react/hoc";
import { UUID } from "@screencloud/uuid";
import { UseFolderById } from "../../pages/Media/MediasList/useFolderById";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useModalContext } from "../../AppContextProvider/modals/ModalContext";
import { FolderPathItem } from "../../components/Media/mediaBreadCrumb";
import { RefType } from "../../constants/constants";
import { isRootFolderInCurrentSpace } from "../../helpers/folderHelper";
import { useAppContext } from "../../hooks/useAppContext";
import { useStateSearch } from "../../hooks/useStateSearch";
import { isInRootSharedFolderFromOtherSpaces as isInRootSharedFolderFromOtherSpacesFunc } from "../../pages/Media/MediasList/mediaListHelper";
import { useBreadCrumbFolderPathItems } from "../../pages/Media/MediasList/useBreadCrumbFolderPathItems";
import {
  CreateFileMutationFn,
  CreateLinkInput,
  Exact,
  File,
  Folder,
  SearchMediaQuery,
  UpdateLinkByIdMutationFn,
  withCreateFile,
  withUpdateLinkById,
  useRootFolderBySpaceIdQuery,
} from "../../types.g";
import { compose } from "../../utils/compose";
import { useStateFolderId } from "./useStateFolderId";
import { useMedialist } from "../../hooks/useMediaList";
import { LinkListHeaderActions } from "../LinkListHeader";
import { useCreateLink } from "../../hooks/useCreateLink";
import { ScreenPickerActions } from "../ScreenPicker";
import { isOrgWidePath } from "src/utils/orgWideFeature";
import { useMediaPickerForOrgWide } from "./hooks/useMediaPickerForOrgWide";
import { useAddAppsToPlaylistsMutation } from "../../pages/Apps/AppInstances/hooks/useAddAppsToPlaylist";

export type MediaPickerOptions = {
  allowMediaMimeType?: string[];
  multiple?: boolean;
  menu?: RefType[];
  section?: RefType;
  screenPickerAction: ScreenPickerActions;
  screenId?: UUID;
  spaceId?: UUID;
  onClose?: () => void;
};

export interface PickerProps extends RouteComponentProps<any> {
  createLink: (
    createLinkInput: Exact<{
      input: CreateLinkInput;
    }>,
    orderBy: LinkListHeaderActions,
  ) => Promise<any>;
  updateLinkById: UpdateLinkByIdMutationFn;
  createFile: CreateFileMutationFn;
  screenId?: UUID;
  updateSelectedSection: React.Dispatch<React.SetStateAction<RefType>>;
}
export interface FolderByIdOwnProps
  extends RouteComponentProps<any>,
    UseFolderById {
  fileItemsFavorite: File[];
  folderItemsFavorite: Folder[];
  mediaSearch: string;
  setMediaSearch: (q: string, spaceId?: UUID) => void;
  searchMediaQuery: DataValue<SearchMediaQuery>;
  isSearch: boolean;
  query?: string;
  clearSearch: () => void;
  mediaFiles: File[];
  mediaFolders: Folder[];
  updateFolderId: (folderId: string) => void;
  folderPathItems: FolderPathItem[];
  folderId: string;
  isInRootSharedFolderFromOtherSpaces: boolean;
  isInSharedFolderFromOtherSpaces: boolean;
  shouldShowSharedFolder: boolean;
  isLoading: boolean;
  updateSelectedMediaFiles: React.Dispatch<React.SetStateAction<File[]>>;
  selectedMediaFiles: File[];
  updateSelectedSection: React.Dispatch<React.SetStateAction<RefType>>;
  selectedSection: RefType;
  hasNextPage: boolean;
  allowMediaMimeType?: string[];
  showAddToPlaylists: boolean;
  spaceId?: UUID;
}

export const withData = compose(
  withRouter,
  withUpdateLinkById({
    name: "updateLinkById",
  }),
  withCreateFile({
    name: "createFile",
  }),
  (Component) => (props: any) => {
    const { selectedSection, updateSelectedSection } = useModalContext();
    const { createLink } = useCreateLink();
    return (
      <Component
        {...props}
        createLink={createLink}
        selectedSection={selectedSection}
        updateSelectedSection={updateSelectedSection}
      />
    );
  },
);

export const withFolderData = compose(
  withRouter,
  (Component) => (props: RouteComponentProps<any>) => {
    const { query, searchString, updateSearchString, isSearch, clearSearch } =
      useStateSearch();
    return (
      <Component
        {...props}
        query={query}
        mediaSearch={searchString}
        setMediaSearch={updateSearchString}
        isSearch={isSearch}
        clearSearch={clearSearch}
      />
    );
  },
  (Component) => (props: FolderByIdOwnProps) => {
    const { clearSearch } = props;
    const context = useAppContext();
    const { data } = useRootFolderBySpaceIdQuery({
      fetchPolicy: "cache-and-network",
      variables: {
        spaceId: props?.spaceId,
      },
      skip: !props?.spaceId,
    });

    const { folderId, updateFolderId } = useStateFolderId({
      updateFolderIdCallback: clearSearch,
    });

    const isInRootFolder = isRootFolderInCurrentSpace({ folderId, context });
    const isDifferentRootFolder = data?.spaceById?.rootFolderId !== folderId;

    return (
      <Component
        {...props}
        folderId={
          isDifferentRootFolder &&
          isInRootFolder &&
          data?.spaceById?.rootFolderId
            ? data?.spaceById?.rootFolderId
            : folderId
        }
        updateFolderId={updateFolderId}
      />
    );
  },
  (Component) => (props: FolderByIdOwnProps) => {
    const { isSearch, query, folderId, allowMediaMimeType, spaceId } = props;
    const context = useAppContext();
    const isInRootFolder = isRootFolderInCurrentSpace({ folderId, context });
    const isInRootSharedFolderFromOtherSpaces =
      isInRootSharedFolderFromOtherSpacesFunc(folderId);
    let mediaProps: {
      mediaFiles: File[];
      mediaFolders: Folder[];
      isLoading: boolean;
      shouldShowSharedFolder: boolean;
      loadMore?: () => void;
      isInSharedFolderFromOtherSpaces: boolean;
      renderFetchMorePickerButton: React.ReactNode;
      refetch?: any;
      spaceId?: UUID;
    };

    if (isOrgWidePath()) {
      const data = useMediaPickerForOrgWide({
        query,
        isInRootSharedFolderFromOtherSpaces,
        isSearchMode: isSearch,
        isInRootFolder,
        folderId,
        allowMediaMimeType,
      });
      mediaProps = {
        ...data,
        shouldShowSharedFolder: false,
        isInSharedFolderFromOtherSpaces: false,
        refetch: () => {},
      };
    } else {
      const data = useMedialist({
        query,
        isInRootSharedFolderFromOtherSpaces,
        isSearchMode: isSearch,
        isInRootFolder,
        folderId,
        allowMediaMimeType,
        spaceId,
      });
      mediaProps = { ...data };
    }

    const folderPathItems = useBreadCrumbFolderPathItems({
      folderId,
      isSearch,
      isInRootFolder,
      isInRootSharedFolderFromOtherSpaces,
      isInSharedFolderFromOtherSpaces:
        mediaProps.isInSharedFolderFromOtherSpaces,
    });

    return (
      <Component
        {...props}
        mediaFiles={mediaProps.mediaFiles}
        mediaFolders={mediaProps.mediaFolders}
        renderFetchMorePickerButton={mediaProps.renderFetchMorePickerButton}
        isLoading={mediaProps.isLoading}
        shouldShowSharedFolder={mediaProps.shouldShowSharedFolder}
        folderPathItems={folderPathItems}
        loadMore={mediaProps.loadMore}
        refetch={mediaProps.refetch}
        spaceId={spaceId}
      />
    );
  },
  (Component) => (props: any) => {
    const {
      updateSelectedMediaFiles,
      selectedMediaFiles,
      selectedSection,
      updateSelectedSection,
    } = useModalContext();
    return (
      <Component
        {...props}
        selectedMediaFiles={selectedMediaFiles}
        updateSelectedMediaFiles={updateSelectedMediaFiles}
        selectedSection={selectedSection}
        updateSelectedSection={updateSelectedSection}
      />
    );
  },
  (Component) => (props: FolderByIdOwnProps) => {
    const showAddToPlaylist = useAddAppsToPlaylistsMutation();
    return <Component {...props} showAddToPlaylist={showAddToPlaylist} />;
  },
);

export interface PickerUploadProps {
  createFile: CreateFileMutationFn;
  updateSelectedMediaFiles: React.Dispatch<React.SetStateAction<File[]>>;
}
export const withPickerData = compose(
  withCreateFile({
    name: "createFile",
  }),
  (Component) => (props: any) => {
    const { updateSelectedMediaFiles } = useModalContext();
    return (
      <Component
        {...props}
        updateSelectedMediaFiles={updateSelectedMediaFiles}
      />
    );
  },
);
