import { isUuid } from "@screencloud/uuid";
import { jwtDecode } from "jwt-decode";
import { Locale, View } from "../../constants/constants";
import { Scalars } from "../../types.g";
import { TokenSession } from "./TokenSession";
import { StoreJsAPILike } from "./types";

export enum Roles {
  GUEST = "STUDIO_GUEST",
  USER = "STUDIO_USER",
  ANON = "",
}

export interface SignageJWTClaims {
  // Basic Claims
  exp: number;
  role: Roles;
  email: string;
  family_name: string;
  given_name: string;
  provider: string;
  connection: string;
  // User Claims
  is_owner?: boolean;
  org_id?: string;
  user_id?: string;
  org_name?: string;
  picture?: string;
  // System User Claims
  system_access_id?: Scalars["UUID"];
  system_user_id?: Scalars["UUID"];
  space_ids?: Scalars["UUID"][];
  roles?: Roles[];
}

export interface AnonClaims {
  role: Roles.ANON;
  // undefined for code completion
  exp: undefined;
  email: undefined;
  provider: undefined;
  connection: undefined;
  familyName: undefined;
  picture: undefined;
  givenName: undefined;
  orgId: undefined;
  orgName;
  string;
  userId: undefined;
}

export interface GuestClaims {
  role: Roles.GUEST;
  exp: number;
  email: string;
  familyName?: string;
  givenName?: string;
  provider: string;
  connection: string;
  picture?: string;
  // undefined for code completion
  orgId: undefined;
  orgName: string;
  userId: undefined;
}

export interface UserClaims {
  role: Roles.USER;
  exp: number;
  isOwner: boolean;
  orgId: Scalars["UUID"];
  orgName: string;
  userId: Scalars["UUID"];
  picture?: string;
  email: string;
  familyName?: string;
  givenName?: string;
  provider: string;
  connection: string;
}

export interface SystemUserClaims extends UserClaims {
  systemAccessId: Scalars["UUID"];
  systemUserId: Scalars["UUID"];
}

export type AnyClaims =
  | UserClaims
  | AnonClaims
  | GuestClaims
  | SystemUserClaims;

export function isValidJwtClaimsObject(
  obj: any | SignageJWTClaims,
): obj is SignageJWTClaims {
  // noinspection SuspiciousTypeOfGuard
  return (
    obj &&
    typeof obj === "object" &&
    obj.provider &&
    typeof obj.provider === "string" && // non-empty string
    ((obj.connection && typeof obj.connection === "string") ||
      (obj.provider && typeof (obj.provider === "string"))) && // non-empty string
    (!obj.family_name || typeof obj.family_name === "string") &&
    (!obj.given_name || typeof obj.given_name === "string") &&
    (!obj.picture || typeof obj.picture === "string") &&
    // Guest JWT
    ((obj.role === Roles.GUEST &&
      !obj.user_id &&
      !obj.org_id &&
      !obj.org_name) ||
      // User JWT
      ([Roles.USER].includes(obj.role) &&
        isUuid(obj.org_id) &&
        isUuid(obj.user_id) &&
        obj.org_name &&
        typeof obj.org_name === "string" && // non-empty string
        ((!obj.system_user_id && !obj.system_access_id) ||
          (isUuid(obj.system_user_id) && isUuid(obj.system_access_id)))))
  );
}

export function parseSignageJWT(token?: string): AnyClaims {
  if (!token) {
    return {
      role: Roles.ANON,
    } as AnonClaims;
  }
  // this can throw and that's good!
  const jwtClaims = jwtDecode(token);
  if (!isValidJwtClaimsObject(jwtClaims)) {
    throw new Error("TOKEN_HAS_MISSING_OR_INVALID_CLAIMS");
  }

  const sharedClaims = {
    connection: jwtClaims.connection,
    email: jwtClaims.email,
    exp: jwtClaims.exp,
    familyName: jwtClaims.family_name,
    givenName: jwtClaims.given_name,
    picture: jwtClaims.picture,
    provider: jwtClaims.provider,
    role: jwtClaims.role,
  };

  if (jwtClaims.role === Roles.GUEST) {
    return { ...sharedClaims } as GuestClaims;
  }

  const userClaims = {
    isOwner: jwtClaims.is_owner,
    orgId: jwtClaims.org_id,
    orgName: jwtClaims.org_name,
    userId: jwtClaims.user_id,
  };

  return !jwtClaims.system_user_id
    ? ({
        ...sharedClaims,
        ...userClaims,
      } as UserClaims)
    : ({
        ...sharedClaims,
        ...userClaims,
        systemAccessId: jwtClaims.system_access_id,
        systemUserId: jwtClaims.system_user_id,
      } as SystemUserClaims);
}

export interface StudioSessionConfig {
  key: string;
  store: StoreJsAPILike;
  initialSettings?: StudioSessionSettings;
  initialToken?: string;
}

export interface StudioSessionSettings {
  locale?: Locale;
  spaceId?: Scalars["UUID"];
  spaceName?: string;
  systemOverrideSpaceId?: Scalars["UUID"];
  systemOverrideRole?: Roles;
  channelViewType?: View;
}

export class StudioSession extends TokenSession<
  AnyClaims,
  StudioSessionSettings
> {
  constructor(config: StudioSessionConfig) {
    super({
      ...config,
      defaultClaims: { role: Roles.ANON } as AnonClaims,
      defaultSettings: { locale: Locale.en },
      initialSettings: config.initialSettings,
      initialToken: config.initialToken,
      parseTokenFn: parseSignageJWT,
    });
  }

  public isRole(role: Roles): boolean {
    return this._claims.role === role;
  }

  public isAnyRole(roles: Roles[]): boolean {
    return roles.includes(this._claims.role);
  }

  get isGuest() {
    return this.isRole(Roles.GUEST);
  }

  get isAnon() {
    return this.isRole(Roles.ANON);
  }

  get isUser() {
    return this.isRole(Roles.USER);
  }
  // get isAdmin() {
  //   return this.isRole(Roles.ADMIN)
  // }

  get isOwner() {
    const claims = this.claims as UserClaims;
    return claims.isOwner === true;
  }

  // get isMember() {
  //   return this.isRole(Roles.USER)
  // }

  // get isContributor() {
  //   return this.isRole(Roles.CONTRIBUTOR)
  // }

  // get isAdminOrOwner() {
  //   return this.isAnyRole([Roles.ADMIN, Roles.OWNER])
  // }

  get isSystemUser() {
    return !!(this.claims as SystemUserClaims).systemUserId;
  }

  get isGuestOrAnon() {
    return this.isAnyRole([Roles.GUEST, Roles.ANON]);
  }

  get isLocal() {
    const { provider, connection } = this.claims;
    return provider && connection && provider === "local";
  }

  get isSSO() {
    const { provider, connection } = this.claims;
    return provider && connection && provider !== connection;
  }

  get isSocial() {
    const { provider, connection } = this.claims;
    return provider && connection && provider !== connection;
  }
}
