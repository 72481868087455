import { FetchResult } from "@apollo/client";
import {
  FileUploadProgress,
  FileUploadProgressEnum,
  Icon,
  Loader,
} from "@screencloud/screencloud-ui-components";
import * as filestack from "filestack-js";
import TagManager from "react-gtm-module";

import {
  PickerCustomText,
  PickerDisplayMode,
  PickerTransformationOptions,
} from "filestack-js/build/main/lib/picker";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { appConfig } from "../../appConfig";
import { AppContext } from "../../AppContextProvider/AppContext";
import {
  FilestackAllowedFileExtensionsWithAudio,
  FileStackSource,
} from "../../constants/constants";
import {
  getImageDimensions,
  trimToLessThan80Char,
} from "../../helpers/mediaHelper";
import {
  CreateFileMutation,
  CreateFileMutationVariables,
  File,
  FileListItemFragmentDoc,
  Maybe,
  Scalars,
} from "../../types.g";
import { StyledMediaPickerContainer } from "../MediaPicker/styles";
import { MediaProps, withData } from "./apollo";
import client, { resolveFileKey } from "../../helpers/filestackHelper";
import { orderBy } from "lodash";
import { AppContextType } from "src/AppContextProvider/type";
export interface MediaUploadOptions {
  maxFiles?: number;
  folderId?: Scalars["UUID"] | null;
  isGlobalUpload?: boolean;
  transformations?: PickerTransformationOptions;
  fullwidth?: boolean;
  onClose?: () => void;
}

// todo: define type:
export type FilestackUploadedFile = any;

export interface MediaUploadProps {
  onSuccess: (files: Maybe<Partial<File>>[]) => void;
  options?: MediaUploadOptions;
}

export interface MediaUploadState {
  filesUploadingList: any[];
  isLoaderActive: boolean;
}

class MediaUpload extends React.Component<
  MediaUploadProps & MediaProps,
  MediaUploadState
> {
  public static contextType = AppContext;
  public context: AppContextType;

  public fileUploadStatusTitle: React.ReactNode;
  public filesUploadProgressList: any[];
  public cancelUpload: () => void;

  constructor(props: MediaUploadProps & MediaProps) {
    super(props);
    this.state = {
      filesUploadingList: [],
      isLoaderActive: false,
    };
  }

  public componentDidMount() {
    this.onMediaUploadReady();
  }

  public componentWillUnmount(): void {
    this.props.options?.onClose?.();
  }

  public onMediaUploadOpen = () => {
    this.setState({ isLoaderActive: false });
  };

  public mapFileUploadProgress = (files, res, event) => {
    const fileUploadingList = files.map((item) => {
      let itemBytesLoaded;

      if (res.uploadId === item.uploadId && event) {
        itemBytesLoaded = event.totalBytes;
      }
      const fileSize = typeof item.size === "string" ? 0 : item.size;

      const fileName = item.filename.substring(0, 120).trim();

      return (
        <FileUploadProgress
          key={item.uploadId}
          title={fileName}
          mimetype={item.mimetype}
          current_filesize={itemBytesLoaded}
          total_filesize={fileSize}
          status={FileUploadProgressEnum.IN_PROGRESS}
        />
      );
    });

    return fileUploadingList;
  };

  public handleUploadStarted = (files) => {
    this.setState({
      filesUploadingList: files,
    });
    this.fileUploadStatusTitle = (
      <span className="uploading">
        <Loader size="small" inline inverted />{" "}
        <FormattedMessage
          id="common.text.uploading_items"
          defaultMessage="Uploading {count} items"
          values={{ count: files.length }}
        />
      </span>
    );
  };

  public handleUploadProgress = (res, event) => {
    this.filesUploadProgressList = this.mapFileUploadProgress(
      this.state.filesUploadingList,
      res,
      event,
    );
    this.context.modal.openNotificationExpandable(
      this.filesUploadProgressList,
      this.fileUploadStatusTitle,
      this.cancelUpload,
    );
  };

  public handleUploadDone = (files: filestack.PickerResponse) => {
    const fileUploadedCount = files.filesUploaded.length;
    if (fileUploadedCount) {
      this.fileUploadStatusTitle = (
        <span className="uploaded">
          <Icon className="icon success" name="checked-circle" color="#FFF" />{" "}
          <FormattedMessage
            id="common.text.uploaded_items"
            defaultMessage="{count} items uploaded"
            values={{ count: fileUploadedCount }}
          />
        </span>
      );
      this.context.modal.openNotificationExpandable(
        this.filesUploadProgressList,
        this.fileUploadStatusTitle,
        this.cancelUpload,
      );
      setTimeout(() => {
        this.context.modal.closeNotificationExpandable();
      }, 1000);
    }
  };

  public onUploadSuccess = async (
    res: FilestackUploadedFile[],
  ): Promise<Maybe<Partial<File>>[] | void> => {
    const spaceId = this.context.user.settings.spaceId;
    let folderId = "";
    if (this.props.options && this.props.options.folderId !== undefined) {
      folderId = this.props.options.folderId;
    }
    const filesUploaded = res;
    const results = await Promise.all(
      filesUploaded.map(
        async (file): Promise<FetchResult<CreateFileMutation> | undefined> => {
          const fileName = trimToLessThan80Char(file.filename);

          let metadata = await resolveFileKey({ ...file });
          let gtmEventName;

          if (file.mimetype?.startsWith("image")) {
            gtmEventName = "studio_image_add";
          } else if (file.mimetype?.startsWith("video")) {
            gtmEventName = "studio_video_add";
          } else {
            gtmEventName = "studio_file_add";
          }

          const tagManagerData = {
            dataLayer: {
              event: gtmEventName,
              studio_upload_type: file.mimetype,
            },
          };

          TagManager.dataLayer(tagManagerData);

          if (file.mimetype?.startsWith("image")) {
            const dimensions = await getImageDimensions(file.handle);
            const { width, height } = await dimensions.json();
            metadata = {
              ...metadata,
              height,
              width,
            };
          }

          const fileInput: CreateFileMutationVariables = {
            input: {
              folderId,
              metadata,
              mimetype: file.mimetype,
              name: fileName,
              size: file.size,
              source: `https://${appConfig.uploadsBaseUrl}/${
                appConfig.uploadsBucket
              }/${escape(metadata.key)}`,
              spaceId: this.props.options?.isGlobalUpload ? null : spaceId,
              tags: [],
            },
          };
          try {
            const file = await this.props.createFile({
              variables: fileInput,
              update: (cache, { data }) => {
                if (data?.createFile?.file) {
                  cache.modify({
                    id: `Folder:${folderId}`,
                    fields: {
                      filesByFolderId(files, options) {
                        const newFileRef = cache.writeFragment({
                          data: data.createFile?.file,
                          fragment: FileListItemFragmentDoc,
                          fragmentName: "FileListItem",
                        });

                        if (
                          files.nodes.some(
                            (ref) =>
                              options.readField("id", ref) ===
                              data.createFile?.file?.id,
                          )
                        ) {
                          return files;
                        }

                        const nodes = [...files.nodes, newFileRef];
                        const reOrder = orderBy(
                          nodes.map((node) => {
                            const created = options.readField(
                              "createdAt",
                              node,
                            );
                            return {
                              created,
                              node,
                            };
                          }),
                          ["created"],
                          ["desc"],
                        );
                        const newFileList = {
                          ...files,
                          nodes: reOrder.map((re) => re.node),
                          totalCount: files.totalCount + 1,
                        };
                        return newFileList;
                      },
                    },
                  });
                }
              },
            });
            return file;
          } catch (err) {
            return;
          }
        },
      ),
    );

    const files = results
      .filter(Boolean)
      .map((item) => item?.data!.createFile!.file);

    if (this.props.onSuccess) {
      this.props.onSuccess(files as File[]);
    }
    return files as File[];
  };

  public onMediaUploadReady = async () => {
    this.setState({ isLoaderActive: true });
    const picker = client.picker({
      accept: FilestackAllowedFileExtensionsWithAudio,
      container: "#droppane", // can be CSS selector string or DOM node
      customText: {
        "My Device": "",
        "Drag & drop to upload images, video, & documents or":
          "Drag & drop to upload images, video, & documents or",
        "or Drag and Drop, Copy and Paste Files":
          "Drop your first video, photo or document here",
      } as PickerCustomText,
      displayMode: PickerDisplayMode.inline,
      dropPane: {
        customText: "Drag & drop to upload images, video, & documents or",
        onSuccess: this.onUploadSuccess,
        overlay: false,
      },
      fromSources: FileStackSource,
      maxFiles:
        (this.props.options && this.props.options.maxFiles) ||
        Number(appConfig.filestackMaxFileUpload),
      onFileUploadProgress: this.handleUploadProgress,
      onOpen: this.onMediaUploadOpen,
      onUploadDone: this.handleUploadDone,
      onUploadStarted: this.handleUploadStarted,
      rootId: "filepicker",
      storeTo: {
        access: "public",
        container: appConfig.uploadsBucket,
        location: appConfig.uploadsLocation,
        path: "",
        region: appConfig.s3Region,
      },
      uploadConfig: {
        intelligent: false,
      },
      uploadInBackground: false,
      transformations: this.props.options?.transformations,
      onClose: () => this.props.options?.onClose,
    });
    picker.open();
    this.cancelUpload = () => {
      picker.cancel();
      picker.close();
    };
  };

  public render() {
    const isLoading = this.state.isLoaderActive;
    if (this.context.modal.isNotificationExpandableShowing()) {
      return null;
    }

    return (
      <>
        <StyledMediaPickerContainer>
          <div id="droppane" />
        </StyledMediaPickerContainer>
        <Loader active={isLoading} />
      </>
    );
  }
}

export default withData(MediaUpload) as React.ComponentType<MediaUploadProps>;
