import { Dictionary, groupBy } from "lodash";
import { FormattedMessage } from "react-intl";
import { AppContextState } from "../AppContextProvider";
import { Typenames } from "../constants/constants";
import { FileAssociationsFragment, Theme } from "../types.g";

export type UsedPlace = Dictionary<
  ({
    position: any;
    isDraft: any;
    name: any;
    id: any;
  } | null)[]
>;

export const getTheUsedPlaceObject = (
  fileAssociations: FileAssociationsFragment[],
): UsedPlace => {
  const listOfPlacesUsedMedia = fileAssociations.map((value) => {
    const usedPlace = Object.fromEntries(
      Object.entries(value).filter(
        ([key, value]) => key !== "__typename" && value,
      ),
    );
    const key = Object.keys(usedPlace)[0];
    const place = value[key];
    const position =
      place.__typename === Typenames.AppInstance ? "Canvas" : place.__typename; // only canvas app can be attach media file
    return {
      position,
      isDraft: place.draft,
      name:
        position !== Typenames.Cast
          ? place.name
          : place.screensByCastId.nodes.map((screen) => screen.name).join(", "),
      id: place.id,
      coverData: place.coverData,
      fileByCoverImageId: place.fileByCoverImageId,
      color: place.color,
    };
  });

  // if there are 2 items from fileAssociations (one is published version and another one is a draft version) should remove the published version and always use the draft item only
  const draftIds = listOfPlacesUsedMedia
    .map((palce) => palce.isDraft?.id)
    .filter(Boolean);
  const publishedIds = listOfPlacesUsedMedia.map((place) => place?.id);
  const idsToRemove = publishedIds.filter((publishedId) =>
    draftIds.includes(publishedId),
  );

  const placesDetail = listOfPlacesUsedMedia
    .map((place) => {
      if (idsToRemove.includes(place.id)) {
        return null;
      }
      if (
        place.position === Typenames.Channel ||
        place.position === Typenames.Playlist
      ) {
        return {
          ...place,
          name: place.isDraft?.name ?? place.name,
        };
      }
      return place;
    })
    .filter((place) => place?.name)
    .filter(Boolean);
  const groupByPosition = groupBy(placesDetail, "position");
  return groupByPosition;
};

export const getTheUsedPlace = (
  fileAssociations?: FileAssociationsFragment[],
) => {
  if (fileAssociations) {
    const groupByPosition = getTheUsedPlaceObject(fileAssociations);
    const places = Object.entries(groupByPosition).map(([key, value]) => {
      return {
        position: key,
        name: value.map((i) => i?.name).join(", "),
      };
    });
    return places;
  }
  return [];
};

export const getUsedPlaceByFont = (themes: Pick<Theme, "id" | "name">[]) => {
  return [
    {
      position: "Theme",
      name: themes.map((theme) => theme.name).join(", "),
    },
  ];
};

export const getConfirmDeletStatus = async ({
  context,
  deleteMessage,
}: {
  context: AppContextState;
  deleteMessage: string | JSX.Element;
}) => {
  const settings = context.currentUser?.preferences?.settings;
  const shouldDeleteWithoutConfirmation =
    settings.delete_media_without_confirmation;

  if (!shouldDeleteWithoutConfirmation) {
    const { confirm, optionCheck } = await context.modal.confirm(
      deleteMessage,
      {
        confirm: (
          <FormattedMessage
            id="ui_component.common.label.move_to_trash"
            defaultMessage="Move to Trash"
          />
        ),
        option: true,
        isDanger: true,
      },
    );

    return { confirm, optionCheck };
  } else {
    return { confirm: true };
  }
};
