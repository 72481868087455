import {
  ChildDataProps,
  withApollo,
  WithApolloClient,
} from "@apollo/client/react/hoc";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "../../utils/compose";

import { ApolloQueryResult, OperationVariables } from "@apollo/client";
import {
  SiteListProps,
  SiteListQuery,
  SiteListQueryVariables,
  withSiteList,
} from "../../types.g";

import { uniqBy } from "lodash";
import { ssm } from "../../state/session/ssm";

export interface ApolloProps
  extends SiteListProps,
    RouteComponentProps<any>,
    WithApolloClient<{}> {
  loadMore: () => Promise<ApolloQueryResult<SiteListQuery>>;
}

export const withData = compose(
  withRouter,
  withApollo,
  withSiteList({
    options: () => {
      const queryVar: SiteListQueryVariables = {
        spaceId: ssm.current.settings.spaceId,
        endCursor: null,
        orderBy: null,
        first: 20,
        filter: {},
      };
      return {
        fetchPolicy: "cache-and-network",
        variables: queryVar,
      };
    },
    props: ({
      data,
      ownProps,
    }): ChildDataProps<
      OperationVariables,
      SiteListQuery,
      SiteListQueryVariables
    > => {
      if (data?.spaceById?.availableSitesBySpaceId?.pageInfo) {
        const variables = {
          endCursor: data.spaceById.availableSitesBySpaceId.pageInfo.endCursor,
        };
        const tmpData = {
          ...(ownProps as SiteListProps),
          loadMore: () =>
            data.fetchMore({
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const prevSiteData =
                  previousResult.spaceById!.availableSitesBySpaceId;
                const currentSiteData =
                  fetchMoreResult!.spaceById!.availableSitesBySpaceId;
                const siteNodes = uniqBy(
                  [...prevSiteData.nodes, ...currentSiteData.nodes],
                  "id",
                );
                return {
                  ...previousResult,
                  spaceById: {
                    ...previousResult.spaceById!,
                    __typename: "Space",
                    availableSitesBySpaceId: {
                      __typename: "SitesConnection",
                      nodes: siteNodes,
                      totalCount: currentSiteData!.totalCount,
                      pageInfo: {
                        ...currentSiteData!.pageInfo,
                      },
                    },
                  },
                };
              },
              variables,
            }),
        };
        return { ...tmpData, data };
      } else {
        return { ...(ownProps as SiteListProps), data: data! };
      }
    },
  }),
);
