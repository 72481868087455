import { orderBy, uniqBy } from "lodash";
import { LinksOrderBy } from "../types.g";
import { fieldsPolicyType } from "./apolloClient";

const linkPolicy = {
  merge(existing, incoming, options) {
    const orderbyState = options.variables?.orderBy
      ? options.variables?.orderBy[0]
      : LinksOrderBy.CreatedAtDesc;
    const existingNodes = existing ? [...existing.nodes] : [];
    const incomingNodes = incoming ? [...incoming.nodes] : [];

    const nodes =
      incomingNodes.length < existingNodes.length &&
      options.variables?.endCursor === null &&
      existing.totalCount > incoming.totalCount
        ? incomingNodes
        : uniqBy([...existingNodes, ...incomingNodes], "__ref");

    const linkOrderBy =
      orderbyState === LinksOrderBy.NameAsc
        ? [(node) => node?.name?.toLowerCase()]
        : ["created"];
    const reOrder = orderBy(
      nodes.map((node) => {
        const name = options.readField("name", node) as string;
        const created = options.readField("createdAt", node) as string;
        return {
          name,
          created,
          node,
        };
      }),
      linkOrderBy,
      orderbyState === LinksOrderBy.NameAsc ? ["asc"] : ["desc"],
    );
    const finalNodesRef = reOrder.map((re) => re.node);

    // fix pageInfo for reorder and fetchmore
    const pageInfo =
      !options.variables?.endCursor && existing?.pageInfo
        ? existing.pageInfo
        : incoming?.pageInfo;
    return {
      ...options.mergeObjects(existing, incoming),
      nodes: finalNodesRef,
      pageInfo,
    };
  },
};

export const linkListField: fieldsPolicyType = {
  linksBySpaceId: {
    keyArgs: ["orderBy", "spaceId"],
    ...linkPolicy,
  },
};

export const linkPickerField: fieldsPolicyType = {
  linksByOrgId: {
    keyArgs: ["orgId", "spaceId", "orderBy", "filter"],
    ...linkPolicy,
  },
};

export const searchLinkField: fieldsPolicyType = {
  searchLink: {
    keyArgs: ["query", "spaceId"],
    merge(existing, incoming, { mergeObjects }) {
      const existingNodes = existing ? [...existing.nodes] : [];
      const nodes = uniqBy([...existingNodes, ...incoming.nodes], "__ref");

      return {
        ...mergeObjects(existing, incoming),
        nodes,
        pageInfo: incoming.pageInfo,
      };
    },
    read(existing) {
      return (
        existing && {
          ...existing,
          nodes: Object.values(existing.nodes),
          pageInfo: existing.pageInfo,
        }
      );
    },
  },
};

export const linkByIdField: fieldsPolicyType = {
  linkById: {
    keyArgs: ["id"],
    merge(existing, incoming, { mergeObjects }) {
      const existingObj = existing ? { ...existing } : {};
      const incomingObj = incoming ? { ...incoming } : {};

      return {
        ...mergeObjects(existingObj, incomingObj),
      };
    },
    read(existing) {
      return (
        existing && {
          ...existing,
        }
      );
    },
  },
};
