import { withApollo, WithApolloClient } from "@apollo/client/react/hoc";
import { UUID } from "@screencloud/uuid";
import { InjectedIntlProps } from "react-intl";
import { ssm } from "../../state/session/ssm";
import {
  ScreenContentHistoriesByScreenIdProps,
  withScreenContentHistoriesByScreenId,
} from "../../types.g";
import { compose } from "../../utils/compose";

export interface ApolloProps
  extends WithApolloClient<{}>,
    ScreenContentHistoriesByScreenIdProps,
    InjectedIntlProps {
  screenId?: UUID;
}

export const withData = compose(
  withApollo,
  withScreenContentHistoriesByScreenId({
    options: (props: ApolloProps) => {
      return {
        variables: {
          id: props.screenId,
          spaceId: ssm.current.settings.spaceId,
          first: null,
        },
        fetchPolicy: "cache-and-network",
      };
    },
    skip: (props: ApolloProps) => !props.screenId,
  }),
);
