import { FEATURE_FLAGS_ENUM } from "../../../constants/featureFlag";
import { AppContextState } from "../../../AppContextProvider";
import { StudioPermissions } from "@screencloud/studio-permissions";

export enum PERMISSION_GROUPS {
  Site = "site",
  Screen = "screen",
}

export enum PERMISSION_KEYS {
  Read = "read",
  Create = "create",
  Update = "update",
  Delete = "delete",
  Share = "share",
  Cast = "cast",
}

interface GetHasSitePermissionParam {
  shouldShowFeature: (feature: string) => boolean;
  studioPermissions: StudioPermissions;
  permissionGroup: PERMISSION_GROUPS;
  permissionKey: PERMISSION_KEYS;
}

export function hasSitePermission(param: GetHasSitePermissionParam) {
  const {
    shouldShowFeature,
    studioPermissions,
    permissionGroup,
    permissionKey,
  } = param;

  const isSecureSiteCustomer = shouldShowFeature(
    FEATURE_FLAGS_ENUM.IS_SECURE_SITES_CUSTOMER,
  );
  const isCustomPermissionsEnabled = shouldShowFeature(
    FEATURE_FLAGS_ENUM.CUSTOM_PERMISSION_SETS,
  );
  const isValidCustomPermission = studioPermissions.validateCurrentSpace(
    permissionGroup,
    permissionKey,
  );
  return (
    isSecureSiteCustomer &&
    (!isCustomPermissionsEnabled || isValidCustomPermission)
  );
}

export function hasSitePermissionByContext(
  context: Pick<AppContextState, "shouldShowFeature" | "currentPermissions">,
  permissionGroup: PERMISSION_GROUPS,
  permissionKey: PERMISSION_KEYS,
): boolean {
  return hasSitePermission({
    studioPermissions: context.currentPermissions,
    shouldShowFeature: context.shouldShowFeature,
    permissionGroup,
    permissionKey,
  });
}
