import { File, Playlist } from "../../../types.g";
import { useAddFilesToPlaylistMutation } from "./useAddFilesToPlaylist";
import { useGetAllMediaByFolderId } from "./useGetAllMediasByFolderId";

export function useBulkAddFilesToPlaylistsMutation() {
  const { addFilesToPlaylist } = useAddFilesToPlaylistMutation();
  const { getAllFiles } = useGetAllMediaByFolderId();

  const addAllFilesByFolderIdToPlaylists = async ({
    folderId,
    playlists,
  }: {
    folderId: string;
    playlists: Playlist[];
  }) => {
    const allFiles = (await getAllFiles(folderId)) as File[];
    return Promise.all(
      playlists.map((playlist) =>
        addFilesToPlaylist({ files: allFiles, playlist }),
      ),
    );
  };

  /**
   *
   * @param files can come from different folders
   */
  const addFilesToPlaylists = async ({
    files,
    playlists,
  }: {
    files: File[];
    playlists: Playlist[];
  }) => {
    return Promise.all(
      playlists.map((playlist) => addFilesToPlaylist({ files, playlist })),
    );
  };

  return { addAllFilesByFolderIdToPlaylists, addFilesToPlaylists };
}
