import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Checkbox } from "@screencloud/screencloud-ui-components";
import {
  getAllSpaces,
  getEntityName,
  getNonOwnerSpaces,
  getOwnerSpace,
} from "../helper";
import { useAppContext } from "src/hooks/useAppContext";
import ShareItem from "./item";
import { Shareable } from "src/helpers/shareableHelper";
import { PrimaryButton } from "src/helpers/whiteLabel";

export interface SpaceListProps {
  sharedSpaceIds: string[];
  isDisabled?: boolean;
  onShareAllChange?: (isShareAll: boolean) => void;
  onShareToSpaceChanges: (sharedSpaceIds: string[]) => void;
  shareable: Shareable;
  setSharedSpaceIds: (sharedSpaceIds: string[]) => void;
  isShareAll: boolean;
  setIsShareAll: (isShareAll: boolean) => void;
  isAllowShareAll?: boolean;
  isCanvasTemplateShare?: boolean;
}

const ShareList = ({
  sharedSpaceIds,
  isDisabled,
  onShareAllChange,
  onShareToSpaceChanges,
  shareable,
  setSharedSpaceIds,
  isShareAll,
  setIsShareAll,
  isAllowShareAll,
  isCanvasTemplateShare,
}: SpaceListProps) => {
  const context = useAppContext();
  const allSpaces = getAllSpaces(context);
  const ownerSpace = getOwnerSpace(context, shareable);

  const handleItemChange = (selectedSpaceId: string, sharedStatus: boolean) => {
    let newSharedSpaces = [...sharedSpaceIds];
    if (sharedStatus) {
      newSharedSpaces.push(selectedSpaceId);
    } else {
      newSharedSpaces = newSharedSpaces.filter((id) => id !== selectedSpaceId);
    }

    onShareToSpaceChanges(newSharedSpaces);
    setSharedSpaceIds(newSharedSpaces);
  };

  const handleAllChange = (event: React.SyntheticEvent<any>, data: any) => {
    setIsShareAll(!isShareAll);
    setSharedSpaceIds(
      !isShareAll
        ? getNonOwnerSpaces(context, shareable).map((item) => item.id)
        : [],
    );
    onShareAllChange && onShareAllChange(data.checked);
  };

  const handleTogglePublishTemplate = (
    event: React.SyntheticEvent<any>,
    data: any,
    value: boolean,
  ) => {
    setIsShareAll(!isShareAll);
    setSharedSpaceIds(
      !isShareAll
        ? getNonOwnerSpaces(context, shareable).map((item) => item.id)
        : [],
    );
    onShareAllChange && onShareAllChange(value);
  };

  const renderShareAllOption = () => {
    if (isAllowShareAll) {
      if (isCanvasTemplateShare) {
        return (
          <div
            data-testid="publish-canvas-template-section"
            className="share-all publish-container"
          >
            <div className="publish-template-text-wrapper">
              <FormattedMessage
                id="common.label.publish_to_template_gallery"
                defaultMessage="Publish to Template Gallery"
              />

              <FormattedMessage
                id="common.label.publish_to_template_gallery_description"
                defaultMessage="Published template will be available for everyone in the organization to use and duplicate."
              />
            </div>
            {isShareAll ? (
              <Button
                data-testid="unpublish-button"
                primary
                danger
                disabled={isDisabled}
                onClick={(e, d) => handleTogglePublishTemplate(e, d, false)}
              >
                <FormattedMessage
                  id="common.label.unpublish"
                  defaultMessage="Unpublish"
                />
              </Button>
            ) : (
              <PrimaryButton
                data-testid="publish-button"
                disabled={isDisabled}
                onClick={(e, d) => handleTogglePublishTemplate(e, d, true)}
              >
                <FormattedMessage
                  id="common.label.publish"
                  defaultMessage="Publish"
                />
              </PrimaryButton>
            )}
          </div>
        );
      } else {
        return (
          <div
            data-testid="share-all-section"
            className={isShareAll ? "share-all active" : "share-all"}
          >
            <div>
              <div className="title">
                <FormattedMessage
                  id="common.label.share_all"
                  defaultMessage="Share All"
                />
              </div>
              <div className="description">
                <FormattedMessage
                  id="common.label.share_all_description"
                  defaultMessage="Make this {entity} available to all spaces."
                  values={{
                    entity: getEntityName(shareable),
                  }}
                />
              </div>
            </div>
            <Checkbox
              toggle
              checked={isShareAll}
              onChange={handleAllChange}
              disabled={isDisabled}
            />
          </div>
        );
      }
    }
    return null;
  };

  const renderShareToSpaceOption = () => {
    return (
      <>
        {isCanvasTemplateShare ? (
          <div className="share-all share-to-space-container">
            <div className="publish-template-text-wrapper">
              <FormattedMessage
                id="common.label.share_to_specific_spaces"
                defaultMessage="Share to Specific Spaces"
              />

              <FormattedMessage
                id="common.label.share_to_specific_spaces_description"
                defaultMessage="Select specific spaces that where this template will be available."
              />
            </div>
          </div>
        ) : (
          <div className="specific-spaces-subheader">
            <FormattedMessage
              id="subheader.label.spaces"
              defaultMessage="Specific Spaces"
            />
          </div>
        )}
        {ownerSpace && (
          <ShareItem
            key={`share-item-${ownerSpace.id}`}
            name={ownerSpace.name}
            id={ownerSpace.id}
            isDisabled={true}
            isShared={true}
            isOwner={true}
          />
        )}
        {getNonOwnerSpaces(context, shareable).map((space) => (
          <ShareItem
            key={`share-item-${space.id}`}
            name={space.name}
            id={space.id}
            isDisabled={isShareAll || isDisabled}
            isShared={isShareAll || sharedSpaceIds.includes(space.id)}
            isOwner={false}
            onItemChange={handleItemChange}
          />
        ))}
      </>
    );
  };

  return (
    <>
      {allSpaces.length > 1 && (
        <div className="space-list" key="share-modal">
          {renderShareAllOption()}
          <div className="divider" />
          {renderShareToSpaceOption()}
        </div>
      )}
    </>
  );
};

export { ShareList };
