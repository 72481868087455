import styled from "styled-components";

export const UpdateCredentialContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 0px 0px 16px 16px;

  h2 {
    font-size: 16px;
  }

  .action-info-text {
    padding-bottom: 16px;
  }

  .action-button-container {
    display: flex;
    width: 20%;
    gap: 10px;
    justify-content: end;
    margin-left: auto;
  }

  .editor-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .field-name {
      flex: 1;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.36;
      margin-bottom: 8px;
    }

    .label {
      width: 100px !important;
    }
  }

  .editor-controls {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: end;
    gap: 10px;
  }

  .editor-button {
    margin: 0 0 8px 0 !important;
  }

  .editor-container {
    display: flex;
    flex-direction: column;
  }

  .field-description {
    font-size: 14px;
    color: var(--color-gray-400);
    margin-bottom: 8px;
  }
`;
