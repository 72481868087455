import { useEffect, useState } from "react";
import { UUID } from "@screencloud/uuid";
import { ssm } from "../state/session/ssm";
import {
  Folder,
  File,
  Exact,
  Maybe,
  FilesOrderBy,
  FoldersOrderBy,
  SearchMediaQuery,
  AvailableFoldersBySpaceIdQuery,
  InputMaybe,
} from "../types.g";
import { useSearchMedia } from "../pages/Media/hooks/useSearchMedia";
import { useSharedFolderFromOtherSpaces } from "../pages/Media/MediasList/useSharedFolderFromOtherSpaces";
import { isOwner } from "../helpers/shareableHelper";
import { useAppContext } from "./useAppContext";
import { useFolderByIdForMediaList } from "../pages/Media/MediasList/useFolderByIdForMediaList";
import { ApolloQueryResult } from "@apollo/client";
import { useRouteMatch } from "react-router";

interface Props {
  query: string | undefined;
  isInRootSharedFolderFromOtherSpaces: boolean | undefined;
  isSearchMode: boolean;
  isInRootFolder: boolean;
  folderId: string;
  allowMediaMimeType?: string[] | undefined;
  filesOrderBy?: FilesOrderBy[];
  foldersOrderBy?: FoldersOrderBy[];
  spaceId?: UUID;
}

export interface UseMediaList {
  mediaFiles: File[];
  mediaFolders: Folder[];
  isLoading: boolean;
  refetch:
    | ((
        variables?:
          | Partial<
              Exact<{
                folderId: any;
                endCursor: any;
                fileOrderBy: Maybe<FilesOrderBy[]>;
                folderOrderBy: Maybe<FoldersOrderBy[]>;
                allowMimeType: Maybe<string[]>;
              }>
            >
          | undefined,
      ) => void)
    | ((
        variables?:
          | Partial<
              Exact<{
                query: Maybe<string>;
                spaceId: any;
              }>
            >
          | undefined,
      ) => Promise<ApolloQueryResult<SearchMediaQuery>>)
    | ((
        variables?:
          | Partial<
              Exact<{
                spaceId: any;
              }>
            >
          | undefined,
      ) => Promise<ApolloQueryResult<AvailableFoldersBySpaceIdQuery>>);
  shouldShowSharedFolder: boolean;
  loadMore: () => void;
  hasNextPage: boolean;
  allItemTotalCount: number;
  renderFetchMoreButton: React.ReactNode;
  renderFetchMorePickerButton: React.ReactNode;
  isInSharedFolderFromOtherSpaces: boolean;
  spaceId?: UUID;
  refetchSearchMedia: (
    variables?:
      | Partial<
          Exact<{
            query: Maybe<string>;
            spaceId: any;
            fileOrderBy: InputMaybe<FilesOrderBy | FilesOrderBy[]>;
            folderOrderBy: InputMaybe<FoldersOrderBy | FoldersOrderBy[]>;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<SearchMediaQuery>>;
}

export function useMedialist({
  query,
  isInRootSharedFolderFromOtherSpaces,
  isSearchMode,
  isInRootFolder,
  folderId,
  allowMediaMimeType,
  filesOrderBy,
  foldersOrderBy,
  spaceId,
}: Props): UseMediaList {
  const context = useAppContext();
  const match = useRouteMatch();
  const {
    data,
    refetch: refetchFolderById,
    loading: folderByIdLoading,
    loadMore,
    hasNextPage,
    allItemsTotalCount: folderByIdAllItemsTotalCount,
    renderFetchMoreButton,
    renderFetchMorePickerButton,
  } = useFolderByIdForMediaList({
    folderId,
    isSearch: isSearchMode,
    allowMediaMimeType,
    filesOrderBy,
    foldersOrderBy,
    spaceId: spaceId ? spaceId : ssm.current.settings.spaceId,
  });
  const {
    mediaFilesSearchResults,
    mediaFoldersSearchResults,
    isSearchMediaLoading,
    refetchSearchMedia,
    allItemsTotalCount: searchMediaAllItemsTotalCount,
  } = useSearchMedia({ query, spaceId });
  const {
    sharedFoldersFromOtherSpaces,
    isSharedFolderLoading,
    refetchSharedFolderFromOtherSpace,
  } = useSharedFolderFromOtherSpaces(spaceId);
  const [mediaFolders, setMediaFolders] = useState([] as Folder[]);
  const [mediaFiles, setMediaFiles] = useState([] as File[]);
  const [isLoading, setIsLoading] = useState(true);
  const [allItemTotalCount, setAllItemTotalCount] = useState(0);
  const shouldShowSharedFolder =
    isInRootFolder && sharedFoldersFromOtherSpaces.length > 0;
  const refetch = isInRootSharedFolderFromOtherSpaces
    ? refetchSharedFolderFromOtherSpace
    : isSearchMode
      ? refetchSearchMedia
      : refetchFolderById;

  useEffect(() => {
    if (isInRootSharedFolderFromOtherSpaces) {
      setMediaFiles([]);
      setMediaFolders(sharedFoldersFromOtherSpaces);
      setIsLoading(isSharedFolderLoading);
      setAllItemTotalCount(sharedFoldersFromOtherSpaces.length);
    } else if (isSearchMode && !isSearchMediaLoading) {
      setMediaFiles(mediaFilesSearchResults);
      setMediaFolders(mediaFoldersSearchResults);
      setAllItemTotalCount(searchMediaAllItemsTotalCount);
      setIsLoading(isSearchMediaLoading);
    } else if (data) {
      setMediaFiles((data?.folderById?.filesByFolderId?.nodes ?? []) as File[]);
      setMediaFolders(
        (data?.folderById?.foldersByParentId?.nodes ?? []) as Folder[],
      );
      setIsLoading(Boolean(folderByIdLoading));
      setAllItemTotalCount(folderByIdAllItemsTotalCount);
    }
  }, [
    isInRootSharedFolderFromOtherSpaces,
    isSearchMode,
    data,
    folderByIdLoading,
    isSearchMediaLoading,
    sharedFoldersFromOtherSpaces.length,
  ]);

  const isInSharedFolderFromOtherSpaces =
    (isInRootSharedFolderFromOtherSpaces ||
      !isOwner({ spaceId: data?.folderById?.spaceId, context })) &&
    match.path.includes("/media/shared");
  return {
    mediaFiles,
    mediaFolders,
    isLoading,
    refetch,
    shouldShowSharedFolder,
    loadMore,
    hasNextPage,
    allItemTotalCount,
    renderFetchMoreButton,
    renderFetchMorePickerButton,
    isInSharedFolderFromOtherSpaces,
    refetchSearchMedia,
  };
}
