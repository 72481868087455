import {
  Folder,
  FolderByIdDocument,
  FolderByIdQuery,
  FolderByIdQueryVariables,
  UpdateFolderByIdMutationVariables,
  useUpdateFolderByIdMutation,
} from "../../../types.g";
import { cloneDeep } from "lodash";
import { MediaOrderBy, Typenames } from "../../../constants/constants";
import { DataValue } from "@apollo/client/react/hoc";
import { getTotalFolders } from "../../../helpers/folderHelper";

interface MoveFolderParams {
  folder: Folder;
  newParentFolderId: string;
}

export interface MoveFolder {
  moveFolder: (params: MoveFolderParams) => Promise<any>;
}

export function useMoveFolder(): MoveFolder {
  const [updateFolderById] = useUpdateFolderByIdMutation();

  const moveFolder = async ({
    folder,
    newParentFolderId,
  }: MoveFolderParams) => {
    const updateMediaFolder: UpdateFolderByIdMutationVariables = {
      input: {
        folderId: folder.id,
        name: folder.name,
        parentId: newParentFolderId,
      },
    };

    return updateFolderById({
      optimisticResponse: {
        __typename: Typenames.Mutation,
        updateFolderById: {
          __typename: Typenames.UpdateFolderByIdPayload,
          folder: {
            ...folder,
            __typename: Typenames.Folder,
            parentId: newParentFolderId,
          },
        },
      },
      update: (proxy, data) => {
        const queryVar: FolderByIdQueryVariables = {
          folderId: folder.parentId,
          fileOrderBy: [MediaOrderBy.FILE_CREATED_AT_DESC],
          folderOrderBy: [MediaOrderBy.FOLDER_CREATED_AT_DESC],
          endCursor: null,
        };

        const cacheData = proxy.readQuery({
          query: FolderByIdDocument,
          variables: queryVar,
        }) as DataValue<FolderByIdQuery>;
        const cloneCacheData = cloneDeep(cacheData);
        if (cloneCacheData?.folderById) {
          cloneCacheData.folderById.foldersByParentId.nodes =
            cloneCacheData.folderById.foldersByParentId.nodes
              // Remove folder from previous folder
              .filter((folderItem) => folderItem.id !== folder.id)
              // Add folder count to new parent folder
              .map((folderItem) => {
                if (folderItem.id === newParentFolderId) {
                  return {
                    ...folderItem,
                    foldersByParentId: {
                      ...folderItem.foldersByParentId,
                      totalCount: getTotalFolders(folderItem as Folder) + 1,
                    },
                  };
                }
                return folderItem;
              });

          proxy.writeQuery({
            data: cloneCacheData,
            query: FolderByIdDocument,
            variables: queryVar,
          });
        }
      },
      variables: updateMediaFolder,
    });
  };

  return { moveFolder };
}
