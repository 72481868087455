import * as React from "react";
import { Link } from "../../types.g";
import LinkList from "../LinkList";
import { LinkListItemActions } from "../LinkListItem";

export interface LinkTabPaneProps {
  onClickLinkCallback: (
    action: LinkListItemActions,
    value: Link | string | boolean,
    event?: React.SyntheticEvent,
  ) => void;
  selectedLinkCallback: (link: Link) => void;
  searchTerms: string;
  onSelectLinkItems?: (linkItems: Partial<Link>[]) => void;
  selectedLinkItems?: Partial<Link>[];
}
class LinkTabPane extends React.Component<LinkTabPaneProps, {}> {
  public render() {
    const {
      selectedLinkCallback,
      onClickLinkCallback,
      selectedLinkItems,
      onSelectLinkItems,
      searchTerms,
    } = this.props;
    return (
      <LinkList
        selectedLinkCallback={selectedLinkCallback}
        onClickLinkCallback={onClickLinkCallback}
        selectedLinkItems={selectedLinkItems}
        onSelectLinkItems={onSelectLinkItems}
        searchTerms={searchTerms}
      />
    );
  }
}

export default LinkTabPane as React.ComponentType<LinkTabPaneProps>;
