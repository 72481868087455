import { Search } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { getProviderIconUrl } from "../../helpers/credentialHelper";
import { PrimaryButton } from "../../helpers/whiteLabel";
import { ApolloProps, withData } from "./apollo";
import {
  API_KEY_PROVIDER_URN,
  BASIC_AUTH_PROVIDER_URN,
  OAUTH2_PROVIDER_URN,
} from "./constants";
import "./CredentialPicker.css";
import { Credential } from "./models/Credential";
import { IdentityProvider } from "./models/IdentityProvider";
import AddNewCredentialModal from "./widgets/modals/add-credential/AddNewCredentialModal";
import CredentialProvidersModal from "./widgets/modals/credential-providers-list/CredentialProvidersModal";
import CredentialPills from "./widgets/nav/CredentialPills";

interface CredentialPickerState {
  isLoading: boolean;
  selectedCredential?: Credential;
  selectedIdentityProvider?: IdentityProvider;
  activeCredentialsTabIndex: number;
  isShowingAddCredentialModal: boolean;
  isShowingProviderPicker: boolean;
  searchText: string;
  previousCredentialsTabIndex: number;
}

export interface CredentialPickerProps extends ApolloProps {
  filterUrn: string;
  onCredentialSelected: Function;
}

class CredentialPicker extends React.Component<
  CredentialPickerProps,
  CredentialPickerState
> {
  constructor(props: CredentialPickerProps) {
    super(props);

    this.state = {
      isLoading: true,
      activeCredentialsTabIndex: 0,
      previousCredentialsTabIndex: 0,
      isShowingAddCredentialModal: false,
      isShowingProviderPicker: false,
      searchText: "",
    };
  }

  public componentDidUpdate(
    prevProps: CredentialPickerProps,
    prevState: CredentialPickerState,
  ) {
    if (
      !prevProps.credentials.allCredentials &&
      this.props.credentials.allCredentials
    ) {
      this.setState({ isLoading: false });
    }
  }

  public render(): React.ReactNode {
    const {
      isLoading,
      selectedCredential,
      activeCredentialsTabIndex,
      isShowingProviderPicker,
      selectedIdentityProvider,
      searchText,
    } = this.state;
    const { filterUrn, identityProviders } = this.props;

    if (isLoading) {
      return (
        <div>
          <p>Please wait</p>
        </div>
      );
    }

    const credentials = this.getFilteredCredentials()
      .sort((a, b) => a.description!.localeCompare(b.description!))
      .map((credential) => {
        return (
          <div
            key={credential.id}
            className={`credential-tile-wrapper ${
              selectedCredential === credential ? "selected" : ""
            }`}
            onClick={() => this.onCredentialSelected(credential as Credential)}
          >
            <div className="credential-tile">
              <div className="icon">
                <img
                  src={
                    getProviderIconUrl(
                      credential.provider,
                      this.props.identityProviders,
                    ) || ""
                  }
                />
              </div>
              <div className="details">
                <div className="heading">{credential.description}</div>
                <div className="subtext">{credential.username}</div>
              </div>
            </div>
          </div>
        );
      });

    return (
      <div className="credential-picker">
        <CredentialPills
          activeTabIndex={activeCredentialsTabIndex}
          onTabChange={this.onCredentialsTabChange}
          filterUrn={filterUrn}
          onNewCredentialTypeSelected={this.onNewCredentialTypeSelected}
        />

        {isShowingProviderPicker && (
          <CredentialProvidersModal
            identityProviders={identityProviders.allIdentityProviders!}
            onProviderSelected={this.onIdentityProviderSelected}
          />
        )}

        {selectedIdentityProvider && (
          <AddNewCredentialModal
            identityProvider={selectedIdentityProvider}
            onCredentialAdded={this.onCredentialAdded}
          />
        )}

        <div className="credential-search-wrapper">
          <div className="column-search">
            <Search
              className="search"
              type="text"
              showNoResults={false}
              placeholder="Search"
              value={searchText}
              onChange={(event, data) => this.onSearchTextChange(data.value)}
              onClear={() => this.onSearchTextChange("")}
            />
          </div>
        </div>

        <div className="credential-tile-group">{credentials}</div>

        <div className="model-footer">
          <PrimaryButton
            disabled={!selectedCredential}
            onClick={this.onContinuePressed}
          >
            Continue
          </PrimaryButton>
        </div>
      </div>
    );
  }

  private getFilteredCredentials = () => {
    const { allCredentials } = this.props.credentials;

    if (!allCredentials) {
      return [];
    }

    const credentials = allCredentials.nodes;
    const { activeCredentialsTabIndex, searchText } = this.state;
    const { filterUrn } = this.props;

    if (searchText !== "") {
      return credentials.filter((credential) => {
        const isMatch = credential
          .description!.toLowerCase()
          .includes(searchText.toLowerCase());

        return filterUrn === ""
          ? isMatch
          : isMatch && credential.provider.startsWith(filterUrn);
      });
    }

    if (filterUrn !== "") {
      return credentials.filter((credential) =>
        credential.provider.startsWith(filterUrn),
      );
    }

    switch (activeCredentialsTabIndex) {
      case 1:
        return credentials.filter((credential) =>
          credential.provider.startsWith(OAUTH2_PROVIDER_URN),
        );
      case 2:
        return credentials.filter((credential) =>
          credential.provider.startsWith(API_KEY_PROVIDER_URN),
        );
      default:
        return credentials.filter((credential) =>
          credential.provider.startsWith(BASIC_AUTH_PROVIDER_URN),
        );
    }
  };

  private onNewCredentialTypeSelected = (type: string) => {
    const { filterUrn } = this.props;

    if (
      (filterUrn === "" || filterUrn === OAUTH2_PROVIDER_URN) &&
      type.startsWith(OAUTH2_PROVIDER_URN)
    ) {
      this.setState({ isShowingProviderPicker: true });
      return;
    }

    const urn = filterUrn !== "" ? filterUrn : type;
    const identityProvider = this.getIdentityProviderByUrn(urn);

    if (!identityProvider) {
      console.log(
        `Something has gone wrong! Failed to find an identity provider matching ${urn}`,
      );
      return;
    }

    this.setState({
      selectedIdentityProvider: identityProvider,
    });
  };

  private getIdentityProviderByUrn(urn: string) {
    const allIdentityProviders =
      this.props.identityProviders.allIdentityProviders;

    if (!allIdentityProviders) {
      return null;
    }

    return (
      allIdentityProviders
        // Sort by URN in descending order to ensure we find the latest provider version first
        .sort((a, b) => b.type.localeCompare(a.type))
        .find((provider) => provider.type.startsWith(urn))
    );
  }

  private onIdentityProviderSelected = (
    identityProvider?: IdentityProvider,
  ) => {
    this.setState({
      selectedIdentityProvider: identityProvider,
      isShowingProviderPicker: false,
    });
  };

  private onCredentialAdded = (credential?: Credential) => {
    const { selectedIdentityProvider } = this.state;
    const { filterUrn } = this.props;

    if (
      !credential &&
      selectedIdentityProvider!.type.startsWith(OAUTH2_PROVIDER_URN) &&
      (filterUrn === "" || filterUrn === OAUTH2_PROVIDER_URN)
    ) {
      this.setState({
        selectedIdentityProvider: undefined,
        isShowingProviderPicker: true,
      });

      return;
    }

    this.setState({ selectedIdentityProvider: undefined });
  };

  private onCredentialSelected = (credential: Credential) => {
    const { selectedCredential } = this.state;
    this.setState({
      selectedCredential:
        credential !== selectedCredential ? credential : undefined,
    });
  };

  private onContinuePressed = () => {
    const { selectedCredential } = this.state;

    if (!selectedCredential) {
      this.props.onCredentialSelected();
      return;
    }

    const credential = {
      ...selectedCredential,
      iconUrl: getProviderIconUrl(
        selectedCredential.provider,
        this.props.identityProviders,
      ),
    };

    this.props.onCredentialSelected(credential);
  };

  private onSearchTextChange = (searchText: string) => {
    const { activeCredentialsTabIndex, previousCredentialsTabIndex } =
      this.state;

    this.setState({
      searchText,
      activeCredentialsTabIndex:
        searchText === "" && activeCredentialsTabIndex === -1
          ? previousCredentialsTabIndex
          : -1,
      previousCredentialsTabIndex:
        activeCredentialsTabIndex !== -1
          ? activeCredentialsTabIndex
          : previousCredentialsTabIndex,
    });
  };

  private onCredentialsTabChange = (index: number) => {
    if (this.state.searchText !== "") {
      return;
    }

    this.setState({ activeCredentialsTabIndex: index });
  };
}

export default withData(CredentialPicker);
