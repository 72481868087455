import { FetchResult } from "@apollo/client/core";
import { withApollo, WithApolloClient } from "@apollo/client/react/hoc";
import {
  Button,
  Icon,
  LoaderBar,
  Theme,
} from "@screencloud/screencloud-ui-components";
import { isUuid } from "@screencloud/uuid";
import {
  UseLinkListOrSearchLink,
  useLinkListOrSearchLink,
} from "../../hooks/useLinkListOrSearchLink";
import { uniq } from "lodash";
import * as React from "react";
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormattedMessage } from "react-intl";
import { appConfig } from "../../appConfig";
import { AppContext } from "../../AppContextProvider/AppContext";
import EmptyState from "../../components/EmptyState";
import { LinkListItemActions } from "../../components/LinkListItem";
import { CreateLinkPayload } from "../../components/LinkPicker/create";
import {
  getImageDimensions,
  trimToLessThan80Char,
} from "../../helpers/mediaHelper";
import {
  CreateFileMutationFn,
  CreateFileMutationVariables,
  CreateLinkMutationFn,
  CreateLinkMutationVariables,
  Link,
  LinkListDocument,
  LinkListQueryVariables,
  LinksOrderBy,
  LinkType,
  SearchLinkProps,
  UpdateLinkByIdMutationFn,
  UpdateLinkByIdMutationVariables,
  withCreateFile,
  withCreateLink,
  withUpdateLinkById,
} from "../../types.g";
import { compose } from "../../utils/compose";
import LinkContentItem from "../LinkContentItem";
import { Styled } from "./styles";

import client, { resolveFileKey } from "../../helpers/filestackHelper";
import { AppContextType } from "src/AppContextProvider/type";

const withData = compose(
  withApollo,
  withCreateFile({
    name: "createFile",
  }),
  withCreateLink({
    name: "createLink",
  }),
  withUpdateLinkById({
    name: "updateLinkById",
  }),
  (Component) => (props: LinkListProps) => {
    const query = props.searchTerms;
    const linkListOrSearchLinkProps = useLinkListOrSearchLink({
      query,
      linksOrderBy: [
        LinksOrderBy.NameAsc,
        LinksOrderBy.CreatedAtAsc,
        LinksOrderBy.IdDesc,
      ],
    });
    return <Component {...props} {...linkListOrSearchLinkProps} />;
  },
);

interface ApolloProps
  extends WithApolloClient<any>,
    SearchLinkProps,
    UseLinkListOrSearchLink {
  createFile: CreateFileMutationFn;
  createLink: CreateLinkMutationFn;
  updateLinkById: UpdateLinkByIdMutationFn;
}

export interface LinkListProps {
  onClickLinkCallback: (
    action: LinkListItemActions,
    value: Partial<Link> | string | boolean,
    event?: React.MouseEvent,
  ) => void;
  selectedLinkCallback: (link: Link) => void;
  searchTerms: string;
  isDroppable?: boolean;
  onSelectLinkItems?: (linkItems: Partial<Link>[]) => void;
  selectedLinkItems?: Partial<Link>[];
}

export interface LinkListState {
  lastSelectIndex: number;
}

class LinkList extends React.Component<
  LinkListProps & ApolloProps,
  LinkListState
> {
  public static contextType = AppContext;

  public static defaultProps: Partial<LinkListProps> = {
    isDroppable: true,
  };

  public context: AppContextType;

  public state: LinkListState = {
    lastSelectIndex: -1,
  };

  public handleCreateLinkOpen = async () => {
    const param = await this.context.modal.openLinkPicker(
      <FormattedMessage
        id="ui_component.link.add_link"
        defaultMessage="Add Link"
      />,
    );

    this.createLink(param)
      .then(async (data) => {
        this.context.modal.closeModals();

        const uploadedFile = await client.upload(
          param.thumbUrl as string,
          {},
          {
            access: "public",
            container: appConfig.uploadsBucket,
            location: appConfig.uploadsLocation,
            path: "",
            region: appConfig.s3Region,
          },
        );

        let metadata = await resolveFileKey({
          ...uploadedFile,
          name: param.name,
        });

        if (uploadedFile.mimetype!.startsWith("image")) {
          const dimensions = await getImageDimensions(uploadedFile.handle);
          const { width, height } = await dimensions.json();
          metadata = {
            ...metadata,
            height,
            width,
          };
        }

        const fileName = trimToLessThan80Char(param.name!);
        const spaceId = this.context.user.settings.spaceId;
        const fileInput: CreateFileMutationVariables = {
          input: {
            metadata,
            mimetype: uploadedFile.mimetype,
            name: fileName,
            size: uploadedFile.size,
            source: `https://${appConfig.uploadsBaseUrl}/${
              appConfig.uploadsBucket
            }/${escape(metadata.key!)}`,
            spaceId,
            tags: [],
          },
        };
        const createdFile = (await this.props.createFile({
          variables: fileInput,
        })) as any;
        const fileId =
          createdFile &&
          createdFile.data.createFile.file &&
          createdFile.data.createFile.file.id;

        if (isUuid(data)) {
          const updateLink: UpdateLinkByIdMutationVariables = {
            input: {
              fileId,
              id: data,
            },
          };

          await this.props.updateLinkById({ variables: updateLink });
        }
      })
      .catch((err) => {
        console.log("error while creating link", err);
      });
  };

  public createLink = (param: CreateLinkPayload) => {
    return new Promise((resolve, reject) => {
      const spaceId = this.context.user.settings.spaceId;
      if (!spaceId) {
        reject("no spaceid");
      }
      const linkTypeParam = param.isInternal
        ? LinkType.Internal
        : param.isCloudRendering
          ? LinkType.Cloud
          : LinkType.Standard;
      const createLinkInput: CreateLinkMutationVariables = {
        input: {
          cloudConfig: param.isCloudRendering
            ? { credential: param.credential }
            : {},
          linkType: linkTypeParam,
          name: param.name || "",
          spaceId,
          tags: param.tags,
          url: param.link || "",
        },
      };
      const linkQueryVariables: LinkListQueryVariables = {
        spaceId,
        orderBy: null,
        endCursor: null,
      };
      this.props
        .createLink({
          refetchQueries: [
            {
              query: LinkListDocument,
              variables: linkQueryVariables,
            },
          ],
          variables: createLinkInput,
        })
        .then(({ data }: FetchResult<any, Record<string, any>>) => {
          if (data.createLink && data.createLink.link !== null) {
            resolve(data.createLink.link.id);
          } else {
            reject("no data saved");
          }
        });
    });
  };

  public selectItem = (link: Partial<Link>, selectedItems: Partial<Link>[]) => {
    const foundItem = selectedItems.find(
      (item) => item.id === link.id && item.__typename === link.__typename,
    );
    if (selectedItems.length > 1) {
      return [link];
    } else {
      return foundItem ? [] : [link];
    }
  };

  public shiftSelectItem = (
    link: Partial<Link>,
    selectedIdx: number,
    lastIdx: number,
    selectedItems: Partial<Link>[],
    linkItems: Partial<Link>[],
  ) => {
    let selected: Partial<Link>[] = [];
    if (selectedItems.length > 0) {
      if (lastIdx >= selectedIdx) {
        selected = [...selectedItems, ...linkItems.slice(selectedIdx, lastIdx)];
      } else if (lastIdx !== -1) {
        selected = [
          ...selectedItems,
          ...linkItems.slice(lastIdx, selectedIdx + 1),
        ];
      }
      return uniq(selected); // remove duplicated items
    }

    return this.selectItem(link, selectedItems);
  };

  public selectMultipleItem = (
    link: Partial<Link>,
    index: number,
    lastSelectedIndex: number,
    selectedItems: Partial<Link>[],
    linkItems: Partial<Link>[],
    event?: React.MouseEvent,
  ) => {
    // const keyboardEvent = event as React.KeyboardEvent
    const shiftKey = event && event.shiftKey;
    const metaKey = event && event.metaKey;
    const ctrlKey = event && event.ctrlKey;

    // with shift key
    if (shiftKey) {
      return this.shiftSelectItem(
        link,
        index,
        lastSelectedIndex,
        selectedItems,
        linkItems,
      );
    }

    // with ctrl/cmd key
    if (metaKey || ctrlKey) {
      const foundItem = selectedItems.find(
        (item) => item.id === link.id && item.__typename === link.__typename,
      );
      if (foundItem) {
        return selectedItems.filter(
          (item) =>
            !(
              item.id === foundItem.id &&
              item.__typename === foundItem.__typename
            ),
        );
      } else {
        return [...selectedItems, link];
      }
    }

    return this.selectItem(link, selectedItems);
  };

  public handleLinkCallback = (
    action: LinkListItemActions,
    index: number,
    lastSelectedIndex: number,
    value: Partial<Link>,
    event?: React.MouseEvent,
  ) => {
    const { links } = this.props;
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (action === LinkListItemActions.SELECTED) {
      const selectedLinks = this.selectMultipleItem(
        value,
        index,
        lastSelectedIndex,
        this.props.selectedLinkItems!,
        links,
        event,
      );
      this.setState({
        lastSelectIndex: index,
      });
      if (this.props.onSelectLinkItems) {
        this.props.onSelectLinkItems(selectedLinks);
      }
    } else {
      this.props.onClickLinkCallback(action, value, event);
    }
  };

  public render() {
    if (!this.context.currentPermissions.validateCurrentSpace("link", "read")) {
      return null;
    }

    const { searchTerms, links, hasNextPage, loadMore, loading } = this.props;
    const hasSearchTerm = searchTerms.length;
    const canCreateLink = this.context.currentPermissions.validateCurrentSpace(
      "link",
      "create",
    );
    const renderLinks = links.map((link: Link, index: number) => (
      <Droppable
        isDropDisabled={true}
        droppableId={`link-${link.id}`}
        key={link.id}
      >
        {(
          droppableProvided: DroppableProvided,
          droppableSnapshot: DroppableStateSnapshot,
        ) => (
          <div
            className="column link-item"
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
            style={{
              backgroundColor: droppableSnapshot.isDraggingOver
                ? Theme.color.silver
                : "transparent",
            }}
          >
            <LinkContentItem
              selectedLinkItems={this.props.selectedLinkItems}
              selectedLinkCallback={this.props.selectedLinkCallback}
              onClickLinkCallback={(action, value, event) =>
                this.handleLinkCallback(
                  action,
                  index,
                  this.state.lastSelectIndex,
                  value,
                  event,
                )
              }
              index={index}
              linkItem={link}
              secureMediaPolicy={this.context.secureMediaPolicy}
            />
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    ));
    const isHaveContentItem = links.length ? (
      <ul className="link-list">{renderLinks}</ul>
    ) : hasSearchTerm ? (
      <EmptyState section="link-sidebar" cover={false} className="empty">
        <p>
          <FormattedMessage
            id="common.text.no_result_found_matching_query"
            defaultMessage="No results found matching query"
          />
        </p>
      </EmptyState>
    ) : (
      <EmptyState section="link-sidebar" cover={false} className="empty">
        {canCreateLink && (
          <>
            <h3>
              <FormattedMessage
                id="links.link_empty.no_content"
                defaultMessage="No link added yet"
              />
            </h3>
            <p>
              <FormattedMessage
                id="links.link_empty.help_text"
                defaultMessage="Add a link or URL here"
              />
            </p>
          </>
        )}
      </EmptyState>
    );
    return (
      <Styled>
        {canCreateLink && (
          <Button
            className="add-link"
            mini
            upload
            onClick={this.handleCreateLinkOpen}
            data-testid="add-link-button"
          >
            <Icon name="plus" />
          </Button>
        )}
        <div className={`layout-list link-section`}>
          <FormattedMessage id="links.links" defaultMessage="Links" />
        </div>
        <div className="layout-container" id="scrollableDiv">
          <InfiniteScroll
            style={{ overflow: "none", height: "none" }}
            dataLength={links.length}
            next={loadMore}
            hasMore={hasNextPage}
            scrollableTarget="scrollableDiv"
            loader={
              loading && (
                <div className="layout-list" key="loading-message">
                  Loading...
                </div>
              )
            }
          >
            {isHaveContentItem}
          </InfiniteScroll>
        </div>
        {loading && <LoaderBar />}
      </Styled>
    );
  }
}

export default withData(LinkList) as React.ComponentType<LinkListProps>;
