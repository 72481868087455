import { ApolloQueryResult } from "@apollo/client";
import { UUID } from "@screencloud/uuid";
import { getSortedAppInstalls, isCanvasApp } from "src/domains/appInstall";
import { ssm } from "../state/session/ssm";
import {
  AppInstall,
  AvailableAppInstallsQuery,
  AvailableAppInstallsQueryVariables,
  useAvailableAppInstallsQuery,
} from "../types.g";

export interface UseAvailableAppInstalls {
  appInstalls: AppInstall[];
  loading: boolean;
  spaceId?: UUID;
  refetch: (
    variables?: Partial<AvailableAppInstallsQueryVariables>,
  ) => Promise<ApolloQueryResult<AvailableAppInstallsQuery>>;
}

export function useAvailableAppInstalls(props?: {
  skip?: boolean;
  spaceId?: UUID;
}): UseAvailableAppInstalls {
  const queryVar: AvailableAppInstallsQueryVariables = {
    spaceId: props?.spaceId ?? ssm.current.settings.spaceId,
  };

  const { data, loading, refetch } = useAvailableAppInstallsQuery({
    fetchPolicy: "cache-and-network",
    variables: queryVar,
    skip: Boolean(props?.skip),
  });

  const appInstalls =
    (data?.spaceById?.availableAppInstallsBySpaceId?.nodes?.filter(
      (item: AppInstall) => !isCanvasApp(item),
    ) ?? []) as AppInstall[];
  return { appInstalls: getSortedAppInstalls(appInstalls), loading, refetch };
}
