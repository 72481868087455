import {
  AvailableAppInstanceFragment,
  AppInstanceByIdForContentFragment,
  CommonAppInstanceTemplate,
  CreateFileDocument,
  CreateFileMutation,
  CreateFileMutationVariables,
  FileListItemFragment,
  JobStatus,
  UpdateAppInstanceMutation,
  CreateAppInstanceMutation,
  DuplicateAppInstanceMutation,
  AvailableAppInstanceFragmentDoc,
  AppInstanceForContentListFragment,
  CreateAppInstanceByCommonAppInstanceTemplateIdMutation,
  AppInstance,
  UpdateAppInstanceMutationVariables,
  SetScreenContentByAppInstanceIdMutationVariables,
  CreateCastsByAppInstanceIdMutationVariables,
  UpdateShareAppInstanceToSpacesMutationVariables,
  UpdateShareAppInstanceToAllSpacesMutationVariables,
  UpdateAppInstanceDocument,
  UpdateShareAppInstanceToSpacesMutation,
  UpdateShareAppInstanceToSpacesDocument,
  UpdateShareAppInstanceToAllSpacesMutation,
  UpdateShareAppInstanceToAllSpacesDocument,
  CreateCastsByAppInstanceIdMutation,
  CreateCastsByAppInstanceIdDocument,
  SetScreenContentByAppInstanceIdMutation,
  SetScreenContentByAppInstanceIdDocument,
  useDuplicateAppInstanceMutation,
  DuplicateAppInstanceMutationFn,
  Playlist,
} from "../types.g";

import { AppContextState } from "../AppContextProvider";
import { appConfig } from "../appConfig";
import { FilestackUploadedFile } from "../components/MediaUpload";
import { FEATURE_FLAGS_ENUM } from "../constants/featureFlag";
import apolloClient from "../state/apolloClient";
import {
  generateImgixURL,
  getImageDimensions,
  trimToLessThan80Char,
} from "./mediaHelper";
import {
  canBeDeleted,
  canBeShared,
  canBeUpdated,
  isShared,
  shouldShowShareButton,
} from "./shareableHelper";
import client, { resolveFileKey } from "./filestackHelper";
import { ApolloCache, Reference } from "@apollo/client";
import { orderBy } from "lodash";
import Nav from "../components/Admin/Nav";
import { FormattedMessage } from "react-intl";
import queryHelper from "../state/helper/query";
import { CastedScreen } from "./castingHelper";
import { getTheUsedPlace } from "./deleteStuffHelper";
import DeleteWarning from "../components/DeleteWarning";
import { UUID } from "@screencloud/uuid";
import {
  CloseButtonPosition,
  ModalSize,
} from "@screencloud/screencloud-ui-components";
import { ScreenPickerActions } from "../components/ScreenPicker";
import {
  modifyCastScreenByAppInstance,
  onDuplicateInstance,
} from "../utils/appInstances";
import ShareModal from "../components/ShareModal";
import { useAppContext } from "src/hooks/useAppContext";
import {
  useDeleteAppInstance,
  UseDeleteAppInstance,
} from "src/hooks/entities/appInstance/useDeleteAppInstance";
import { getCanvasRoute } from "src/components/CanvasTab/utils";
import AddToPlaylistModal from "src/components/AddToPlaylistModal";
import {
  useAddAppsToPlaylistsMutation,
  UseAddAppsToPlaylistsMutation,
} from "src/pages/Apps/AppInstances/hooks/useAddAppsToPlaylist";
import { isUnderDevelopment } from "src/utils/featureFlag";
import {
  CanReadFunction,
  ReadFieldFunction,
  ToReferenceFunction,
} from "@apollo/client/cache/core/types/common";
import { StorageType } from "@apollo/client/cache/inmemory/policies";
import { APP_STORE_DISCOVER_PATH_NANE } from "src/pages/Apps/AppStore/constants";

export enum AppInstanceActionEnums {
  EDIT = "edit",
  DELETE = "delete",
  RENAME = "rename",
  CAST = "cast",
  DUPLICATE = "duplicate",
  NEW_CANVAS = "newCanvas",
  NEW_TEMPLATE = "newTemplate",
  PUBLISH_TO_SC_GALLERY = "publishToSCGallery",
  USE_PUBLIC_TEMPLATE = "usePublicTemplate",
  UNPUBLISH = "unpublish",
  SHARE = "share",
  ADD_TO_PLAYLIST = "addToPlaylist",
  EDIT_ORIGINAL = "editOriginal",
}

export type AppInstanceActions =
  | AppInstanceActionEnums.EDIT
  | AppInstanceActionEnums.DELETE
  | AppInstanceActionEnums.RENAME
  | AppInstanceActionEnums.CAST
  | AppInstanceActionEnums.DUPLICATE
  | AppInstanceActionEnums.NEW_CANVAS
  | AppInstanceActionEnums.NEW_TEMPLATE
  | AppInstanceActionEnums.PUBLISH_TO_SC_GALLERY
  | AppInstanceActionEnums.USE_PUBLIC_TEMPLATE
  | AppInstanceActionEnums.UNPUBLISH
  | AppInstanceActionEnums.SHARE
  | AppInstanceActionEnums.ADD_TO_PLAYLIST
  | AppInstanceActionEnums.EDIT_ORIGINAL;

export enum AppInstanceBulkActionEnums {
  DELETE = "delete",
  DUPLICATE = "duplicate",
  UPDATE_AVAILABILITY = "updateAvailability",
  UPDATE_TAG = "updateTag",
  ADD_TO_PLAYLIST = "aapToPlaylist",
}

export function isCanvasInstance(
  appInstance: AvailableAppInstanceFragment | CommonAppInstanceTemplate,
) {
  return (
    appInstance.__typename === "AppInstance" &&
    appInstance.appByAppId?.name === "Canvas" &&
    !appInstance?.isTemplate
  );
}

export function isCanvasTemplateInstance(
  appInstance: AvailableAppInstanceFragment | CommonAppInstanceTemplate,
) {
  return (
    appInstance.__typename === "AppInstance" &&
    appInstance?.appByAppId?.name === "Canvas" &&
    appInstance?.isTemplate
  );
}

interface CacheOptions {
  DELETE: any;
  INVALIDATE: any;
  fieldName: string;
  storeFieldName: string;
  readField: ReadFieldFunction;
  canRead: CanReadFunction;
  isReference: (obj: any) => obj is Reference;
  toReference: ToReferenceFunction;
  storage: StorageType;
}

/**
 * Common template are things in gallery
 */
export function isPublicTemplateGalleryInstance({
  __typename,
}: Pick<
  AvailableAppInstanceFragment | CommonAppInstanceTemplate,
  "__typename"
>) {
  return __typename === "CommonAppInstanceTemplate";
}

export function isAppShared(
  appInstance: AvailableAppInstanceFragment | CommonAppInstanceTemplate,
) {
  if (isPublicTemplateGalleryInstance(appInstance)) {
    return false;
  }
  return isShared(appInstance as AvailableAppInstanceFragment);
}

export function isAppTemplate(
  appInstance: AvailableAppInstanceFragment | CommonAppInstanceTemplate,
) {
  if (isPublicTemplateGalleryInstance(appInstance)) {
    return false;
  }
  return (appInstance as AvailableAppInstanceFragment).isTemplate;
}

export function getCastedScreen(
  appInstance: AvailableAppInstanceFragment | CommonAppInstanceTemplate,
) {
  if (isPublicTemplateGalleryInstance(appInstance)) {
    return [];
  }
  return (appInstance as AvailableAppInstanceFragment)
    ?.castedScreenByAppInstanceId?.nodes;
}

export function canAppBeUpdated({
  appInstance,
  context,
}: {
  appInstance: AvailableAppInstanceFragment | CommonAppInstanceTemplate;
  context: Pick<AppContextState, "currentSpace" | "currentPermissions">;
}) {
  if (isPublicTemplateGalleryInstance(appInstance)) {
    return false;
  }
  return canBeUpdated({ shareable: appInstance, context });
}

export function canAppBeDeleted({
  appInstance,
  context,
}: {
  appInstance: AvailableAppInstanceFragment | CommonAppInstanceTemplate;
  context: Pick<AppContextState, "currentSpace" | "currentPermissions">;
}) {
  if (isPublicTemplateGalleryInstance(appInstance)) {
    return false;
  }
  return canBeDeleted({ shareable: appInstance, context });
}

export function canAppBeCasted({
  appInstance,
  context,
}: {
  appInstance: AvailableAppInstanceFragment | CommonAppInstanceTemplate;
  context: Pick<AppContextState, "shouldShowFeature" | "currentPermissions">;
}) {
  if (isPublicTemplateGalleryInstance(appInstance)) {
    return false;
  }
  return (
    context.currentPermissions.validateCurrentSpace("screen", "cast") &&
    context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING)
  );
}

export function canAppSetContent({
  appInstance,
  context,
}: {
  appInstance: AvailableAppInstanceFragment | CommonAppInstanceTemplate;
  context: Pick<AppContextState, "shouldShowFeature" | "currentPermissions">;
}) {
  if (isPublicTemplateGalleryInstance(appInstance)) {
    return false;
  }
  return context.currentPermissions.validateCurrentSpace("screen", "content");
}

export function shouldAppShowSetScreenMenuItem({
  appInstance,
  context,
}: {
  appInstance: AvailableAppInstanceFragment | CommonAppInstanceTemplate;
  context: Pick<AppContextState, "shouldShowFeature" | "currentPermissions">;
}) {
  return (
    canAppBeCasted({ appInstance, context }) ||
    canAppSetContent({ appInstance, context })
  );
}

export const isAppSharingUnderDevelopment = (
  appInstance: AppInstance,
): boolean => {
  const sharingIsUnderDevleopmentApps = [
    "Emergency Alerts",
    "Meta Workplace",
    "Playgrounds",
    "ScreenCloud Broadcast",
    "Team News",
  ];

  return sharingIsUnderDevleopmentApps.includes(
    appInstance?.appByAppId?.name ??
      appInstance.appVersionByAppInstanceId?.appByAppId?.name ??
      "",
  );
};

export function shouldAppShowShareButton({
  appInstance,
  context,
}: {
  appInstance: AvailableAppInstanceFragment | CommonAppInstanceTemplate;
  context: AppContextState;
}) {
  // TODO: Remove this check once we fix the issue with the app sharing
  if (
    isAppSharingUnderDevelopment(appInstance as AppInstance) &&
    !isUnderDevelopment(context)
  ) {
    return false;
  }
  if (isPublicTemplateGalleryInstance(appInstance)) {
    return false;
  }

  // Hide share button for canvas template when org enable IS_COMMON_APP_INSTANCE_TEMPLATE ff
  const isOrgEnableCommonAppInstanceTemplate = context.shouldShowFeature(
    FEATURE_FLAGS_ENUM.IS_COMMON_APP_INSTANCE_TEMPLATE,
  );
  if (
    isCanvasTemplateInstance(appInstance) &&
    isOrgEnableCommonAppInstanceTemplate
  ) {
    return false;
  }

  return shouldShowShareButton({ context, shareable: appInstance });
}

export const appIconByAppVersion = (
  appVersion?:
    | AppInstanceByIdForContentFragment
    | AvailableAppInstanceFragment
    | null,
): string => {
  const appIcon =
    appVersion && appVersion.appByAppId && appVersion.appByAppId.iconUrl
      ? appVersion.appByAppId.iconUrl
      : "";
  return appIcon;
};

export const appInstanceIconUrl = (
  appInstance: AppInstanceByIdForContentFragment | AvailableAppInstanceFragment,
): string => {
  const thumbnailSource = appInstance.fileByThumbnailFileId?.source;
  const iconUrl = thumbnailSource
    ? thumbnailSource
    : appIconByAppVersion(appInstance);
  return iconUrl;
};

export const appInstanceIconUrlForPlaylist = (
  appInstance: AppInstanceForContentListFragment,
): string => {
  const thumbnailSource = appInstance.fileByThumbnailFileId?.source;
  const iconUrl = thumbnailSource
    ? thumbnailSource
    : appInstance?.appByAppId?.iconUrl || "";
  return iconUrl;
};

const uploadFile = async (
  file: string,
  fileName: string,
): Promise<FilestackUploadedFile> => {
  // upload file to filestack
  const fileUploaded: FilestackUploadedFile = await client.upload(
    file,
    { onProgress: () => {} },
    {
      filename: fileName,
      access: "public",
      container: appConfig.uploadsBucket,
      location: appConfig.uploadsLocation,
      path: "",
      region: appConfig.s3Region,
    },
  );
  return fileUploaded;
};

export const createAppThumbnail = async (
  file: string,
  fileName: string,
  spaceId: string,
): Promise<string | undefined> => {
  const fileUploaded: FilestackUploadedFile = await uploadFile(file, fileName);
  let metadata = await resolveFileKey({ ...fileUploaded });

  if (fileUploaded?.mimetype?.startsWith("image")) {
    const dimensions = await getImageDimensions(fileUploaded.handle);
    const { width, height } = await dimensions.json();
    metadata = {
      ...metadata,
      height,
      width,
    };
  }
  // create file
  const trimmedFileName = trimToLessThan80Char(fileUploaded.filename);
  const fileInput: CreateFileMutationVariables = {
    input: {
      metadata,
      mimetype: fileUploaded.mimetype,
      name: trimmedFileName,
      size: fileUploaded.size,
      source: `https://${appConfig.uploadsBaseUrl}/${
        appConfig.uploadsBucket
      }/${escape(metadata.key)}`,
      spaceId,
      tags: [],
    },
  };
  const { data } = await apolloClient.mutate<
    CreateFileMutation,
    CreateFileMutationVariables
  >({
    mutation: CreateFileDocument,
    variables: fileInput,
  });
  const createdFile = data && data.createFile && data.createFile.file;

  // return id of file created
  if (createdFile && createdFile.id) {
    return createdFile.id;
  }
  return undefined;
};

/**
 *
 * @param app return duration in millisecond
 */
export const getAppInstanceDuration = (
  app: Partial<AppInstanceByIdForContentFragment>,
) => {
  if (app?.config?.appInstanceDurationInSeconds) {
    return app.config.appInstanceDurationInSeconds * 1000;
  }
  return null;
};

export const getCanvasThumbnail = (
  file: FileListItemFragment | null | undefined,
  secureMediaPolicy: string | undefined,
  width: number = 320,
  height: number = 180,
) => {
  return file?.fileProcessingStatus === JobStatus.Completed
    ? generateImgixURL(file, width, height, secureMediaPolicy)
    : file?.source;
};

export function updateAppInstancesListOrder(
  context: AppContextState,
  cache: ApolloCache<UpdateAppInstanceMutation>,
) {
  cache.modify({
    id: cache.identify(context.currentSpace!),
    fields: {
      availableAppInstancesBySpaceIdAndAppId(publishCh, options) {
        if (options.storeFieldName.includes('"equalTo":')) {
          const reOrder = orderBy(
            publishCh.nodes.map((node) => {
              const name = options.readField("name", node) as string;
              return {
                name,
                node,
              };
            }),
            [(node) => node?.name?.toLowerCase()],
            ["asc"],
          );
          const newAppInstanceList = {
            ...publishCh,
            nodes: reOrder.map((re) => re.node),
            totalCount: publishCh.totalCount,
          };
          return newAppInstanceList;
        } else {
          return publishCh;
        }
      },
    },
  });
}

export function addNewAppinstanceToCache(
  appins,
  options,
  cache: ApolloCache<
    | CreateAppInstanceMutation
    | DuplicateAppInstanceMutation
    | CreateAppInstanceByCommonAppInstanceTemplateIdMutation
  >,
  newAppinstance: AvailableAppInstanceFragment,
  orderField: string = "name",
) {
  const newAppinstanceRef = cache.writeFragment({
    data: newAppinstance,
    fragment: AvailableAppInstanceFragmentDoc,
    fragmentName: "AvailableAppInstance",
  });

  if (
    appins.nodes.some(
      (ref) => options.readField("id", ref) === newAppinstance?.id,
    )
  ) {
    return appins;
  }

  const nodes = [...appins.nodes, newAppinstanceRef];
  const reOrder = orderBy(
    nodes.map((node) => {
      const orderKey = options.readField(orderField, node) as string;
      return {
        orderKey,
        node,
      };
    }),
    [(node) => node?.orderKey?.toLowerCase()],
    orderField === "name" ? ["asc"] : ["desc"],
  );
  const newAppInstanceList = {
    ...appins,
    nodes: reOrder.map((re) => re.node),
    totalCount: appins.totalCount + 1,
  };
  return newAppInstanceList;
}

export function updateNewAppInstanceToList(
  context: AppContextState,
  cache: ApolloCache<CreateAppInstanceMutation | DuplicateAppInstanceMutation>,
  newAppinstance: AvailableAppInstanceFragment,
  orderField: string = "name",
) {
  cache.modify({
    id: cache.identify(context.currentSpace!),
    fields: {
      availableAppInstancesBySpaceIdAndAppId(appins, options) {
        if (
          options.storeFieldName.includes('"equalTo":') &&
          options.storeFieldName.includes(
            '"appId":"' + newAppinstance.appId + '"',
          )
        ) {
          const newAppInstanceList = addNewAppinstanceToCache(
            appins,
            options,
            cache,
            newAppinstance,
            orderField,
          );
          return newAppInstanceList;
        } else {
          return appins;
        }
      },
      availableAppInstancesBySpaceId(appins, options) {
        const newAppInstanceList = addNewAppinstanceToCache(
          appins,
          options,
          cache,
          newAppinstance,
          "createdAt",
        );
        return newAppInstanceList;
      },
    },
  });
}

function modifyCacheField(
  appins: AvailableAppInstanceFragment | CommonAppInstanceTemplate,
  options: CacheOptions,
  cache: ApolloCache<
    | CreateAppInstanceMutation
    | DuplicateAppInstanceMutation
    | CreateAppInstanceByCommonAppInstanceTemplateIdMutation
  >,
  newAppinstance: AvailableAppInstanceFragment,
  isTemplate: boolean,
) {
  if (
    options.storeFieldName.includes('"equalTo":') &&
    options.storeFieldName.includes(`"appId":"${newAppinstance.appId}"`) &&
    options.storeFieldName.includes(
      `"condition":{"isTemplate":${isTemplate}}`,
    ) &&
    !options.storeFieldName.includes('"isSharedAll":{"equalTo":false}')
  ) {
    const newAppInstanceList = addNewAppinstanceToCache(
      appins,
      options,
      cache,
      newAppinstance as AvailableAppInstanceFragment,
    );
    return newAppInstanceList;
  } else {
    return appins;
  }
}

export function updateNewCanvasToList(
  context: AppContextState,
  cache: ApolloCache<
    | CreateAppInstanceMutation
    | DuplicateAppInstanceMutation
    | CreateAppInstanceByCommonAppInstanceTemplateIdMutation
  >,
  newAppinstance: AvailableAppInstanceFragment | CommonAppInstanceTemplate,
  isTemplate: boolean,
) {
  cache.modify({
    id: cache.identify(context.currentSpace!),
    fields: {
      availableAppInstancesBySpaceIdAndAppId(appins, options) {
        return modifyCacheField(
          appins,
          options,
          cache,
          newAppinstance as AvailableAppInstanceFragment,
          isTemplate,
        );
      },
    },
  });
}

export function updateNewTemplateCanvasToList(
  context: AppContextState,
  cache: ApolloCache<CreateAppInstanceMutation | DuplicateAppInstanceMutation>,
  newAppinstance: AvailableAppInstanceFragment,
) {
  cache.modify({
    id: cache.identify(context.currentSpace!),
    fields: {
      availableAppInstancesBySpaceIdAndAppId(appins, options) {
        if (
          options.storeFieldName.includes('"equalTo":') &&
          options.storeFieldName.includes('"condition":{"isTemplate":true}')
        ) {
          const newAppInstanceList = addNewAppinstanceToCache(
            appins,
            options,
            cache,
            newAppinstance,
          );
          return newAppInstanceList;
        } else {
          return appins;
        }
      },
    },
  });
}

export function updateAppinstanceListOrder(
  context: AppContextState,
  cache: ApolloCache<UpdateAppInstanceMutation>,
) {
  cache.modify({
    id: cache.identify(context.currentSpace!),
    fields: {
      availableAppInstancesBySpaceIdAndAppId(appins, options) {
        if (options.storeFieldName.includes('"equalTo":')) {
          const nodes = [...appins.nodes];
          const reOrder = orderBy(
            nodes.map((node) => {
              const name = options.readField("name", node) as string;
              return {
                name,
                node,
              };
            }),
            [(node) => node?.name?.toLowerCase()],
            ["asc"],
          );
          const newAppInstanceList = {
            ...appins,
            nodes: reOrder.map((re) => re.node),
            totalCount: appins.totalCount,
          };
          return newAppInstanceList;
        } else {
          return appins;
        }
      },
    },
  });
}

export function renderAppNavBar(
  context: AppContextState,
  shouldRenderSharedTab: boolean,
) {
  const canReadApp = context.currentPermissions.validateCurrentSpace(
    "app",
    "read",
  );
  const hasMultipleSpaces = context.allSpaces.length > 0;
  const APP_NAV = [
    {
      text: "app store",
      route: APP_STORE_DISCOVER_PATH_NANE,
      isVisible: true,
    },
    {
      text: "my apps",
      route: "/apps/",
      isVisible: canReadApp,
    },
    {
      text: "shared by others",
      route: "/apps-shared/",
      isVisible: shouldRenderSharedTab && hasMultipleSpaces,
    },
  ];
  return <Nav className="custom-tab" customTabs={APP_NAV} />;
}

export const getDeleteAppInstanceConfirmMsg = async (
  appInstance: AppInstance,
  castedScreens?: number,
  castedScreensData?: CastedScreen[],
) => {
  const app = await queryHelper.getAppInstanceAssociation(appInstance.id);
  let message = (
    <>
      <h2>
        <FormattedMessage
          id="ui_component.confirm.delete_heading_name"
          defaultMessage="Delete {name}?"
          values={{ name: appInstance.name }}
        />
      </h2>
      <p>
        <FormattedMessage
          id="ui_component.confirm.delete_message_with_cast"
          defaultMessage="{screenCount, select, 0 {{name} will be deleted permanently and this action cannot be undone.} 1 {{name} is currently casting on {screenName}. Are you sure you want to continue? This action cannot be undone.} other {{name} is currently casting on {screenCount} screens, Are you sure you want to continue? This action cannot be undone.}}"
          values={{
            name: <strong>{appInstance.name}</strong>,
            screenCount: castedScreens ?? 0,
            screenName: (
              <strong>
                {castedScreensData && castedScreensData.length
                  ? castedScreensData[0].name
                  : ""}
              </strong>
            ),
          }}
        />
      </p>
    </>
  );
  if (
    app?.data?.appInstanceById?.associationsByToAppInstanceAndOrgId
      .totalCount ??
    0 > 0
  ) {
    const places = getTheUsedPlace(
      app?.data.appInstanceById?.associationsByToAppInstanceAndOrgId.nodes,
    );
    message = <DeleteWarning name={appInstance.name ?? ""} places={places} />;
  }
  return message;
};

export const handleUpdateInstanceName = async (
  appId: AppInstance,
  name: string,
  spaceId: UUID,
) => {
  const updateAppInstanceVar: UpdateAppInstanceMutationVariables = {
    input: {
      id: appId,
      name,
      spaceId,
    },
  };

  try {
    await apolloClient.mutate<
      UpdateAppInstanceMutation,
      UpdateAppInstanceMutationVariables
    >({
      mutation: UpdateAppInstanceDocument,
      variables: updateAppInstanceVar,
    });
  } catch (err) {
    console.error(err);
  }
};

export const getEditAppInstanceUrl = (
  context: AppContextState,
  appInstance: AppInstance,
) => {
  const isCanvas = appInstance.appId === context.canvasAppId;
  if (isCanvas) {
    const isTemplateGallery = false;
    const isCanvasTemplate = appInstance.isTemplate;
    const canvasUrl = getCanvasRoute(isTemplateGallery, isCanvasTemplate);
    return `${canvasUrl}${appInstance.appInstallId}/${appInstance.id}`;
  }
  return `/apps/${appInstance.appInstallId}/${appInstance.id}`;
};

export const getCloseAppUrlRedirect = (
  context: AppContextState,
  appInstance: AppInstance,
) => {
  const isCanvas = appInstance.appId === context.canvasAppId;
  if (isCanvas) {
    const isTemplateGallery = false;
    const isCanvasTemplate = appInstance.isTemplate;
    const canvasUrl = getCanvasRoute(isTemplateGallery, isCanvasTemplate);
    return canvasUrl;
  }
  return `/apps/${appInstance.appId}`;
};
export const onEditAppInstance = async (
  context: AppContextState,
  appInstance: AppInstance | CommonAppInstanceTemplate,
) => {
  if (appInstance) {
    const url = getEditAppInstanceUrl(context, appInstance as AppInstance);
    context.history.push(url);

    await context.modal.openAppConfigure(
      appInstance.id,
      "",
      undefined,
      undefined,
      <FormattedMessage id="apps.configure" defaultMessage="Configure" />,
      {
        opts: {
          className: "app-config-modal",
          closeButtonPosition: CloseButtonPosition.LEFT,
          disableTitle: true,
          size: ModalSize.FULLSCREEN,
        },
        onClose: () => {
          const url = getCloseAppUrlRedirect(
            context,
            appInstance as AppInstance,
          );
          context.history.replace(url);
        },
      },
    );
  }
};

const handleShare = async (
  selectedApp: AppInstance,
  sharedSpaceIds: string[],
) => {
  const updateAppInstanceInput: UpdateShareAppInstanceToSpacesMutationVariables =
    {
      input: {
        appInstanceId: selectedApp.id,
        spaces: sharedSpaceIds,
      },
    };
  await apolloClient.mutate<
    UpdateShareAppInstanceToSpacesMutation,
    UpdateShareAppInstanceToSpacesMutationVariables
  >({
    mutation: UpdateShareAppInstanceToSpacesDocument,
    variables: updateAppInstanceInput,
  });
};

const handleShareAll = async (selectedApp: AppInstance, value: boolean) => {
  const sharedAllInput: UpdateShareAppInstanceToAllSpacesMutationVariables = {
    input: {
      appInstanceId: selectedApp.id,
      isSharedAll: value,
    },
  };

  await apolloClient.mutate<
    UpdateShareAppInstanceToAllSpacesMutation,
    UpdateShareAppInstanceToAllSpacesMutationVariables
  >({
    mutation: UpdateShareAppInstanceToAllSpacesDocument,
    variables: sharedAllInput,
  });
};

export const showShareModal = (
  context: AppContextState,
  selectedApp: AppInstance,
) => {
  const sharedSpaces = selectedApp.sharedSpacesByAppInstanceId.nodes;

  context.modal.openModal(
    <ShareModal
      shareable={selectedApp}
      sharedSpaces={sharedSpaces}
      isDisabled={!canBeShared({ shareable: selectedApp, context })}
      onShareToSpaceChanges={(spaceIds) => handleShare(selectedApp, spaceIds)}
      onShareAllChange={(sharedSpaceIds) =>
        handleShareAll(selectedApp, sharedSpaceIds)
      }
    />,
    <>
      {(context.allSpaces?.length ?? 0) > 1 ? (
        <>
          <FormattedMessage
            id="common.label.share_app_instance"
            defaultMessage="Share App Instance"
          />
          : {selectedApp.name}
        </>
      ) : (
        <>Share {selectedApp.name} with others</>
      )}
    </>,
    {
      opts: {
        size:
          (context.allSpaces ?? []).length > 1
            ? ModalSize.MEDIUM
            : ModalSize.SMALL,
        disableTitle: true,
      },
    },
  );
};

export const handleAddToPlaylists = async (
  context: AppContextState,
  appInstanceIds: string[],
  addAppsToPlaylists: UseAddAppsToPlaylistsMutation["addAppsToPlaylists"],
) => {
  context.modal.openModal(
    <AddToPlaylistModal
      onAddToPlaylistClick={async (playlists: Playlist[]) => {
        try {
          await addAppsToPlaylists({ appInstanceIds, playlists });
          context.modal.closeModals();
        } catch (error) {
          context.modal.closeModals();
          console.error(error);
        }
      }}
    />,
    "Select playlists",
    {
      opts: {
        closeOnDimmerClick: true,
        size: ModalSize.LARGE,
      },
    },
  );
};

export const handleDeleteAppInstance = async (
  context: AppContextState,
  appInstance: AppInstance,
  deleteAppInstance: UseDeleteAppInstance["deleteAppInstance"],
) => {
  try {
    const message = await getDeleteAppInstanceConfirmMsg(
      appInstance,
      appInstance.castedScreenByAppInstanceId.totalCount,
      appInstance.castedScreenByAppInstanceId.nodes,
    );
    const { confirm } = await context.modal.confirm(message, {
      confirm: (
        <FormattedMessage
          id="ui_component.common.label.delete"
          defaultMessage="Delete"
        />
      ),
      isDanger: true,
    });
    if (confirm) {
      deleteAppInstance({
        appInstanceId: appInstance.id,
        appId: appInstance.appId,
        isTemplate: appInstance.isTemplate,
        isShared: !!(appInstance.isSharedAll || appInstance.isSharedExplicit),
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const handleCastScreen = async (
  context: AppContextState,
  appInstance: AppInstance,
  spaceId: UUID,
) => {
  try {
    const result = await context.modal.openScreenPicker(appInstance);
    if (result.action === ScreenPickerActions.SET_CONTENT) {
      const setContents = result.data.map((screenId) => {
        const setAppContentVar: SetScreenContentByAppInstanceIdMutationVariables =
          {
            input: {
              screenId,
              appInstanceId: appInstance.id,
            },
          };
        return apolloClient.mutate<
          SetScreenContentByAppInstanceIdMutation,
          SetScreenContentByAppInstanceIdMutationVariables
        >({
          mutation: SetScreenContentByAppInstanceIdDocument,
          variables: setAppContentVar,
        });
      });
      await Promise.all(setContents);
      context.modal.closeModals();
    } else {
      if (result.data.length > 0) {
        const castStartVar: CreateCastsByAppInstanceIdMutationVariables = {
          input: {
            screenIds: result.data,
            appInstanceId: appInstance.id,
            expiresAt: result.expiresAt,
          },
        };

        await apolloClient
          .mutate<
            CreateCastsByAppInstanceIdMutation,
            CreateCastsByAppInstanceIdMutationVariables
          >({
            mutation: CreateCastsByAppInstanceIdDocument,
            variables: castStartVar,
            update: (cache, { data }) => {
              const cast =
                data?.createCastsByAppInstanceId?.casts &&
                data?.createCastsByAppInstanceId.casts[0];
              modifyCastScreenByAppInstance(cache, cast, appInstance);
            },
          })
          .then(() => {
            queryHelper.updateCastedScreen(spaceId);
            context.modal.closeModals();
          });
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export const handleDuplicateApp = async (
  context: AppContextState,
  appInstance: AppInstance,
  duplicateAppInstance: DuplicateAppInstanceMutationFn,
) => {
  const newAppInsatnce = await onDuplicateInstance({
    appInstance,
    appId: appInstance.appId,
    duplicateAppInstance,
    context,
    isTemplate: appInstance.isTemplate,
  });
  if (newAppInsatnce) {
    onEditAppInstance(context, newAppInsatnce);
  }
};

export interface HandleAppInstanceAction extends UseDeleteAppInstance {
  context: AppContextState;
  appInstance: AppInstance;
  action: AppInstanceActions;
  newName?: string;
  duplicateAppInstance: DuplicateAppInstanceMutationFn;
  addAppsToPlaylists: UseAddAppsToPlaylistsMutation["addAppsToPlaylists"];
}

export const handleAppInstanceAction = async (
  props: HandleAppInstanceAction,
) => {
  const {
    context,
    appInstance,
    action,
    newName,
    duplicateAppInstance,
    deleteAppInstance,
    addAppsToPlaylists,
  } = props;
  const spaceId = context.user.settings.spaceId;
  switch (action) {
    case AppInstanceActionEnums.RENAME:
      if (newName) {
        await handleUpdateInstanceName(appInstance.id, newName, spaceId);
      }
      break;
    case AppInstanceActionEnums.EDIT:
    case AppInstanceActionEnums.EDIT_ORIGINAL:
      await onEditAppInstance(context, appInstance);
      break;
    case AppInstanceActionEnums.DELETE:
      await handleDeleteAppInstance(context, appInstance, deleteAppInstance);
      break;
    case AppInstanceActionEnums.CAST:
      await handleCastScreen(context, appInstance, spaceId);
      break;
    case AppInstanceActionEnums.SHARE:
      showShareModal(context, appInstance);
      break;
    case AppInstanceActionEnums.DUPLICATE:
      await handleDuplicateApp(context, appInstance, duplicateAppInstance);
      break;
    case AppInstanceActionEnums.ADD_TO_PLAYLIST:
      await handleAddToPlaylists(context, [appInstance.id], addAppsToPlaylists);
      break;
  }
};

export interface HandleAppInstanceActionFacade {
  appInstance: AppInstance;
  action: AppInstanceActions;
  newName?: string;
}

export interface UseHandleAppInstanceAction {
  handleAppInstanceAction: (
    props: HandleAppInstanceActionFacade,
  ) => Promise<void>;
}

export const useHandleAppInstanceAction = () => {
  const context = useAppContext();
  const { deleteAppInstance } = useDeleteAppInstance();
  const { addAppsToPlaylists } = useAddAppsToPlaylistsMutation();

  const [duplicateAppInstance] = useDuplicateAppInstanceMutation();
  const handleAppInstanceActionFacade = (
    props: HandleAppInstanceActionFacade,
  ) => {
    return handleAppInstanceAction({
      ...props,
      context,
      deleteAppInstance,
      duplicateAppInstance,
      addAppsToPlaylists,
    });
  };
  return { handleAppInstanceAction: handleAppInstanceActionFacade };
};
