import {
  DataValue,
  withApollo,
  WithApolloClient,
} from "@apollo/client/react/hoc";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "../../../../../utils/compose";

import {
  AllCredentialsBySpaceIdDocument,
  CreateCredentialMutationFn,
  IdentityProviderLaunchUrlQuery,
  withCreateCredential,
  withIdentityProviderLaunchUrl,
} from "../../../../../types.g";

import { ssm } from "../../../../../state/session/ssm";
import { CredentialModalProps } from "./AddNewCredentialModal";

export interface ApolloProps
  extends RouteComponentProps<any>,
    WithApolloClient<{}> {
  identityProviderLaunchUrl: DataValue<IdentityProviderLaunchUrlQuery>;
  createCredential: CreateCredentialMutationFn;
}

export const withData = compose(
  withRouter,
  withApollo,
  withIdentityProviderLaunchUrl({
    name: "identityProviderLaunchUrl",
    options: (props: CredentialModalProps) => {
      return {
        fetchPolicy: "network-only",
        variables: {
          input: {
            type: props.identityProvider.type,
            id: props.identityProvider.id,
          },
        },
      };
    },
  }),
  withCreateCredential({
    name: "createCredential",
    options: {
      refetchQueries: [
        {
          query: AllCredentialsBySpaceIdDocument,
          variables: { spaceId: ssm.current.settings.spaceId },
        },
      ],
    },
  }),
);
