import { Icon } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import {
  ConnectDropTarget,
  DragElementWrapper,
  DragSourceOptions,
} from "react-dnd";
import { ActionMessage } from "../reducers";
import { LoopControlContainer } from "../styles";
import { renderAction } from "./factory";
import { LoopAction, SiteRecorderAction } from "./models";

export enum ActionListItemType {
  Action = "action",
  StartLoop = "start-loop",
  EndLoop = "end-loop",
  ChildAction = "child-action",
}

export interface ActionListItemProps {
  index: number;
  type: ActionListItemType;
  action: SiteRecorderAction;
  dispatch: (action: ActionMessage) => void;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  onItemDropped: (originalIndex: number, newIndex: number) => void;
  removeItem: (index: number) => void;
  connectDragSource?: DragElementWrapper<DragSourceOptions>;
  connectDropTarget?: ConnectDropTarget;
  isDragging?: boolean;
  step?: string;
  selectItem: (index: number) => void;
  currentEditorActionId: string | undefined;
}

const BaseActionListItem: React.FC<ActionListItemProps> = (props) => {
  let item: JSX.Element | undefined;

  switch (props.type) {
    case ActionListItemType.StartLoop:
      item = (
        <LoopControl
          action={props.action}
          index={props.index}
          step={props.step}
          removeItem={props.removeItem}
          selectItem={props.selectItem}
        />
      );
      break;
    case ActionListItemType.EndLoop:
      item = (
        <LoopControl
          action={props.action}
          index={props.index}
          removeItem={props.removeItem}
          selectItem={props.selectItem}
          end
        />
      );
      break;
    default:
      item = renderAction(
        props.action,
        props.index,
        props.removeItem,
        props.selectItem,
        props.step,
        props.currentEditorActionId,
      );
  }

  const isChildAction = props.type === ActionListItemType.ChildAction;

  return (
    <div
      style={{
        opacity: props.isDragging ? 0 : 0.99,
        marginLeft: isChildAction ? 42 : 0,
        borderLeft: isChildAction ? `5px dashed #fecf00` : `none`,
      }}
    >
      {item}
    </div>
  );
};

export const ActionListItem = BaseActionListItem;

interface LoopControlProps {
  action: LoopAction;
  step?: string;
  end?: boolean;
  index: number;
  removeItem: (index: number) => void;
  selectItem: (index: number) => void;
}

const LoopControl: React.FC<LoopControlProps> = ({
  action,
  step,
  end,
  index,
  removeItem,
  selectItem,
}) => {
  return (
    <LoopControlContainer>
      <div className="index">{step}</div>
      <div className="container">
        <div className="drag-control">
          <Icon name="drag" className="icon" />
        </div>
        <div className="details" onClick={() => selectItem(index)}>
          <div className="name">{end ? "End" : "Start"} loop</div>
          <div className="detail">
            Repeat {action.config.loops} time{action.config.loops > 1 && "s"}
          </div>
        </div>
        <div className="delete-control" onClick={() => removeItem(index)}>
          <Icon name="remove-fill" className="icon" />
        </div>
      </div>
    </LoopControlContainer>
  );
};
