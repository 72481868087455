import {
  Exact,
  LinksOrderBy,
  Maybe,
  PageInfo,
  SearchLinkQueryHookResult,
  SearchLinkQueryVariables,
  useSearchLinkQuery,
} from "../types.g";
import { useAppContext } from "./useAppContext";
import { useEffect, useState } from "react";
import {
  renderFetchMoreButton,
  renderFetchMorePickerButton,
} from "src/helpers/generalHelper";

export interface UseSearchLink {
  links: NonNullable<
    NonNullable<SearchLinkQueryHookResult["data"]>["searchLink"]
  >["nodes"];
  hasNextPage: boolean;
  pageInfo?: Pick<PageInfo, "endCursor" | "hasNextPage">;
  loadMore: () => void;
  loading: boolean;
  isLoading: boolean;
  refetch: (
    variables?: Partial<
      Exact<{ spaceId: any; endCursor: any; orderBy: Maybe<LinksOrderBy[]> }>
    >,
  ) => void;
  isFirstTimeAlreadyLoaded: boolean;
  totalCount: number;
  renderFetchMoreButton: React.ReactNode;
  renderFetchMorePickerButton?: React.ReactNode;
}

interface Prop {
  query: string;
}

export function useSearchLink({ query }: Prop): UseSearchLink {
  const context = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [isFirstTimeAlreadyLoaded, setIsFirstTimeAlreadyLoaded] =
    useState(false);

  const variables: SearchLinkQueryVariables = {
    query,
    spaceId: context.currentSpace?.id,
    endCursor: null,
  };

  const {
    data: searchLinkQuery,
    fetchMore,
    refetch,
    loading,
  } = useSearchLinkQuery({
    variables,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !query,
  });

  const loadMore = () => {
    setIsLoading(true);
    if (!loading) {
      fetchMore({
        variables: {
          endCursor: searchLinkQuery?.searchLink?.pageInfo.endCursor,
        },
      }).then(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    setHasNextPage(
      Boolean(searchLinkQuery?.searchLink?.pageInfo.hasNextPage) &&
        searchLinkQuery?.searchLink?.nodes!.length! <
          searchLinkQuery?.searchLink?.totalCount!,
    );
  }, [
    searchLinkQuery?.searchLink?.pageInfo.hasNextPage,
    searchLinkQuery?.searchLink?.totalCount,
  ]);

  useEffect(() => {
    setIsFirstTimeAlreadyLoaded(Boolean(searchLinkQuery?.searchLink));
  }, [searchLinkQuery?.searchLink]);

  const currentItemsCount = searchLinkQuery?.searchLink?.nodes.length ?? 0;
  const totalCount = searchLinkQuery?.searchLink?.totalCount ?? 0;

  return {
    links: searchLinkQuery?.searchLink?.nodes ?? [],
    refetch,
    loading,
    isLoading,
    loadMore,
    hasNextPage,
    pageInfo: searchLinkQuery?.searchLink?.pageInfo,
    isFirstTimeAlreadyLoaded,
    totalCount,
    renderFetchMoreButton: renderFetchMoreButton(
      currentItemsCount,
      totalCount,
      isLoading,
      hasNextPage,
      loadMore,
    ),
    renderFetchMorePickerButton: renderFetchMorePickerButton(
      currentItemsCount,
      totalCount,
      isLoading,
      hasNextPage,
      loadMore,
    ),
  };
}
