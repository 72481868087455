import { UNKNOWN_PLATFORM } from "../constants/constants";
import { UseAllScreens } from "../hooks/useAllScreens";
import {
  Screen,
  ScreenDetailQuery,
  ScreensByScreenGroupIdAndOrgIdFragment,
} from "../types.g";

/**
 * @description When platform is other or not available, we will fallback to the platform from deviceInfo.
 *
 * @link https://github.com/screencloud/signage-next-frontend/issues/12376
 */
export const getPlatform = (
  screen:
    | Partial<Screen>
    | Partial<ScreenDetailQuery["screenById"]>
    | Partial<ScreensByScreenGroupIdAndOrgIdFragment>
    | Partial<UseAllScreens["allScreens"][number]>
    | null
    | undefined,
): string => {
  const platform =
    screen?.devicePlatform && screen?.devicePlatform !== "other"
      ? screen?.devicePlatform
      : screen?.deviceInfo?.native_player?.platform;

  if (!platform) return UNKNOWN_PLATFORM;

  return platform;
};
