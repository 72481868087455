import { orderBy, uniqBy } from "lodash";
import { fieldsPolicyType } from "./apolloClient";

const playlistPolicy = {
  merge(existing, incoming, options) {
    const existingNodes = existing?.nodes ? [...existing.nodes] : [];
    const incomingNodes = incoming?.nodes ? [...incoming.nodes] : [];
    const nodes =
      incomingNodes.length < existingNodes.length &&
      options.variables?.endCursor === null
        ? incomingNodes
        : uniqBy([...existingNodes, ...incomingNodes], "__ref");

    const reOrder = orderBy(
      nodes.map((node) => {
        const name = options.readField("name", node) as string;
        return {
          name,
          node,
        };
      }),
      [(node) => node?.name?.toLowerCase()],
      ["asc"],
    );
    const finalNodesRef = reOrder.map((re) => re.node);
    return {
      ...options.mergeObjects(existing, incoming),
      nodes: finalNodesRef,
    };
  },
};

export const playlistListField: fieldsPolicyType = {
  publishedPlaylistsBySpaceId: {
    keyArgs: ["filter", "spaceId", "orderBy"],
    ...playlistPolicy,
  },
};

export const playlistPickerField: fieldsPolicyType = {
  playlistsByOrgId: {
    keyArgs: ["filter", "orgId", "spaceId", "orderBy"],
    ...playlistPolicy,
  },
};

export const searchPlaylistField: fieldsPolicyType = {
  searchPlaylist: {
    keyArgs: ["query", "spaceId", "orderBy", "filter"],
    merge(existing, incoming, options) {
      const existingNodes = existing ? [...existing.nodes] : [];
      const nodes = uniqBy([...existingNodes, ...incoming.nodes], "__ref");
      const pageInfo =
        !options.variables?.endCursor && existing?.pageInfo
          ? existing.pageInfo
          : incoming?.pageInfo;
      return {
        ...options.mergeObjects(existing, incoming),
        nodes,
        pageInfo,
      };
    },
  },
};
