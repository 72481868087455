import { getCastedDate, getExpiredDate } from "src/domains/cast";
import { appInstanceIconUrl } from "src/helpers/appHelper";
import { getChannelCover } from "src/helpers/channelHelper";
import { generateImgixThumbnail } from "src/helpers/mediaHelper";
import { AppInstance, Cast } from "src/types.g";
import { differenceInSeconds } from "date-fns";
import { CastItemProps, MimeType } from "./types";

export const getCastItems = (
  casts: Cast[],
  secureMediaPolicy: string,
): CastItemProps[] => {
  const results = casts.map((props) => getCastItem(props, secureMediaPolicy));
  return results.filter(Boolean) as CastItemProps[];
};

export const getCastItem = (
  castItem: Cast,
  secureMediaPolicy: string,
): CastItemProps | undefined => {
  const castedDate = getCastedDate(castItem);
  const expireDate = getExpiredDate(castItem);
  const duration = expireDate
    ? differenceInSeconds(new Date(expireDate), new Date())
    : undefined;
  if (castItem && castItem.channelByCastId) {
    const channel = castItem.channelByCastId;
    const cover = getChannelCover(channel, secureMediaPolicy);
    const coverStyle =
      cover.indexOf("http") !== -1
        ? { backgroundImage: "url(" + cover + ")" }
        : { backgroundImage: cover };

    return {
      castedDate,
      duration,
      id: castItem.id,
      mimeType: MimeType.CHANNEL,
      name: channel.name,
      coverStyle,
      screenCount: castItem.screensByCastId
        ? castItem.screensByCastId.nodes.length
        : 0,
      source: "",
    };
  }
  if (castItem && castItem.fileByCastId) {
    const file = castItem.fileByCastId;
    const thumbnail = generateImgixThumbnail(file, secureMediaPolicy, true);
    return {
      castedDate,
      duration,
      id: castItem.id,
      mimeType: file.mimetype as MimeType,
      name: file.name,
      screenCount: castItem.screensByCastId
        ? castItem.screensByCastId.nodes.length
        : 0,
      source: thumbnail,
    };
  }
  if (castItem && castItem.playlistByCastId) {
    const playlist = castItem.playlistByCastId;
    return {
      color: playlist.color!,
      castedDate,
      duration,
      id: castItem.id,
      mimeType: MimeType.PLAYLIST,
      name: playlist.name,
      screenCount: castItem.screensByCastId
        ? castItem.screensByCastId.nodes.length
        : 0,
      source: "",
    };
  }
  if (castItem && castItem.linkByCastId) {
    const link = castItem.linkByCastId;
    return {
      castedDate,
      duration,
      id: castItem.id,
      mimeType: MimeType.LINK,
      name: link.name,
      screenCount: castItem.screensByCastId
        ? castItem.screensByCastId.nodes.length
        : 0,
      source: (link.fileByFileId && link.fileByFileId.source) || "",
    };
  }
  if (castItem && castItem.siteByCastId) {
    const site = castItem.siteByCastId;
    return {
      castedDate,
      duration,
      id: castItem.id,
      mimeType: MimeType.SITE,
      name: site.name,
      screenCount: castItem.screensByCastId
        ? castItem.screensByCastId.nodes.length
        : 0,
      source: site.fileByThumbnailId
        ? generateImgixThumbnail(site.fileByThumbnailId, secureMediaPolicy)
        : "",
    };
  }
  if (castItem && castItem.appInstanceByCastId) {
    const appInstance = castItem.appInstanceByCastId;
    const iconUrl = appInstanceIconUrl(appInstance as unknown as AppInstance);
    return {
      castedDate,
      duration,
      id: castItem.id,
      mimeType: MimeType.APP,
      name: appInstance.name!,
      screenCount: castItem.screensByCastId
        ? castItem.screensByCastId.nodes.length
        : 0,
      source: iconUrl,
    };
  }
  return undefined;
};
