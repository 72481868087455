import { CANVAS_ROUTE } from "src/constants/constants";

export const CANVAS_TABS = [
  CANVAS_ROUTE.CANVAS_TEMPLATE_GALLERY,
  CANVAS_ROUTE.CANVAS,
  CANVAS_ROUTE.CANVAS_TEMPLATE,
];

export const getActiveIndexByPath = (pathname: string) => {
  if (pathname.includes(CANVAS_ROUTE.CANVAS_TEMPLATE_GALLERY)) {
    return 0;
  } else if (pathname.includes(CANVAS_ROUTE.CANVAS_TEMPLATE)) {
    return 2;
  } else {
    return 1;
  }
};

export const getCanvasRoute = (
  isTemplateGallery: boolean,
  isTemplate: boolean,
) => {
  if (isTemplateGallery) {
    return `${CANVAS_ROUTE.CANVAS_TEMPLATE_GALLERY}/`;
  } else if (isTemplate) {
    return `${CANVAS_ROUTE.CANVAS_TEMPLATE}/`;
  } else {
    return `${CANVAS_ROUTE.CANVAS}/`;
  }
};
