import { Maybe } from "graphql/jsutils/Maybe";
import { ModalHeader } from "src/AppContextProvider/modals/type";
import { Site } from "src/types.g";
import { useAppContext } from "src/hooks/useAppContext";
import UpdateCredentialControls from "./updateCredentialControls";
import { useEffect, useState } from "react";
import { withUpdateCredentialsData } from "src/pages/Sites/apollo";
import Feedback, { FeedbackTypes } from "../SiteFeedbackModal/feedback";

interface SiteCredentialUpdateModalProps {
  title?: ModalHeader;
  site: Maybe<Partial<Site>>;
  onCredentialUpdated: () => void;
  onCancel: () => void;
  updateSiteCredentials: (options: {
    variables: {
      input: {
        spaceId: string;
        usernamePasswordCredentialId: string;
        username: string;
        password: string;
        secretKeyCredentialId?: string;
        secretKey?: string;
      };
    };
  }) => Promise<{
    data: {
      updateSiteCredentials: {
        success: boolean;
      };
    };
  }>;
  refetchSites: () => Promise<void>;
}

const CredentialsActionTypes = {
  USERNAME: "browser/send/username",
  PASSWORD: "browser/send/password",
  SECRET_KEY: "browser/send/otc",
};

type DashboardUpdateState = "VALID" | FeedbackTypes;

/**
 *
 * Process the site actions to get the username and password credential ids and the secret key credential id
 * We want to make sure that we only have one username and password credential id and one secret key credential id
 * as we don't support multiple username and password credentials or multiple secret key credentials yet but
 * we want to make sure that we can handle that in the future
 *
 * @param site
 * @returns
 */
const processSiteActions = (
  site: Maybe<Partial<Site>>,
): {
  usernamePasswordCredentialIds: string[];
  secretKeyCredentialIds: string[];
} => {
  if (site?.actions) {
    const result = site.actions.reduce(
      (
        acc: {
          usernamePasswordCredentialIds: Set<string>;
          secretKeyCredentialIds: Set<string>;
        },
        action: {
          type: string;
          config: {
            credentialId: string;
          };
        },
      ) => {
        if (action.type === CredentialsActionTypes.USERNAME) {
          acc.usernamePasswordCredentialIds.add(action.config.credentialId);
          return acc;
        }

        if (action.type === CredentialsActionTypes.PASSWORD) {
          acc.usernamePasswordCredentialIds.add(action.config.credentialId);
          return acc;
        }

        if (action.type === CredentialsActionTypes.SECRET_KEY) {
          acc.secretKeyCredentialIds.add(action.config.credentialId);
          return acc;
        }
        return acc;
      },
      {
        usernamePasswordCredentialIds: new Set(),
        secretKeyCredentialIds: new Set(),
      },
    );
    return {
      usernamePasswordCredentialIds: Array.from(
        result.usernamePasswordCredentialIds,
      ),
      secretKeyCredentialIds: Array.from(result.secretKeyCredentialIds),
    };
  }
  return {
    usernamePasswordCredentialIds: [],
    secretKeyCredentialIds: [],
  };
};

/**
 *
 * Update the site credentials modal
 * We have four different states that we need to handle:
 * 1. The user is not the creator of the site
 * 2. The user has multiple username and password credentials
 * 3. The user has no username and password credentials
 * 4. The user has a single username and password credential and a single secret key credential
 * 5. An error occurred
 *
 * @param props
 * @returns
 */
const UpdateSiteCredentialsModal = (props: SiteCredentialUpdateModalProps) => {
  const context = useAppContext();
  const {
    onCancel,
    onCredentialUpdated,
    site,
    updateSiteCredentials,
    refetchSites,
  } = props;

  const [usernamePasswordCredentialIds, setUsernamePasswordCredentialIds] =
    useState<string[]>([]);
  const [secretKeyCredentialIds, setSecretKeyCredentialIds] = useState<
    string[]
  >([]);
  const [dashboardState, setDashboardState] =
    useState<DashboardUpdateState | null>(null);

  const updateCredentials = async (
    usernamePasswordCredentialId: string,
    username: string,
    password: string,
    secretKeyCredentialId?: string,
    secretKey?: string,
  ) => {
    const result = await updateSiteCredentials({
      variables: {
        input: {
          spaceId: context.currentSpace?.id,
          usernamePasswordCredentialId,
          username,
          password,
          secretKeyCredentialId,
          secretKey,
        },
      },
    });

    if (result.data.updateSiteCredentials.success) {
      await refetchSites();
      onCredentialUpdated();
    } else {
      setDashboardState("ERROR");
    }
  };

  useEffect(() => {
    if (site) {
      const { usernamePasswordCredentialIds, secretKeyCredentialIds } =
        processSiteActions(site);
      setUsernamePasswordCredentialIds(usernamePasswordCredentialIds);
      setSecretKeyCredentialIds(secretKeyCredentialIds);

      if (site.createdBy !== context.currentUser?.id) {
        setDashboardState("INVALID_USER");
      } else if (usernamePasswordCredentialIds.length > 1) {
        setDashboardState("MULTIPLE_CREDENTIALS");
      } else if (!usernamePasswordCredentialIds.length) {
        setDashboardState("NO_CREDENTIALS");
      } else {
        setDashboardState("VALID");
      }
    }
  }, [site]);

  /**
   * Render the component based on the dashboard state, there are five different states that we need to handle
   * 1. The user is not the creator of the site
   * 2. The user has multiple username and password credentials
   * 3. The user has no username and password credentials
   * 4. The user has a single username and password credential and a single secret key credential
   * 5. An error occurred
   *
   */
  return (
    <div>
      {dashboardState === "VALID" && (
        <UpdateCredentialControls
          usernamePasswordCredentialId={usernamePasswordCredentialIds[0]}
          secretKeyCredentialId={secretKeyCredentialIds[0]}
          onCredentialUpdated={updateCredentials}
          onCancel={onCancel}
        />
      )}
      {dashboardState === "INVALID_USER" && <Feedback type="INVALID_USER" />}
      {dashboardState === "MULTIPLE_CREDENTIALS" && (
        <Feedback type="MULTIPLE_CREDENTIALS" />
      )}
      {dashboardState === "NO_CREDENTIALS" && (
        <Feedback type="NO_CREDENTIALS" />
      )}
      {dashboardState === "ERROR" && <Feedback type="ERROR" />}
    </div>
  );
};

export default withUpdateCredentialsData(UpdateSiteCredentialsModal);
