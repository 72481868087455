import {
  Button,
  DeviceIcon,
  Dropdown,
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
  Icon,
  InlineInput,
  ModalSize,
  Popover,
  SCREEN_STATUS_ENUM,
  ScreenState,
  TagInlineLabel,
  TagList,
} from "@screencloud/screencloud-ui-components";
import { UUID } from "@screencloud/uuid";
import React, { SyntheticEvent, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  Operating,
  SleepStatus,
  useIsInOperatingHour,
} from "../../pages/Screens/OffHour/offHour";
import { appState } from "../../../src/state/appState/appState";
import { RefType, SCREEN_TAG_LIMIT } from "../../constants/constants";
import { FEATURE_FLAGS_ENUM } from "../../constants/featureFlag";
import { useAppContext } from "../../hooks/useAppContext";
import { Scalars, Maybe, CastByCastIdFragment, Screen } from "../../types.g";
import ScreenContentPicker, { ContentItem } from "../ScreenContentPicker";

import {
  ScreenItem,
  ScreenItemFooterContainer,
  ScreenItemTag,
  ScreenShortNote,
  Styled,
} from "./styles";
import { getMuteOptionMessage } from "../../helpers/screenHelper";
import { canTransferScreen } from "src/domains/screens/screenTransfer";
import ScreenStatusNotificationButton from "src/components/ScreenStatusNotificatonButton/screenStatusNotificationButton";
import TagSelectorModal from "../TagSelectorModal";
import { DeviceInfo } from "@screencloud/native-players-helpers";
import { ScreenshotModalView } from "src/domains/screens/helpers/actions";
import { canReplaceDevice } from "src/domains/screensManager/permissions";
import { useNoteModal } from "../ScreenNote/hooks/useNoteModal";
import { PopoverNotificationBadges } from "src/pages/Screens/ScreenDetail/components/notificationBadges/notificationBadges";
import { getDeviceModel } from "../../helpers/getDeviceModel";
import { getPlatform } from "../../helpers/getPlatform";

export interface EventProps {
  event: SyntheticEvent<any>;
  data: string[];
}

export interface ReturnEventProps {
  event: SyntheticEvent<any>;
  data: any;
  action: string;
}

export enum ScreenItemActions {
  ChangeChannel = "change_channel",
  CastStart = "cast_start",
  CastStop = "cast_stop",
  ContentStop = "content_stop",
  Refresh = "refresh",
  Rename = "rename",
  Pause = "pause",
  Move = "move",
  Delete = "delete",
  View = "view",
  Transfer = "transfer",
  Location = "location",
  ClearCache = "clear_cache",
  PairNewScreen = "pair_new_screen",
  ReplaceDevice = "replace_device",
  Mute = "mute",
  Tags = "tags",
}

export interface ScreenItemListType {
  callback: (
    screenId: string,
    action: ScreenItemActions,
    data?: string | Maybe<CastByCastIdFragment> | string[],
    isSetContentActive?: boolean,
  ) => void;
  onSelectScreen?: (checked: boolean, id: string) => void;
  onClickCurrentCast?: (castItem: any) => void;
  onHistoryPick?: (id: UUID, type: RefType) => void;
  name: string;
  deviceProvider?: Maybe<string>;
  disabled?: boolean;
  isSelected: boolean;
  isSelectionActive?: boolean;
  status: SCREEN_STATUS_ENUM;
  preview?: boolean;
  playing?: boolean;
  player_height?: number;
  player_width?: number;
  playerTimezone?: string;
  overrideTimezone?: string;
  additionalPlayerSettings?: Scalars["JSON"];
  className?: string;
  url: string;
  screenId: string;
  currentChannelId: string;
  tags: string[];
  isVisible: boolean;
  env?: Scalars["JSON"];
  castByCastId: Maybe<CastByCastIdFragment>;
  contentItem?: ContentItem;
  operatingHours?: Operating;
  isMuted?: boolean;
  isAllowClearCache: boolean;
  screen: Screen;
  availableTags?: string[];
  selectedTagForQuery?: string[];
  shouldShowOptionUpward: boolean;
}

export interface State {
  isSelected: boolean;
  isFocus: boolean;
}

export const handleActionClick = (
  props: ScreenItemListType,
  action: ScreenItemActions,
  castItem?: Maybe<CastByCastIdFragment>,
) => {
  const hasHistory: boolean = false;

  // set fake screen id and name
  if (action === ScreenItemActions.PairNewScreen) {
    appState({
      ...appState(),
      fakeScreenId: props.screenId,
      fakeScreenName: props.name,
    });
  }

  if (props.callback) {
    props.callback(props.screenId, action, castItem, hasHistory);
  }
};

export const ScreenItemList = (props: ScreenItemListType) => {
  const context = useAppContext();
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const {
    className,
    disabled,
    isSelectionActive,
    name,
    player_width,
    player_height,
    preview,
    screenId,
    status,
    tags,
    url,
    isMuted,
    isAllowClearCache,
    env,
    isVisible,
    castByCastId,
    contentItem,
    additionalPlayerSettings,
    overrideTimezone,
    playerTimezone,
    operatingHours,
    onHistoryPick,
    callback,
    onSelectScreen,
    isSelected,
    availableTags,
    selectedTagForQuery,
    screen,
    shouldShowOptionUpward,
  } = props;

  const isReseller = context.shouldShowFeature(FEATURE_FLAGS_ENUM.RESELLER);

  const timezoneOverride = overrideTimezone || playerTimezone;
  const isInOperatingHour = useIsInOperatingHour(
    {
      timezoneOverride,
      timeOffset: 0,
      timelineControlOffset: 0,
    },
    operatingHours,
    context.currentSpace?.preferences?.operating,
  );
  const { openModal: openNoteModal } = useNoteModal();
  const screenNote = props.screen.screenNotesByScreenId?.nodes?.[0];

  const handleNameSave = (event: React.SyntheticEvent<any>, value: string) => {
    setIsFocus(false);
    callback(screenId, ScreenItemActions.Rename, value);
  };

  const onToggleSelect = () => {
    if (
      !isFocus &&
      context.currentPermissions.validateCurrentSpace("screen", "update")
    ) {
      onSelectScreen && onSelectScreen(!isSelected, screenId);
    }
  };

  const onClickFocusInput = (isFocus: boolean) => {
    setIsFocus(isFocus);
  };

  const canDeleteScreen = context.currentPermissions.validateCurrentSpace(
    "screen",
    "delete",
  );
  const canUpdateScreen = context.currentPermissions.validateCurrentSpace(
    "screen",
    "update",
  );
  const canCastScreen = context.currentPermissions.validateCurrentSpace(
    "screen",
    "cast",
  );
  const locationEnv = env ?? {};
  const isLocationExist = Object.keys(locationEnv).some(
    (k) => ~k.indexOf("sc_"),
  );

  const onSaveTags = (tags: string[]) => {
    callback(screenId, ScreenItemActions.Tags, tags);
    context.modal.closeModals();
  };

  const toggleTagSelectorModal = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    context.modal.openModal(
      <TagSelectorModal
        availableTags={availableTags ?? []}
        tags={(props.tags as string[]) ?? []}
        placeholder={context.intl.formatMessage({
          defaultMessage: "add tags",
          id: "canvas.tagging.add_tags",
        })}
        saveText={context.intl.formatMessage({
          defaultMessage: "Save",
          id: "canvas.tagging.save",
        })}
        onSaveTags={onSaveTags}
        limit={SCREEN_TAG_LIMIT}
        subTitle={context.intl.formatMessage({
          id: "screens.tag_limit",
          defaultMessage: "Add up to 10 tags per screen.",
        })}
      />,
      <>
        <FormattedMessage
          id="common.label.edit_tags"
          defaultMessage="Edit tags"
        />
        &nbsp;-&nbsp;{props.screen.name}
      </>,
      {
        opts: {
          size: ModalSize.SMALL,
        },
      },
    );
  };

  return (
    <Styled
      showfeature={context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING)}
      className={className}
      selected={props.isSelected}
      onClick={isSelectionActive ? onToggleSelect : undefined}
      data-testid="screen-item-list"
    >
      <ScreenItem
        canUpdate={canUpdateScreen}
        data-testid={canUpdateScreen ? "editable-screen-item" : "screen-item"}
      >
        {isVisible && (
          <>
            <div className="screen-core">
              <div className="screen-alpha">
                <div
                  className="thumbnail-preview"
                  onClick={() =>
                    handleActionClick(props, ScreenItemActions.View)
                  }
                >
                  <DeviceIcon
                    model={getDeviceModel(props.screen)}
                    platform={getPlatform(props.screen)}
                    showDetails={true}
                    player_width={player_width}
                    player_height={player_height}
                    data-testid="device-icon"
                  />
                </div>
                <div className="screen-title">
                  {!disabled && canUpdateScreen ? (
                    <h3 title={name} data-testid={`screen-title-${name}`}>
                      <InlineInput
                        data-testid="screen-name-input"
                        disabled={isSelectionActive}
                        value={name}
                        onClickFocusInput={onClickFocusInput}
                        onSaved={handleNameSave}
                        url={url}
                      />
                    </h3>
                  ) : (
                    <h3
                      title={name}
                      data-testid={`screen-title-${name}`}
                      onClick={() =>
                        handleActionClick(props, ScreenItemActions.View)
                      }
                    >
                      {name}
                    </h3>
                  )}
                  {!isInOperatingHour && (
                    <SleepStatus data-testid="sleep-status" />
                  )}
                </div>
              </div>
              <div className="screen-player">
                <ScreenContentPicker
                  disabled={isSelectionActive}
                  inline={true}
                  screenId={screenId}
                  castByCastId={castByCastId}
                  contentItem={contentItem}
                  onCastStart={() =>
                    handleActionClick(props, ScreenItemActions.CastStart)
                  }
                  onCastStop={(castItem) =>
                    handleActionClick(
                      props,
                      ScreenItemActions.CastStop,
                      castItem,
                    )
                  }
                  onStopContent={() =>
                    handleActionClick(props, ScreenItemActions.ContentStop)
                  }
                  onHistoryPick={onHistoryPick}
                />
              </div>
              <div className="screen-status">
                <PopoverNotificationBadges messages={screen.messages} mini />
                <div className="screen-state">
                  {preview && <ScreenState status="trial" />}
                  <ScreenState status={status} hideHelpLink={isReseller} />
                </div>
                {additionalPlayerSettings?.output_4k && (
                  <div className="screen-output">
                    <Icon data-testid="4k-icon" name="4k" />
                  </div>
                )}

                {context.shouldShowFeature(FEATURE_FLAGS_ENUM.MUTE_AUDIO) &&
                  isMuted && (
                    <div className="screen-muted">
                      <div data-testid="muted-icon">
                        <Icon name="audio-off" />
                      </div>
                    </div>
                  )}

                <div className="screen-location">
                  {isLocationExist && (
                    <Popover
                      trigger={
                        canUpdateScreen ? (
                          <Button
                            icon
                            mini
                            borderless
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleActionClick(
                                props,
                                ScreenItemActions.Location,
                              );
                            }}
                          >
                            <Icon
                              data-testid="location-button"
                              name="location"
                            />
                          </Button>
                        ) : (
                          <Icon
                            data-testid="location-button-no-permission"
                            name="location"
                          />
                        )
                      }
                      content={
                        <span>
                          {locationEnv?.sc_address ||
                            context.intl.formatMessage({
                              id: "common.text.no-location-set",
                              defaultMessage: "No Location Set",
                            })}
                        </span>
                      }
                      position="top center"
                    />
                  )}
                </div>
                <div className="screen-notification">
                  {context.shouldShowFeature(
                    FEATURE_FLAGS_ENUM.SCREEN_NOTIFICATIONS,
                  ) && (
                    <ScreenStatusNotificationButton
                      iconOnly
                      screen={props.screen}
                    />
                  )}
                </div>
                <div className="screen-tags">
                  <TagList taglist={tags} />
                </div>
              </div>
            </div>
            <div
              className="screen-options"
              data-testid="screen-options"
              data-cy="screen-options"
            >
              {!context.currentOrg?.isDraft && !disabled && (
                <>
                  {!isSelectionActive ? (
                    <Dropdown
                      upward={shouldShowOptionUpward}
                      floating={shouldShowOptionUpward}
                      checkEmpty={true}
                      className="screen-dropdown"
                      data-testid="screen-item-dropdown"
                      icon={
                        <Button icon borderless mini>
                          <Icon name="dots" data-testid="dot-icon" />
                        </Button>
                      }
                    >
                      <DropdownMenu>
                        {!preview && status === "paused" && (
                          <>
                            <DropdownItem
                              data-testid="screens-button-reactivate"
                              onClick={() =>
                                handleActionClick(
                                  props,
                                  ScreenItemActions.Pause,
                                )
                              }
                            >
                              <Icon name="screen-activate" />{" "}
                              <FormattedMessage
                                id="screens.button.reactivate"
                                defaultMessage="Reactivate"
                              />
                            </DropdownItem>
                            <DropdownDivider />
                          </>
                        )}
                        {context.shouldShowFeature(
                          FEATURE_FLAGS_ENUM.CASTING,
                        ) &&
                          canCastScreen && (
                            <>
                              <DropdownItem
                                data-testid="cast-screen-button"
                                onClick={() =>
                                  handleActionClick(
                                    props,
                                    ScreenItemActions.CastStart,
                                  )
                                }
                              >
                                <Icon name="screen-play" />{" "}
                                <FormattedMessage
                                  id="ui_component.common.label.set_content"
                                  defaultMessage="Set Content"
                                />
                              </DropdownItem>
                              <DropdownDivider />
                            </>
                          )}

                        {canUpdateScreen && (
                          <>
                            <DropdownItem
                              data-testid="dropdown-refresh"
                              onClick={() =>
                                handleActionClick(
                                  props,
                                  ScreenItemActions.Refresh,
                                )
                              }
                            >
                              <Icon name="refresh" />{" "}
                              <FormattedMessage
                                id="ui_component.common.label.refresh"
                                defaultMessage="Refresh"
                              />
                            </DropdownItem>
                            {context.shouldShowFeature(
                              FEATURE_FLAGS_ENUM.MUTE_AUDIO,
                            ) && (
                              <DropdownItem
                                data-testid="dropdown-mute-screen"
                                onClick={() =>
                                  handleActionClick(
                                    props,
                                    ScreenItemActions.Mute,
                                  )
                                }
                              >
                                {getMuteOptionMessage(isMuted ?? false)}
                              </DropdownItem>
                            )}
                            {isAllowClearCache && (
                              <DropdownItem
                                data-testid="dropdown-clearcache"
                                onClick={() =>
                                  handleActionClick(
                                    props,
                                    ScreenItemActions.ClearCache,
                                  )
                                }
                              >
                                <Icon name="clear-cache" />{" "}
                                <FormattedMessage
                                  id="ui_component.common.label.clear_cache"
                                  defaultMessage="Clear Cache"
                                />
                              </DropdownItem>
                            )}
                            {context.shouldShowFeature(
                              FEATURE_FLAGS_ENUM.SCREENSHOT,
                            ) &&
                              (screen.deviceInfo as DeviceInfo)?.native_player
                                ?.capabilities?.screenshot && (
                                <DropdownItem
                                  key="screenshot-dropdown"
                                  onClick={() => {
                                    context.modal.openModal(
                                      <ScreenshotModalView
                                        screenId={screen?.id}
                                        screenStatus={status}
                                      />,
                                      context.intl.formatMessage({
                                        defaultMessage: "Screenshot",
                                        id: "ui_component.common.label.screenshot",
                                      }),
                                      {
                                        opts: {
                                          overflow: false,
                                          size: ModalSize.LARGE,
                                        },
                                      },
                                    );
                                  }}
                                >
                                  <Icon
                                    data-testid="screen-screenshot"
                                    name="camera"
                                  />
                                  <FormattedMessage
                                    id="ui_component.common.label.screenshot"
                                    defaultMessage="Screenshot"
                                  />
                                </DropdownItem>
                              )}
                            <DropdownItem
                              data-testid="dropdown-moveto"
                              onClick={() =>
                                handleActionClick(props, ScreenItemActions.Move)
                              }
                            >
                              <Icon name="move" />{" "}
                              <FormattedMessage
                                id="ui_component.screen_list_item.move_to"
                                defaultMessage="Move to"
                              />
                            </DropdownItem>
                            {canTransferScreen(context) && (
                              <DropdownItem
                                data-testid="dropdown-transfer"
                                onClick={() =>
                                  handleActionClick(
                                    props,
                                    ScreenItemActions.Transfer,
                                  )
                                }
                              >
                                <Icon name="org-transfer" />{" "}
                                <FormattedMessage
                                  id="ui_component.screen_list_item.transfer"
                                  defaultMessage="Transfer"
                                />
                              </DropdownItem>
                            )}
                            <DropdownItem
                              data-testid="dropdown-editlocation"
                              onClick={() =>
                                handleActionClick(
                                  props,
                                  ScreenItemActions.Location,
                                )
                              }
                            >
                              <Icon name="location" />{" "}
                              <FormattedMessage
                                id="ui_component.screen_list_item.edit_location"
                                defaultMessage="Edit Location"
                              />
                            </DropdownItem>
                            {props?.deviceProvider === "fake" && (
                              <DropdownItem
                                data-testid="dropdown-pair-screen"
                                onClick={() =>
                                  handleActionClick(
                                    props,
                                    ScreenItemActions.PairNewScreen,
                                  )
                                }
                              >
                                <Icon name="screen" />{" "}
                                <FormattedMessage
                                  id="ui_component.screen_list_item.pair_real_device"
                                  defaultMessage="Pair to Real Device"
                                />
                              </DropdownItem>
                            )}
                            <DropdownItem
                              data-testid="screen-note-action"
                              onClick={() => openNoteModal(props.screen)}
                            >
                              <Icon name="edit" />{" "}
                              <FormattedMessage
                                id="ui_component.common.label.note"
                                defaultMessage="Note"
                              />
                            </DropdownItem>
                            <DropdownDivider />
                          </>
                        )}

                        {canUpdateScreen && !preview && status !== "paused" && (
                          <DropdownItem
                            data-testid="screens-button-deactivate"
                            onClick={() =>
                              handleActionClick(props, ScreenItemActions.Pause)
                            }
                          >
                            <Icon name="screen-deactivate" />{" "}
                            <FormattedMessage
                              id="screens.button.deactivate"
                              defaultMessage="Deactivate"
                            />
                          </DropdownItem>
                        )}
                        {canReplaceDevice(context, screen) && (
                          <DropdownItem
                            data-testid="replace-device-button"
                            onClick={() =>
                              handleActionClick(
                                props,
                                ScreenItemActions.ReplaceDevice,
                              )
                            }
                            className="danger"
                          >
                            <Icon name="swap" />
                            <FormattedMessage
                              id="ui_component.common.label.replace_screen"
                              defaultMessage="Replace Device"
                            />
                          </DropdownItem>
                        )}
                        {canDeleteScreen && (
                          <DropdownItem
                            data-testid="delete-item-button"
                            onClick={() =>
                              handleActionClick(props, ScreenItemActions.Delete)
                            }
                            className="danger"
                          >
                            <Icon name="trash" />{" "}
                            <FormattedMessage
                              id="ui_component.common.label.delete_screen"
                              defaultMessage="Delete Screen"
                            />
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  ) : (
                    <Button
                      mini
                      borderless
                      data-testid="checkbbox"
                      icon
                      className="checkbox"
                    >
                      {isSelected ? (
                        <Icon name="checkbox-checked" className="checked" />
                      ) : (
                        <Icon name="checkbox-empty" />
                      )}
                    </Button>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </ScreenItem>
      <ScreenItemFooterContainer>
        <ScreenItemTag onClick={toggleTagSelectorModal}>
          <TagInlineLabel
            placeholder={context.intl.formatMessage({
              id: "common.text.tags_add_tags",
              defaultMessage: "add tags",
            })}
            tags={props.tags}
            searchTags={selectedTagForQuery}
          />
        </ScreenItemTag>
        {screenNote?.note && screenNote.note.trim() !== "" && (
          <ScreenShortNote
            onClick={(e) => {
              e.stopPropagation();
              openNoteModal(props.screen);
            }}
          >
            {props.screen.screenNotesByScreenId?.nodes?.[0]?.note}
          </ScreenShortNote>
        )}
      </ScreenItemFooterContainer>
    </Styled>
  );
};

export default ScreenItemList;
