import { Dropdown } from "@screencloud/screencloud-ui-components";
import { UUID } from "@screencloud/uuid";
import { FormattedMessage } from "react-intl";
import useScreenNotification from "src/hooks/ScreenNotification/useScreenNotification";
import { NotificationRule } from "src/types.g";

type Props = {
  notificationRule: NotificationRule;
  screenId: UUID;
};

const NotificationThreshold = (props: Props): JSX.Element => {
  const { createNotificationRule, updateNotificationRule } =
    useScreenNotification(props.screenId);

  const handleUpdateNotificationRule = async (waitTime: number) => {
    if (!props.notificationRule) {
      await createNotificationRule(props.screenId, waitTime);
    } else {
      await updateNotificationRule(props.notificationRule.id, waitTime);
    }
  };

  return (
    <div className="notification-threshold">
      <div className="text" data-testid="info">
        <div className="title" data-testid="title">
          <FormattedMessage
            id="ui_component.screen_notification.modal.admin_settings.notification_threshold.title"
            defaultMessage="Notification Threshold"
          />
        </div>
        <div className="sub-title" data-testid="sub-title">
          <FormattedMessage
            id="ui_component.screen_notification.modal.admin_settings.notification_threshold.sub_title"
            defaultMessage="Set an offline notification threshold value. Notifications will only be sent once the screen has been offline longer than this value"
          />
        </div>
      </div>
      <Dropdown
        className="notification-threshold__options"
        data-testid="notification-threshold-options"
        selection
        value={props.notificationRule ? props.notificationRule.waitTime : 0}
        options={[
          { key: "30", text: "30 minutes", value: 1800 },
          { key: "10", text: "10 minutes", value: 600 },
          { key: "5", text: "5 minutes", value: 300 },
          { key: "1", text: "1 minute", value: 60 },
          { key: "0", text: "No delay", value: 0 },
        ]}
        onChange={(_, d) => handleUpdateNotificationRule(Number(d.value))}
      />
    </div>
  );
};

export default NotificationThreshold;
