import {
  Button,
  ButtonDropdown,
  Dropdown,
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
  Icon,
  InlineInput,
  ModalSize,
  Popover,
  SyncStateText,
} from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useDuplicateChannel } from "src/hooks/duplication/useDuplicateChannel";
import { getDuplicateInstanceName } from "src/utils/duplication";
import { isFromDetailPage } from "src/utils/url";
import { ChannelDetailMode } from "..";
import { CastedScreenInfoActions } from "../../../../components/CastedScreenInfo";
import DateTime from "../../../../components/DateTime";
import Header from "../../../../components/Header";
import { ScreenPickerActions } from "../../../../components/ScreenPicker";
import ShareModal, { EmbedStatus } from "../../../../components/ShareModal";
import UpdatedByUser from "../../../../components/UpdatedByUser";
import UserName from "../../../../components/UserName";
import { NameMaxLengthAllow } from "../../../../constants/constants";
import { FEATURE_FLAGS_ENUM } from "../../../../constants/featureFlag";
import { renderCastingStatus } from "../../../../helpers/castingHelper";
import {
  canToggleShare,
  updateChannelListOrder,
  updateNewChannelToList,
} from "../../../../helpers/channelHelper";
import { shouldShowShareButton } from "../../../../helpers/shareableHelper";
import { useAppContext } from "../../../../hooks/useAppContext";
import { useGetScreenSize } from "../../../../hooks/useGetScreenSize";
import queryHelper from "../../../../state/helper/query";
import {
  CastByCastIdFragment,
  CastsBySpaceIdDocument,
  Channel,
  CreateCastsByChannelIdMutationVariables,
  DuplicateChannelMutationVariables,
  RevertDraftChannelMutationVariables,
  Screen,
  ScreenCastStopMutationVariables,
  SetScreenContentByChannelIdMutationVariables,
  UpdateChannelEmbedMutationVariables,
  UpdateChannelNameMutationVariables,
  UpdateShareChannelToAllSpacesMutationVariables,
  UpdateShareChannelToSpacesMutationVariables,
} from "../../../../types.g";
import { ApolloProps, withData } from "./apollo";
import {
  StyledDuplicateDropdownOption,
  StyledPublishedDropdownItem,
} from "./styles";

export interface ChannelDetailHeaderProps {
  createdUserId?: string;
  updatedUserId?: string;
  publishUserId?: string;
  channelById: Channel;
  isHidePublishAnimation: boolean;
  isLoading: boolean;
  isDrafting: boolean;
  mode: ChannelDetailMode;
  isFullScreen: boolean;
  isNew: boolean;
  onEditChannelClick: () => void;
  onPreviewChannelClick: () => void;
  onPreviewFullscreen: () => void;
  onBackToListPage: () => void;
  handlePublishChannel: () => void;
  handleRevertChange: () => void;
  refreshPlayerData: () => void;
  refetchChannel: () => void;
  shouldDisplayCleanupButton?: boolean;
  onCleanupChannel?: () => void;
}

export const ChannelDetailHeader = (
  props: ChannelDetailHeaderProps & ApolloProps,
) => {
  const {
    channelById,
    match,
    history,
    mode,
    isNew,
    castByChannelById,
    isHidePublishAnimation,
    isLoading,
    isDrafting,
    handlePublishChannel,
    handleRevertChange,
    refetchChannel,
    setContentByChannelId,
    onEditChannelClick,
    onPreviewChannelClick,
    onBackToListPage,
    screenCastStop,
    createCastsByChannelId,
    revertDraftChannel,
    refreshPlayerData,
    duplicateChannel,
    updateChannelName,
    deleteChannelById,
    updateShareChannelToSpaces,
    updateChannelEmbed,
    updateShareChannelToAllSpaces,
    shouldDisplayCleanupButton,
    onCleanupChannel,
  } = props;
  const context = useAppContext();
  const { isMobileView } = useGetScreenSize();
  const showEditButton = mode === ChannelDetailMode.Preview;
  const startChannelId = context.currentOrg?.startChannelId;
  const draft = channelById.draft;
  const canCreateChannel = context.currentPermissions.validateCurrentSpace(
    "channel",
    "create",
  );
  const canUpdateChannel = context.currentPermissions.validateCurrentSpace(
    "channel",
    "update",
  );
  const canCastChannel = context.currentPermissions.validateCurrentSpace(
    "screen",
    "cast",
  );
  const canDeleteChannel = context.currentPermissions.validateCurrentSpace(
    "channel",
    "delete",
  );
  const updatedUserBy = draft?.isPublished ? channelById : draft;
  const isEditableSharedChannel = !!channelById?.childOf;
  const isDefaultChannel = channelById.id === startChannelId;
  const isOriginalSharedEditableChannel =
    isEditableSharedChannel && channelById?.duplicateOf === null;
  const shouldDisableDeleteOption =
    isDefaultChannel || isOriginalSharedEditableChannel;
  const getCastScreenByChannel = () => {
    return channelById?.castedScreenByChannelId?.nodes as Screen[];
  };

  const { duplicateChannel: duplicateToAnotherSpace } = useDuplicateChannel({
    id: channelById.id,
    name: channelById.name,
  });

  const onScreenPickerCallback = (data: string[], expiresAt?: Date) => {
    const spaceId = context.user.settings.spaceId;
    const castStartVar: CreateCastsByChannelIdMutationVariables = {
      input: {
        screenIds: data,
        channelId: channelById.id,
        expiresAt,
      },
    };

    createCastsByChannelId({
      refetchQueries: [
        {
          query: CastsBySpaceIdDocument,
          variables: {
            spaceId,
          },
        },
      ],
      variables: castStartVar,
    }).then(() => {
      queryHelper.updateCastedScreen(spaceId);
      refetchChannel();
      castByChannelById?.refetch();
      context.modal.closeModals();
    });
  };

  const onDiscardChanges = () => {
    const channelInput: RevertDraftChannelMutationVariables = {
      input: {
        id: channelById.id,
      },
    };
    handleRevertChange();
    revertDraftChannel({
      variables: channelInput,
    }).then(() => {
      refreshPlayerData && refreshPlayerData();
    });
  };

  const openScreenPicker = async () => {
    if (channelById) {
      const result = await context.modal.openScreenPicker(channelById);
      if (result.action === ScreenPickerActions.SET_CONTENT) {
        const setContents = result.data.map((screenId) => {
          const setchannelContent: SetScreenContentByChannelIdMutationVariables =
            {
              input: {
                screenId,
                channelId: match.params.channelId,
              },
            };
          return setContentByChannelId({
            variables: setchannelContent,
          });
        });
        await Promise.all(setContents);
        context.modal.closeModals();
      } else {
        onScreenPickerCallback(result.data, result.expiresAt);
      }
    }
  };

  const renderPublishDropdownItem = () => {
    if (channelById.content) {
      const draft = channelById.draft!;
      const isPublished = draft!.isPublished && !isHidePublishAnimation;
      const isChannelOwner =
        context.user.settings.spaceId === channelById.spaceId;

      const publishDropdownItem = (
        <>
          <DropdownItem className="info">
            <StyledPublishedDropdownItem className="metaUpdate">
              <FormattedMessage
                id="common.text.latest_published_on_date_name"
                defaultMessage="Last published on {date} by {name}"
                values={{
                  date: <DateTime value={channelById.publishedAt} />,
                  name: (
                    <strong>
                      {channelById.publishedBy && (
                        <UserName
                          className="username"
                          id={channelById.publishedBy}
                        />
                      )}
                    </strong>
                  ),
                }}
              />
            </StyledPublishedDropdownItem>
          </DropdownItem>

          {isChannelOwner && (
            <>
              <DropdownItem
                disabled={isPublished!}
                onClick={() => !isPublished && onDiscardChanges()}
              >
                <Icon name="revert" />
                <span className="text">
                  <FormattedMessage
                    id="channels.discard_changes"
                    defaultMessage="Discard Changes"
                  />
                </span>
              </DropdownItem>
            </>
          )}
        </>
      );

      return publishDropdownItem;
    } else {
      return null;
    }
  };

  const onDuplicateChannel = async (): Promise<void> => {
    const createChannelInput: DuplicateChannelMutationVariables = {
      input: {
        id: channelById.id,
        name: getDuplicateInstanceName(channelById.name),
      },
    };
    try {
      const result = await duplicateChannel({
        variables: createChannelInput,
        update: (cache, { data }) => {
          if (data?.duplicateChannel?.channel) {
            updateNewChannelToList(
              context,
              cache,
              data?.duplicateChannel?.channel,
            );
          }
        },
      });
      if (result.data?.duplicateChannel?.channel) {
        history.push(
          `/channels/${result.data.duplicateChannel.channel.id}/edit?new=true`,
        );
      }
    } catch (error) {
      // do not thing
    }
  };

  const renderPublishButton = () => {
    const canPublishChannel = context.currentPermissions.validateCurrentSpace(
      "channel",
      "publish",
    );
    if (channelById?.draft && canPublishChannel) {
      const draft = channelById.draft!;
      return (
        <>
          {draft.isPublished &&
          !isHidePublishAnimation &&
          draft!.themeId === channelById.themeId ? (
            <ButtonDropdown
              data-testid="published-button"
              className="button-publish"
              disabled
              content={context.intl.formatMessage({
                defaultMessage: "Published",
                id: "common.button.published",
              })}
            >
              {renderPublishDropdownItem()}
            </ButtonDropdown>
          ) : (
            <ButtonDropdown
              data-testid="publish-button"
              callBack={handlePublishChannel}
              primary
              isSuccess={isHidePublishAnimation}
              isLoading={isLoading}
              disabled={!canPublishChannel || isLoading}
              content={context.intl.formatMessage({
                defaultMessage: "Publish",
                id: "common.button.publish",
              })}
              className="button-publish"
            >
              {renderPublishDropdownItem()}
            </ButtonDropdown>
          )}
        </>
      );
    }
    return null;
  };

  const onCastedScreensCallback = async (
    data: string,
    action: CastedScreenInfoActions,
  ) => {
    switch (action) {
      case CastedScreenInfoActions.STOP_CAST:
        const castStopVar: ScreenCastStopMutationVariables = {
          input: {
            screenId: data,
          },
        };
        const castItem = {
          channelByCastId: {
            id: channelById.id,
          },
        } as CastByCastIdFragment;
        screenCastStop(castStopVar, castItem);
        break;
      case CastedScreenInfoActions.NAVIGATE_SCREEN:
        history.push("/screens/" + data);
        break;
      default:
    }
  };

  const onUpdateChannelName = (e: React.SyntheticEvent<any>, value: string) => {
    if (value.length > 79) {
      // limit at 80
      value = value.substring(0, 79);
    }

    if (channelById.draft) {
      const channel = { ...channelById };
      const channelDraft = { ...channelById.draft } as Channel;
      const channelInput: UpdateChannelNameMutationVariables = {
        input: {
          id: channelById.id,
          name: value,
        },
      };

      channel.name = channelInput.input.name;
      channelDraft.name = channelInput.input.name;

      updateChannelName({
        variables: channelInput,
        update: (cache, { data }) => {
          if (data?.updateChannelName?.channel) {
            updateChannelListOrder(context, cache);
          }
        },
      });
    }
  };

  const onDeleteChannel = async () => {
    const { id, name, castedScreenByChannelId } = channelById;

    const { confirm } = await context.modal.confirm(
      <>
        <h2>
          <FormattedMessage
            id="ui_component.confirm.delete_heading_name"
            defaultMessage="Delete {name}?"
            values={{ name }}
          />
        </h2>
        <p>
          <FormattedMessage
            id="ui_component.confirm.delete_message_name"
            defaultMessage="{name} will be deleted permanently and this action cannot be undone."
            values={{ name: <strong>{name}</strong> }}
          />
        </p>
      </>,
      {
        confirm: (
          <FormattedMessage
            id="ui_component.common.label.delete"
            defaultMessage="Delete"
          />
        ),
        isDanger: true,
      },
    );
    if (confirm === true) {
      const screenIds: string[] = !castedScreenByChannelId
        ? []
        : castedScreenByChannelId.nodes.map((screen) => screen.id);
      for (const screenId of screenIds) {
        const castStopVar: ScreenCastStopMutationVariables = {
          input: {
            screenId,
          },
        };
        const castItem = {
          channelByCastId: {
            id,
          },
        } as CastByCastIdFragment;
        await screenCastStop(castStopVar, castItem);
      }
      deleteChannelById(id);
      onBackToListPage();
    }
  };

  const onBackButtonClicked = () => {
    return isFromDetailPage() ? history.goBack() : onBackToListPage();
  };

  const renderDuplicateDropdown = () => {
    return (
      <StyledDuplicateDropdownOption
        direction="left"
        className="button-duplicate"
        data-testid="channel-duplicate-options"
        icon={
          <Popover
            inverted
            content={
              <FormattedMessage
                id="ui_component.label.duplicate"
                defaultMessage="Duplicate"
              />
            }
            trigger={
              <Button icon>
                <Icon name="duplicate" />
              </Button>
            }
          />
        }
      >
        <DropdownMenu>
          {canCreateChannel && (
            <DropdownItem
              data-testid="duplicate-item-button"
              onClick={onDuplicateChannel}
            >
              <Icon name="duplicate" />
              &nbsp;
              <FormattedMessage
                id="duplication.duplicate_button"
                defaultMessage="Duplicate"
              />
            </DropdownItem>
          )}
          {canUpdateChannel && (
            <DropdownItem
              data-testid="handle-export-click"
              onClick={duplicateToAnotherSpace}
            >
              <Icon name="move-out" />
              &nbsp;
              <FormattedMessage
                id="channels.duplicate_channel_to_another_space"
                defaultMessage="Duplicate to Another Space"
              />
            </DropdownItem>
          )}
        </DropdownMenu>
      </StyledDuplicateDropdownOption>
    );
  };

  const renderDropdownOptions = () => {
    if (!canCreateChannel && !canDeleteChannel) {
      return null;
    }

    return (
      <Dropdown
        checkEmpty
        direction="left"
        data-testid="channel-header-options"
        className="dropdown-options"
        icon={
          <Button icon>
            <Icon name="dots" />
          </Button>
        }
      >
        <DropdownMenu>
          {!isEditableSharedChannel && isMobileView && (
            <>
              {canCreateChannel && (
                <DropdownItem
                  data-testid="duplicate-item-button"
                  onClick={onDuplicateChannel}
                >
                  <Icon name="duplicate" />
                  &nbsp;
                  <FormattedMessage
                    id="channels.duplicate_channel"
                    defaultMessage="Duplicate"
                  />
                </DropdownItem>
              )}
              {canUpdateChannel && (
                <DropdownItem
                  data-testid="handle-export-click"
                  onClick={duplicateToAnotherSpace}
                >
                  <Icon name="move-out" />
                  &nbsp;
                  <FormattedMessage
                    id="channels.duplicate_channel_to_another_space"
                    defaultMessage="Duplicate to Another Space"
                  />
                </DropdownItem>
              )}
            </>
          )}
          {shouldDisplayCleanupButton && (
            <DropdownItem
              data-testid="cleanup-item-button"
              onClick={onCleanupChannel}
            >
              <Icon name="broom" />
              <FormattedMessage
                id="ui_component.common.label.cleanup"
                defaultMessage="Clear Expired Content"
              />
            </DropdownItem>
          )}

          {(canCreateChannel || canUpdateChannel) &&
            canDeleteChannel &&
            isMobileView &&
            !isEditableSharedChannel && (
              <DropdownDivider data-testid="dropdown-divider" />
            )}

          {canDeleteChannel && (
            <DropdownItem
              data-testid="delete-item-button"
              className="danger"
              onClick={onDeleteChannel}
              disabled={shouldDisableDeleteOption}
            >
              {shouldDisableDeleteOption ? (
                <>
                  <Icon name="warning" />
                  <div className="deleteChannelMenu">
                    <FormattedMessage
                      id="ui_component.common.label.delete_channel"
                      defaultMessage="Delete Channel"
                    />
                    <span className="option-disabled">
                      {isDefaultChannel && (
                        <FormattedMessage
                          id="channels.delete_default_can_not_delete"
                          defaultMessage="Default channels cannot be deleted."
                        />
                      )}
                      {isOriginalSharedEditableChannel && (
                        <FormattedMessage
                          id="channels.cannot_delete_shared_channels"
                          defaultMessage="Shared channels cannot be deleted."
                        />
                      )}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <Icon name="trash" />{" "}
                  <FormattedMessage
                    id="ui_component.common.label.delete_channel"
                    defaultMessage="Delete Channel"
                  />
                </>
              )}
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    );
  };

  const handleShare = (sharedSpaceIds: string[]) => {
    const updateChannelInput: UpdateShareChannelToSpacesMutationVariables = {
      input: {
        channelId: channelById.id,
        spaces: sharedSpaceIds,
      },
    };
    updateShareChannelToSpaces({
      variables: updateChannelInput,
    });
  };

  const handleShareAll = (value: boolean) => {
    const sharedAllInput: UpdateShareChannelToAllSpacesMutationVariables = {
      input: {
        channelId: channelById.id,
        isSharedAll: value,
      },
    };

    updateShareChannelToAllSpaces({
      variables: sharedAllInput,
    });
  };

  const handleEmbedShare = ({ isEmbedEnabled, isEmbedPublic }: EmbedStatus) => {
    const channelInput: UpdateChannelEmbedMutationVariables = {
      input: {
        channelId: channelById?.id,
        isEmbedEnabled,
        isEmbedPublic,
      },
    };

    updateChannelEmbed({
      variables: channelInput,
    });
  };

  const showShareModal = () => {
    const embedShortId = channelById.embedsByChannelId.nodes?.[0]?.shortId;

    context.modal.openModal(
      <ShareModal
        embedShortId={embedShortId}
        shareable={channelById}
        isDisabled={canToggleShare({ context, channel: channelById }) === false}
        onShareToSpaceChanges={handleShare}
        onShareAllChange={handleShareAll}
        onEmbedChange={handleEmbedShare}
      />,
      <>
        <FormattedMessage
          id="common.label.share_channel"
          defaultMessage="Share Channel"
        />
        : {channelById!.name}
      </>,
      {
        opts: {
          size:
            (context.allSpaces ?? []).length > 1
              ? ModalSize.MEDIUM
              : ModalSize.SMALL,
          disableTitle: true,
          className: isEditableSharedChannel ? "embed-share-modal" : "",
        },
      },
    );
  };

  const isShowShareButton = React.useMemo(() => {
    if (!channelById) return;
    if (isEditableSharedChannel) {
      // todo: uncomment to enable share button
      // return context.shouldShowFeature(FEATURE_FLAGS_ENUM.EMBED_PLAYER);
      return false;
    }
    return shouldShowShareButton({ context, shareable: channelById });
  }, [channelById, isEditableSharedChannel]);

  if (channelById.draft) {
    const castedScreens = getCastScreenByChannel();
    return (
      <Header className="header" data-testid="channel-header">
        <div className="wrapper detail">
          <div className="column-back">
            <Button
              data-testid="column-back-button"
              className="back"
              basic
              onClick={onBackButtonClicked}
              title={context.intl.formatMessage({
                defaultMessage: "Back to previous page",
                id: "common.text.back_to_previous_page",
              })}
            >
              <Icon data-testid="back-arrow" name="arrow-left" />
            </Button>
          </div>
          <div className="column-title" data-testid="channel-column-title">
            <h1>
              <InlineInput
                data-testid="search-box"
                placeholder={isNew && draft?.name ? draft.name : "New Channel"}
                disabled={!canUpdateChannel}
                value={draft?.name}
                editmode={isNew}
                onSaved={onUpdateChannelName}
                showIcon={true}
                maxLength={NameMaxLengthAllow}
              />
            </h1>
            <span className="meta-update">
              <UpdatedByUser
                createdAt={updatedUserBy?.createdAt}
                createdBy={updatedUserBy?.createdBy}
                updatedAt={updatedUserBy?.updatedAt}
                updatedBy={updatedUserBy?.updatedBy}
                publishedAt={updatedUserBy?.publishedAt}
                publishedBy={updatedUserBy?.publishedBy}
                usePublished={true}
              />
              <SyncStateText
                className="draft-state"
                isDraftSaving={isDrafting}
              />
            </span>
          </div>

          <div className="column-options" data-testid="channel-column-options">
            <SyncStateText
              className="draft-state"
              data-testid="draft-state"
              isDraftSaving={isDrafting}
            />
            {renderCastingStatus({
              castScreenData: castedScreens,
              callBack: onCastedScreensCallback,
              context,
            })}
            {canCastChannel &&
              context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING) && (
                <Popover
                  inverted
                  content={
                    <FormattedMessage
                      id="ui_component.common.label.set_to_screen"
                      defaultMessage="Set to Screen"
                    />
                  }
                  trigger={
                    <Button
                      onClick={openScreenPicker}
                      className="button-casting"
                      data-testid="cast-button"
                    >
                      <Icon name="screen-play" />
                    </Button>
                  }
                />
              )}
            <>
              {isShowShareButton && (
                <Popover
                  inverted
                  content={
                    <FormattedMessage
                      id="ui_component.label.share"
                      defaultMessage="Share"
                    />
                  }
                  trigger={
                    <Button
                      className="share-button"
                      data-testid="share-button"
                      onClick={showShareModal}
                    >
                      <Icon name="users" />
                    </Button>
                  }
                />
              )}
            </>
            {!isEditableSharedChannel && renderDuplicateDropdown()}
            {canUpdateChannel && (
              <>
                {showEditButton ? (
                  <Button
                    className="btn-edit"
                    data-testid="edit-channel"
                    onClick={onEditChannelClick}
                  >
                    <FormattedMessage
                      id="common.button.edit_channel"
                      defaultMessage="Edit Channel"
                    />
                  </Button>
                ) : (
                  <Button
                    data-testid="preview-button"
                    onClick={onPreviewChannelClick}
                    className="btn-preview"
                  >
                    <Icon name="play" />
                    <FormattedMessage
                      id="common.button.preview"
                      defaultMessage="Preview"
                    />
                  </Button>
                )}
              </>
            )}
            {renderPublishButton()}
            {renderDropdownOptions()}
          </div>
        </div>
      </Header>
    );
  } else {
    return <div />;
  }
};

export default withData(
  ChannelDetailHeader,
) as React.ComponentClass<ChannelDetailHeaderProps>;
