import * as Sdk from "@screencloud/auth-sdk";
import { Loader } from "@screencloud/screencloud-ui-components";
import { get } from "lodash";
import * as React from "react";
import TagManager from "react-gtm-module";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Route } from "react-router-dom";
import AppContextProvider from "./AppContextProvider";
import LiveChat from "./components/LiveChat";
import { Locale } from "./constants/constants";
import {
  CurrentFeatureFlag,
  FEATURE_FLAGS_ENUM,
} from "./constants/featureFlag";
import { withCurrentUser, WithCurrentUserProps } from "./hocs/withCurrentUser";
import Maintenance from "./pages/Maintenance";

import RouteController from "./RouteController";
import helper from "./state/helper/query";
import { ssm } from "./state/session/ssm";
// Load English text by default to avoid runtime import of translation file for
// the most common case
import enTranslations from "./translations/enTranslations.json";
import { UpdateUserSettingsMutationVariables, User } from "./types.g";
import { compose } from "./utils/compose";
import { getLanguage } from "./domains/user/user";
import { DatadogContext } from "./utils/datadog";

export interface Props extends WithCurrentUserProps {}
type State = {
  isMaintenance: boolean;
  loading: boolean;
  translations: { [key: string]: string };
  lang: string;
  featureFlags: CurrentFeatureFlag;
};

class App extends React.Component<Props, State> {
  public state: State = {
    lang: "en",
    isMaintenance: false,
    loading: true,
    translations: enTranslations,
    featureFlags: [],
  };
  protected auth: Sdk.Auth;

  public async componentDidMount() {
    await Promise.all([this.initFeatureFlags(), this.initUser()]);

    this.setState({
      loading: false,
    });
  }

  public initFeatureFlags = async () => {
    const featureFlags = await helper.getFeatureFlags(
      ssm.current.settings.spaceId,
    );

    const isMaintenance = featureFlags.includes(FEATURE_FLAGS_ENUM.MAINTENANCE);

    this.setState({ isMaintenance, featureFlags });

    if (isMaintenance) {
      // TODO: A more effieient option may be to subscribe to
      // the feature flags query.
      this.onReloadUntilFinishMaintenanceMode();
    }
  };

  public initUser = async () => {
    const start = Date.now();

    if ((import.meta.env.VITE_REACT_APP_ENV as string) !== "development") {
      const tagManagerArgs = {
        gtmId: "GTM-T8LM4NN",
      };
      TagManager.initialize(tagManagerArgs);
    }

    // redirect if initial path doesn't match role
    if (ssm.redirectIfRequired()) {
      console.log(`App.componentDidMount: redirectIfRequired returned true`);
      return;
    }

    const tagManagerData = {
      dataLayer: {
        event: "studio_log_in",
        studio_space_id: ssm.current.settings.spaceId,
        studio_org_id: ssm.current.claims.orgId,
        studio_user_id: ssm.current.claims.userId,
      },
    };

    TagManager.dataLayer(tagManagerData);

    const { currentUser } = this.props;
    const { lang } = this.state;

    if (currentUser) {
      const userLanguage = getLanguage(currentUser) ?? lang;

      await this.loadTranslations(userLanguage as Locale);
    }

    // print out duration
    console.log(`App.componentDidMount() total took`, Date.now() - start);
  };

  public onReloadUntilFinishMaintenanceMode = () => {
    let intervalId: any = 0;

    const reloadFeatureFlag = async () => {
      const featureFlags = await helper.getFeatureFlags(
        ssm.current.settings.spaceId,
      );
      if (!featureFlags.includes(FEATURE_FLAGS_ENUM.MAINTENANCE)) {
        this.setState({ isMaintenance: false });
        clearInterval(intervalId);
      }
    };

    intervalId = setInterval(reloadFeatureFlag, 60000);
  };

  public onLanguageChange = async (locale: Locale) => {
    const { currentUser } = this.props;
    ssm.current.updateSettings({ locale });
    // noinspection JSIgnoredPromiseFromCall
    this.setState({ lang: locale });

    // TODO: replace with function in user.ts when have time to test
    const settings = get(currentUser, ["preferences", "settings"], null);
    const variables: UpdateUserSettingsMutationVariables = {
      input: {
        settingsData: {
          ...settings,
          lang: locale,
        },
      },
    };
    await helper.updateUserSettings(variables);
    this.loadTranslations(locale);
  };

  public loadTranslations = async (lang: Locale) => {
    const translations =
      lang === "en"
        ? enTranslations
        : await import(`./translations/${lang}Translations.json`);

    this.setState({ translations });
  };

  public render(): JSX.Element {
    const { isMaintenance, loading, translations, featureFlags } = this.state;
    const { currentUser } = this.props;
    const basename = ssm.all.length <= 1 ? `/` : `/org/${ssm.current.key}`;

    if (isMaintenance) {
      return (
        <BrowserRouter>
          <Route>
            <Maintenance />
          </Route>
        </BrowserRouter>
      );
    }

    if (!translations || loading) {
      return <Loader active style={{ top: "50%", left: "50%" }} />;
    }

    return (
      <IntlProvider locale={this.state.lang} messages={this.state.translations}>
        <BrowserRouter basename={basename}>
          <AppContextProvider
            initialFeatureFlags={featureFlags}
            currentUser={currentUser as User}
          >
            <RouteController onLanguageChange={this.onLanguageChange} />
            <LiveChat />
            <DatadogContext />
          </AppContextProvider>
        </BrowserRouter>
      </IntlProvider>
    );
  }
}

export default compose(withCurrentUser)(App) as React.ComponentType<{}>;
