import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { AppContextType } from "../../../../AppContextProvider/type";
import {
  ThemeListDocument,
  CreateThemeMutationVariables,
  File,
  RemoveThemeLogoMutationVariables,
  Theme,
  UpdateThemeMutationVariables,
  CreateFontFromCommonMutationVariables,
} from "../../../../types.g";
import { ApolloProps, withData } from "./apollo";

import { AppContext } from "../../../../AppContextProvider/AppContext";
import { NewTheme } from "../../../../pages/Admin/Themes";
import { generateColorVariants } from "../../../../utils/colorConversions";
import ThemeConfigure, { Font } from "../ThemeConfigure";
import { DEFAULT_FONT, UUID } from "../../../../constants/constants";
import {
  IStudioCustomFileFont,
  StudioCustomFileFontBase,
} from "@screencloud/studio-custom-fonts";

export interface ThemeFormData {
  bodyFont?: Font;
  headingFont?: Font;
  name: string;
  primaryColor: string;
  secondaryColor: string;
  titleFontColor: string;
  bodyFontColor: string;
  titleFontId: UUID;
  bodyFontId: UUID;
}

export interface ThemeContainerProps {
  theme?: Theme;
  title: string;
}

interface State {
  logo: File | null | undefined;
  initialLogoRemoved: boolean;
  isImageUploading: boolean;
}

class ThemeContainer extends Component<
  ThemeContainerProps & ApolloProps,
  State
> {
  public static contextType = AppContext;
  public context: AppContextType;
  constructor(props: ThemeContainerProps & ApolloProps) {
    super(props);
    this.state = {
      initialLogoRemoved: false,
      logo: props.theme?.fileByLogoFileId,
      isImageUploading: false,
    };
  }

  public installCommonFont = async (fontId: UUID) => {
    const createFontVar: CreateFontFromCommonMutationVariables = {
      input: {
        commonFontId: fontId,
      },
    };
    return this.props.createFontFromCommon({
      variables: createFontVar,
    });
  };

  public getFontIdToCreateTheme = async (fontId: UUID): Promise<UUID> => {
    const allFonts = this.props.allFonts?.nodes;
    const customFont = allFonts?.find((font) => font.id === fontId);
    const installedCommonFont = allFonts?.find(
      (f) => f.commonFontId === fontId,
    );
    if (customFont) {
      return customFont.id;
    } else if (installedCommonFont) {
      return installedCommonFont.id;
    } else {
      const { data } = await this.installCommonFont(fontId);
      return data?.createFontFromCommon?.font?.id;
    }
  };

  public constructThemeInput = async (
    theme: ThemeFormData,
  ): Promise<NewTheme> => {
    const allFonts = this.props.allFonts?.nodes;

    const primaryColor = generateColorVariants(theme.primaryColor);
    const secondaryColor = generateColorVariants(theme.secondaryColor);
    const textOnPrimary = generateColorVariants(theme.titleFontColor);
    const textOnSecondary = generateColorVariants(theme.bodyFontColor);
    const initialLogoId = this.props.theme?.fileByLogoFileId?.id;
    const currentLogoId = this.state.logo?.id;

    const titleFontId = await this.getFontIdToCreateTheme(theme.titleFontId);
    const bodyFontId = await this.getFontIdToCreateTheme(theme.bodyFontId);

    const isCustomTitleFont = !!allFonts?.find(
      (font) => font.id === titleFontId && font.commonFontId === null,
    );
    const isCustomBodyFont = !!allFonts?.find(
      (font) => font.id === bodyFontId && font.commonFontId === null,
    );

    const titleFontData = allFonts?.find((font) => font.id === titleFontId);
    const bodyFontData = allFonts?.find((font) => font.id === bodyFontId);

    let headingFontUrl = titleFontData?.cssUrl;
    let bodyFontUrl = bodyFontData?.cssUrl;

    if (!headingFontUrl && isCustomTitleFont) {
      const encodeHeadingFont = new StudioCustomFileFontBase({
        font: {
          fontFamily: titleFontData?.fontFamily,
          regularFont: titleFontData?.fileByRegularFileId?.source,
          boldFont: titleFontData?.fileByBoldItalicFileId?.source,
          boldItalicFont: titleFontData?.fileByBoldItalicFileId?.source,
          italicFont: titleFontData?.fileByItalicFileId?.source,
        } as IStudioCustomFileFont,
      });
      headingFontUrl = encodeHeadingFont.base64CSSFontURI;
    }

    if (!bodyFontUrl && isCustomBodyFont) {
      const encodeBodyFont = new StudioCustomFileFontBase({
        font: {
          fontFamily: bodyFontData?.fontFamily,
          regularFont: bodyFontData?.fileByRegularFileId?.source,
          boldFont: bodyFontData?.fileByBoldItalicFileId?.source,
          boldItalicFont: bodyFontData?.fileByBoldItalicFileId?.source,
          italicFont: bodyFontData?.fileByItalicFileId?.source,
        } as IStudioCustomFileFont,
      });
      bodyFontUrl = encodeBodyFont.base64CSSFontURI;
    }

    const inputTheme: NewTheme = {
      config: {
        usingCustomFont: isCustomTitleFont || isCustomBodyFont,
        bodyFont: {
          url: bodyFontUrl ?? DEFAULT_FONT.url,
          family: theme.bodyFont?.family ?? DEFAULT_FONT.family,
        },
        headingFont: {
          url: headingFontUrl ?? DEFAULT_FONT.url,
          family: theme.headingFont?.family ?? DEFAULT_FONT.family,
        },
        primaryColor,
        secondaryColor,
        textOnPrimary,
        textOnSecondary,
      },
      logoFileId: currentLogoId
        ? currentLogoId
        : this.state.initialLogoRemoved
          ? null
          : initialLogoId,
      name: theme.name,
      titleFontId,
      bodyFontId,
    };

    return inputTheme;
  };

  public handleThemeConfigureSave = async (theme: ThemeFormData) => {
    try {
      const themeInput = await this.constructThemeInput(theme);
      if (this.props.theme) {
        await this.removeOldLogo(this.props.theme.id);
        const updateThemeVar: UpdateThemeMutationVariables = {
          input: {
            themeId: this.props.theme.id,
            autoPublish: true,
            ...themeInput,
          },
        };
        await this.props.updateTheme({
          refetchQueries: [{ query: ThemeListDocument }],
          variables: updateThemeVar,
        });
      } else {
        const createThemeVar: CreateThemeMutationVariables = {
          input: themeInput,
        };

        await this.props.createTheme({
          refetchQueries: [{ query: ThemeListDocument }],
          variables: createThemeVar,
        });
      }
    } catch (error) {
      console.log("Failed to save theme: ", error);
    }

    this.context.modal.closeFullscreenModal();
  };

  public onUploadLogoClick = async () => {
    try {
      const mediaOptions = {
        maxFiles: 1,
        folderId: null,
        isGlobalUpload: true,
      };

      this.setState({ isImageUploading: true });
      const uploadResult = await this.context.modal.openMediaUpload(
        <FormattedMessage
          defaultMessage="Upload Logo"
          id="admin.themes.upload_logo"
        />,
        mediaOptions,
      );

      this.setState({ logo: uploadResult[0] as any, isImageUploading: false });
    } catch (err) {
      console.log("uploaded media error: ", err);
      this.setState({ isImageUploading: false });
    }
  };

  public removeOldLogo = async (themeId: string) => {
    const { theme, removeThemeLogo } = this.props;
    const { initialLogoRemoved } = this.state;
    const initialLogo = theme?.fileByLogoFileId;
    const isNewLogo = initialLogoRemoved && initialLogo !== this.state.logo;
    if (initialLogo && isNewLogo) {
      try {
        const removeThemeLogoVar: RemoveThemeLogoMutationVariables = {
          input: {
            themeId,
            autoPublish: true,
          },
        };
        await removeThemeLogo({
          variables: removeThemeLogoVar,
        });
      } catch (err) {
        console.log("Remove theme logo error: ", err);
      }
    }
    Promise.resolve();
  };

  public onRemoveLogoClick = async () => {
    const { confirm } = await this.context.modal.confirm(
      <FormattedMessage
        id="admin.organization.remove_logo_confirm"
        defaultMessage="Are you sure you want to remove your logo?"
      />,
      {
        confirm: (
          <FormattedMessage
            id="admin.themes.remove_logo"
            defaultMessage="Remove Logo"
          />
        ),
        isDanger: true,
      },
    );

    if (confirm) {
      this.setState({
        initialLogoRemoved: true,
        logo: null,
      });
      this.context.modal.closeModals();
    }
  };

  public render() {
    const { initialLogoRemoved, isImageUploading, logo } = this.state;
    const { title, theme } = this.props;
    return (
      <ThemeConfigure
        title={title}
        theme={theme}
        initialLogo={theme?.fileByLogoFileId}
        initialLogoRemoved={initialLogoRemoved}
        logo={logo}
        onSave={this.handleThemeConfigureSave}
        onUploadLogoClick={this.onUploadLogoClick}
        onRemoveLogoClick={this.onRemoveLogoClick}
        isImageUploading={isImageUploading}
      />
    );
  }
}

export default withData(ThemeContainer);
