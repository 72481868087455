import { useCacheInvalidation } from "src/hooks/useCacheInvalidation";
import { Link } from "src/types.g";

export function useOnLinkDeleted() {
  const { invalidateScreenGroupCache, invalidateEntityCache } =
    useCacheInvalidation();

  const onLinkDeleted = ({
    link,
  }: {
    link: Pick<Link, "__typename" | "id" | "name">;
  }) => {
    invalidateEntityCache(link);
    invalidateScreenGroupCache();
  };

  return { onLinkDeleted };
}
