import { FormattedMessage } from "react-intl";

import {
  CreateDuplicateJobMutationVariables,
  DuplicateJobType,
  SnapshotType,
  useCreateDuplicateJobMutation,
} from "../../types.g";
import { useAppContext } from "../useAppContext";

export interface UseCreateSnapshot {
  createSnapshot: (
    contentName: string,
    contentId: string,
    isPublicSnapshot?: boolean,
  ) => Promise<void>;
}

export function useCreateSnapshot(): UseCreateSnapshot {
  const context = useAppContext();
  const [createDuplicateJob] = useCreateDuplicateJobMutation();

  const createSnapshot = async (
    contentName: string,
    contentId: string,
    isPublicSnapshot?: boolean,
  ) => {
    const { confirm } = await context.modal.confirm(
      <>
        <h2>
          <FormattedMessage
            id="ui_component.confirm.publish_snapshot_header"
            defaultMessage="Publish snapshot?"
          />
        </h2>
        <p>
          <FormattedMessage
            id="ui_component.confirm.publish_snapshot_confirm_message"
            defaultMessage="Are you sure to publish snapshot for {name}?"
            values={{ name: <strong>{contentName}</strong> }}
          />
        </p>
      </>,
      {
        confirm: (
          <FormattedMessage
            id="ui_component.common.label.confirm"
            defaultMessage="Confirm"
          />
        ),
        cancel: (
          <FormattedMessage
            id="ui_component.common.label.cancel"
            defaultMessage="Cancel"
          />
        ),
      },
    );
    if (confirm === true) {
      const snapshotInput: CreateDuplicateJobMutationVariables = {
        input: {
          jobType: DuplicateJobType.Snapshot,
          sourceContentId: contentId,
          sourceSpaceId: context.currentSpace?.id,
          snapshotType: SnapshotType.Channel,
          isPublicSnapshot: isPublicSnapshot || false,
        },
      };
      createDuplicateJob({ variables: snapshotInput });
    }
  };

  return { createSnapshot };
}
