import { FIRST_FETCH_ITEMS } from "src/constants/constants";
import { renderFetchMorePickerButton } from "src/helpers/generalHelper";
import { useAppContext } from "src/hooks/useAppContext";
import { useLoadMore } from "src/hooks/useLoadMore";
import {
  AllPlaylistsInOrgQueryVariables,
  Playlist,
  useAllInternalPlaylistsInOrgQuery,
  useAllPlaylistsInOrgQuery,
} from "src/types.g";

interface AllPlaylistInCurrentOrgParams {
  skip: boolean;
  excludeShare?: boolean;
}

/**
 *
 * @returns All playlist in current organization
 */
export function useAllPlaylistInCurrentOrg({
  skip,
  excludeShare,
}: AllPlaylistInCurrentOrgParams) {
  const context = useAppContext();

  const variables: AllPlaylistsInOrgQueryVariables = {
    orgId: context?.currentOrg?.id,
    first: FIRST_FETCH_ITEMS,
    endCursor: null,
  };
  const queryPlaylist = excludeShare
    ? useAllInternalPlaylistsInOrgQuery
    : useAllPlaylistsInOrgQuery;
  const { data, fetchMore, loading } = queryPlaylist({
    variables,
    fetchPolicy: "cache-first",
    skip,
  });

  const playlistNodes = (data?.orgById?.playlistsByOrgId.nodes ??
    []) as Playlist[];
  const playlistTotalCount = data?.orgById?.playlistsByOrgId.totalCount;
  const isQueryHasNextPage =
    data?.orgById?.playlistsByOrgId.pageInfo.hasNextPage;
  const endCursor = data?.orgById?.playlistsByOrgId.pageInfo.endCursor;

  const { isLoading, loadMore } = useLoadMore(fetchMore, endCursor);

  const fetchMoreButton = renderFetchMorePickerButton(
    Number(playlistNodes?.length),
    Number(playlistTotalCount),
    isLoading,
    Boolean(isQueryHasNextPage) && playlistNodes.length! < playlistTotalCount!,
    loadMore,
  );

  return {
    playlists: playlistNodes,
    isLoading,
    loading,
    renderFetchMoreButton: fetchMoreButton,
  };
}
