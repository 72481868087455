import { DataValue } from "@apollo/client/react/hoc";
import { AllIdentityProvidersQuery } from "../types.g";
import {
  API_KEY_ICON,
  API_KEY_PROVIDER_URN,
  BASIC_AUTH_ICON,
  BASIC_AUTH_PROVIDER_URN,
  OAUTH2_ICON,
} from "../components/CredentialPicker/constants";

export const getProviderIconUrl = (
  providerUrn: string,
  allProvidersQuery: DataValue<AllIdentityProvidersQuery>,
) => {
  if (providerUrn.startsWith(API_KEY_PROVIDER_URN)) {
    return `${window.location.origin}${API_KEY_ICON}`;
  }

  if (providerUrn.startsWith(BASIC_AUTH_PROVIDER_URN)) {
    return `${window.location.origin}${BASIC_AUTH_ICON}`;
  }

  const identityProviders = allProvidersQuery.allIdentityProviders;

  if (!identityProviders) {
    return `${window.location.origin}${OAUTH2_ICON}`;
  }

  const matchingProvider = identityProviders.find(
    (provider) => provider.type === providerUrn,
  );

  if (!matchingProvider) {
    return `${window.location.origin}${OAUTH2_ICON}`;
  }

  return matchingProvider.iconUrl || `${window.location.origin}${OAUTH2_ICON}`;
};
