import { OperationVariables } from "@apollo/client/core";
import { ChildDataProps } from "@apollo/client/react/hoc";
import { uniqBy } from "lodash";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import {
  Screen,
  ScreensByChannelIdProps,
  ScreensByChannelIdQuery,
  ScreensByChannelIdQueryVariables,
  withScreensByChannelId,
} from "../../types.g";
import { compose } from "../../utils/compose";
import { ChannelPlayingScreenStyled } from "./styles";

import { ApolloQueryResult } from "@apollo/client";
import { getDeviceModel } from "../../helpers/getDeviceModel";

export enum ChannelPlayingScreenActions {
  NAVIGATE_SCREEN = "NAVIGATE_SCREEN",
}

const withData = compose(
  withScreensByChannelId({
    options: (props: PlayingScreenContentProps) => {
      const queryVar: ScreensByChannelIdQueryVariables = {
        id: props.channelId,
        endCursor: null,
      };
      return {
        fetchPolicy: "cache-and-network",
        variables: queryVar,
      };
    },
    props: ({
      data,
      ownProps,
    }): ChildDataProps<
      OperationVariables,
      ScreensByChannelIdQuery,
      ScreensByChannelIdQueryVariables
    > => {
      if (data?.channelById?.screensByChannelId) {
        const variables = {
          endCursor: data.channelById.screensByChannelId.pageInfo.endCursor,
        };
        const tmpData = {
          ...(ownProps as ScreensByChannelIdProps),
          loadMore: () =>
            data.fetchMore({
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const prevScreensData =
                  previousResult.channelById!.screensByChannelId;
                const currentScreensData =
                  fetchMoreResult!.channelById!.screensByChannelId;
                const screensNodes = uniqBy(
                  [...prevScreensData!.nodes, ...currentScreensData!.nodes],
                  "id",
                );
                return {
                  ...previousResult,
                  channelById: {
                    ...previousResult.channelById!,
                    __typename: "Channel",
                    screensByChannelId: {
                      __typename: "ScreensConnection",
                      nodes: screensNodes,
                      pageInfo: currentScreensData!.pageInfo,
                    },
                  },
                };
              },
              variables,
            }),
        };
        return { ...tmpData, data };
      } else {
        return { ...(ownProps as ScreensByChannelIdProps), data: data! };
      }
    },
  }),
);

export interface PlayingScreenContentProps extends ScreensByChannelIdProps {
  callBack?: (screenId: string, action: ChannelPlayingScreenActions) => void;
  loadMore: () => Promise<ApolloQueryResult<ScreensByChannelIdQuery>>;
  channelId: string;
}

export interface ChannelPlayingScreenState {
  screens: Partial<Screen>[];
}

class PlayingScreenContent extends Component<
  PlayingScreenContentProps,
  ChannelPlayingScreenState
> {
  public static getDerivedStateFromProps(
    nextProps: PlayingScreenContentProps,
    prevState: ChannelPlayingScreenState,
  ) {
    if (
      nextProps.data &&
      nextProps.data.channelById &&
      nextProps.data.channelById.screensByChannelId.nodes
    ) {
      return { screens: nextProps.data.channelById.screensByChannelId.nodes };
    }
    return null;
  }

  constructor(props: PlayingScreenContentProps) {
    super(props);
    this.state = {
      screens: [],
    };
  }

  public handleCallback(data: string, action: ChannelPlayingScreenActions) {
    return this.props.callBack && this.props.callBack(data, action);
  }

  public render() {
    const { screens } = this.state;
    let items: Partial<Screen>[] | null = [];
    let loadMore: JSX.Element | null = null;
    if (this.props.data?.channelById && screens) {
      items = screens;
      if (this.props.data.channelById.screensByChannelId.pageInfo.hasNextPage) {
        loadMore = (
          <div className="loadmore-button" onClick={this.props.loadMore}>
            Load More
          </div>
        );
      }
    }

    return (
      <ChannelPlayingScreenStyled>
        <div className="header">
          <FormattedMessage
            id="ui_component.common.currently_playing_on"
            defaultMessage="Currently playing on"
          />
        </div>
        <div className="cast-container">
          {items.map((screen, index) => {
            const deviceName = getDeviceModel(screen);
            return (
              <div className="screen-item" key={`screen-${index}`}>
                <div className="screen-alpha">
                  <h3
                    title={screen.name}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.handleCallback(
                        screen.id,
                        ChannelPlayingScreenActions.NAVIGATE_SCREEN,
                      );
                    }}
                  >
                    {screen.name}
                  </h3>
                  <span title={deviceName}>{deviceName}</span>
                </div>
              </div>
            );
          })}
          {loadMore}
        </div>
      </ChannelPlayingScreenStyled>
    );
  }
}

export default withData(PlayingScreenContent);
