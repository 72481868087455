import {
  DataValue,
  withApollo,
  WithApolloClient,
} from "@apollo/client/react/hoc";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ssm } from "../../state/session/ssm";
import { compose } from "../../utils/compose";

import {
  AllCredentialsBySpaceIdDocument,
  AllCredentialsBySpaceIdQuery,
  AllIdentityProvidersQuery,
  DeleteCredentialMutationFn,
  withAllCredentialsBySpaceId,
  withAllIdentityProviders,
  withDeleteCredential,
} from "../../types.g";

import { CredentialPickerProps } from "./index";

export interface ApolloProps
  extends RouteComponentProps<any>,
    WithApolloClient<{}> {
  credentials: DataValue<AllCredentialsBySpaceIdQuery>;
  deleteCredential: DeleteCredentialMutationFn;
  identityProviders: DataValue<AllIdentityProvidersQuery>;
}

export const withData = compose(
  withRouter,
  withApollo,
  withAllCredentialsBySpaceId({
    name: "credentials",
    options: (props: CredentialPickerProps) => {
      return {
        fetchPolicy: "network-only",
        variables: { spaceId: ssm.current.settings.spaceId },
      };
    },
  }),
  withAllIdentityProviders({
    name: "identityProviders",
    options: {
      fetchPolicy: "network-only",
    },
  }),
  withDeleteCredential({
    name: "deleteCredential",
    options: {
      refetchQueries: [{ query: AllCredentialsBySpaceIdDocument }],
    },
  }),
);
