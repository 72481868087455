import { Button, Icon, Loader } from "@screencloud/screencloud-ui-components";
import { uniq } from "lodash";
import * as React from "react";
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormattedMessage } from "react-intl";
import EmptyState from "../../components/EmptyState";
import { SiteListItemActions } from "../../components/SiteListItem";
import { Site } from "../../types.g";
import SiteContentItem from "../SiteContentItem";
import { Styled } from "./styles";
import {
  hasSitePermissionByContext,
  PERMISSION_GROUPS,
  PERMISSION_KEYS,
} from "../../pages/Sites/helpers/permissions";
import { ApolloProps, withData } from "./apollo";
import { useAppContext } from "src/hooks/useAppContext";

const SiteList = (props: ApolloProps) => {
  const context = useAppContext();
  const [lastSelectIndex, setLastSelectIndex] = React.useState(-1);
  const {
    onLoadMoreSiteData,
    onClickSiteCallback,
    selectedSiteCallback,
    searchTerms,
    siteData,
    onSelectSiteItems,
    selectedSiteItems,
    loadMore,
    data,
    loading,
  } = props;

  if (!context.currentPermissions.validateCurrentSpace("site", "read")) {
    return null;
  }

  const isLoading = siteData?.loading;
  const hasSearchTerm = searchTerms?.length;
  const allSites =
    props.searchTerms && props.data.searchSite
      ? props.data.searchSite.nodes
      : props.siteData?.spaceById?.availableSitesBySpaceId.nodes;
  const handleAddNewSite = () => {
    context.modal.openNewSiteModal();
  };

  const selectItem = (site: Partial<Site>, selectedItems: Partial<Site>[]) => {
    const foundItem = selectedItems.find(
      (item) => item.id === site.id && item.__typename === site.__typename,
    );
    if (selectedItems.length > 1) {
      return [site];
    } else {
      return foundItem ? [] : [site];
    }
  };

  const shiftSelectItem = (
    site: Partial<Site>,
    selectedIdx: number,
    lastIdx: number,
    selectedItems: Partial<Site>[],
    siteItems: Partial<Site>[],
  ) => {
    let selected: Partial<Site>[] = [];
    if (selectedItems.length > 0) {
      if (lastIdx >= selectedIdx) {
        selected = [...selectedItems, ...siteItems.slice(selectedIdx, lastIdx)];
      } else if (lastIdx !== -1) {
        selected = [
          ...selectedItems,
          ...siteItems.slice(lastIdx, selectedIdx + 1),
        ];
      }
      return uniq(selected); // remove duplicated items
    }

    return selectItem(site, selectedItems);
  };

  const selectMultipleItem = (
    site: Partial<Site>,
    index: number,
    lastSelectedIndex: number,
    selectedItems: Partial<Site>[],
    siteItems: Partial<Site>[],
    event?: React.MouseEvent,
  ) => {
    // const keyboardEvent = event as React.KeyboardEvent
    const shiftKey = event && event.shiftKey;
    const metaKey = event && event.metaKey;
    const ctrlKey = event && event.ctrlKey;

    // with shift key
    if (shiftKey) {
      return shiftSelectItem(
        site,
        index,
        lastSelectedIndex,
        selectedItems,
        siteItems,
      );
    }

    // with ctrl/cmd key
    if (metaKey || ctrlKey) {
      const foundItem = selectedItems.find(
        (item) => item.id === site.id && item.__typename === site.__typename,
      );
      if (foundItem) {
        return selectedItems.filter(
          (item) =>
            !(
              item.id === foundItem.id &&
              item.__typename === foundItem.__typename
            ),
        );
      } else {
        return [...selectedItems, site];
      }
    }

    return selectItem(site, selectedItems);
  };

  const handleSiteCallback = (
    action: SiteListItemActions,
    index: number,
    lastSelectedIndex: number,
    value: Partial<Site>,
    event?: React.MouseEvent,
  ) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (action === SiteListItemActions.SELECTED) {
      const selectedSites = selectMultipleItem(
        value,
        index,
        lastSelectedIndex,
        selectedSiteItems!,
        allSites as Site[],
        event,
      );
      setLastSelectIndex(index);
      if (onSelectSiteItems) {
        onSelectSiteItems(selectedSites);
      }
    } else {
      onClickSiteCallback?.(action, value, event);
    }
  };
  const handleLoadMore = () => {
    if (searchTerms && data?.searchSite?.pageInfo!.hasNextPage && !loading) {
      loadMore();
    } else {
      onLoadMoreSiteData?.();
    }
  };

  let hasNextPage = data?.searchSite
    ? data.searchSite.pageInfo!.hasNextPage
    : false;
  if (!searchTerms) {
    hasNextPage = siteData?.spaceById
      ? siteData.spaceById.availableSitesBySpaceId.pageInfo!.hasNextPage
      : false;
  }
  const renderSites = allSites
    ? allSites.map((site: Site, index: number) => (
        <Droppable
          isDropDisabled={true}
          droppableId={`site-${site.id}`}
          key={site.id}
        >
          {(
            droppableProvided: DroppableProvided,
            droppableSnapshot: DroppableStateSnapshot,
          ) => (
            <div
              className="column site-item"
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
              style={{
                backgroundColor: droppableSnapshot.isDraggingOver
                  ? "#eee"
                  : "transparent",
              }}
            >
              <SiteContentItem
                selectedSiteItems={selectedSiteItems}
                selectedSiteCallback={selectedSiteCallback}
                onClickSiteCallback={(action, value, event) =>
                  handleSiteCallback(
                    action,
                    index,
                    lastSelectIndex,
                    value,
                    event,
                  )
                }
                index={index}
                siteItem={site}
                secureMediaPolicy={context.secureMediaPolicy}
              />
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      ))
    : null;
  const isHaveContentItem =
    allSites && allSites.length ? (
      <ul className="site-list">{renderSites}</ul>
    ) : hasSearchTerm ? (
      <EmptyState section="site-sidebar" cover={false} className="empty">
        <p>
          <FormattedMessage
            id="common.text.no_result_found_matching_query"
            defaultMessage="No results found matching query"
          />
        </p>
      </EmptyState>
    ) : (
      <EmptyState section="site-sidebar" cover={false} className="empty">
        {hasSitePermissionByContext(
          context,
          PERMISSION_GROUPS.Site,
          PERMISSION_KEYS.Create,
        ) && (
          <>
            <h3>
              <FormattedMessage
                id="sites.site_empty.no_content"
                defaultMessage="No site added yet"
              />
            </h3>
            <p>
              <FormattedMessage
                id="sites.site_empty.help_text"
                defaultMessage="Add a site or URL here"
              />
            </p>
          </>
        )}
      </EmptyState>
    );

  return (
    <Styled>
      {hasSitePermissionByContext(
        context,
        PERMISSION_GROUPS.Site,
        PERMISSION_KEYS.Create,
      ) && (
        <Button
          className="add-site"
          mini
          upload
          onClick={handleAddNewSite}
          data-testid="add-site-button"
        >
          <Icon name="plus" />
        </Button>
      )}
      <div className={`layout-list site-section`}>
        <FormattedMessage id="sites.sites" defaultMessage="Dashboards" />
      </div>
      <div className="layout-container" id="scrollableDiv">
        <InfiniteScroll
          style={{ overflow: "none", height: "none" }}
          dataLength={allSites ? allSites.length : 0}
          next={handleLoadMore}
          hasMore={hasNextPage}
          scrollableTarget="scrollableDiv"
          loader={
            <div className="layout-list" key="loading-message">
              Loading...
            </div>
          }
        >
          {isHaveContentItem}
        </InfiniteScroll>
      </div>
      <Loader active={isLoading} />
    </Styled>
  );
};

export default withData(SiteList);
