import { ListItemType } from "../constants/constants";
import { Channel, Org, ScreenRotation, ScreenStatus } from "../types.g";

import { ssm } from "../state/session/ssm";
import { AppContextType } from "src/AppContextProvider/type";
import { appConfig } from "src/appConfig";

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export function capitalizeEachWord(
  str: string,
  splitText: string = " ",
): string {
  return str
    .split(splitText)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
export const parseMillisecondstoTime = (millisec: number) => {
  const date = new Date(0);
  date.setMilliseconds(millisec);
  const duration = date.toISOString().substr(11, 8);
  return duration;
};

export const getTotalTimeDuration = (value: number): string => {
  const seconds = Math.floor(value / 1000) % 60;
  const minutes = Math.floor(value / (1000 * 60)) % 60;
  const hours = Math.floor(value / (1000 * 60 * 60));

  const hrs = hours < 10 ? "0" + hours : hours > 999 ? "+999" : hours;
  const mins = minutes < 10 ? "0" + minutes : minutes;
  const secs = seconds < 10 ? "0" + seconds : seconds;
  return `${hrs}:${mins}:${secs}`;
};

export const getRequestFullscreenFunc = (element: HTMLElement) => {
  const fullscreenFunc =
    element.requestFullscreen ||
    (element as any).msRequestFullscreen ||
    (element as any).mozRequestFullScreen;

  if (fullscreenFunc) {
    return fullscreenFunc.bind(element);
  } else {
    return undefined;
  }
};

export const getListItemType = (contentType: string) => {
  if (contentType.startsWith("text") || contentType.startsWith("application")) {
    return ListItemType.DOCUMENT;
  } else if (contentType.startsWith("image")) {
    return ListItemType.IMAGE;
  } else if (contentType.startsWith("audio")) {
    return ListItemType.AUDIO;
  } else if (contentType.startsWith("video")) {
    return ListItemType.VIDEO;
  } else if (contentType.startsWith("AppInstance")) {
    return ListItemType.APP;
  } else if (contentType.startsWith("Link")) {
    return ListItemType.LINK;
  } else if (contentType.startsWith("Playlist")) {
    return ListItemType.PLAYLIST;
  } else if (contentType.startsWith("Site")) {
    return ListItemType.SITE;
  } else {
    return;
  }
};

export function orgsList(): Partial<Org>[] {
  return ssm.users
    .map((session) => ({
      id: session.claims.orgId as string,
      index: parseInt(session.key),
      name: session.claims.orgName,
    }))
    .sort((a, b): number => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
}

export function getDefaultTranslationId(name?: string): string {
  return `defaults.${name}`;
}

export function screenStub(channel: Channel): any {
  // TODO
  return {
    castId: "",
    channelsByScreenId: {
      nodes: [channel],
      totalCount: 1,
    },
    content: {
      _ref: {
        id: channel.id,
      },
    },
    createdAt: "",
    createdBy: "",
    device: null,
    env: {},
    id: "",
    name: channel.name!,
    orgId: "",
    preview: false,
    rotation: ScreenRotation.Rotation_0,
    screenGroupId: "",
    spaceId: "",
    status: ScreenStatus.Live,
    tags: [],
    updatedAt: "",
    updatedBy: "",
  };
}

export function removeEmptyFromArray(a: any[]) {
  return a.filter((n) => n);
}

export function formatBytes(bytes: number, decimals: number = 2) {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const SC_STUDIO_PLAYER_URL = "__sc_player_url";
export function getStudioPlayerUrl(context: AppContextType) {
  const scPlayerUrl = localStorage.getItem(SC_STUDIO_PLAYER_URL);
  const playerUrlOverride =
    context.currentOrg?.preferences.player?.player_url_override;
  return scPlayerUrl ?? playerUrlOverride ?? appConfig.studioPlayerUrl;
}
