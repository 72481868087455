import * as React from "react";
import {
  Button,
  Icon,
  ScreenPreview,
} from "@screencloud/screencloud-ui-components";
import { appConfig } from "../../appConfig";
import FullScreenModalContentMain from "../FullScreenModal/FullScreenModalContentMain";
import { FormattedMessage } from "react-intl";
import { useAppContext } from "../../hooks/useAppContext";
import { ssm } from "../../state/session/ssm";
import { PreviewWrapper } from "./styles";
import {
  mapEnvToPlayerSdk,
  mapRegionToPlayerSdk,
} from "../../helpers/playerSdkHelper";
import {
  renderStudioPlayer,
  StudioPlayer,
} from "@screencloud/studio-player-sdk";
import { getStudioPlayerUrl } from "src/utils";
import { FEATURE_FLAGS_ENUM } from "src/constants/featureFlag";

export enum PreviewMode {
  None,
  IsRecording,
  Recorded,
  Previewing,
}

interface IProps {
  siteId?: string;
  siteType?: string;
  previewMode: PreviewMode;
  focusOnPopup: any;
}

export const SitePreview = (props: IProps) => {
  const studioPlayerRef = React.useRef<StudioPlayer>();
  const playerPreviewTargetRef =
    React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const isStudioPlayerLoadedRef = React.useRef(false);
  const context = useAppContext();

  const handleRequestAuthToken = () =>
    Promise.resolve({ authToken: ssm.current.token || "", expiresAt: 0 });

  React.useEffect(() => {
    if (
      !isStudioPlayerLoadedRef.current &&
      props.siteId &&
      props.previewMode === PreviewMode.Previewing
    ) {
      const currentRegion = mapRegionToPlayerSdk(appConfig.studioPlayerRegion);
      const currentEnvironment = mapEnvToPlayerSdk(
        appConfig.studioPlayerEnvironment,
      );
      const playerDevicePlatform = "studio";
      const playerDeviceModel = "app-preview";

      if (
        playerPreviewTargetRef.current &&
        currentRegion &&
        currentEnvironment
      ) {
        // render single instance of studio player
        studioPlayerRef.current = renderStudioPlayer({
          target: playerPreviewTargetRef.current,
          region: currentRegion, // eu | us
          token: context.user.token || "",
          contentPath: `/site/${props.siteId}/preview`,
          environment: currentEnvironment, // production | staging
          playerUrl: getStudioPlayerUrl(context),
          spaceId: context.user.settings.spaceId,
          overrideAppInitialize: {
            appInstanceId: props.siteId,
            config: {
              isPreview: true,
              siteId: props.siteId,
              type: props.siteType,
              url: "",
            },
            orgId: context.currentUser?.orgId,
            viewerUrl: appConfig.sitesViewerUrl,
            device: {
              platform: playerDevicePlatform,
              model: playerDeviceModel,
            },
          },
          context: {
            userInteractionEnabled: true,
            isAiFeatureEnabled: context.shouldShowFeature(
              FEATURE_FLAGS_ENUM.AI_FEATURES,
            ),
          },
          features: {
            offlineStorage: false,
            simulatedDimensions: {
              width: 1920, // Simulate HD screen values
              height: 1080,
            },
          },
        });
        // setup callbacks to studio player to handle communication from apps
        studioPlayerRef.current?.on("requestAuthToken", handleRequestAuthToken);
        isStudioPlayerLoadedRef.current = true;
      }
    }
  });

  const renderPreview = () => {
    let content;

    if (props.siteId && props.previewMode === PreviewMode.Previewing) {
      // remove content if there is a preview
      content = null;
    } else if (props.siteId && props.previewMode === PreviewMode.None) {
      content = (
        <div className="preview-content">
          <div
            className="popupMessage"
            data-testid="ui_component.site.recorder.popup_recording"
          >
            <div className="recording-prompt">
              <Icon name={"record"} className="recording-ready" />
              <span className="title-text">
                <FormattedMessage
                  id={`ui_component.site.recorder.popup_recording_ready`}
                  defaultMessage={`Ready to record...`}
                />
              </span>
            </div>
            <span className="sub-text">
              <FormattedMessage
                id={`ui_component.site.recorder.popup_recording_subtext_ready`}
                defaultMessage={`Your journey will be recorded in a separate popup window.`}
              />
            </span>
          </div>
        </div>
      );
    } else if (props.previewMode === PreviewMode.IsRecording) {
      content = (
        <div className="preview-content">
          <div
            className="popupMessage"
            data-testid="ui_component.site.recorder.popup_recording"
          >
            <div className="recording-prompt">
              <Icon name={"record"} className="recording-icon" />
              <span className="title-text">
                <FormattedMessage
                  id={`ui_component.site.recorder.popup_recording`}
                  defaultMessage={`Recording in progress...`}
                />
              </span>
            </div>
            <span className="sub-text">
              <FormattedMessage
                id={`ui_component.site.recorder.popup_recording_subtext`}
                defaultMessage={`Your journey is being recorded in a separate popup window.`}
              />
            </span>
            <div className="btn">
              <Button
                fullWidth
                outline
                className="spaced"
                onClick={props.focusOnPopup}
              >
                <FormattedMessage
                  id="ui_component.site.recorder.focus_on_popup"
                  defaultMessage="Focus on Popup"
                />
              </Button>
            </div>
          </div>
        </div>
      );
    } else if (props.previewMode === PreviewMode.Recorded) {
      content = (
        <div
          className="preview-content"
          data-testid="ui_component.site.recorder.save_and_preview_message"
        >
          <div
            className="popupMessage"
            data-testid="ui_component.site.recorder.recording-complete"
          >
            <div className="recording-prompt">
              <Icon name="checked-circle" className="recording-complete" />
              <span className="title-text">
                <FormattedMessage
                  id={`ui_component.site.recorder.recording_complete`}
                  defaultMessage={`Recording completed`}
                />
              </span>
            </div>
            <span className="sub-text">
              <FormattedMessage
                id={`ui_component.site.recorder.popup_complete_subtext`}
                defaultMessage={`Click 'Save & Preview' to finalize and review your recording.`}
              />
            </span>
          </div>
        </div>
      );
    } else {
      content = (
        <div
          className="preview-content"
          data-testid="ui_component.site.recorder.popup_warning"
        >
          <FormattedMessage
            id={`ui_component.site.recorder.popup_warning`}
            defaultMessage={`Your site will open in a popup window.`}
          />
        </div>
      );
    }

    return (
      <>
        {content}
        <div
          style={{ height: "100%", width: "100%", background: "black" }}
          ref={playerPreviewTargetRef}
        />
      </>
    );
  };

  return (
    <PreviewWrapper>
      <FullScreenModalContentMain>
        <ScreenPreview width={1920} height={1080}>
          {renderPreview()}
        </ScreenPreview>
      </FullScreenModalContentMain>
    </PreviewWrapper>
  );
};
