import { withApollo } from "@apollo/client/react/hoc";
import { UUID } from "@screencloud/uuid";
import { uniqBy } from "lodash";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  CastByCastIdFragment,
  CastByChannelByIdQuery,
  CastByChannelByIdQueryVariables,
  CreateCastsByChannelIdMutationFn,
  DuplicateChannelMutationFn,
  Exact,
  Maybe,
  RevertDraftChannelMutationFn,
  ScreenCastStopInput,
  SetScreenContentByChannelIdMutationFn,
  UpdateChannelEmbedMutationFn,
  UpdateChannelNameMutationFn,
  UpdateShareChannelToAllSpacesMutationFn,
  UpdateShareChannelToSpacesMutationFn,
  useCastByChannelByIdQuery,
  useCreateCastsByChannelIdMutation,
  useDuplicateChannelMutation,
  useRevertDraftChannelMutation,
  useSetScreenContentByChannelIdMutation,
  useUpdateChannelEmbedMutation,
  useUpdateChannelNameMutation,
  useUpdateShareChannelToAllSpacesMutation,
  useUpdateShareChannelToSpacesMutation,
} from "../../../../types.g";
import { compose } from "../../../../utils/compose";
import { QueryResult } from "@apollo/client";
import {
  UseDeleteChannel,
  useDeleteChannel,
} from "../../../../hooks/useDeleteChannel";
import { useScreenCastStop } from "../../../../hooks/useStopCasts";
import { useCreateSnapshot } from "../../../../hooks/duplication/useCreateSnapshot";

export interface ApolloProps
  extends RouteComponentProps<any>,
    UseDeleteChannel {
  loadMore: (page: number, id: UUID) => void;
  castByChannelById?: QueryResult<
    CastByChannelByIdQuery,
    CastByChannelByIdQueryVariables
  >;
  duplicateChannel: DuplicateChannelMutationFn;
  revertDraftChannel: RevertDraftChannelMutationFn;
  createCastsByChannelId: CreateCastsByChannelIdMutationFn;
  setContentByChannelId: SetScreenContentByChannelIdMutationFn;
  updateChannelName: UpdateChannelNameMutationFn;
  updateShareChannelToSpaces: UpdateShareChannelToSpacesMutationFn;
  screenCastStop: (
    stopCastsInput: Exact<{
      input: ScreenCastStopInput;
    }>,
    castItem?: Maybe<CastByCastIdFragment>,
  ) => Promise<any>;
  updateShareChannelToAllSpaces: UpdateShareChannelToAllSpacesMutationFn;
  updateChannelEmbed: UpdateChannelEmbedMutationFn;
  createSnapshot: (
    contentName: string,
    contentId: string,
    isPublicSnapshot?: boolean,
  ) => Promise<void>;
}

export const withData = compose(
  withRouter,
  withApollo,
  (Component) => (props: ApolloProps) => {
    const channelId = props.match.params.channelId;
    const queryVar: CastByChannelByIdQueryVariables = {
      id: channelId,
      offset: 0,
      expectedItemPerPage: 200,
    };

    const castByChannelById = useCastByChannelByIdQuery({
      fetchPolicy: "cache-and-network",
      variables: queryVar,
      skip:
        !props.match || !props.match.params || !props.match.params.channelId,
    });

    const loadMore = (page: number, id: UUID) =>
      castByChannelById?.fetchMore({
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const previousData =
            previousResult.channelById?.associationsByToChannelAndOrgId;
          const currentData =
            fetchMoreResult!.channelById?.associationsByToChannelAndOrgId;
          const nodes = uniqBy(
            [...previousData!.nodes, ...currentData!.nodes],
            "id",
          );
          return {
            ...previousResult,
            channelById: {
              ...previousResult.channelById!,
              __typename: "Channel",
              associationsByToChannelAndOrgId: {
                ...previousData,
                __typename: "AssociationsConnection",
                nodes,
                totalCount: currentData?.totalCount ?? 0,
              },
            },
          };
        },
        variables: {
          id,
          offset: page,
        },
      });

    const [updateShareChannelToSpaces] =
      useUpdateShareChannelToSpacesMutation();
    const [updateShareChannelToAllSpaces] =
      useUpdateShareChannelToAllSpacesMutation();
    const [updateChannelName] = useUpdateChannelNameMutation();
    const [duplicateChannel] = useDuplicateChannelMutation();
    const [revertDraftChannel] = useRevertDraftChannelMutation();
    const [createCastsByChannelId] = useCreateCastsByChannelIdMutation();
    const [setContentByChannelId] = useSetScreenContentByChannelIdMutation();
    const { screenCastStop } = useScreenCastStop();
    const [updateChannelEmbed] = useUpdateChannelEmbedMutation();
    return (
      <Component
        {...props}
        castByChannelById={castByChannelById}
        updateShareChannelToSpaces={updateShareChannelToSpaces}
        updateShareChannelToAllSpaces={updateShareChannelToAllSpaces}
        updateChannelName={updateChannelName}
        duplicateChannel={duplicateChannel}
        revertDraftChannel={revertDraftChannel}
        createCastsByChannelId={createCastsByChannelId}
        setContentByChannelId={setContentByChannelId}
        screenCastStop={screenCastStop}
        updateChannelEmbed={updateChannelEmbed}
        loadMore={loadMore}
        {...useDeleteChannel()}
      />
    );
  },
  (Component) => (props: ApolloProps) => {
    const { createSnapshot } = useCreateSnapshot();
    return <Component {...props} createSnapshot={createSnapshot} />;
  },
);
