export const autoPairingCodeKey = "code";

export const getAutoPairingCode = (
  searchParams: URLSearchParams = new URLSearchParams(window.location.search),
): string => {
  return searchParams.get(autoPairingCodeKey) || "";
};

export const removePairingCodeFromUrl = (): void => {
  const searchParams: URLSearchParams = new URLSearchParams(
    window.location.search,
  );
  if (!searchParams.has(autoPairingCodeKey)) {
    return;
  }

  searchParams.delete(autoPairingCodeKey);
  const newUrl =
    window.location.pathname +
    (searchParams.toString() ? `?${searchParams.toString()}` : "");
  window.history.replaceState(null, "", newUrl);
};

export const appendAllowedQueryParams = (
  url: string,
  searchParams = new URLSearchParams(window.location.search),
) => {
  const filteredParams = new URLSearchParams();
  const allowedKeys = [autoPairingCodeKey];

  allowedKeys.forEach((key) => {
    if (searchParams.has(key)) {
      filteredParams.append(key, searchParams.get(key)!);
    }
  });

  if (filteredParams.toString()) {
    if (url.includes("?")) {
      return `${url}&${filteredParams.toString()}`;
    } else {
      return `${url}?${filteredParams.toString()}`;
    }
  }
  return url;
};
