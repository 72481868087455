import { Icon, LoaderBar } from "@screencloud/screencloud-ui-components";
import { UUID } from "@screencloud/uuid";
import { PureComponent } from "react";
import { FormattedMessage } from "react-intl";
import { SortingActions } from "../../constants/constants";
import { File, Link, LinkType, Maybe } from "../../types.g";
import { compose } from "../../utils/compose";
import { LinkPickerItem } from "./styles";
import { generateImgixThumbnail } from "../../helpers/mediaHelper";
import { updateAndToggleSelectedItems } from "../../helpers/updateAndToggleSelectedItemsHelper";
import DateTime from "../DateTime";
import EmptyState from "../EmptyState";
import {
  UseSearchLinkPicker,
  useSearchLinkPicker,
} from "./hooks/useSearchLinkPicker";

const withData = compose((Component) => (props) => {
  const searchLinkProps = useSearchLinkPicker(props.searchTerms, props.spaceId);
  return <Component {...props} {...searchLinkProps} />;
});

export interface ApolloProps extends UseSearchLinkPicker {}

export interface SearchLinkContentPickerProps {
  searchTerms: string;
  isMultipleSelect: boolean | undefined;
  callback: (ids: string[], selectedLinks: Maybe<Partial<Link>>[]) => void;
  secureMediaPolicy: string | undefined;
  spaceId?: UUID;
}

export interface LinkContentPickerState {
  selectedIds: string[];
  sortBy: SortingActions;
  isOrderByAscending: boolean;
}

class SearchLinkContentPicker extends PureComponent<
  SearchLinkContentPickerProps & ApolloProps,
  LinkContentPickerState
> {
  constructor(props: SearchLinkContentPickerProps & ApolloProps) {
    super(props);
    this.state = {
      isOrderByAscending: true,
      selectedIds: [],
      sortBy: SortingActions.SORT_BY_NAME,
    };
  }

  public onToggleSelection = (link: Maybe<Partial<Link>>) => {
    const { links } = this.props;
    if (links && link) {
      const { selectedArray } = updateAndToggleSelectedItems(
        link.id,
        link,
        this.state.selectedIds,
        [],
        this.props.isMultipleSelect,
      );
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            selectedIds: selectedArray,
          };
        },
        () => {
          const selectedLinks = this.state.selectedIds.reduce(
            (allSelectedLink: Link[], currentId) => {
              const linkById = links.find(
                (linkData) => linkData!.id === currentId,
              );
              return [...allSelectedLink, linkById];
            },
            [],
          );
          this.props.callback(this.state.selectedIds, selectedLinks as Link[]);
        },
      );
    }
  };

  public renderOrderCaretIcon = (): JSX.Element => {
    const orderIcon = this.state.isOrderByAscending ? "caret-down" : "caret-up";
    return <Icon className="caret-order" name={orderIcon} />;
  };

  public renderLinkItem = (): JSX.Element[] => {
    const { links } = this.props;
    return links.map((link) => (
      <LinkPickerItem
        className="media-item"
        key={`playlist-${link && link.id}`}
        onClick={() => this.onToggleSelection(link as Link)}
        selected={this.state.selectedIds.includes(link && link.id)}
      >
        <div className="media-core">
          <div className="media-alpha">
            <div className="thumbnail-preview">
              <div className="thumbnail">
                <div className="wrapper">
                  <img
                    src={
                      (link &&
                        link.fileByFileId &&
                        generateImgixThumbnail(
                          link.fileByFileId as File,
                          this.props.secureMediaPolicy,
                          true,
                        )) ||
                      ""
                    }
                  />
                </div>
              </div>
            </div>
            <div className="media-title">
              <h3>{link && link.name}</h3>
              <div>
                <span className="media-item__date">
                  <FormattedMessage
                    id="common.text.created_on"
                    defaultMessage="Created on"
                  />{" "}
                  <DateTime value={link && link.createdAt} />
                </span>
                <span className="media-item__url">{link && link.url}</span>
              </div>
            </div>
          </div>

          <div className="media-starred">
            <Icon name={link && link.isFavorite ? "star" : ""} />
          </div>

          <div className="media-type">
            {link && link.linkType === LinkType.Internal && (
              <FormattedMessage
                id="ui_component.link.type_internal_website"
                defaultMessage="Internal Website"
              />
            )}
            {link && link.linkType === LinkType.Cloud && (
              <FormattedMessage
                id="ui_component.link.type_cloud_render"
                defaultMessage="Cloud Render"
              />
            )}
            {link && link.linkType === LinkType.Standard && (
              <FormattedMessage
                id="ui_component.link.type_website"
                defaultMessage="Website"
              />
            )}
          </div>

          <div className="media-tags">{/* add tags here */}</div>
        </div>
        <div className="media-checked">
          <Icon name="checked-circle" />
        </div>
      </LinkPickerItem>
    ));
  };

  public render() {
    const { links, loading, renderFetchMorePickerButton } = this.props;
    const shouldShowSearchEmptyState = !loading && links.length === 0;

    return (
      <>
        {links.length > 0 ? (
          <>
            {this.renderLinkItem()}
            {renderFetchMorePickerButton}
          </>
        ) : null}
        {shouldShowSearchEmptyState && (
          <EmptyState section="search-links" className="empty">
            <h3>
              <FormattedMessage
                id="common.search.no_results"
                defaultMessage="We couldn’t find any matches"
              />
            </h3>
            <p>
              <FormattedMessage
                id="common.search.no_results_description"
                defaultMessage="Try adjusting or using different search terms."
              />
            </p>
          </EmptyState>
        )}
        {loading && <LoaderBar />}
      </>
    );
  }
}

export default withData(SearchLinkContentPicker);
