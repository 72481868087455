import { useAppContext } from "src/hooks/useAppContext";
import { Space } from "src/types.g";

export function useOnCurrentUserSettingLastAccessSpaceUpdated() {
  const context = useAppContext();

  const onCurrentUserSettingLastAccessSpaceUpdated = ({
    lastAccessSpace,
    isSpaceNewlyCreated,
  }: {
    lastAccessSpace: Pick<Space, "id" | "name">;
    isSpaceNewlyCreated: boolean;
  }) => {
    context.setActiveSpace(
      lastAccessSpace.id,
      lastAccessSpace.name,
      isSpaceNewlyCreated,
    );
  };

  return { onCurrentUserSettingLastAccessSpaceUpdated };
}
