import { UUID } from "@screencloud/uuid";
import { FEATURE_FLAGS_ENUM } from "src/constants/featureFlag";
import { isResellerShowSupport } from "src/domains/reseller/reseller";

export const isShowSupport = (
  shouldShowFeature: (feature: string) => boolean,
  orgId: UUID,
): boolean => {
  const isReseller = shouldShowFeature(FEATURE_FLAGS_ENUM.RESELLER);

  if (isReseller && isResellerShowSupport(orgId)) {
    return true;
  } else if (!isReseller) {
    return true;
  } else {
    return false;
  }
};
