import { StudioPermissions } from "@screencloud/studio-permissions";
import { AppContextType } from "src/AppContextProvider/type";
import { FEATURE_FLAGS_ENUM } from "src/constants/featureFlag";
import { isShowDashboardByOrgId } from "src/domains/dashboard/dashboard";
import {
  hasSitePermission,
  PERMISSION_GROUPS,
  PERMISSION_KEYS,
} from "src/pages/Sites/helpers/permissions";
import { getCurrentSpaceId } from "src/state/session/ssm";
import { useAppContext } from "../hooks/useAppContext";
import { isShowSupport } from "./isShowSupport";
export interface GetUserInterfaceVisibilitiesParams {
  orgId: string;
  shouldShowFeature: (feature: string) => boolean;
  studioPermissions: StudioPermissions;
}

export function getUserInterfaceVisibilities(
  param: GetUserInterfaceVisibilitiesParams,
) {
  const { shouldShowFeature, studioPermissions, orgId } = param;
  const currentSpaceId = getCurrentSpaceId();

  return {
    mainMenu: {
      isShowApp: studioPermissions.validateCurrentSpace("app", "read"),
      isShowChannel: studioPermissions.validateCurrentSpace("app", "read"),
      /**
       * Note: We want everyone to see dashboard as an advertisement from mainmenu.
       * This dashboard page does not contains any data just some kind of feature introduction.
       * So people who have no permission for dashboard (whiich is represent by feature flag IS_SECURE_SITES_CUSTOMER can use it)
       */
      isShowDashboard:
        isShowDashboardByOrgId(orgId) &&
        studioPermissions.validateCurrentSpace("site", "read"),
      /**
       * TODO: Make sure if we need featureflag link menu here, this is being used in media picker.
       * But it was not using in mainmenu, wonder if we should use it ?
       */
      // shouldShowFeature(FEATURE_FLAGS_ENUM.LINKS_MENU),
      isShowLink: studioPermissions.validateCurrentSpace("link", "read"),
      isShowMedia: studioPermissions.validateCurrentSpace("media", "read"),
      isShowPlaylist: studioPermissions.validateCurrentSpace(
        "playlist",
        "read",
      ),
      isShowQrCodeMetrics: shouldShowFeature(FEATURE_FLAGS_ENUM.QRCODE_METRICS),
      isShowScreen: studioPermissions.validateCurrentSpace("screen", "read"),
      isShowSupport: isShowSupport(shouldShowFeature, orgId),
    },
    /**
     * These menu is in Playlistdetail, Channeldetail and screenHelper
     */
    mediaPicker: {
      isShowDashboard: hasSitePermission({
        shouldShowFeature,
        studioPermissions,
        permissionGroup: PERMISSION_GROUPS.Site,
        permissionKey: PERMISSION_KEYS.Read,
      }),
      isShowLink:
        studioPermissions.validateCurrentSpace("link", "read") &&
        shouldShowFeature(FEATURE_FLAGS_ENUM.LINKS_MENU),
    },
    /**
     * ScreenDetails section touch toggle
     */
    screenDetails: {
      isShowTouch: shouldShowFeature(FEATURE_FLAGS_ENUM.TOUCH),
    },
    space: {
      isShowDuplicateButton: shouldShowFeature(
        FEATURE_FLAGS_ENUM.DUPLICATE_SPACE,
      ),
      isShowDeleteButton: ({ spaceId }: { spaceId: string }) =>
        studioPermissions.validateCurrentSpace("space", "delete") &&
        spaceId !== currentSpaceId,
    },
  };
}

export function getCurrentUserInterfaceVisibilitiesByContext(
  context: Pick<
    AppContextType,
    "currentOrg" | "currentPermissions" | "shouldShowFeature"
  >,
) {
  return getUserInterfaceVisibilities({
    studioPermissions: context.currentPermissions,
    shouldShowFeature: context.shouldShowFeature,
    orgId: context?.currentOrg?.id,
  });
}

/**
 * Please note that this function does not factor feature flags or other specific condition into account
 */
export function useCurrentUserInterfaceVisibilities() {
  const context = useAppContext();
  return getCurrentUserInterfaceVisibilitiesByContext(context);
}
