import {
  Button,
  DeviceIcon,
  Icon,
  ScreenState,
  TagList,
} from "@screencloud/screencloud-ui-components";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RefType } from "../../constants/constants";
import { getDeviceModel } from "../../helpers/getDeviceModel";
import { getPlatform } from "../../helpers/getPlatform";
import { useAppContext } from "../../hooks/useAppContext";
import {
  CastByCastIdFragment,
  Maybe,
  ScreensByScreenGroupIdAndOrgIdFragment,
} from "../../types.g";
import { ContentItem } from "../ScreenContentPicker";
import { TrackVisibilityV2 } from "../TrackVisibility";
import { Styled } from "./styles";

export interface ScreenItemSearchResultProps {
  callBack: (checked: boolean, id: string) => void;
  className?: string;
  id: string;
  player_height?: number;
  player_width?: number;
  name: string;
  group: string;
  tags: (string | null)[];
  status: string;
  checked: boolean;
  castItem?: Maybe<CastByCastIdFragment>;
  contentItem?: ContentItem;
  isCasting: boolean;
  isReadOnly?: boolean;
  isURLClickable?: boolean;
  preview?: boolean;
  url: string;
  forceVisible?: boolean;
  screen: ScreensByScreenGroupIdAndOrgIdFragment;
}

export interface ScreenItemSearchResultState {
  isChecked: boolean;
}

export const getEditableClassName = (
  canUpdateScreen: boolean,
  isReadOnly?: boolean,
) => {
  return !isReadOnly && canUpdateScreen ? "editable" : "";
};

export const ScreenItemSearchResult = (props: ScreenItemSearchResultProps) => {
  const context = useAppContext();
  const [isChecked, setIsChecked] = useState<boolean>(props.checked);

  useEffect(() => {
    setIsChecked(props.checked);
  }, [props.checked]);

  const onToggleSelect = () => {
    if (!props.isReadOnly) {
      props.callBack && props.callBack(!isChecked, props.id);
      setIsChecked(!isChecked);
    }
  };

  const renderColumnCasting = (): JSX.Element => {
    if (props.isCasting && props.castItem) {
      const { castItem } = props;
      let castItemType = "";
      let castItemName = "";
      if (
        castItem &&
        castItem.playlistByCastId &&
        castItem.playlistByCastId.__typename === "Playlist"
      ) {
        castItemType = "playlist";
        castItemName = castItem.playlistByCastId.name;
      } else if (
        castItem &&
        castItem.linkByCastId &&
        castItem.linkByCastId.__typename === "Link"
      ) {
        castItemType = "link";
        castItemName = castItem.linkByCastId.name;
      } else if (
        castItem &&
        castItem.siteByCastId &&
        castItem.siteByCastId.__typename === "Site"
      ) {
        castItemType = "dashboard";
        castItemName = castItem.siteByCastId.name;
      } else if (
        castItem &&
        castItem.fileByCastId &&
        castItem.fileByCastId.mimetype &&
        castItem.fileByCastId.__typename === "File"
      ) {
        const fileType = castItem.fileByCastId.mimetype.split("/")[0];
        castItemName = castItem.fileByCastId.name;
        switch (fileType) {
          case "image":
            castItemType = "image";
            break;
          case "video":
            castItemType = "video";
            break;
          case "application":
            castItemType = "document";
            break;
        }
      } else if (
        castItem &&
        castItem.appInstanceByCastId &&
        castItem.appInstanceByCastId.__typename === "AppInstance"
      ) {
        castItemType = "app";
        castItemName = castItem.appInstanceByCastId.name!;
      } else if (
        castItem &&
        castItem.channelByCastId &&
        castItem.channelByCastId.__typename === "Channel"
      ) {
        castItemType = RefType.CHANNEL;
        castItemName = castItem.channelByCastId.name!;
      }

      return (
        <div className="casting">
          <Icon name={castItemType} />
          <span>{castItemName}</span>
        </div>
      );
    } else {
      let castItemType = "channel";
      let castItemName = "None";
      if (props.contentItem) {
        castItemType = props.contentItem.type;
        castItemName = props.contentItem.name;

        castItemType =
          castItemType === RefType.SITE ? "dashboard" : castItemType;

        if (castItemType === RefType.FILE) {
          const fileType =
            props.contentItem.mimeType &&
            props.contentItem.mimeType.split("/")[0];
          switch (fileType) {
            case "image":
              castItemType = "image";
              break;
            case "video":
              castItemType = "video";
              break;
            case "application":
              castItemType = "document";
              break;
          }
        }
      }
      return (
        <div className="channel">
          <Icon name={castItemType} />
          <span>{castItemName}</span>
        </div>
      );
    }
  };

  const { name, group, status, tags, url, isReadOnly, isURLClickable } = props;
  const canUpdateScreen = context.currentPermissions.validateCurrentSpace(
    "screen",
    "update",
  );
  return (
    <Styled
      className={props.className}
      selected={isChecked}
      onClick={canUpdateScreen ? onToggleSelect : undefined}
    >
      <TrackVisibilityV2
        key={`track-visibility-screen-${props.id}`}
        partialVisibility={true}
        offset={500}
        forceVisible={props.forceVisible}
        container="lazy-ref-container"
      >
        <div
          className={`screen-core ${getEditableClassName(
            canUpdateScreen,
            isReadOnly,
          )}`}
        >
          <div className="screen-alpha">
            <div className="thumbnail-preview">
              <Link to={url}>
                <DeviceIcon
                  model={getDeviceModel(props.screen)}
                  platform={getPlatform(props.screen)}
                  showDetails
                  player_width={props.player_width}
                  player_height={props.player_height}
                />
              </Link>
            </div>

            <div className="screen-title">
              <h3>{isURLClickable ? <Link to={url}>{name}</Link> : name}</h3>
              <span className="screen-meta__group">{group}</span>
            </div>
          </div>

          <div className="screen-group">
            <span>{group}</span>
          </div>

          <div className="screen-player">{renderColumnCasting()}</div>

          <div className="screen-state">
            <ScreenState status={status} />
            {props.preview && <ScreenState status="trial" />}
          </div>

          <div className="screen-tags">
            <TagList taglist={(tags as string[]) || []} />
          </div>
        </div>

        {!isReadOnly && canUpdateScreen && (
          <div data-testid="screen-check" className="screen-check">
            <Button icon borderless>
              {isChecked ? (
                <Icon name="checkbox-checked" className="checked" />
              ) : (
                <Icon name="checkbox-empty" />
              )}
            </Button>
          </div>
        )}
      </TrackVisibilityV2>
    </Styled>
  );
};

export default ScreenItemSearchResult;
