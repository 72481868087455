import { Button, Icon, Popover } from "@screencloud/screencloud-ui-components";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { FEATURE_FLAGS_ENUM } from "../../constants/featureFlag";
import { calculateLayoutForPreview } from "../../helpers/channelHelper";
import { ChannelListItemFragment } from "../../types.g";
import { StyledPickerItem } from "./styles";

import { AppContext } from "../../AppContextProvider/AppContext";
import { getSpaceNameById } from "../../helpers/spaceHelper";
import { AppContextType } from "src/AppContextProvider/type";
import { CastedScreen, renderCastingStatus } from "../../helpers/castingHelper";
export interface ChannelPickerItemProps {
  background: string;
  channel: ChannelListItemFragment;
  spaceExist: boolean;
  selected?: boolean;
  isChannelOwner?: boolean;
  isPublish: boolean;
  isHome?: boolean;
  className?: string;
  callBack?: (channelId: string, data: ChannelListItemFragment) => void;
}

export interface ChannelPickerItemState {}

export class ChannelPickerItem extends Component<
  ChannelPickerItemProps,
  ChannelPickerItemState
> {
  public static contextType = AppContext;
  public context: AppContextType;

  constructor(props) {
    super(props);
  }

  public selectChannel = () => {
    const { channel, callBack } = this.props;
    if (callBack) {
      callBack(channel.id, channel);
    }
  };
  public render() {
    const { channel, spaceExist, isChannelOwner } = this.props;
    const isShared: boolean = channel.isSharedExplicit! || channel.isSharedAll!;
    const spaceName = getSpaceNameById(this.context, channel.spaceId);
    const castedScreensData = channel.castedScreenByChannelId.nodes.map(
      (screen) => ({
        id: screen.id,
        name: screen.name,
        spaceId: screen.spaceId,
        deviceModel: null,
      }),
    ) as CastedScreen[];
    return (
      <StyledPickerItem
        className={`${this.props.className || ""} media-item ${
          this.props.selected ? "selected" : ""
        }`}
        onClick={this.selectChannel}
      >
        <div className="media-core">
          <div className="media-alpha">
            {this.getThumbnailPreview()}
            <div className="media-title">
              {this.getTitle()}
              {this.getPublishStatus()}
            </div>
          </div>
          <div className="media-layout">{this.getOrientation()}</div>
          {this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING) && (
            <div className="media-screen">
              {renderCastingStatus({
                castScreenData: castedScreensData!,
                context: this.context,
              })}
            </div>
          )}
          {isShared &&
            this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.SHARING) &&
            spaceExist && (
              <div className="ch-share isShared">
                <Popover
                  trigger={
                    <Button
                      icon
                      mini
                      borderless
                      className="share-icon isShared"
                    >
                      <Icon name="users" />
                    </Button>
                  }
                  content={
                    isChannelOwner ? "Shared" : `Shared by ${spaceName} space`
                  }
                  position="top center"
                  inverted
                />
              </div>
            )}
        </div>
        <div className="media-checked">
          <Icon name="checked-circle" />
        </div>
      </StyledPickerItem>
    );
  }

  private getThumbnailPreview = () => {
    const { background } = this.props;
    const coverStyle =
      background.indexOf("http") !== -1
        ? { backgroundImage: "url(" + background + ")" }
        : { backgroundImage: background };
    return <div className="thumbnail-preview" style={coverStyle} />;
  };

  private getTitle = () => {
    const { channel } = this.props;

    return <h3>{channel.name}</h3>;
  };

  private getPublishStatus = () => {
    const { channel, isPublish, isChannelOwner } = this.props;
    const isShared: boolean = channel.isSharedExplicit! || channel.isSharedAll!;

    const publishedStatus = (
      <span className="ch-status published">
        <FormattedMessage
          id="ui_component.common.label.published"
          defaultMessage="Published"
        />
      </span>
    );
    const draftStatus = (
      <span className="ch-status">
        <Popover
          inverted
          position="top center"
          content={
            <span>
              <FormattedMessage
                id="common.text.draft_content_message"
                defaultMessage="Changes have been made. Please publish to play the latest changes on your screens"
              />
            </span>
          }
          trigger={
            <span>
              <Icon name="warning" />
              <FormattedMessage
                id="common.text.draft_saved"
                defaultMessage="Draft Saved. Publish required."
              />
            </span>
          }
        />
      </span>
    );

    return isPublish || (isShared && !isChannelOwner)
      ? publishedStatus
      : draftStatus;
  };

  private getOrientation = () => {
    const layoutWH: number[] = calculateLayoutForPreview(
      this.props.channel.width!,
      this.props.channel.height!,
      20,
      20,
    );
    const layoutName = this.props.channel?.layoutByChannel?.config?.name || "";

    return (
      <>
        <div className="orientation">
          <span
            className="orientation-preview"
            style={{ width: layoutWH[0] + "px", height: layoutWH[1] + "px" }}
          />
        </div>
        <span className="layout-name">{layoutName}</span>
      </>
    );
  };
}

export default ChannelPickerItem;
