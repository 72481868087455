import {
  ApolloClient,
  ApolloLink,
  FieldPolicy,
  FieldReadFunction,
  HttpLink,
  InMemoryCache,
  Observable,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { snakeCase } from "lodash";
import { ReactAppEnvironment } from "../constants/constants";
import { ssm } from "./session/ssm";
import apolloLogger from "apollo-link-logger";
import { appConfig } from "../appConfig";
import {
  channelListField,
  channelPickerField,
  searchChannelListField,
} from "./ChannelTypePolicies";
import { screenListField } from "./ScreenTypePolicies";
import {
  linkListField,
  searchLinkField,
  linkByIdField,
  linkPickerField,
} from "./LinkTypePolicies";
import {
  mediaListField,
  mediaPickerForOrgWideField,
  searchMediaField,
} from "./MediaTypePolicies";
import { allUsersField } from "./UserTypePolicies";
import {
  playlistListField,
  playlistPickerField,
  searchPlaylistField,
} from "./PlaylistTypePolicies";
import {
  appInstancesBySpaceIdField,
  appInstancesField,
  appInstancePickerField,
  searchAppinstancesField,
  searchAppInstancesByAppIdField,
} from "./AppInstancesTypePolicies";
import { appinstallField, appPickerField } from "./AppInstallTypePolicies";
import { searchSiteField, siteListField } from "./SiteTypePolicies";
import { allSpacesField } from "./SpacesTypePolicies";
import { allScreensField } from "./AllScreensTypePolicies";
export type fieldsPolicyType = {
  [fieldName: string]: FieldPolicy<any, any, any> | FieldReadFunction<any, any>;
};

const cache = new InMemoryCache({
  addTypename: true,
  typePolicies: {
    Query: {
      fields: {
        ...linkByIdField,
        ...searchLinkField,
        ...searchPlaylistField,
        ...searchChannelListField,
        ...allUsersField,
        ...searchAppinstancesField,
        ...searchAppInstancesByAppIdField,
        ...searchMediaField,
        ...mediaPickerForOrgWideField,
        ...searchSiteField,
        ...allSpacesField,
        ...allScreensField,
      },
    },
    Folder: {
      fields: {
        ...mediaListField,
      },
    },
    Space: {
      fields: {
        ...channelListField,
        ...screenListField,
        ...linkListField,
        ...playlistListField,
        ...appinstallField,
        ...appInstancesField,
        ...appInstancesBySpaceIdField,
        ...siteListField,
      },
    },
    Org: {
      fields: {
        ...appPickerField,
        ...appInstancePickerField,
        ...channelPickerField,
        ...linkPickerField,
        ...playlistPickerField,
      },
    },
  },
});

const httpLink = new HttpLink({
  uri: `${appConfig.regions.current.service.baseUrl}/graphql`,
});

// adds network delay so that development feels as slow as worst case latency
const slowNetworkSimulatorLink =
  appConfig.reactEnvironment === ReactAppEnvironment.Development
    ? new ApolloLink((operation, forward) => {
        if (!forward) {
          return null;
        }
        const timeout = 500 + Math.round(Math.random() * 20);
        // console.log(`ApolloDevDelay: delay query ${operation.operationName} for ${timeout}ms`) // keep this
        return new Observable((observer) => {
          setTimeout(() => {
            forward(operation).subscribe(observer);
          }, timeout);
        });
      })
    : undefined;

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  if (!(headers && headers.authorization)) {
    const { settings } = ssm.current;
    const systemOverrides = ssm.current.isSystemUser
      ? Object.keys(settings)
          .filter((k) => k.startsWith("systemOverride"))
          .reduce((obj, k) => {
            obj[`x-sc-${snakeCase(k.substr(14))}`] = settings[k];
            return obj;
          }, {})
      : {};

    return {
      headers: {
        ...headers,
        ...systemOverrides,
        authorization: ssm.current.authorization,
      },
    };
  }

  return { headers };
});

const client = new ApolloClient({
  cache,
  connectToDevTools: appConfig.reactEnvironment === "development",
  link: ApolloLink.from(
    /**
     * slowNetworkSimulatorLink is undefined in production so we filter out
     */
    [
      apolloLogger,
      authLink,
      slowNetworkSimulatorLink as ApolloLink,
      httpLink,
    ].filter((mw) => !!mw),
  ),
});

export default client;
