import { getOnCastableDeletedRefetchQueries } from "src/domains/castableEntity/events/onCastableEntityDeleted";
import { useOnFoldersDeleted } from "src/domains/folder/events/useOnFoldersDeleted";
import { Typenames } from "../../../constants/constants";
import {
  DeleteFolderByIdsMutationVariables,
  Folder,
  useDeleteFolderByIdsMutation,
} from "../../../types.g";

export interface DeleteFolders {
  deleteFolders: (folders: Folder[]) => Promise<any>;
}

export function useDeleteFolders(): DeleteFolders {
  const [deleteFolderByIdsMutation] = useDeleteFolderByIdsMutation();
  const { onFoldersDeleted } = useOnFoldersDeleted();
  const deleteFolders = async (folders: Folder[]) => {
    if (folders.length === 0) {
      return null;
    }

    const folderIds = folders.map(({ id }) => id);
    const deleteFolderByIdMutationVariables: DeleteFolderByIdsMutationVariables =
      {
        input: {
          folderIds,
        },
      };

    return deleteFolderByIdsMutation({
      variables: deleteFolderByIdMutationVariables,
      optimisticResponse: {
        __typename: Typenames.Mutation,
        deleteFolderByIds: {
          __typename: Typenames.DeleteFolderByIdsPayload,
          folders,
        } as any,
      },
      update: (_, { data }) => {
        const folders = data?.deleteFolderByIds?.folders;
        if (folders) {
          onFoldersDeleted({ folders });
        }
      },
      refetchQueries: getOnCastableDeletedRefetchQueries(),
    });
  };

  return { deleteFolders };
}
