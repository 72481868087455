import {
  DAY_MS,
  HOUR_MS,
  MIN_MS,
  MONTH_MS,
  YEAR_MS,
} from "../constants/constants";

export class DateWithoutTimezone {
  constructor(
    public Year: number,
    public Month: number,
    public Date: number,
    public Hour: number = 0,
    public Minute: number = 0,
    public Second: number = 0,
  ) {}

  /**
   * A function to find a different millisecond from the another date
   *
   * @param anotherDate A target date to find the difference
   * @returns The difference from target in millisecond
   */
  public diffMs(anotherDate: DateWithoutTimezone) {
    const yearDiff = (this.Year - anotherDate.Year) * YEAR_MS;
    const monthDiff = (this.Month - anotherDate.Month) * MONTH_MS;
    const dayDiff = (this.Date - anotherDate.Date) * DAY_MS;
    const hourDiff = (this.Hour - anotherDate.Hour) * HOUR_MS;
    const minDiff = (this.Minute - anotherDate.Minute) * MIN_MS;
    const secondDiff = (this.Second - anotherDate.Second) * 1000;
    return yearDiff + monthDiff + dayDiff + hourDiff + minDiff + secondDiff;
  }

  /**
   * A function to compare date with another date
   *
   * @param anotherDate A target date to compare
   * @returns 0 if identical, 1 if base date is later and -1 if another date is later.
   */
  public compare(anotherDate: DateWithoutTimezone) {
    if (this.Year < anotherDate.Year) {
      return -1;
    }
    if (this.Year > anotherDate.Year) {
      return 1;
    }
    if (this.Month < anotherDate.Month) {
      return -1;
    }
    if (this.Month > anotherDate.Month) {
      return 1;
    }
    if (this.Date < anotherDate.Date) {
      return -1;
    }
    if (this.Date > anotherDate.Date) {
      return 1;
    }
    if (this.Hour < anotherDate.Hour) {
      return -1;
    }
    if (this.Hour > anotherDate.Hour) {
      return 1;
    }
    if (this.Minute < anotherDate.Minute) {
      return -1;
    }
    if (this.Minute > anotherDate.Minute) {
      return 1;
    }
    return 0;
  }
}
