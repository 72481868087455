import { File } from "../../../types.g";
import { useAddTagsToFileMutation } from "./useAddTagsToFile";
import { useGetAllMediaByFolderId } from "./useGetAllMediasByFolderId";

export function useBulkAddTagsMutation() {
  const { addTags } = useAddTagsToFileMutation();
  const { getAllFiles } = useGetAllMediaByFolderId();

  const addTagsToAllFilesByFolderId = async ({
    folderId,
    tags,
  }: {
    folderId: string;
    tags: string[];
  }) => {
    const allFiles = await getAllFiles(folderId);
    const allAddTagsResult = await Promise.all(
      allFiles.map((file) => addTags({ file, tagsToAdd: tags })),
    );
    return allAddTagsResult.map((item) => item?.data?.updateFileById?.file);
  };

  /**
   *
   * @param files can come from different folders
   */
  const addTagsToFiles = async ({
    files,
    tags,
  }: {
    files: File[];
    tags: string[];
  }) => {
    const allAddTagsResult = await Promise.all(
      files.map((file) => addTags({ file, tagsToAdd: tags })),
    );
    return allAddTagsResult.map((item) => item?.data?.updateFileById?.file);
  };

  return { addTagsToFiles, addTagsToAllFilesByFolderId };
}
