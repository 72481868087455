import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useAppContext } from "src/hooks/useAppContext";
import {
  ScreenDetailQuery,
  useScreenNoteByIdQuery,
  useUpdateScreenNoteByIdMutation,
} from "src/types.g";
import { useModifiedCacheScreenNoteMutation } from "../hooks/useModifiedCacheScreenNoteMutation";
import { useNoteAutoHeightByScrollHeight } from "../hooks/useNoteAutoHeightByScrollHeight";
import { debounce } from "lodash";
import classNames from "clsx";
import { NoteStyled } from "./styles";
import { Button } from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import spacetime from "spacetime";

interface NoteProps {
  screen: ScreenDetailQuery["screenById"];
  isModal?: boolean;
}
export const Note = memo(({ screen, isModal = false }: NoteProps) => {
  const [textAreaRef, setTextAreaRef] = useState<HTMLTextAreaElement | null>(
    null,
  );
  const context = useAppContext();
  const [updateScreenNoteByIdMutation] = useUpdateScreenNoteByIdMutation();
  const { deleteScreenNote, createScreenNote } =
    useModifiedCacheScreenNoteMutation();

  const currentScreenNoteId = screen?.screenNotesByScreenId?.nodes?.[0]?.id;
  const { data, refetch } = useScreenNoteByIdQuery({
    variables: { id: currentScreenNoteId },
  });
  const screenNote = useMemo(() => data?.screenNoteById, [data]);

  useEffect(() => {
    if (currentScreenNoteId || data) {
      return;
    }
    // If there is no note existed for this screen, create one
    createScreenNote(screen, context.currentSpace?.id!).then(({ data }) =>
      refetch({ id: data?.createScreenNote?.screenNote?.id }),
    );
  }, [data]);

  const [noteValue, setNoteValue] = useState<string | null>(null);
  useNoteAutoHeightByScrollHeight(textAreaRef, noteValue ?? "");

  const [isModalRemovingNote, setIsModalRemovingNote] =
    useState<boolean>(false);

  useEffect(() => {
    if (noteValue !== null || !screenNote) {
      return;
    }
    // Update note value only once when the data is fetched
    setNoteValue(screenNote.note!);
  }, [screenNote, noteValue]);

  const debounceUpdateOnNoteChanged = useCallback(
    debounce(async (updatedNote) => {
      await updateScreenNoteByIdMutation({
        variables: {
          input: {
            screenNoteId: screenNote?.id,
            newNote: updatedNote,
          },
        },
      });
    }, 700),
    [screenNote],
  );

  const onModalRemoveNote = useCallback(async () => {
    setIsModalRemovingNote(true);
    await deleteScreenNote(screen, screenNote?.id);
    context.modal.closeModals();
    setIsModalRemovingNote(false);
  }, [screenNote]);

  const updatedAt = screenNote?.updatedAt;
  return (
    <NoteStyled
      className={classNames("note", { loading: !screenNote, modal: isModal })}
    >
      <textarea
        className="note-textarea"
        ref={(ref) => setTextAreaRef(ref)}
        disabled={isModalRemovingNote || !screenNote}
        value={noteValue ?? ""}
        maxLength={1000}
        autoFocus
        placeholder="Type something here"
        onChange={(e) => {
          const value = e.target.value;
          setNoteValue(value);
          debounceUpdateOnNoteChanged(value);
        }}
      />

      <Button
        className="remove-button"
        disabled={isModalRemovingNote || !screenNote}
        mini
        danger
        onClick={onModalRemoveNote}
      >
        <FormattedMessage id="common.text.remove" defaultMessage="Remove" />
      </Button>
      <div className="updated-info">
        {screenNote && (
          <>
            By {screenNote?.userByUpdatedBy?.givenName},{" "}
            {spacetime(updatedAt).goto(null).unixFmt("MMM dd, yyyy, hh:mm a")}
          </>
        )}
      </div>
    </NoteStyled>
  );
});
