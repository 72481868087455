import lightFormat from "date-fns/lightFormat";

/**
 * A function to generate duplicate content name by concatenating original name with time eg. 'Original Space Copy 10:20:20'
 *
 * @param originalName Original content name
 * @returns Generated new name with time to avoid duplication
 */
const getDuplicateContentName = (originalName: string): string => {
  return `${originalName} Copy (${lightFormat(
    new Date(Date.now()),
    "HH:mm:ss",
  )})`;
};

/**
 * A function to generate duplicate space name by concatenating original name with time eg. 'Original Space Copy 10:20:20'
 *
 * @param originalSpaceName Original space name
 * @returns Generated space name with time to avoid duplication
 */
export const getDuplicateSpaceName = (originalSpaceName: string): string =>
  getDuplicateContentName(originalSpaceName);

/**
 * A function to generate duplicate channel name by concatenating original name with time eg. 'Original Channel Copy 10:20:20'
 *
 * @param originalChannelName Original channel name
 * @returns Generated channel name with time to avoid duplication
 */
export const getDuplicateChannelName = (originalChannelName: string): string =>
  getDuplicateContentName(originalChannelName);

/**
 * A function to generate duplicate playlist name by concatenating original name with time eg. 'Playlist Copy 10:20:20'
 *
 * @param originalPlaylistName Original playlist name
 * @returns Generated playlist name with time to avoid duplication
 */
export const getDuplicatePlaylistName = (
  originalPlaylistName: string,
): string => getDuplicateContentName(originalPlaylistName);
