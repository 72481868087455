import {
  Button,
  DocumentPreview,
  Icon,
  InlineInput,
  Popover,
  Tab,
  TabPane,
  ToggleStar,
} from "@screencloud/screencloud-ui-components";
import classNames from "clsx";
import { get } from "lodash";
import * as React from "react";

import { FormattedMessage } from "react-intl";
import { DropdownItemProps } from "semantic-ui-react";
import { AppContextType } from "src/AppContextProvider/type";
import { appConfig } from "../../appConfig";
import { AppContext } from "../../AppContextProvider/AppContext";
import { NameMaxLengthAllow, RefType } from "../../constants/constants";
import { FEATURE_FLAGS_ENUM } from "../../constants/featureFlag";
import { getSecureUrl } from "../../domains/media/mediaPreview";
import { CastedScreen, renderCastingStatus } from "../../helpers/castingHelper";
import {
  getDeleteMediaConfirmMsg,
  getFileAudioDuration,
  getFileDuration,
  getFileOutputImageSet,
  getFileOutputs4kVideoDetail,
  getFileOutputVideo,
  getMediaPlaceHolder,
  getPreviewImageURLFromFile,
  isAudio,
  isDocument,
  isImage,
  isVideo,
} from "../../helpers/mediaHelper";
import { hasSchedule } from "../../helpers/scheduleableHelper";
import { canBeUpdated } from "../../helpers/shareableHelper";
import { File, FileAssociationsFragment, FileOutput } from "../../types.g";
import AvailabilityStatusIcon from "../AvailabilityStatusIcon";
import { CastedScreenInfoActions } from "../CastedScreenInfo";
import MediaDetails from "./details";
import MediaPublication from "./publication";
import { Styled } from "./styles";
import { renderContentPreview } from "./utils/getPlayerPreview";
import { withOpenReplaceModal } from "src/pages/Media/MediasList/apollo";
import { UseReplaceContent } from "src/hooks/useReplaceContent";

export enum MediaPreviewActions {
  DELETE = "delete",
  CAST = "cast",
  DOWNLOAD = "download",
  RENAME = "rename",
  FAVORITE = "favorite",
  ADD_EXPIRY_DATE = "add_expiry_date",
  REMOVE_EXPIRY_DATE = "remove_expiry_date",
  ADD_AVAILABLE_DATE = "add_available_at",
  REMOVE_AVAILABLE_DATE = "remove_available_date",
  RESET_PUBLICATION_DATES = "reset_publication_dates",
  UPDATE_TAGS = "update_tags",
  SHOW_SCREEN_PICKER = "show_screen_picker",
  SHOW_DELETE_CONFIRM = "show_delete_confirm",
  HIDE_DELETE_CONFIRM = "hide_delete_confirm",
}

export interface MediaPreviewPayload {
  title?: string;
  checked?: boolean;
  imageUrl?: string;
  availableDate?: string;
  expiryDate?: string;
  tags?: any;
  tagsList?: DropdownItemProps[];
  screenIds?: string[];
}

export interface MediaPreviewProps extends UseReplaceContent {
  callBack?: (
    mediaId: string,
    action: MediaPreviewActions,
    data: MediaPreviewPayload,
  ) => any;
  castedScreensCallback?: (
    data: string,
    action: CastedScreenInfoActions,
  ) => void;
  media: File;
  title: string;
  tagsList: DropdownItemProps[];
  logs: any;
  isCastButtonDisabled?: boolean;
  dimensionWidth?: number;
  dimensionHeight?: number;
  showScreenPicker?: boolean;
  screenPickerComponent?: React.ReactNode;
  showDeleteConfirm?: boolean;
  imageSet?: string[];
  castedScreensData?: CastedScreen[];
  defaultTabIndex?: number;
}

export interface MediaPreviewState {
  action: MediaPreviewActions | null;
  _defaultTabIndex?: number;
  isRenameState: boolean;
  associations: FileAssociationsFragment[];
}

class MediaPreviewUI extends React.Component<
  MediaPreviewProps,
  MediaPreviewState
> {
  public static contextType = AppContext;
  public context: AppContextType;

  private imagePreviewRef: React.RefObject<HTMLDivElement>;
  private documentPreviewRef: React.RefObject<HTMLImageElement>[] = [];
  private documentPreviewFiles: string[] = [];
  private videoFile: Partial<FileOutput>;
  private video4kDetail?: Partial<FileOutput>;
  private audioFile: string;
  private placeholder: string;

  private isDocumentMimeType: boolean = false;
  private isVideoMimeType: boolean = false;
  private isAudioMimeType: boolean = false;
  private isImageMimeType: boolean = false;

  constructor(props: MediaPreviewProps) {
    super(props);
    const { media } = props;
    this.isDocumentMimeType = isDocument(media);
    this.isVideoMimeType = isVideo(media);
    this.isAudioMimeType = isAudio(media);
    this.isImageMimeType = isImage(media);

    this.placeholder = getMediaPlaceHolder(media);

    if (this.isDocumentMimeType) {
      const documentFiles = getFileOutputImageSet(media);
      this.documentPreviewFiles = documentFiles
        ? documentFiles.content.urls
        : [];
    } else if (this.isVideoMimeType) {
      const videoOutput = getFileOutputVideo(media);
      this.video4kDetail = getFileOutputs4kVideoDetail(media);
      if (videoOutput) {
        this.videoFile = videoOutput;
      }
    } else if (this.isAudioMimeType) {
      this.audioFile = props.media.source || "";
    }

    this.state = {
      _defaultTabIndex: this.props.defaultTabIndex,
      action: null,
      isRenameState: false,
      associations: [] as FileAssociationsFragment[],
    };
  }
  get isMediaOwner() {
    return this.context.user.settings.spaceId === this.props.media.spaceId;
  }
  public async componentDidMount() {
    const { media } = this.props;
    if (this.isImageMimeType || this.isDocumentMimeType) {
      const imageURL: string | string[] = getPreviewImageURLFromFile(
        media,
        1248,
        1248,
        this.context.secureMediaPolicy,
      );

      if (
        imageURL != null &&
        typeof imageURL === "string" &&
        this.imagePreviewRef &&
        this.imagePreviewRef.current
      ) {
        this.setBackgroundToDiv(imageURL, this.imagePreviewRef.current);
      } else if (imageURL != null && typeof imageURL === "object") {
        imageURL.forEach((value, index) => {
          if (this.documentPreviewRef[index].current) {
            this.setImageSource(
              value,
              this.documentPreviewRef[index].current as HTMLImageElement,
            );
          }
        });
      }
    }
  }

  public handleViewSchedule = ({ event, data }) => {
    this.setState({ _defaultTabIndex: data.activeIndex });
  };

  public renderTabComponent = () => {
    const { media } = this.props;

    const { _defaultTabIndex } = this.state;

    const detailPane = () => {
      let duration = "";
      if (this.isVideoMimeType) {
        duration = this.formatTimeDuration(getFileDuration(media, 0) / 1000);
      }
      if (this.isAudioMimeType) {
        duration = this.formatTimeDuration(
          getFileAudioDuration(media, 0) / 1000,
        );
      }
      const width = this.video4kDetail?.metadata
        ? this.video4kDetail?.metadata.videoDetails.widthInPx
        : media.metadata && media.metadata.width;
      const height = this.video4kDetail
        ? this.video4kDetail?.metadata.videoDetails.heightInPx
        : media.metadata && media.metadata.heightInPx;

      const createdAt = media.createdAt;
      const updatedBy = media.userByCreatedBy
        ? media.userByCreatedBy.givenName +
          " " +
          media.userByCreatedBy.familyName!
        : "";
      const spaceId = media.spaceId;
      return (
        <TabPane attached={false}>
          <MediaDetails
            id={media.id}
            tags={(media.tags as string[]) || []}
            tagsList={this.props.tagsList}
            callBack={this.props.callBack}
            duration={duration}
            size={media ? media.size : 0}
            totalPages={this.documentPreviewFiles.length}
            dimensionWidth={width}
            dimensionHeight={height}
            mimeType={media!.mimetype!}
            createdAt={createdAt}
            updatedBy={updatedBy}
            spaceId={spaceId}
            isMediaOwner={this.isMediaOwner}
          />
        </TabPane>
      );
    };

    const schedulePane = () => {
      const expiryDate = media!.expireAt;
      const availableDate = media!.availableAt;
      return (
        <TabPane attached={false}>
          {this.isMediaOwner && (
            <MediaPublication
              locale={get(
                this.context,
                ["currentUser", "preferences", "settings", "locale"],
                null,
              )}
              id={media.id}
              expiryDate={expiryDate}
              availableDate={availableDate}
              callBack={this.props.callBack}
            />
          )}
        </TabPane>
      );
    };

    if (
      this.isMediaOwner &&
      this.context.currentPermissions.validateCurrentSpace("media", "read")
    ) {
      return (
        <Tab
          className="media-tabs"
          menu={{ secondary: true, pointing: true }}
          panes={[
            { menuItem: "Details", render: detailPane },
            { menuItem: "Availability", render: schedulePane },
          ]}
          activeIndex={_defaultTabIndex || 0}
          totaltab={2}
          onTabChange={(event, data) =>
            this.handleViewSchedule({ event, data })
          }
        />
      );
    } else {
      return (
        <Tab
          className="media-tabs"
          menu={{ secondary: true, pointing: true }}
          panes={[{ menuItem: "Details", render: detailPane }]}
          activeIndex={_defaultTabIndex || 0}
          totaltab={1}
        />
      );
    }
  };

  public renderCalendar = () => {
    const { media } = this.props;

    return hasSchedule(media) ? (
      <div className="scheduled">
        <AvailabilityStatusIcon
          data-testid="calendar-icon"
          callback={() =>
            this.handleViewSchedule({ event: null, data: { activeIndex: 1 } })
          }
          availableAt={media.availableAt}
          expireAt={media.expireAt}
        />
      </div>
    ) : null;
  };

  public renderFavorite = () => {
    const { media } = this.props;
    const favorite: boolean = !!media.isFavorite;
    if (
      this.isMediaOwner &&
      this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.STARRING)
    ) {
      return <ToggleStar onClick={this.handleFavorite} active={favorite} />;
    } else {
      return null;
    }
  };

  public renderCastInfo = () => {
    const { castedScreensCallback, castedScreensData } = this.props;
    return (
      <div className="casting">
        {renderCastingStatus({
          castScreenData: castedScreensData!,
          callBack: castedScreensCallback!,
          context: this.context,
        })}
      </div>
    );
  };

  public renderTitle = () => {
    const { title, media } = this.props;
    if (this.state.isRenameState) {
      return (
        <h1 className="inline-input">
          <InlineInput
            maxLength={NameMaxLengthAllow}
            editmode
            value={title}
            onSaved={this.handleRename}
            spellCheck={false}
          />
        </h1>
      );
    } else {
      const isEditable = canBeUpdated({
        context: this.context,
        shareable: media,
      });
      const startEditing = () => this.setState({ isRenameState: true });
      return (
        <h1>
          <div
            onClick={isEditable ? startEditing : () => {}}
            className={classNames("title", { clickable: isEditable })}
          >
            {title}
          </div>
          {isEditable && (
            <Icon
              name="edit"
              data-testid="edit-title"
              onClick={startEditing}
              title="Rename"
            />
          )}
        </h1>
      );
    }
  };

  public renderDownloadButton = (secureMediaPolicy: string | undefined) => {
    return (
      <Popover
        trigger={
          <a
            className="button link icon"
            target={isImage(this.props.media) ? "_self" : "_blank"}
            href={getSecureUrl(this.props.media.source, secureMediaPolicy) ?? "#"}
            rel={"noreferrer"}
          >
            <Icon name="download" />
          </a>
        }
        content="Download"
        position="top center"
        inverted
      />
    );
  };

  public renderDeleteButton = () => {
    return (
      <Popover
        trigger={
          <Button icon danger borderless onClick={this.handleDelete}>
            <Icon name="trash" />
          </Button>
        }
        content="Move to Trash"
        position="top center"
        inverted
      />
    );
  };

  public renderReplaceButton = () => {
    const handleReplace = () => {
      const { media } = this.props;
      this.props.openReplaceContentModal({
        originalContent: media,
        associations: media.associationsByFileAndSpaceId.nodes || [],
      });
    };

    return (
      <Popover
        trigger={
          <Button icon borderless onClick={handleReplace}>
            <Icon name="swap" />
          </Button>
        }
        content="Find & Replace"
        position="top center"
        inverted
      />
    );
  };

  public renderCastButton = () => {
    const { isCastButtonDisabled } = this.props;
    if (isCastButtonDisabled) {
      return null;
    } else if (
      this.context.currentPermissions.validateCurrentSpace("screen", "cast") &&
      this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING)
    ) {
      return (
        <Popover
          trigger={
            <Button
              className="media-cast"
              onClick={this.handleCasting}
              title="Set to Screen"
              icon
              borderless
            >
              <Icon name="screen-play" />
            </Button>
          }
          content="Set to Screen"
          position="top center"
          inverted
        />
      );
    }
    return;
  };

  public getPreviewClassName = () => {
    const { showScreenPicker, showDeleteConfirm } = this.props;
    const classes: string[] = [];
    if (showScreenPicker) {
      classes.push("casting");
    }
    if (showDeleteConfirm) {
      classes.push("deleting");
    }
    return classes.join(" ");
  };

  public formatTimeDuration(seconds: number) {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  }

  public getVideoPlayer() {
    if (this.videoFile) {
      const video = getFileOutputVideo(this.props.media);
      let src = `${appConfig.mediaOrigin}/${video?.content.keys[0]}`;

      src = getSecureUrl(src, this.context?.secureMediaPolicy);

      return (
        <video className="video-preview" autoPlay controls muted>
          <source src={src ?? this.videoFile.url ?? ""} type="video/mp4" />
          <p>
            Your browser does not support the<code>video</code> element.
          </p>
        </video>
      );
    } else {
      return null;
    }
  }

  public getAudioPlayer() {
    // if secure policy exists access by secure domain
    this.audioFile = getSecureUrl(
      this.audioFile,
      this.context.secureMediaPolicy,
    );

    if (this.audioFile) {
      return (
        <audio autoPlay loop controls>
          <source src={this.audioFile} type="audio/mpeg" />
          <p>
            Your browser does not support the<code>audio</code> element.
          </p>
        </audio>
      );
    } else {
      return null;
    }
  }

  public getDocumentPlayer = () => {
    this.placeholder = getSecureUrl(
      this.placeholder,
      this.context.secureMediaPolicy,
    );

    const imageSetPreview = this.documentPreviewFiles.
    map(
      (previewContent, index) => {
        this.documentPreviewRef[index] = React.createRef();
        return (
          <div className="document" key={index}>
            <img ref={this.documentPreviewRef[index]} src={previewContent} />
          </div>
        );
      },
    );
    return imageSetPreview.length > 0 ? (
      <DocumentPreview images={this.documentPreviewFiles}>
        {imageSetPreview}
      </DocumentPreview>
    ) : (
      <div
        className="media-content"
        style={{ backgroundImage: "url(" + this.placeholder + ")" }}
      />
    );
  };

  public getImagePlayer = () => {
    this.imagePreviewRef = React.createRef();
    return <div className="media-content" ref={this.imagePreviewRef} />;
  };

  public setBackgroundToDiv = (src: string, divElement: HTMLDivElement) => {
    src = getSecureUrl(src, this.context.secureMediaPolicy);
    const img = new Image();
    img.onerror = () => {
      divElement.setAttribute(
        "style",
        `width: 200px; background-image: url('${this.placeholder}')`,
      );
    };
    img.src = src;
    divElement.setAttribute("style", `background-image: url('${src}')`);
  };

  public setImageSource = (src: string, imageElement: HTMLImageElement) => {
    const documentImg = new Image();
    documentImg.src = src;
    documentImg.onload = () => {
      imageElement.setAttribute("src", `${src}`);
    };
  };

  public handleDelete = async (data = {}) => {
    const { media } = this.props;
    const message = await getDeleteMediaConfirmMsg(media);
    const { confirm } = await this.context.modal.confirm(message, {
      confirm: (
        <FormattedMessage
          id="ui_component.label.move_to_trash"
          defaultMessage="Move to Trash"
        />
      ),
      isDanger: true,
    });
    if (confirm && this.props.callBack) {
      this.props.callBack(
        this.props.media.id,
        MediaPreviewActions.DELETE,
        data,
      );
    }
  };

  public handleCasting = () => {
    if (this.props.callBack) {
      this.props.callBack(
        this.props.media.id,
        MediaPreviewActions.SHOW_SCREEN_PICKER,
        {},
      );
    }
  };

  public handleRename = async (e, value) => {
    if (this.props.callBack) {
      const result = await this.props.callBack(
        this.props.media.id,
        MediaPreviewActions.RENAME,
        { title: value },
      );
      if (result) {
        this.setState({ isRenameState: false });
      }
    }
  };

  public handleFavorite = () => {
    if (this.props.callBack) {
      this.props.callBack(this.props.media.id, MediaPreviewActions.FAVORITE, {
        checked: !this.props.media!.isFavorite!,
      });
    }
  };

  public render() {
    const { media } = this.props;
    return (
      <Styled className={this.getPreviewClassName()}>
        <div className="preview-title">{this.renderTitle()}</div>
        <div className="media-preview">
          <div className="media-status">
            {this.renderFavorite()}
            {this.renderCalendar()}
            {this.renderCastInfo()}
          </div>
          <div className="content-preview">
            {this.isImageMimeType
              ? this.getImagePlayer()
              : renderContentPreview(
                  { ...media, type: RefType.FILE },
                  this.context.secureMediaPolicy as string,
                )}
          </div>
        </div>
        <div className="media-info">
          {this.renderTabComponent()}
          <div className="media-options">
            {this.isMediaOwner &&
              this.context.currentPermissions.validateCurrentSpace(
                "media",
                "delete",
              ) &&
              this.renderDeleteButton()}
            {this.isMediaOwner && this.renderDownloadButton(this.context.secureMediaPolicy)}
            {media.associationsByFileAndSpaceId.nodes.length > 0 &&
              this.renderReplaceButton()}
            {this.renderCastButton()}
          </div>
        </div>
        <div className="cast-on">
          <div className="head">
            <span>Select screens</span>
          </div>
          {this.props.screenPickerComponent}
        </div>
      </Styled>
    );
  }
}

export default withOpenReplaceModal(MediaPreviewUI);
