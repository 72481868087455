import { DropdownItem, Icon } from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import { useAppContext } from "../../../../hooks/useAppContext";

interface DuplicateDropdownItemsProps {
  onDuplicatePlayList: () => void;
  duplicateToAnotherSpace: () => Promise<void>;
}

export const DuplicateDropdownItems = (props: DuplicateDropdownItemsProps) => {
  const { onDuplicatePlayList, duplicateToAnotherSpace } = props;
  const context = useAppContext();
  const canUpdatePlaylist = context.currentPermissions.validateCurrentSpace(
    "playlist",
    "update",
  );
  const canCreatePlaylist = context.currentPermissions.validateCurrentSpace(
    "playlist",
    "create",
  );
  return (
    <>
      {canCreatePlaylist && (
        <DropdownItem
          data-testid="duplicate-item-button"
          data-cy="duplicate-item-button"
          onClick={onDuplicatePlayList}
        >
          <Icon name="duplicate" />
          <FormattedMessage
            id="duplication.duplicate_button"
            defaultMessage="Duplicate"
          />
        </DropdownItem>
      )}
      {canUpdatePlaylist && (
        <DropdownItem
          data-testid="duplicate-to-another-space-item-button"
          data-cy="duplicate-to-another-space-item-button"
          onClick={duplicateToAnotherSpace}
        >
          <Icon name="move-out" />
          <FormattedMessage
            id="duplication.duplicate_to_another_space_button"
            defaultMessage="Duplicate to Another Space"
          />
        </DropdownItem>
      )}
    </>
  );
};
