import {
  Icon,
  Checkbox,
  ButtonCheckbox,
} from "@screencloud/screencloud-ui-components";
import { UUID } from "@screencloud/uuid";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  NotificationRule,
  UserStatus,
  useSearchUserBySpaceIdQuery,
} from "src/types.g";
import PeopleList from "../peopleList/peopleList";
import useScreenNotification from "src/hooks/ScreenNotification/useScreenNotification";
import { useAppContext } from "src/hooks/useAppContext";
import { CheckboxProps } from "semantic-ui-react";
import NotificationThreshold from "./NotificationThreshold";
import { EXCLUDE_SYSTEM_AND_ARCHIVED_USERS_FOR_SEARCH_QUERY } from "src/constants/user";

interface Props {
  notificationRule: NotificationRule;
  subscribeNotification: (userids: UUID[]) => void;
  screenId: UUID;
}

export default function AdminSettings(props: Props) {
  const { data } = useSearchUserBySpaceIdQuery({
    variables: {
      query: EXCLUDE_SYSTEM_AND_ARCHIVED_USERS_FOR_SEARCH_QUERY,
    },
  });
  const context = useAppContext();

  const subscriptions =
    props?.notificationRule?.notificationSubscriptionsByNotificationRuleId.nodes.filter(
      (sub) => sub.userByUserIdAndOrgId?.id !== context.currentUser?.id,
    ) || [];

  const [showOtherUsers, setShowOtherUsers] = useState(
    subscriptions.length > 0,
  );

  const otherUsers =
    data?.searchUser?.nodes
      .filter((user) => user.id !== context.currentUser?.id)
      .filter((user) => user.status === UserStatus.Active) || [];

  const { deleteSubscription } = useScreenNotification(props?.screenId);

  const handleSubscribeAll = (checked: boolean, _indeterminate: boolean) => {
    if (checked) {
      // subscribe all users
      const userIds = otherUsers.map((user) => user.id);
      props.subscribeNotification(userIds);
    } else {
      // TODO replace with bulk delete operation
      subscriptions.map(async (sub) => {
        await deleteSubscription(sub.id);
      });
    }
  };

  const handleGeneralNotificationToggle = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
    data: CheckboxProps,
  ) => {
    if (subscriptions.length > 0 && showOtherUsers) {
      // TODO replace with bulk delete operation
      subscriptions.map(async (sub) => {
        await deleteSubscription(sub.id);
      });
    }
    setShowOtherUsers(!showOtherUsers);
  };

  return (
    <div className="admin-settings" data-testid="admin-settings">
      <div className="sub-heading">
        <div className="badge" data-testid="badge">
          <Icon name="users" />
        </div>
        <div className="text" data-testid="info">
          <div className="title" data-testid="title">
            <FormattedMessage
              id="ui_component.screen_notification.modal.admin_settings.info.title"
              defaultMessage="General Notification"
            />
          </div>
          <div className="sub-title" data-testid="sub-title">
            <FormattedMessage
              id="ui_component.screen_notification.modal.admin_settings.info.sub_title"
              defaultMessage="Turn on email notifications for everyone who has access to this screen"
            />
          </div>
        </div>
        <div className="toggle">
          <Checkbox
            data-testid="toggle-general"
            disabled={otherUsers.length === 0}
            toggle
            name={""}
            onChange={handleGeneralNotificationToggle}
            checked={showOtherUsers}
          />
        </div>
      </div>

      <NotificationThreshold
        notificationRule={props.notificationRule}
        screenId={props.screenId}
      />

      {showOtherUsers && (
        <>
          <div className="select-all">
            <div>
              {otherUsers.length}{" "}
              <FormattedMessage
                id="ui_component.screen_notification.modal.admin_settings.info.memberse"
                defaultMessage="members"
              />
            </div>
            <div>
              <ButtonCheckbox
                data-testid="toggle"
                onChange={handleSubscribeAll}
                checked={otherUsers?.length === subscriptions?.length}
                popover={["Deselect All", "Select All"]}
                indeterminate={
                  subscriptions.length > 0 &&
                  subscriptions.length < otherUsers.length
                }
              />
            </div>
          </div>
          <PeopleList
            notificationRule={props.notificationRule}
            onSubscribe={props.subscribeNotification}
            screenId={props.screenId}
          />
        </>
      )}
    </div>
  );
}
