import { useAppContext } from "src/hooks/useAppContext";
import { FeedbackContainer } from "./styles";

export type FeedbackTypes =
  | "INVALID_USER"
  | "MULTIPLE_CREDENTIALS"
  | "NO_CREDENTIALS"
  | "ERROR";

const FeedbackMessageIds: Record<FeedbackTypes, string> = {
  INVALID_USER: "site.credential.update.invalid.user.feedback",
  MULTIPLE_CREDENTIALS: "site.credential.update.multiple.credentials.feedback",
  NO_CREDENTIALS: "site.credential.update.no.credentials.feedback",
  ERROR: "site.credential.update.error.feedback",
};

const DefaultFeedbackMessages: Record<FeedbackTypes, string> = {
  INVALID_USER:
    "Oops! It looks like you're not the owner of this dashboard. Only the original creator can make changes. If you need guidance, contact our support team for help.",
  MULTIPLE_CREDENTIALS:
    "Hold on! We found multiple credentials for this dashboard, and we can only update one at a time. Take a moment to review the credentials linked to your dashboard, then try again to keep your screens in sync. If you need guidance, contact our support team for help.",
  NO_CREDENTIALS:
    "Configuration needed. We couldn't find any credentials to update for this dashboard. If you need guidance, contact our support team for help.",
  ERROR:
    "Oops! Looks like there was an error updating your dashboard. Please try again. If the issue persists, contact our support team for further assistance.",
};

/**
 * This component is used to display the feedback messages for actions carried out
 * from the site context menu such as updating credentials and rerecording site journeys.
 *
 * @param props
 * @returns
 */
const Feedback = (props: { type: FeedbackTypes }) => {
  const context = useAppContext();
  const { formatMessage } = context.intl;
  const { type } = props;

  return (
    <FeedbackContainer>
      <div className="feedback-container">
        <div className="feedback-content">
          <span className="feedback-content-info">
            {formatMessage({
              defaultMessage: DefaultFeedbackMessages[type],
              id: FeedbackMessageIds[type],
            })}{" "}
          </span>
        </div>
      </div>
    </FeedbackContainer>
  );
};

export default Feedback;
