import { Button, Input } from "@screencloud/screencloud-ui-components";
import { useEffect, useState } from "react";
import { useAppContext } from "src/hooks/useAppContext";
import { UpdateCredentialContainer } from "./styles";

interface UpdateCredentialControlsProps {
  onCredentialUpdated: (
    usernamePasswordCredentialId: string,
    username: string,
    password: string,
    secretKeyCredentialId?: string,
    secretKey?: string,
  ) => void;
  onCancel: () => void;
  usernamePasswordCredentialId: string;
  secretKeyCredentialId?: string;
}

/**
 * This component is used to update the username and password credentials for a site and the secret key credential
 * It is used in the UpdateSiteCredentialsModal component
 *
 * @param props
 * @returns
 */
const UpdateCredentialControls = (props: UpdateCredentialControlsProps) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [updateEnabled, setUpdateEnabled] = useState(false);
  const context = useAppContext();
  const { formatMessage } = context.intl;

  useEffect(() => {
    setUpdateEnabled(username.length > 0 && password.length > 0);
  }, [username, password]);

  return (
    <UpdateCredentialContainer>
      <div className="editor-container">
        <div className="editor-field">
          <div className="field-info">
            <div className="field-name">
              {" "}
              {formatMessage({
                defaultMessage: "Update Username",
                id: "site.credential.update.username.label",
              })}
            </div>
          </div>

          <div className="editor-field">
            <Input
              value={username}
              type="text"
              onChange={(event) => setUsername(event.target.value)}
              fluid
            />
          </div>
        </div>
        <div className="editor-field">
          <div className="field-name">
            {" "}
            {formatMessage({
              defaultMessage: "Update Password",
              id: "site.credential.update.password.label",
            })}
          </div>
          <Input
            value={password}
            type="password"
            onChange={(event) => setPassword(event.target.value)}
            fluid
          />
        </div>
        {props.secretKeyCredentialId && (
          <div className="editor-field">
            <div className="field-info">
              <div className="field-name">
                {" "}
                {formatMessage({
                  defaultMessage: "Update Secret Key",
                  id: "site.credential.update.secret.key.label",
                })}
              </div>
            </div>
            <div className="field-description">
              <span>
                {formatMessage({
                  defaultMessage:
                    "This is optional, only update if your account has changed or the secret key has changed due to security concerns.",
                  id: "site.credential.update.secret_key.description",
                })}
              </span>
            </div>
            <Input
              value={secretKey}
              type="text"
              onChange={(event) => setSecretKey(event.target.value)}
              fluid
            />
          </div>
        )}

        <div className="editor-controls">
          <Button
            primary
            disabled={!updateEnabled}
            onClick={() =>
              props.onCredentialUpdated(
                props.usernamePasswordCredentialId,
                username,
                password,
                props.secretKeyCredentialId,
                secretKey.length > 0 ? secretKey : undefined,
              )
            }
          >
            Update
          </Button>
          <Button onClick={props.onCancel}>Cancel</Button>
        </div>
      </div>
    </UpdateCredentialContainer>
  );
};

export default UpdateCredentialControls;
