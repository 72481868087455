import {
  AuthManager,
  IAuthClaims,
  StorageKeyName,
  IAuthSession,
} from "@screencloud/auth-sdk";
import { isUuid, UUID } from "@screencloud/uuid";
import localStore from "store";
import { appConfig } from "../../appConfig";
import { AppContextState } from "../../AppContextProvider";
import { StudioTokenProvider } from "./fetchStudioTokens";
import { parsePathname } from "./sessionUtils";
import { ssm } from "./ssm";
import {
  AnyClaims,
  StudioSession,
  StudioSessionSettings,
  SystemUserClaims,
} from "./StudioSession";
import {
  determineReactionForRoleOnPath,
  getPageQueryString,
  getRedirectPage,
  getRedirectUrl,
  hasUserChanged,
  setLastAccessedOrg,
} from "./studioSessionManagerUtils";
import { Subscribable } from "./Subscribable";
import { StoreJsAPILike } from "./types";

export interface SessionManagerConfig {
  store: StoreJsAPILike;
  fetchStudioTokensFn: StudioTokenProvider;
}

enum Keys {
  LastRefresh = "lastRefresh",
  SystemAccessIds = "systemAccessIds",
}

export class StudioSessionManager extends Subscribable<{
  index: string;
  settings: StudioSessionSettings;
  claims: AnyClaims;
}> {
  get debug(): boolean {
    return this._debug;
  }

  set debug(active: boolean) {
    document.cookie = `DEBUG_SSM=${active ? 1 : 0};path=/`;
    this._debug = active;
  }

  get autoRefresh(): boolean {
    return !!this._autoRefresh;
  }

  set autoRefresh(v: boolean) {
    if (v && !this._autoRefresh) {
      this._autoRefresh = window.setInterval(async () => {
        if (this.shouldRefresh) {
          await this.refresh();
        }
      }, 35 * 1000);
    } else if (!v && this._autoRefresh) {
      window.clearInterval(this._autoRefresh);
      this._autoRefresh = 0;
    }
  }

  get lastRefresh(): number {
    return this._lastRefresh;
  }

  get shouldRefresh(): boolean {
    // more than 10m ago
    return (
      this.lastRefresh + 10 * 60 * 1000 < Date.now() ||
      // or any known session is expired
      !!this.all.find((s) => s && s.expired)
    );
  }

  get users(): StudioSession[] {
    return this.all.filter((s) => !s.isGuestOrAnon);
  }

  get current(): StudioSession {
    // if the url is integer-sub-pathed, take that, otherwise default to '0'
    const { key } = parsePathname();

    if (!this.sessions[key]) {
      this.debug &&
        console.log(
          `StudioSessionManager.current created session ${key} on the fly`,
        );
      return this.load(key);
    }

    // get an existing session or create a new one
    return this.sessions[key];
  }

  get all(): StudioSession[] {
    return Object.values(this.sessions);
  }

  get userSessionCount(): number {
    return this.users.length;
  }

  protected store: StoreJsAPILike;
  protected auth: AuthManager;
  protected sessions: { [index: string]: StudioSession } = {};
  protected fetchStudioTokensFn: StudioTokenProvider;
  protected _autoRefresh: any;
  protected _debug: boolean = document.cookie.includes("DEBUG_SSM=1");
  protected _authData?: Readonly<{
    claims: IAuthClaims | null;
    token: string | null;
  }>;
  protected _lastRefresh: number = 0;
  protected _initDone = false;
  protected _appContext: AppContextState;

  constructor({ store, fetchStudioTokensFn }: SessionManagerConfig) {
    super({
      valueFn: () => {
        const c = this.current;
        return {
          claims: c.claims,
          index: c.key,
          settings: c.settings,
        };
      },
    });
    this.store = store;
    this.fetchStudioTokensFn = fetchStudioTokensFn;

    // # Initial values (and multi-tab sync)
    // get lastRefresh time and sync between tabs
    this._lastRefresh = this.store.get(Keys.LastRefresh) || 0;
    this.store.watch(Keys.LastRefresh, (val) => {
      if (this._lastRefresh === val) {
        return;
      }
      this._lastRefresh = val;
    });

    this.auth = new AuthManager({
      debug: appConfig.auth.debug,
      autoRefresh: appConfig.auth.autoRefreshSession,
      autoSync: appConfig.auth.autoSyncSession,
      service: { url: appConfig.auth.service },
      frontend: { url: appConfig.auth.frontend },
    });
  }

  public getBySystemAccessId(systemAccessId: UUID): StudioSession | null {
    return (
      (systemAccessId &&
        this.all.find(
          (s) =>
            (s.claims as SystemUserClaims).systemAccessId === systemAccessId,
        )) ||
      null
    );
  }

  public addSystemAccessId(systemAccessId: UUID) {
    if (!isUuid(systemAccessId)) {
      return;
    }

    const { set, get } = this.store;
    const ids: UUID[] = get(Keys.SystemAccessIds, []);
    set(
      Keys.SystemAccessIds,
      [systemAccessId, ...ids.filter((id) => id !== systemAccessId)].slice(
        0,
        5,
      ),
    );
  }

  public clearSystemAccessIds() {
    // find matching sessions in .all and call destroy() and them.
    this.all
      .filter((s) => (s.claims as SystemUserClaims).systemAccessId)
      .forEach((s) => s.destroy());

    // remove all data from store
    this.store.remove(Keys.SystemAccessIds);
  }

  public removeSystemAccessId(systemAccessId: UUID) {
    if (!isUuid(systemAccessId)) {
      return;
    }

    const { get, set } = this.store;
    set(
      Keys.SystemAccessIds,
      get(Keys.SystemAccessIds).filter((s) => s !== systemAccessId),
    );
  }

  public endSystemAccessSession() {
    if (!this.current.isSystemUser) {
      return;
    }
    const { systemAccessId } = this.current.claims as SystemUserClaims;
    this.removeSystemAccessId(systemAccessId);
  }

  public async refresh() {
    this.debug && console.log(`StudioSessionManager.refresh()`);
    // timestamp first to keep other tabs from refreshing
    this._lastRefresh = Date.now();
    this.store.set(Keys.LastRefresh, this._lastRefresh);
    const systemAccessIds = this.store.get(Keys.SystemAccessIds, null) as
      | UUID[]
      | null;
    const { token: authAccessToken } = await this.getAuthData();

    let response;
    if (authAccessToken) {
      try {
        response = await this.fetchStudioTokensFn({
          authAccessToken: authAccessToken || undefined,
          // invite: invite || undefined,
          systemAccessIds: systemAccessIds || undefined,
        });
      } catch (error) {
        throw error;
      }
      // null or empty means we either have no authAccessToken, or we really have no data available
      if (
        !response ||
        !response.accessTokens ||
        !response.accessTokens.length
      ) {
        // in either case, we'll be cleaning up our sessions.
        this.flush();
        return;
      }

      response.accessTokens.forEach((x, index) => {
        const s = this.load(`${index}`, x.token);
        const { isGuestOrAnon } = s;

        s.updateSettings({
          spaceId: isGuestOrAnon ? undefined : s.settings.spaceId || undefined,
          spaceName: isGuestOrAnon
            ? undefined
            : s.settings.spaceName || undefined,
        });
      });
    } else {
      this.flush();
    }
  }

  public load(
    key: string = "0",
    initialToken?: string,
    initialSettings?: StudioSessionSettings,
  ): StudioSession {
    const rx = /^0|([1-9][0-9]*)$/;
    if (!rx.test(key)) {
      throw new Error(`INVALID_SESSION_IDENTIFIER: "${key}"`);
    }

    if (!this.sessions[key]) {
      this.sessions[key] = new StudioSession({
        initialSettings,
        initialToken,
        key,
        store: this.store,
      });
      this.sessions[key].subscribe(() => {
        // if any child trigger, we may want to trigger our own value
        this.trigger();
      });
    } else {
      try {
        this.sessions[key].setToken(initialToken);
      } catch (e) {
        this.debug &&
          console.log("StudioSessionManager: failed on set token", e);
        this.unsetAuthData();
        throw e;
      }
    }

    return this.sessions[key];
  }

  // public discoverAll(): string[] {
  //   // find all rootKeys that are just an integer
  //   const identifiers: string[] = [];
  //   const rx = /^_(0|([1-9][0-9]*))_token$/;
  //   this.store.each((val, key) => {
  //     const [, index = null] = key.match(rx) || [];
  //     if (index) {
  //       identifiers.push(index);
  //     }
  //   });
  //   return identifiers;
  // }
  //
  // public loadAll(): StudioSession[] {
  //   this.discoverAll().forEach((i) => {
  //     this.load(i);
  //   });
  //   return this.all;
  // }

  public flush() {
    this.debug && console.log(`StudioSessionManager.flush()`);

    // unsubscribe from and destroy all known sessions
    Object.values(this.sessions).forEach((s) => {
      s.unwatch();
      s.destroy();
      delete this.sessions[s.key];
    });

    // remove any other StudioSession fragments from storage
    this.store.each((v, k) => {
      if (/^[0-9]+_/.test(k)) {
        this.store.remove(k);
      }
    });
  }

  public destroy(key: string) {
    const s = this.sessions[key];
    if (s) {
      s.unwatch();
      s.destroy();
      // flush if not base session
      if (key !== "0") {
        delete this.sessions[key];
      }
    }
  }

  public destroyAll(): void {
    this.all.forEach((s) => {
      s.destroy();
      // flush all except 0 session, as it should be Anon now
      if (s.key !== "0") {
        delete this.sessions[s.key];
      }
    });
  }

  public logout() {
    this.debug && console.log(`SignageSessionManager.logout()`);
    this.auth.logout().then(({ redirectUrl }) => {
      this.debug &&
        console.log(
          `SignageSessionManager.logout() redirect to ${redirectUrl}`,
        );
      this.clearSystemAccessIds();
      this.destroyAll();
      localStore.set("firstLogin", false);
      localStore.set("isFromLogin", false);
      window.location.href = `${appConfig.auth.frontend}/logout`;
    });
  }

  public async resetPassword() {
    const resp = await this.auth.requestPasswordReset({
      email: ssm.current.claims.email as string,
    });
    return resp;
  }

  public async verifyRegistrationCode(code: string) {
    const resp = await this.auth.verifyRegistrationCode({
      email: ssm.current.claims.email as string,
      registrationCode: code,
    });
    return resp;
  }

  /**
   * Fetch and store the authentication token.
   *
   * @remarks
   * This method retrieves the session from the ID service. It's a quick and straightforward way to update the
   * authentication session. We avoid using the SDK's get() method as it only refreshes when the SDK decides to.
   * Instead, we use the refresh method to force a refresh, which will also automatically store the session in
   * localStorage.
   *
   * @see {@link https://github.com/screencloud/studio-auth-service Studio Auth Service}
   * @see {@link https://github.com/screencloud/auth-next-sdk Auth SDK}
   */
  public async refreshAuthToken() {
    try {
      await this.auth.refresh();
    } catch (error) {
      // We can ignore this here.
    }
  }

  /**
   * Simple method to return the Auth Token directly from localStorage.
   *
   * @remarks
   * While the Auth SDK can fetch the token, this process is asynchronous and may involve requests to the Auth API. In
   * certain cases, we prefer to simply retrieve the token.
   *
   * @returns {string} The token. If the token is missing or an error occurs, an empty string is returned.
   *
   * @throws {Error} If the auth token is missing in localStorage.
   */
  public getAuthToken(): string {
    try {
      const stored = localStorage.getItem(StorageKeyName);
      if (!stored) {
        throw new Error("Auth token appears to be missing?");
      }
      const session: IAuthSession = JSON.parse(stored).session;
      return session.token;
    } catch (error) {
      console.error(error);
      return "";
    }
  }

  public getByOrgId(orgId: UUID): StudioSession | undefined {
    return (
      (orgId && this.all.find((s) => s.claims.orgId === orgId)) || undefined
    );
  }

  public changeOrg(indexOrOrgId: string | UUID): boolean {
    if (isUuid(indexOrOrgId)) {
      const session = this.getByOrgId(indexOrOrgId);
      if (!session) {
        return false;
      }
      setLastAccessedOrg(indexOrOrgId);
      indexOrOrgId = session.key;
    } else if (this.all[indexOrOrgId]?.claims?.orgId) {
      setLastAccessedOrg(this.all[indexOrOrgId]?.claims?.orgId);
    }

    const queryString = getPageQueryString();
    const page = parsePathname().page;
    const redirectPage = getRedirectPage(page, queryString);
    const redirectUrl = getRedirectUrl(
      this.userSessionCount,
      indexOrOrgId,
      redirectPage,
    );

    window.location.href = redirectUrl;
    return true;
  }

  public async getCurrentTokenByOrgId(orgId): Promise<string | undefined> {
    return new Promise(async (resolve, reject) => {
      if (isUuid(orgId)) {
        const systemAccessIds = this.store.get(Keys.SystemAccessIds, null) as
          | UUID[]
          | null;
        const { token: authAccessToken } = await this.getAuthData();
        let response;
        if (authAccessToken) {
          try {
            response = await this.fetchStudioTokensFn({
              authAccessToken: authAccessToken || undefined,
              systemAccessIds: systemAccessIds || undefined,
            });
          } catch (error) {
            throw error;
          }
          if (response) {
            response.accessTokens.forEach((x, index) => {
              if (x.tokenPayload?.org_id === orgId) {
                resolve(`Bearer ${x.token}`);
              }
            });
          } else {
            reject(undefined);
          }
        } else {
          reject(undefined);
        }
      } else {
        reject(undefined);
      }
    });
  }

  public gotoSection(orgId: UUID, path: string) {
    if (isUuid(orgId)) {
      const session = this.getByOrgId(orgId);
      if (!session) {
        return false;
      }
      window.location.href =
        this.userSessionCount > 1 ? `/org/${session.key}/${path}` : `/${path}`;
    }
    return true;
  }

  public changeSpace(
    spaceId: UUID,
    spaceName: string,
    defaultRedirectPath?: string,
  ): boolean {
    const session = this.current;
    if (!session || session.isGuestOrAnon) {
      return false;
    }
    session.updateSettings({
      spaceId,
      spaceName,
    });

    localStore.set(`${session.claims.orgId}_lastAccessedSpaceId`, spaceId);
    return true;
  }

  public get(key: string): StudioSession | undefined {
    return this.sessions[key] || undefined;
  }

  public async init(initOptions: { autoRefresh: boolean }) {
    // todo we should have a cooler way of logging this stuff...
    let last = Date.now();
    this.debug && console.log(`Auth.init() took ${Date.now() - last}ms`);
    last = Date.now();
    const orgId =
      new URLSearchParams(window.location.search).get("org") || undefined;
    const invite =
      new URLSearchParams(window.location.search).get("invite") || undefined;
    await this.refresh();

    if (orgId) {
      localStore.set("isFromLogin", true);
      ssm.changeOrg(orgId);
    } else if (invite) {
      localStore.set("isFromLogin", true);
      ssm.changeOrg(invite);
    }

    this.debug &&
      console.log(`StudioSessionManager.refresh() took ${Date.now() - last}ms`);
    this.autoRefresh = initOptions.autoRefresh;
    this._initDone = true;
  }

  public redirectIfRequired(): boolean {
    this.debug && console.log(`StudioSessionManager.redirectIfRequired()`);
    const { redirect, logout } = determineReactionForRoleOnPath();
    this.debug &&
      console.log(`StudioSessionManager.redirectIfRequired() result`, {
        redirect,
        logout,
      });
    if (logout) {
      ssm.logout();
      return true;
    } else if (redirect) {
      window.location.href = redirect;
      return true;
    }

    return false;
  }

  public async getUserStatus() {
    // Get the status of user from auth database
    return this.auth.getUserStatus();
  }

  public async getAuthData() {
    // authData wasn't set yet OR token exists, but is almost expired
    if (
      !this._authData ||
      (this._authData.claims &&
        this._authData.claims.exp &&
        this._authData.claims.exp < Date.now() / 1000 - 30)
    ) {
      const authSession = await this.auth.get();
      if (authSession) {
        this.setAuthData(authSession.token, authSession.claims);
      } else {
        // If user is not logged in, redirect to auth-frontend.
        // When previewUrl is set, we are running a preview build.
        // In this case, we pass the preview url in the ?redirect query
        // paramter to redirect back to the preview build after login.
        window.location.href = `${appConfig.auth.frontend}${
          appConfig.previewUrl
            ? `?redirect=${encodeURIComponent(appConfig.previewUrl)}`
            : ""
        }`;
      }
    }
    return this._authData!; // definitely set here
  }

  protected setAuthData(token: string | null, claims: IAuthClaims | null) {
    // redirect
    if (!token && !claims) {
      return this.unsetAuthData();
    }
    // sanity
    if (!claims) {
      throw new Error(`claims cant be null if token is set`);
    } else if (!token) {
      throw new Error(`token cant be null if claims is set`);
    } else if (!token.startsWith("ey")) {
      throw new Error(`token must be jwt-shaped`);
    }

    const prev = this._authData;
    const next = Object.freeze({ token, claims });
    this._authData = next;

    // user logged in
    if (prev !== undefined && hasUserChanged(prev.claims, next.claims)) {
      // remove all session data
      this.flush();

      this.redirectIfRequired();
    }
  }

  protected unsetAuthData(redirectUri?: string) {
    const prev = this._authData;
    const next = Object.freeze({ token: null, claims: null });
    this._authData = next;

    // remove all session data
    this.flush();

    // redirect if url provided
    if (redirectUri) {
      window.location.href = redirectUri;
    } else if (prev !== undefined && hasUserChanged(prev.claims, next.claims)) {
      // user logged out
      this.redirectIfRequired();
    }
  }
}
