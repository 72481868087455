import { useState, useEffect } from "react";
import { UUID } from "@screencloud/uuid";
import { useAppContext } from "../../../hooks/useAppContext";
import { Folder, useAvailableFoldersBySpaceIdQuery } from "../../../types.g";

/**
 * React custom hook
 * rootFolderId
 * sharedFolderFromOtherSpace : folder that has been shared from other spaces
 */
export function useSharedFolderFromOtherSpaces(spaceId?: UUID) {
  const [sharedFoldersFromOtherSpaces, setSharedFolderFromOtherSpaces] =
    useState([] as Folder[]);
  const context = useAppContext();
  const currentSpaceId = spaceId ?? context?.currentSpace?.id;

  const {
    data: availiableFoldersBySpaceIdQuery,
    loading,
    refetch: refetchSharedFolderFromOtherSpace,
  } = useAvailableFoldersBySpaceIdQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      spaceId: currentSpaceId,
    },
  });

  useEffect(() => {
    // This folder also contain folder in same space
    const avaliableSharedFolders = (availiableFoldersBySpaceIdQuery?.spaceById
      ?.availableFoldersBySpaceId?.nodes ?? []) as Folder[];
    const sharedFromOtherSpaces = avaliableSharedFolders.filter(
      (item) => item.spaceId !== currentSpaceId,
    );
    setSharedFolderFromOtherSpaces(sharedFromOtherSpaces);
  }, [availiableFoldersBySpaceIdQuery, spaceId]);

  return {
    sharedFoldersFromOtherSpaces,
    isSharedFolderLoading: loading,
    refetchSharedFolderFromOtherSpace,
  };
}
