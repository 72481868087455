import { withApollo, WithApolloClient } from "@apollo/client/react/hoc";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  CreateFileMutationFn,
  CreateSiteMutationFn,
  UpdateSiteByIdMutationFn,
  withCreateFile,
  withCreateSite,
  withUpdateSiteById,
} from "../../types.g";
import { compose } from "../../utils/compose";

export interface ApolloProps
  extends RouteComponentProps<any>,
    WithApolloClient<any> {
  createSite: CreateSiteMutationFn;
  createFile: CreateFileMutationFn;
  updateSiteById: UpdateSiteByIdMutationFn;
}

export const withData = compose(
  withRouter,
  withApollo,
  withCreateSite({
    name: "createSite",
  }),
  withUpdateSiteById({
    name: "updateSiteById",
  }),
  withCreateFile({
    name: "createFile",
  }),
);
