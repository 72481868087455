import { orderBy } from "lodash";
import { LinkListHeaderActions } from "../components/LinkListHeader";
import {
  CreateLinkMutationVariables,
  LinkListItemFragmentDoc,
  useCreateLinkMutation,
} from "../types.g";
import { useAppContext } from "./useAppContext";

export function useCreateLink() {
  const context = useAppContext();
  const [createLinkMutation] = useCreateLinkMutation();
  const createLink = (
    createLinkInput: CreateLinkMutationVariables,
    linkOrderByAction: LinkListHeaderActions,
  ) => {
    return createLinkMutation({
      variables: createLinkInput,
      update: (cache, { data }) => {
        if (data?.createLink?.link) {
          const linkOrderBy =
            linkOrderByAction === LinkListHeaderActions.SORT_BY_NAME
              ? [(node) => node?.name?.toLowerCase()]
              : ["created"];
          cache.modify({
            id: cache.identify(context.currentSpace!),
            fields: {
              linksBySpaceId(links, options) {
                const newLinkRef = cache.writeFragment({
                  data: data.createLink?.link,
                  fragment: LinkListItemFragmentDoc,
                  fragmentName: "LinkListItem",
                });
                if (
                  links.nodes.some(
                    (ref) =>
                      options.readField("id", ref) ===
                      data.createLink?.link?.id,
                  )
                ) {
                  return links;
                }

                const nodes = [...links.nodes, newLinkRef];
                const reOrder = orderBy(
                  nodes.map((node) => {
                    const name = options.readField("name", node) as string;
                    const created = options.readField(
                      "createdAt",
                      node,
                    ) as string;
                    return {
                      name,
                      created,
                      node,
                    };
                  }),
                  linkOrderBy,
                  linkOrderByAction === LinkListHeaderActions.SORT_BY_NAME
                    ? ["asc"]
                    : ["desc"],
                );
                const newLinkList = {
                  ...links,
                  nodes: reOrder.map((re) => re.node),
                  totalCount: links.totalCount + 1,
                };
                return newLinkList;
              },
            },
          });
        }
      },
    });
  };
  return { createLink };
}
