import { withApollo, WithApolloClient } from "@apollo/client/react/hoc";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useAddAppsToPlaylistsMutation } from "../../pages/Apps/AppInstances/hooks/useAddAppsToPlaylist";
import { ssm } from "../../state/session/ssm";
import {
  Exact,
  InputMaybe,
  SidebarQueryQuery,
  SidebarSearchQuery,
  useSidebarQueryQuery,
  useSidebarSearchQuery,
} from "../../types.g";
import { compose } from "../../utils/compose";
import { ApolloQueryResult } from "@apollo/client";

export interface ApolloProps {
  loadMore: any;
  showAddToPlaylists: boolean;
  data: SidebarQueryQuery;
  loading: boolean;
  sidebarRefetch: (
    variables?:
      | Partial<
          Exact<{
            spaceId: any;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<SidebarQueryQuery>>;
}

export const withData = compose(
  withRouter,
  (Component) => (props) => {
    const { data, loading, refetch } = useSidebarQueryQuery({
      variables: {
        spaceId: ssm.current.settings.spaceId,
      },
      fetchPolicy: "cache-and-network",
    });
    return (
      <Component
        {...props}
        data={data}
        loading={loading}
        sidebarRefetch={refetch}
      />
    );
  },
  (Component) => (props) => {
    const showAddToPlaylists = useAddAppsToPlaylistsMutation();
    return <Component {...props} showAddToPlaylists={showAddToPlaylists} />;
  },
);

export interface SidebarSearchProps
  extends RouteComponentProps<any>,
    WithApolloClient<any> {
  searchTerms: string;
  loadSearchMore: any;
  sidebarSearchData: SidebarSearchQuery;
  sidebarSearchDataLoading: boolean;
  sidebarSearchRefetch: (
    variables?:
      | Partial<
          Exact<{
            query: InputMaybe<string>;
            spaceId: any;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<SidebarSearchQuery>>;
}

export const withSidebarSearchData = compose(
  withRouter,
  withApollo,
  (Component) => (props) => {
    const { data, loading, refetch } = useSidebarSearchQuery({
      variables: {
        query: props.searchTerms,
        spaceId: ssm.current.settings.spaceId,
      },
      fetchPolicy: "cache-and-network",
      skip: !props.searchTerms,
    });
    return (
      <Component
        {...props}
        sidebarSearchData={data}
        sidebarSearchDataLoading={loading}
        sidebarSearchRefetch={refetch}
      />
    );
  },
);
