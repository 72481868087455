import {
  Button,
  Checkbox,
  DatetimeInput,
  Icon,
  Popover,
} from "@screencloud/screencloud-ui-components";
import { format as toFormat } from "date-fns";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import { MediaPreviewActions, MediaPreviewPayload } from "./preview";

export interface MediaPublicationProps {
  callBack?: (
    mediaId: string,
    action: MediaPreviewActions,
    data: MediaPreviewPayload,
  ) => void;
  id: string;
  expiryDate?: string;
  availableDate?: string;
  locale?: string;
}

export interface MediaPublicationState {
  availableDate?: string;
  expiryDate?: string;
  isPublish: boolean;
  isExpiryDateFocus: boolean;
}

export enum MediaDetailActions {}

class MediaPublication extends React.Component<
  MediaPublicationProps,
  MediaPublicationState
> {
  public static contextType = AppContext;
  public context: AppContextType;

  constructor(props: MediaPublicationProps) {
    super(props);

    this.state = {
      availableDate: this.props.availableDate,
      expiryDate: this.props.expiryDate,
      isExpiryDateFocus: false,
      isPublish: !!this.props.availableDate || !!this.props.expiryDate,
    };
  }

  public handleEnablePublication = async (
    event: React.SyntheticEvent,
    data: { checked: boolean },
  ) => {
    await this.setState({
      isExpiryDateFocus: true,
      isPublish: data.checked,
    });
    if (this.props.callBack && data.checked) {
      await this.props.callBack(
        this.props.id,
        MediaPreviewActions.ADD_AVAILABLE_DATE,
        {
          availableDate: toFormat(new Date(), "MM/dd/YYY HH:mm:ss"),
        },
      );
    } else if (this.props.callBack && !data.checked) {
      this.setState({
        expiryDate: "",
      });
      await this.props.callBack(
        this.props.id,
        MediaPreviewActions.RESET_PUBLICATION_DATES,
        {
          availableDate: "",
          expiryDate: "",
        },
      );
    }
  };

  public handleAvailableDatetimeChange = (value: string) => {
    if (this.props.callBack && this.state.isPublish) {
      this.props.callBack(
        this.props.id,
        MediaPreviewActions.ADD_AVAILABLE_DATE,
        { availableDate: value },
      );
      this.setState({
        availableDate: value,
      });
    }
  };

  public handleExpiryDatetimeChange = (value: string) => {
    if (this.props.callBack && this.state.isPublish) {
      this.props.callBack(this.props.id, MediaPreviewActions.ADD_EXPIRY_DATE, {
        expiryDate: value,
      });
      this.setState({
        expiryDate: value,
      });
    }
  };

  public getDateFormat = (
    value: string | undefined,
    format: string,
  ): string => {
    if (value && value !== "") {
      const date: Date = new Date(value);
      return toFormat(date, format);
    }
    return "";
  };

  public clearExpiryDate = () => {
    if (this.props.callBack && this.state.isPublish) {
      this.props.callBack(
        this.props.id,
        MediaPreviewActions.REMOVE_EXPIRY_DATE,
        { expiryDate: "" },
      );
      this.setState({
        expiryDate: "",
      });
    }
  };

  public render() {
    const dateNow = new Date();
    const now = toFormat(dateNow, "YYY-MM-dd'T'HH:mm:ss+00:00");

    const availableMinDate = this.state.availableDate
      ? new Date(this.state.availableDate)
      : dateNow;
    const availableMaxDate = this.state.expiryDate
      ? new Date(this.state.expiryDate)
      : undefined;
    const canScheduleMedia =
      this.context.currentPermissions.validateCurrentSpace("media", "schedule");

    return (
      <>
        <div className="wrapper">
          <div className="publication">
            <div className="head" data-testid="enable-schedule">
              <div className="text">
                <h3>
                  <FormattedMessage
                    id="ui_component.media_preview.enable_availability"
                    defaultMessage="Enable availability"
                  />
                </h3>
                <Popover
                  trigger={<Icon name="info" />}
                  content="Allows you to set an expiration date in the future"
                  position="top center"
                  inverted
                />
              </div>
              <Checkbox
                disabled={!canScheduleMedia}
                checked={this.state.isPublish}
                toggle
                onChange={this.handleEnablePublication}
              />
            </div>

            {this.state.isPublish && (
              <div className="expand" data-testid="schedule">
                <div>
                  <h3>
                    <FormattedMessage
                      id="ui_component.media_preview.available_date"
                      defaultMessage="Available date &amp; time"
                    />
                  </h3>
                  <DatetimeInput
                    locale={this.props.locale}
                    disabled={!canScheduleMedia}
                    placeholder="Available"
                    minDate={new Date()}
                    maxDate={availableMaxDate}
                    value={
                      this.props.availableDate ? this.props.availableDate : now
                    }
                    callback={this.handleAvailableDatetimeChange}
                    workInUTC
                  />
                </div>
                <div>
                  <h3>
                    <FormattedMessage
                      id="ui_component.media_preview.expiration_date"
                      defaultMessage="Expiration date &amp; time"
                    />
                    <Button
                      inline
                      danger
                      mini
                      className="btn-reset"
                      onClick={this.clearExpiryDate}
                    >
                      <FormattedMessage
                        id="common.text.clear"
                        defaultMessage="Clear"
                      />
                    </Button>
                  </h3>
                  <DatetimeInput
                    locale={this.props.locale}
                    disabled={!canScheduleMedia}
                    autoFocus={this.state.isExpiryDateFocus}
                    placeholder="No expires"
                    minDate={availableMinDate}
                    value={this.state.expiryDate ?? ""}
                    callback={this.handleExpiryDatetimeChange}
                    workInUTC
                  />
                  <p className="desc">
                    Leave this empty to disable expiration date
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default MediaPublication;
