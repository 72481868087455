import {
  Button,
  Icon,
  InfoBanner,
  Loader,
} from "@screencloud/screencloud-ui-components";
import { FunctionComponent } from "enzyme";
import { useEffect, useState } from "react";
import { FormattedMessage, MessageValue } from "react-intl";
import { useHistory } from "react-router";
import { AppNames } from "src/constants/constants";
import { useAllAppsQuery } from "../../types.g";
import { isYoutubeLink } from "../../utils/validation";
import { Styled } from "./styles";
import { AppInfo, getAppInfo } from "./utils";
import { APP_STORE_DISCOVER_PATH_NANE } from "src/pages/Apps/AppStore/constants";

interface Props {
  linkInput: string;
  formatMessage: (
    messageDescriptor: FormattedMessage.MessageDescriptor,
    values?: { [key: string]: MessageValue },
  ) => string;
  onLinkClicked?: () => void;
}

const AppBanner: FunctionComponent<Props> = ({
  linkInput,
  formatMessage,
  onLinkClicked,
}) => {
  const [appInfo, setAppInfo] = useState<AppInfo | undefined>(undefined);
  const history = useHistory();
  if (!isYoutubeLink(linkInput)) return null;

  const { data, loading } = useAllAppsQuery({
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    data && setAppInfo(getAppInfo(data, AppNames.Youtube));
  }, [data]);

  const onAppLinkClick = (appId: string) => () => {
    history.push(`${APP_STORE_DISCOVER_PATH_NANE}${appId}`);
    onLinkClicked && onLinkClicked();
  };

  return (
    <InfoBanner
      description={formatMessage({
        defaultMessage:
          "Looks like you are trying to add a YouTube video. Please try our YouTube app for a better experience.",
        id: "ui_component.link.youtube_app",
      })}
      color="success"
      className="info-banner"
    >
      <Styled>
        {loading && <Loader active inline size="small" />}
        {appInfo && (
          <div className="app-banner" data-testid="app-info-banner">
            <div
              className="app-logo-container"
              style={{ backgroundImage: `url('${appInfo.iconUrl}')` }}
            />
            <div className="app-info">
              <span className="app-name">{appInfo.name}</span>
              <span className="app-category">{appInfo.category}</span>
            </div>
            <div className="app-link" data-testid="app-link-button">
              <Button onClick={onAppLinkClick(appInfo.id)}>
                {formatMessage({
                  defaultMessage: "Try it now",
                  id: "ui_component.link.try_it_now",
                })}
                <span className="app-link-icon">
                  <Icon name="external" />
                </span>
              </Button>
            </div>
          </div>
        )}
      </Styled>
    </InfoBanner>
  );
};

export { AppBanner };
