import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";
export const Styled = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .onboarding-cover {
    background: ${Theme.color.base};
    border-radius: ${Theme.default.borderRadiusValue}
      ${Theme.default.borderRadiusValue} 0 0;
    overflow: hidden;
    position: relative;
    height: auto;
    min-height: 430px;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      object-fit: none;
    }

    > * {
      height: auto;
      /* position: absolute; */
      width: 100%;
    }

    > iframe {
      height: 100%;
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  .onboarding-reminder {
    margin-top: 20px;
  }

  .onboarding-content {
    padding: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 288px;

    h1 {
      font-style: italic;
      font-weight: 900;
    }

    > p {
      margin: 0 auto 40px;
      max-width: 560px;
      padding: 0 40px;
    }

    .onboarding-copy {
      margin-bottom: 40px;
    }

    .button {
      min-width: 200px;
    }

    .skip-button {
      margin-top: 20px;
    }

    .ui.label.invert-icon {
      background-color: ${Theme.color.base};
      color: ${Theme.color.white};
      font-size: 1em;
      line-height: 1.4em;
      height: 34px;

      i {
        background-color: ${Theme.color.white};
        margin-right: 5px;
        position: relative;
      }

      &.invert-icon__channel {
        i {
          bottom: -3px;
        }
      }

      &.invert-icon__screen {
        i {
          bottom: -2px;
        }
      }
    }

    &.branding {
      height: 100%;
      justify-content: flex-start;
      padding: 60px 40px;

      h2 {
        margin-bottom: 20px;
      }

      .br-card {
        display: inline-flex;
        width: 100%;

        .br-editor {
          background: ${Theme.color.white};
          border: 1px solid ${Theme.color.lightGrey};
          border-radius: ${Theme.default.borderRadiusValue} 0 0
            ${Theme.default.borderRadiusValue};
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          text-align: left;
          padding: 40px 40px;
          width: 50%;

          > h3 {
            width: 100%;
          }

          .br-logo {
            margin-right: 20px;
            position: relative;
            width: calc(50% - 10px);

            p {
              margin-bottom: 5px;
            }

            .picker-render {
              cursor: pointer;
              position: relative;
              width: 129px;
              padding-bottom: 127px;
              height: 0;
              border: 1px solid ${Theme.color.lightGrey};
              ${Theme.default.borderRadius};
              background:
                -webkit-linear-gradient(
                  45deg,
                  rgba(0, 0, 0, 0.3) 25%,
                  rgba(0, 0, 0, 0) 25%,
                  rgba(0, 0, 0, 0) 75%,
                  rgba(0, 0, 0, 0.3) 75%,
                  rgba(0, 0, 0, 0.3) 0
                ),
                -webkit-linear-gradient(
                    45deg,
                    rgba(0, 0, 0, 0.3) 25%,
                    rgba(0, 0, 0, 0) 25%,
                    rgba(0, 0, 0, 0) 75%,
                    rgba(0, 0, 0, 0.3) 75%,
                    rgba(0, 0, 0, 0.3) 0
                  ),
                ${Theme.color.silver};
              background-position:
                0 0,
                16px 16px;
              background-size: 32px 32px;
              overflow: hidden;

              > span {
                position: absolute;
                top: 0;
                width: 100%;
                text-align: center;
                font-size: 0.75em;
                line-height: 1;
                opacity: 0.8;
                color: ${Theme.color.lightGrey};
                background: rgba(0, 0, 0, 0.5);
              }

              img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: contain;
              }

              .overlay {
                width: 100%;
                height: 100%;
                position: absolute;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background: rgba(0, 0, 0, 0.3);
                opacity: 0;

                .edit {
                  align-items: center;
                  height: 50px;
                  display: inline-flex;
                  justify-content: center;
                  border-radius: 100%;
                  background: rgba(0, 0, 0, 0.2);
                  border: 2px solid ${Theme.color.white};
                  transition: all 0.1s ease-out 0s;
                  width: 50px;

                  i {
                    width: 20px;
                    height: 20px;
                    background-color: ${Theme.color.white};
                  }
                }
              }

              .button {
                width: 127px;
                font-size: 0.875em;
                text-align: center;
                min-width: auto;
                bottom: 0;
                position: absolute;
                height: 30px;
                display: flex;
                align-items: center;
                background: rgba(0, 0, 0, 0.5);
                color: ${Theme.color.white};
                border-radius: 0;
                opacity: 0;
              }

              :hover {
                > span {
                  opacity: 1;
                }
                .overlay {
                  opacity: 1;
                }
              }
            }

            > .button {
              width: 127px;
              text-align: center;
              font-size: 0.75em;
              color: ${Theme.color.grey};
              font-weight: normal;
              min-width: auto;
              position: absolute;
              bottom: -15px;
            }
          }

          .br-colors {
            width: calc(50% - 10px);
            position: relative;

            .color {
              margin-top: 5px;

              p {
                margin-bottom: 5px;
                white-space: nowrap;
              }

              .color-picker {
                position: relative;
                width: 100%;
              }

              &.secondary {
                .color-picker {
                  .popover {
                    bottom: 55px;
                  }
                }
              }
            }

            .color:first-child {
              margin-top: 0;
            }

            .swap {
              position: absolute;
              width: 40px;
              right: -40px;
              top: calc(50% - 12px);
              min-width: auto;

              i {
                background-color: ${Theme.color.darkGrey};
              }
            }
          }

          .br-note {
            padding: 20px 0 0;
            width: 100%;

            span {
              color: ${Theme.color.darkGrey};
              display: block;
            }

            .note {
              color: ${Theme.color.grey};
              font-size: 0.875em;
              font-style: italic;
              line-height: 1.357;
              margin-bottom: 20px;
            }
          }
        }

        .br-preview {
          width: 50%;
          border-radius: 0 6px 6px 0;
          background-size: cover;
          position: relative;
          background-position: center;
          user-select: none;

          :after {
            content: "";
            display: block;
            background-image: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.8) 100%
            );
            height: 50px;
            position: absolute;
            opacity: 0.8;
            bottom: 0;
            width: 100%;
            border-radius: 0 0 6px 0;
          }

          .preview-monitor {
            display: flex;
            flex-direction: center;
            justify-content: center;
            align-items: center;
            background: ${Theme.color.base};
            width: 298px;
            height: 171px;
            position: absolute;
            top: 60px;
            left: calc(50% - 149px);
            border-radius: 2px;
            border: 4px solid ${Theme.color.black};
            box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.7);

            .preview-logo {
              border-radius: 3px;
              width: 75px;
            }
          }

          .preview-text {
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: center;
            padding: 0 40px 10px;
            font-size: 0.875em;
            color: ${Theme.color.white};
            font-style: italic;
            z-index: 1;
          }
        }
      }

      .button-container {
        margin: 40px 0;
      }
    }
  }

  .onboarding-actions {
    text-align: center;
    display: flex;
    flex-direction: column;

    .button {
      min-width: 200px;
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    .onboarding-content {
      &.branding {
        padding: 40px 0;

        .br-card {
          .br-editor,
          .br-preview {
            border-radius: 0;
            border-left-width: 0;
            border-right-width: 0;

            :after {
              border-radius: 0;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .onboarding-cover {
      border-radius: 0;
      min-height: 55%;

      img {
        object-fit: cover;
        height: 100%;
      }
    }

    .onboarding-content {
      &.branding {
        padding-top: 280px;
        position: relative;

        .br-card {
          .br-editor {
            width: 100%;

            .br-logo {
              .picker-render {
                .overlay {
                  :hover {
                    opacity: 0;
                  }
                }
                .button {
                  opacity: 1;
                }
              }
            }
          }

          .br-preview {
            position: absolute;
            top: 0;
            height: 236px;
            width: 100%;
            background-position: center;

            .preview-monitor {
              width: 236px;
              height: 135px;
              top: 38px;
              left: calc(50% - 118px);
              border-width: 3px;

              .preview-logo {
                border-radius: 2px;
                width: 60px;
              }
            }
          }

          :after {
            height: 70px;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    .onboarding-content {
      padding: 40px 20px;

      .button {
        width: 100%;
        margin: 0 0 10px 0;
      }

      &.branding {
        padding-top: 40px;

        h2 {
          margin-bottom: 12px;
        }

        > p {
          margin: 0 20px 20px;
          padding: 0;
        }

        p {
          font-size: 0.875em;
          line-height: 1.357;
        }

        .br-card {
          .br-editor {
            padding: 20px;

            .br-logo {
              .picker-render {
                padding-bottom: 121px;
                width: 130px;

                .button {
                  width: 130px;
                  margin: 0;
                }
              }
            }

            .br-colors {
              .color {
                .color-picker {
                  .popover {
                    right: 20px;
                  }
                }
              }

              .swap {
                right: -10px;
              }
            }

            .br-note {
              span {
                font-size: 0.875em;
                line-height: 1.357;
              }
            }
          }

          .br-preview {
            display: none;

            .preview-monitor {
              width: 196px;
              height: 112px;
              left: calc(50% - 89px);
              top: 50px;

              .preview-logo {
                width: 49px;
              }
            }

            .preview-text {
              padding: 0 20px 10px;
              font-size: 0.75em;
              line-height: 1.2;
            }
          }
        }

        .button-container {
          margin-top: 20px;
          width: 100%;
          padding: 0 15px;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column-reverse;

          .button {
            margin: 10px 0 0;
          }
        }
      }
    }
  }
`;
