import {
  DataValue,
  withApollo,
  WithApolloClient,
} from "@apollo/client/react/hoc";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ssm } from "../../state/session/ssm";
import { compose } from "../../utils/compose";

import {
  AllTagsQuery,
  FileByIdExtendedAssociationsQueryVariables,
  FileByIdProps,
  Scalars,
  SetScreenContentByFileIdMutationFn,
  withAllTags,
  withFileByIdExtendedAssociations,
  withSetScreenContentByFileId,
} from "../../types.g";
import {
  useDeleteFile,
  UseDeleteFile,
} from "src/hooks/entities/file/useDeleteFile";

export interface WithMediaProps
  extends FileByIdProps,
    UseDeleteFile,
    WithApolloClient<any>,
    RouteComponentProps<any> {
  fileId: Scalars["UUID"];
  allTags: DataValue<AllTagsQuery>;
  setContentByFileId: SetScreenContentByFileIdMutationFn;
}

export const withMediaPreview = compose(
  withRouter,
  withApollo,
  withAllTags({
    name: "allTags",
  }),
  withFileByIdExtendedAssociations({
    options: (props: WithMediaProps) => {
      const variables: FileByIdExtendedAssociationsQueryVariables = {
        id: props.fileId,
        spaceId: ssm.current.settings.spaceId,
      };
      return {
        fetchPolicy: "cache-and-network",
        variables,
      };
    },
  }),
  withSetScreenContentByFileId({ name: "setContentByFileId" }),
  (Component) => (props: WithMediaProps) => {
    const { deleteFile } = useDeleteFile();
    return <Component {...props} deleteFile={deleteFile} />;
  },
);
