import {
  SearchSiteQuery,
  SearchSiteQueryVariables,
  Site,
  SiteListQuery,
  SitesOrderBy,
  useSearchSiteQuery,
} from "src/types.g";
import uniqBy from "lodash/uniqBy";
import { compose } from "src/utils/compose";
import { ssm } from "src/state/session/ssm";
import { useLoadMore } from "src/hooks/useLoadMore";
import { SiteListItemActions } from "../SiteListItem";
import { DataValue } from "@apollo/client/react/hoc";
import { FIRST_FETCH_ITEMS } from "src/constants/constants";

export interface SiteListProps {
  onLoadMoreSiteData: () => void;
  onClickSiteCallback: (
    action: SiteListItemActions,
    value: Partial<Site> | string | boolean,
    event?: React.MouseEvent,
  ) => void;
  selectedSiteCallback: (site: Site) => void;
  searchTerms: string;
  siteData: DataValue<SiteListQuery>;
  onSelectSiteItems?: (siteItems: Partial<Site>[]) => void;
  selectedSiteItems?: Partial<Site>[];
}

export interface ApolloProps extends SiteListProps {
  loadMore: () => void;
  data: SearchSiteQuery;
  loading: boolean;
}

export const withData = compose((Component) => (props: SiteListProps) => {
  const spaceId = ssm.current.settings.spaceId;
  const queryVar: SearchSiteQueryVariables = {
    query: props.searchTerms,
    spaceId,
    endCursor: null,
    orderBy: [SitesOrderBy.NameAsc, SitesOrderBy.CreatedAtAsc],
    filter: {},
    first: FIRST_FETCH_ITEMS,
  };
  const { data, fetchMore, loading } = useSearchSiteQuery({
    fetchPolicy: "cache-and-network",
    variables: queryVar,
    skip: !props.searchTerms,
  });

  const endCursor = data?.searchSite?.pageInfo?.endCursor;
  const siteFetchMore = () => {
    return fetchMore({
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const prevData = previousResult.searchSite!;
        const currentData = fetchMoreResult!.searchSite!;
        const nodes = uniqBy([...prevData!.nodes, ...currentData!.nodes], "id");
        return {
          ...previousResult,
          searchSite: {
            ...previousResult.searchSite!,
            __typename: "SitesConnection",
            nodes,
            pageInfo: {
              ...currentData!.pageInfo,
            },
          },
        };
      },
      variables: { endCursor },
    });
  };

  const { loadMore } = useLoadMore(siteFetchMore, endCursor);

  return (
    <Component {...props} data={data} loading={loading} loadMore={loadMore} />
  );
});
