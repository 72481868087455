import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Icon,
  ModalSize,
  UserName,
} from "@screencloud/screencloud-ui-components";
import { PureComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { appConfig } from "../../../appConfig";
import { AppContextType } from "../../../AppContextProvider/type";
import { ssm } from "../../../state/session/ssm";
import { orgsList } from "../../../utils";
import OrgSwitcher from "../../OrgSwitcher";

import Avatar from "../Avatar";
import AdminNav from "../Nav";

import { AppContext } from "../../../AppContextProvider/AppContext";
import { getWhiteLabelStyles } from "../../../helpers/whiteLabel";

import { Styled } from "./styles";

interface State {
  familyName: string;
  givenName: string;
}

export interface AdminHeaderProps {
  givenName: string;
  familyName: string;
  picture?: string;
  orgSelected: number;
  canAccessOnlyAdminSection: boolean;
  hideAdminNav?: boolean;
}

class AdminHeader extends PureComponent<AdminHeaderProps, State> {
  public static contextType = AppContext;
  public listenId: number;
  public context: AppContextType;

  constructor(props: AdminHeaderProps) {
    super(props);
    this.state = {
      familyName: this.props.familyName,
      givenName: this.props.givenName,
    };
  }

  public componentDidMount() {
    this.listenId = ssm.subscribe(({ claims }) =>
      this.setState({
        familyName: claims.familyName || "",
        givenName: claims.givenName || "",
      }),
    );
  }

  public componentWillUnmount() {
    ssm.unsubscribe(this.listenId);
  }

  public handleClickOpenOrgSwitcher = () => {
    this.context.modal.openModal(
      <OrgSwitcher />,
      <FormattedMessage
        id="ui_component.account_nav.switch_org"
        defaultMessage="Switch Organization"
      />,
      {
        opts: {
          disableTitle: false,
          overflow: false,
          size: ModalSize.SMALL,
          className: "fix-height",
        },
      },
    );
  };

  public render() {
    const { tokenPayloadsByRegion } = this.context;
    const hasMultipleOrgs =
      orgsList().length > 1 ||
      (appConfig.regions.allNonCurrent.length > 0 &&
        tokenPayloadsByRegion &&
        tokenPayloadsByRegion.length > 1);

    const profileTrigger: JSX.Element = (
      <div className="user">
        <UserName
          givenName={this.state.givenName}
          familyName={this.state.familyName}
        />
        <Avatar url={this.props.picture} name={this.state.givenName} />
      </div>
    );

    const whiteLabelStyles = getWhiteLabelStyles(this.context);

    return (
      <>
        <Styled isMultipleOrgs={hasMultipleOrgs} {...whiteLabelStyles}>
          <div className="back">
            {!this.props.canAccessOnlyAdminSection && (
              <Link
                to={{ pathname: "/screens", state: { fromAdmin: true } }}
                className="signage"
              >
                <Icon className="icon" name="arrow-left" />
                <FormattedMessage
                  id="admin.back_to_signage"
                  defaultMessage="Back to Studio"
                />
              </Link>
            )}
          </div>
          <div
            className="orgs"
            onClick={
              hasMultipleOrgs ? this.handleClickOpenOrgSwitcher : undefined
            }
          >
            <div className="org-core">
              <div className="label">
                <FormattedMessage
                  id="admin.organization"
                  defaultMessage="organization"
                />
              </div>
              <div className="selected-org">
                {this.context.currentOrg?.name}
              </div>
            </div>
            {hasMultipleOrgs && <Icon name="org-switch" />}
          </div>
          <div className="right-section">
            <div className="who">
              <Dropdown direction="right" icon={false} trigger={profileTrigger}>
                <DropdownMenu>
                  {hasMultipleOrgs && (
                    <DropdownItem onClick={this.handleClickOpenOrgSwitcher}>
                      <Icon name="org-switch" className="icon-move" />{" "}
                      <FormattedMessage
                        id="ui_component.account_nav.switch_org"
                        defaultMessage="Switch Organization"
                      />
                    </DropdownItem>
                  )}
                  <DropdownItem
                    onClick={() => this.context.sessions.logout()}
                    className="danger"
                  >
                    <Icon name="logout" />{" "}
                    <FormattedMessage
                      id="ui_component.account_nav.logout"
                      defaultMessage="Logout"
                    />
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </Styled>
        {!this.props?.hideAdminNav && <AdminNav />}
      </>
    );
  }
}

export default AdminHeader;
