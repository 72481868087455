import { find } from "lodash";
import React, { TouchEvent } from "react";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { Link } from "../../types.g";
import { LinkListItemActions } from "../LinkListItem";
import { DraggableContainer, SelectionCount } from "../Main/styles";
import LinkContentItem from "./item";
import { StyledDraggableWrapper } from "./styles";
export interface LinkContentItemProps {
  linkItem: Partial<Link>;
  index: number;
  selectedLinkCallback: (link: Link) => void;
  onClickLinkCallback: (
    action: LinkListItemActions,
    value: Link,
    event?: React.MouseEvent,
  ) => void;
  selectedLinkItems?: Partial<Link>[];
  secureMediaPolicy: string | undefined;
}

const getItemStyle = (
  snapshot: DraggableStateSnapshot,
  draggableStyle: object | undefined,
) => {
  const { isDragging } = snapshot;
  return {
    ...draggableStyle,
    backgroundColor: "#FFFFFF",
    boxShadow: isDragging ? "0 2px 15px 0 rgba(0,0,0,0.15)" : undefined,
  };
};

class LinkContentItemWrapper extends React.Component<
  LinkContentItemProps,
  any
> {
  public handleDragLinkItem = (linkItem) => {
    this.props.selectedLinkCallback(linkItem);
  };
  public handleOnClickLink = (
    action: LinkListItemActions,
    value: Link,
    event?: React.MouseEvent,
  ) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.props.onClickLinkCallback(action, value, event);
  };
  public render() {
    const { linkItem, index } = this.props;
    const linkContent = (
      <LinkContentItem
        className={
          this.props.selectedLinkItems &&
          find(this.props.selectedLinkItems, { id: linkItem.id })
            ? "selected"
            : ""
        }
        link={linkItem}
        onClickLinkCallback={this.handleOnClickLink}
        secureMediaPolicy={this.props.secureMediaPolicy}
      />
    );
    return (
      <StyledDraggableWrapper key={linkItem.id} className="row">
        <Draggable
          draggableId={linkItem.id}
          index={index}
          isDragDisabled={false}
        >
          {(
            draggableProvided: DraggableProvided,
            draggableSnapshot: DraggableStateSnapshot,
          ): JSX.Element => {
            const onMouseDown = () => {
              this.handleDragLinkItem(linkItem);
            };

            const onTouchStart = (() => {
              if (!draggableProvided.dragHandleProps) {
                // @ts-ignore
                return onTouchStart;
              }
              return (event: TouchEvent) => {
                if (draggableProvided.dragHandleProps) {
                  draggableProvided.dragHandleProps.onTouchStart(event);
                  this.handleDragLinkItem(linkItem);
                }
              };
            })();

            return (
              <>
                <div
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                  {...draggableProvided.dragHandleProps}
                  onMouseDown={onMouseDown}
                  onTouchStart={onTouchStart}
                  style={getItemStyle(
                    draggableSnapshot,
                    draggableProvided.draggableProps.style,
                  )}
                >
                  <DraggableContainer>
                    {linkContent}
                    {draggableSnapshot.isDragging &&
                    this.props.selectedLinkItems &&
                    this.props.selectedLinkItems.length > 1 ? (
                      <SelectionCount>
                        {this.props.selectedLinkItems.length}
                      </SelectionCount>
                    ) : null}
                  </DraggableContainer>
                </div>
                <DraggableContainer>
                  {draggableSnapshot.isDragging && linkContent}
                </DraggableContainer>
              </>
            );
          }}
        </Draggable>
      </StyledDraggableWrapper>
    );
  }
}

export default LinkContentItemWrapper;
