import { ComponentType } from "react";
import { UUID } from "@screencloud/uuid";
import { compose } from "../../utils/compose";
import { useStateSearch } from "../../hooks/useStateSearch";
import {
  UseAppInstancePicker,
  useMediaPickerAppInstances,
} from "../AppPicker/hooks/useAppInstancePicker";
import { useAppContext } from "src/hooks/useAppContext";

export interface CanvasAppProps {
  data: UseAppInstancePicker;
  searchString: string;
  updateSearchString: (searchString: string) => void;
  clearSearch: () => void;
  query: string;
  spaceId?: UUID;
}

export const withData = compose(
  (Component: ComponentType<CanvasAppProps>) => (props: CanvasAppProps) => {
    const context = useAppContext();
    const canvasPicker = useMediaPickerAppInstances(
      context.canvasAppId,
      props.spaceId as UUID,
    );
    const { query, searchString, updateSearchString, clearSearch } =
      useStateSearch();

    return (
      <Component
        {...props}
        data={canvasPicker}
        query={query}
        searchString={searchString}
        updateSearchString={updateSearchString}
        clearSearch={clearSearch}
      />
    );
  },
);
