import { UUID } from "@screencloud/uuid";
import { FIRST_FETCH_ITEMS } from "src/constants/constants";
import { renderFetchMorePickerButton } from "src/helpers/generalHelper";
import { useAppContext } from "src/hooks/useAppContext";
import { useLoadMore } from "src/hooks/useLoadMore";
import {
  AvailableAppInstanceFragment,
  SearchAppInstancePickerQueryVariables,
  useSearchAppInstancePickerQuery,
} from "src/types.g";
import { shouldUseSpecificSpaceId } from "src/utils/shouldUseSpecificSpaceId";

export interface UseSearchAppInstancePicker {
  appInstances: AvailableAppInstanceFragment[];
  loading: boolean;
  renderFetchMoreButton: React.ReactNode;
}

export function useSearchAppInstancePicker(
  query: string,
  spaceId?: UUID,
): UseSearchAppInstancePicker {
  const context = useAppContext();
  const variables: SearchAppInstancePickerQueryVariables = {
    query,
    first: FIRST_FETCH_ITEMS,
    endCursor: null,
    spaceId: shouldUseSpecificSpaceId(spaceId),
    filter: { appId: { notEqualTo: context.canvasAppId } },
  };

  const { data, fetchMore, loading } = useSearchAppInstancePickerQuery({
    fetchPolicy: "cache-and-network",
    variables,
  });

  const appInstanceNodes = data?.searchAppInstance?.nodes ?? [];
  const appInstanceTotalCount = data?.searchAppInstance?.totalCount;
  const isQueryHasNextPage = data?.searchAppInstance?.pageInfo.hasNextPage;
  const endCursor = data?.searchAppInstance?.pageInfo?.endCursor;

  const { isLoading, loadMore } = useLoadMore(fetchMore, endCursor);

  const fetchMoreButton = renderFetchMorePickerButton(
    Number(appInstanceNodes?.length),
    Number(appInstanceTotalCount),
    isLoading,
    Boolean(isQueryHasNextPage) &&
      appInstanceNodes.length! < appInstanceTotalCount!,
    loadMore,
  );

  return {
    appInstances: appInstanceNodes,
    loading,
    renderFetchMoreButton: fetchMoreButton,
  };
}
