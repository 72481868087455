import { useModifyFolderCache } from "src/hooks/caches/modifications/useModifyFolderCache";
import { useCacheInvalidation } from "src/hooks/useCacheInvalidation";
import { File } from "src/types.g";

interface OnFileDeletedProps {
  files: Pick<File, "__typename" | "id" | "folderId">[];
}

export const useOnFilesDeleted = () => {
  const { invalidateEntityCache, invalidateScreenGroupCache } =
    useCacheInvalidation();
  const { removeFileFromFolder } = useModifyFolderCache();

  const onFilesDeleted = ({ files }: OnFileDeletedProps) => {
    files.forEach((file) => {
      removeFileFromFolder({ file });
      invalidateEntityCache(file);
    });
    invalidateScreenGroupCache();
  };

  return {
    onFilesDeleted,
  };
};
