import {
  Button,
  Icon,
  LoaderBar,
  Search,
} from "@screencloud/screencloud-ui-components";
import { UUID } from "@screencloud/uuid";
import classNames from "clsx";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { isOrgWidePath } from "src/utils/orgWideFeature";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import LinkPicker from "../../components/LinkPicker";
import { CreateLinkPayload } from "../../components/LinkPicker/create";
import { SortingActions } from "../../constants/constants";
import { updateAndToggleSelectedItems } from "../../helpers/updateAndToggleSelectedItemsHelper";
import { Link } from "../../types.g";
import EmptyState from "../EmptyState";
import { ApolloProps, withData } from "./apollo";
import LinkContentPickerItem, {
  LinkContentPickerItemCallBackActions,
  LinkContentPickerItemCallBackActionsEnum,
} from "./linkContentPickerItem";
import SearchLinkContentPicker from "./search";
import { LinkPickerItem, Styled } from "./styles";

export interface LinkContentPickerProps {
  isMultipleSelect: boolean | undefined;
  callback: (ids: string[], selectedLinks: Link[]) => void;
  createLinkCallback: (param: CreateLinkPayload) => void;
  linkType?: string;
  router?: any;
  spaceId?: UUID;
}
export interface LinkContentPickerState {
  selectedIds: string[];
  sortBy: SortingActions;
  isOrderByAscending: boolean;
}

class LinkContentPicker extends React.PureComponent<
  LinkContentPickerProps & ApolloProps,
  LinkContentPickerState
> {
  public static contextType = AppContext;
  public context: AppContextType;

  constructor(props: LinkContentPickerProps & ApolloProps) {
    super(props);
    this.state = {
      isOrderByAscending: true,
      selectedIds: [],
      sortBy: SortingActions.SORT_BY_NAME,
    };
  }

  public onToggleSelection = (link: Link) => {
    const { links } = this.props;
    const { selectedArray } = updateAndToggleSelectedItems(
      link.id,
      link,
      this.state.selectedIds,
      [],
      this.props.isMultipleSelect,
    );
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          selectedIds: selectedArray,
        };
      },
      () => {
        const selectedLinks = this.state.selectedIds.reduce(
          (allSelectedLink: Link[], currentId) => {
            const linkById = links.find(
              (linkData) => linkData!.id === currentId,
            );
            return [...allSelectedLink, linkById];
          },
          [],
        );
        this.props.callback(this.state.selectedIds, selectedLinks as Link[]);
      },
    );
  };

  public toggleSortAndOrderMediaItem = (action: SortingActions) => {
    if (this.state.sortBy === action) {
      this.setState((prevState: LinkContentPickerState) => ({
        ...prevState,
        isOrderByAscending: !prevState.isOrderByAscending,
        sortBy: action,
      }));
    } else {
      this.setState({ isOrderByAscending: true, sortBy: action });
    }
  };

  public onOpenCreateLink = () => {
    this.props.router.push({
      component: <LinkPicker callback={this.props.createLinkCallback} />,
      props: {},
      title: <FormattedMessage id="links.new_link" defaultMessage="New Link" />,
    });
  };

  public renderOrderCaretIcon = (): JSX.Element => {
    const orderIcon = this.state.isOrderByAscending ? "caret-down" : "caret-up";
    return <Icon className="caret-order" name={orderIcon} />;
  };

  public linkContentPickerItemCallBack = (
    action: LinkContentPickerItemCallBackActions,
  ) => {
    const { refetch } = this.props;
    switch (action) {
      case LinkContentPickerItemCallBackActionsEnum.REFETCH:
        refetch();
        break;
      default:
        // nothing
        break;
    }
  };

  public renderLinkItem = (): JSX.Element[] => {
    const { links } = this.props;
    return links.map((link) => (
      <LinkPickerItem
        className="media-item"
        key={`playlist-${link && link.id}`}
        onClick={() => this.onToggleSelection(link as Link)}
        selected={this.state.selectedIds.includes(link && link.id)}
      >
        <LinkContentPickerItem
          link={link as Link}
          callBack={this.linkContentPickerItemCallBack}
          secureMediaPolicy={this.context.secureMediaPolicy}
        />
      </LinkPickerItem>
    ));
  };

  public render() {
    const {
      links,
      loading,
      renderFetchMorePickerButton,
      clearSearch,
      updateSearchString,
      searchString,
      query,
    } = this.props;
    const canCreateLink = this.context.currentPermissions.validateCurrentSpace(
      "link",
      "create",
    );
    const shouldShowEmptyState =
      !loading && links.length === 0 && searchString.length === 0;
    return (
      <Styled className="media-content link">
        <div className="content-header">
          <Search
            className={classNames({ "media-search-box": canCreateLink })}
            onClear={clearSearch}
            onChange={(_, data) => updateSearchString(data.value)}
            placeholder="Search Links"
            showNoResults={false}
            value={searchString}
          />
          {!isOrgWidePath() && canCreateLink && (
            <Button
              primary
              onClick={this.onOpenCreateLink}
              data-testid="new-link-button"
            >
              <FormattedMessage id="links.new_link" defaultMessage="New Link" />
            </Button>
          )}
        </div>
        <div className="container">
          <div className="media-item-header">
            <div className="media-core">
              <div
                className="media-alpha"
                onClick={() =>
                  this.toggleSortAndOrderMediaItem(SortingActions.SORT_BY_NAME)
                }
              >
                <FormattedMessage
                  id="subheader.label.name"
                  defaultMessage="Name"
                />
                {this.state.sortBy === SortingActions.SORT_BY_NAME &&
                  this.renderOrderCaretIcon()}
              </div>
              <div
                className="media-starred"
                onClick={() =>
                  this.toggleSortAndOrderMediaItem(
                    SortingActions.SORT_BY_STARRED,
                  )
                }
              >
                <FormattedMessage
                  id="subheader.label.starred"
                  defaultMessage="Starred"
                />
                {this.state.sortBy === SortingActions.SORT_BY_STARRED ? (
                  this.renderOrderCaretIcon()
                ) : (
                  <div className="empty-icon" />
                )}
              </div>
              <div className="media-type">
                <FormattedMessage
                  id="subheader.label.type"
                  defaultMessage="Type"
                />
              </div>
              <div className="media-url">
                <FormattedMessage
                  id="subheader.label.url"
                  defaultMessage="URL"
                />
              </div>
              <div className="media-tags">
                <FormattedMessage
                  id="subheader.label.tags"
                  defaultMessage="Tags"
                />
              </div>
            </div>
          </div>
          <div className="layout-list" id="scrollableDiv">
            {searchString.length > 0 ? (
              <SearchLinkContentPicker
                searchTerms={query}
                isMultipleSelect={this.props.isMultipleSelect}
                spaceId={this.props.spaceId}
                callback={this.props.callback}
                createLinkCallback={this.props.createLinkCallback}
              />
            ) : links.length > 0 ? (
              <>
                {this.renderLinkItem()}
                {renderFetchMorePickerButton}
              </>
            ) : null}
          </div>
          {shouldShowEmptyState && (
            <EmptyState section="link" className="empty">
              <h3>
                <FormattedMessage
                  id={
                    canCreateLink
                      ? `links.link_empty.no_content`
                      : `links.link_empty.no_content_no_edit`
                  }
                  defaultMessage="Want to show a website or dashboard?"
                />
              </h3>
              <p>
                <FormattedMessage
                  id={
                    canCreateLink
                      ? `links.link_empty.help_text`
                      : `links.link_empty.help_text_no_edit`
                  }
                  defaultMessage="Add any web link or URL here to add a live site to your Playlist or Channel. For dashboards, see the Dashboards feature for more info."
                />
              </p>
            </EmptyState>
          )}
          {loading && <LoaderBar />}
        </div>
      </Styled>
    );
  }
}

export default withData(LinkContentPicker);
