import {
  Folder,
  FolderByIdDocument,
  FolderByIdQuery,
  FolderByIdQueryVariables,
  UpdateFolderByIdMutationVariables,
  useUpdateFolderByIdMutation,
} from "../../../types.g";
import { cloneDeep } from "lodash";
import { MediaOrderBy, Typenames } from "../../../constants/constants";
import { DataValue } from "@apollo/client/react/hoc";

export interface RenameFolderParams {
  folder: Folder;
  updatedName: string;
}

export interface RenameFolder {
  renameFolder: (params: RenameFolderParams) => Promise<any>;
}

export function useRenameFolder(): RenameFolder {
  const [updateFolderById] = useUpdateFolderByIdMutation();
  const renameFolder = async ({ folder, updatedName }: RenameFolderParams) => {
    const updateMediaFolder: UpdateFolderByIdMutationVariables = {
      input: {
        folderId: folder.id,
        name: updatedName,
        parentId: folder.parentId,
      },
    };
    return updateFolderById({
      optimisticResponse: {
        __typename: Typenames.Mutation,
        updateFolderById: {
          __typename: Typenames.UpdateFolderByIdPayload,
          folder: {
            ...folder,
            __typename: Typenames.Folder,
            name: updatedName,
          },
        },
      },
      update: (proxy) => {
        const queryVar: FolderByIdQueryVariables = {
          folderId: folder.parentId,
          fileOrderBy: [MediaOrderBy.FILE_CREATED_AT_DESC],
          folderOrderBy: [MediaOrderBy.FOLDER_CREATED_AT_DESC],
          endCursor: null,
        };

        const cacheData = proxy.readQuery({
          query: FolderByIdDocument,
          variables: queryVar,
        }) as DataValue<FolderByIdQuery>;
        const cloneCacheData = cloneDeep(cacheData);
        if (cloneCacheData?.folderById) {
          cloneCacheData.folderById.foldersByParentId.nodes =
            cloneCacheData.folderById.foldersByParentId.nodes.map((item) => {
              return item.id !== folder.id
                ? item
                : { ...item, name: updatedName };
            });
          proxy.writeQuery({
            data: cloneCacheData,
            query: FolderByIdDocument,
            variables: queryVar,
          });
        }
      },
      variables: updateMediaFolder,
    });
  };

  return { renameFolder };
}
